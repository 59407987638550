import * as type from "./type";
import produce from "immer";

const initialState = {
  getVendor: [],
  likeVender: null,
  removeVendor: null,
  vendor: null,
};
let vendorList = [];

const VendorUserReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // case type.GET_VENDER_REQUEST:
      //     return { ...state, getVendor: null };
      case type.GET_VENDER_SUCCESS: {
        if (!vendorList.includes(action?.payload?.offset)) {
          draft.getVendor = draft.getVendor?.concat(action.payload.list);
        }
        break;
      }
      case type.CLEAR_REPONSE: {
        draft.getVendor = [];
        vendorList = [];
        break;
      }
      case type.LIKE_VENDER_REQUEST:
          return { ...state, likeVender: null };
      case type.LIKE_VENDER_SUCCESS:
        return { ...state, likeVender: action.payload };
      default:
        return {
          ...state,
        };
    }
  });
};
export default VendorUserReducer;
