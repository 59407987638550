import * as type from "./type";

const initialState = {
  documentList: null,
};

const AgreementFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_DOCUMENT_LIST_SUCCESS:
      return { ...state, documentList: action.payload };

    default:
      return {
        ...state,
      };
  }
};
export default AgreementFormsReducer;
