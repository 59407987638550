import React from "react";
import { Badge, Button } from "reactstrap";
import { CloseSvg, RightSvg } from "../../../components/CommonSvg";

export default function ViewAchDetails({
    modalData,
    changeStatusAchRequest, dispatchData,
    onModalDismiss,
}) {
    return (
        <div className={modalData.previousRoutingNumber && modalData.routingNumber ? "Previous" : "current"}>
            <div className="ach-modal_content">
                {modalData.previousRoutingNumber && (
                    <div className="ach_details_body">
                        <h4>
                            {modalData.previousRoutingNumber && modalData.routingNumber
                                ? "Previous Record"
                                : "current Record"}
                        </h4>
                        <ul>
                            <li>
                                <label>Routing No. :</label>
                                <span>{modalData.previousRoutingNumber}</span>
                            </li>
                            <li>
                                <label>Account No. :</label>
                                <span>{modalData.previousAccountNumber}</span>
                            </li>
                            <li>
                                <label>FEIN No. :</label>
                                <span>{modalData.previousChequeNumber}</span>
                            </li>
                            <li>
                                <label>Bank Name :</label>
                                <span>{modalData.previousBankName}</span>
                            </li>
                        </ul>
                        <div className="ach_check_img">
                            <h3>Check Image :</h3>
                            <div className="d-flex justify-content-center">
                                <img src={modalData.achManagement[0]?.previousDocument} />
                            </div>
                        </div>
                    </div>
                )}
                {modalData.routingNumber && (
                    <div className="ach_details_body">
                        <h4>
                            {modalData.previousRoutingNumber && modalData.routingNumber
                                ? "Updated Record"
                                : "Current Record"}
                        </h4>
                        <ul>
                            <li>
                                <label>Routing No. :</label>
                                <span>{modalData.routingNumber}</span>
                            </li>
                            <li>
                                <label>Account No. :</label>
                                <span>{modalData.accountNumber}</span>
                            </li>
                            <li>
                                <label>FEIN No. :</label>
                                <span>{modalData.chequeNumber}</span>
                            </li>
                            <li>
                                <label>Bank Name :</label>
                                <span>{modalData.bankName}</span>
                            </li>
                        </ul>
                        <div className="ach_check_img">
                            <h3>Check Image :</h3>
                            <div className="d-flex justify-content-center">
                                <img src={modalData.achManagement[0]?.currentDocument} />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="ach_action text-center  mt-1">
                {modalData.achManagement[0]?.status === "PENDING" && (
                    <>
                        <Button
                            size="sm"
                            color="primary"
                            className="approved_btn"
                            onClick={() => {
                                onModalDismiss();
                                changeStatusAchRequest({ value: { status: "APPROVED", achManagementId: modalData.achManagement[0].achManagementId }, dispatchData: dispatchData });
                            }}
                        >
                            <RightSvg />
                            Approve
                        </Button>
                        <Button
                            size="sm"
                            color="primary"
                            className="reject_btn"
                            onClick={() => {
                                onModalDismiss();
                                changeStatusAchRequest({ value: { status: "REJECTED", achManagementId: modalData.achManagement[0].achManagementId }, dispatchData: dispatchData });

                            }}
                        >
                            <CloseSvg />
                            Reject
                        </Button>
                    </>
                )}
                {modalData.achManagement[0]?.status === "APPROVED" && (
                    <Badge color="primary" className="confirmed_badge"><RightSvg />APPROVED</Badge>
                )}
                {modalData.achManagement[0]?.status === "REJECTED" && (
                    <Badge color="danger" className="reject_badge"><CloseSvg />REJECTED</Badge>
                )}
            </div>
        </div>
    );
}
