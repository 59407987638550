import React, { useEffect, useState } from "react";
import { BackSvg, LikeSvg, Pdf_Svg } from "../../../components/CommonSvg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMembersPromotionViewDetailsRequest } from "../../../redux/frontend/promotion/action";
import { connect } from "react-redux";
import { getCardImage } from "../Home/Common";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { getValueByKey } from "../../../utils/authUtils";
import { getOptInStatusRequest } from "../../../redux/admin/optinhistory/action";
import { Button } from "reactstrap";
import Lightbox from "yet-another-react-lightbox";

function PromotionDetailsPage(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [memberPromotionData, setMemberPromotionData] = useState([]);
  const [index, setIndex] = useState();
  let maxIdObject = null;
  let maxId = -1;
  if (
    memberPromotionData?.data?.InterestedMemberOffers &&
    Array.isArray(memberPromotionData.data.InterestedMemberOffers)
  ) {
    for (const offer of memberPromotionData.data.InterestedMemberOffers) {
      const offerId = parseInt(offer.id);
      if (!isNaN(offerId) && offerId > maxId) {
        maxId = offerId;
        maxIdObject = offer;
      }
    }
  }

  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";
  useEffect(() => {
    props.getViewDetails({ id: id, hraMemberId: hraMemberId });
  }, [id]);

  useEffect(() => {
    if (props?.memberPromotionData) {
      setMemberPromotionData(props?.memberPromotionData);
    }
  }, [props?.memberPromotionData]);
  const handleNavigation = () => {
    if (state?.isPromotionPage) {
      navigate("/promotion");
    } else {
      navigate("/home");
    }
  };
  const optStatus = (product) => {
    let values = {};
    if (maxIdObject?.id) {
      values = {
        hraMemberId: hraMemberId,
        promotionalOfferId: id,
        interestedMemberId: maxIdObject?.id,
        isActive: maxIdObject?.isActive === true ? false : true,
      };
    } else {
      values = {
        hraMemberId: hraMemberId,
        promotionalOfferId: id,
      };
    }
    props.getOptInStatusRequest({
      optin: values,
      status: "promationDeatils",
      calldata: { id: id, hraMemberId: hraMemberId },
    });
  };
  const slides = memberPromotionData?.data?.PromotionalOffersOthers.filter(
    (item) => item?.documentPath && !item.documentPath?.endsWith(".pdf")
  ).map((item) => ({
    src: item.documentPath,
  }));
  const LightBoxData =
    memberPromotionData?.data?.PromotionalOffersOthers.filter(
      (item) => item?.documentPath && !item.documentPath?.endsWith(".pdf")
    );

  return (
    <>
      <div className="container">
        <div className="section-head">
          <div>
            <button
              className="back_btn"
              title="back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <BackSvg />
            </button>
            <h3>{memberPromotionData?.data?.title}</h3>
          </div>
        </div>

        <div className="promotion-detail-box">
          <div className="promotion-detail-data">
            <div className="img-box">
              {memberPromotionData?.data?.PromotionalOffersOthers?.length >
                0 && (
                  <img
                    src={getCardImage(memberPromotionData?.data)}
                    alt="Promotion Detail Image"
                  />
                )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="like-expire-box align-item-center bottom_btn">
                <Button
                  title="Opt-In"
                  color="primary"
                  className={
                    maxIdObject?.isActive === true ? "mt-0 active" : "mt-0"
                  }
                  onClick={async () => {
                    const resp = await commonConfirmBox(
                      maxIdObject?.isActive
                        ? `Are you sure to Opt-Out ${memberPromotionData?.data?.title} ?`
                        : `Are you sure to Opt-In ${memberPromotionData?.data?.title}?`,
                      "Confirm",
                      "Cancel"
                    );
                    if (resp) {
                      optStatus(memberPromotionData?.data);
                    }
                  }}
                >
                  <LikeSvg /> {maxIdObject?.isActive ? "Opt-Out" : " Opt-In"}
                </Button>

              </div>
              <p className="promotion-expire-date mb-0">
                Expiry date :{" "}
                <span>
                  {
                    memberPromotionData?.data?.endDate
                  }
                </span>
              </p>

            </div>
            {memberPromotionData.representiveDetails && (
              <div className="promotion-contact">
                <p>
                  Representative Name:{" "}
                  <b>{memberPromotionData.representiveDetails.userName}</b>
                </p>
                <p>
                  {memberPromotionData.representiveDetails.contactNumber && (
                    <>
                      Contact No:{" "}
                      <b>
                        {memberPromotionData.representiveDetails.contactNumber}
                      </b>
                    </>
                  )}
                </p>
              </div>
            )}

            <p>{memberPromotionData?.data?.description} </p>
            <div className="requirements-list">
              <ul>
                {memberPromotionData?.data?.customFields?.map((item, index) => {
                  if (item?.label && item?.value) {
                    return (
                      <li key={index}>
                        <p>
                          <span>{item.label} : </span>
                          {item.value}
                        </p>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>

            <div className="btn-box">
              {memberPromotionData?.data?.PromotionalOffersOthers?.map(
                (data, index) => {
                  return (
                    data?.documentType === "DOCUMENT" && (
                      <div className="hra-form-box pdf-view-box" key={index}>
                        <button
                          className="btn btn-primary"
                          title="View Document"
                          onClick={() =>
                            window.open(data?.documentPath, "_blank")
                          }
                        >
                          <Pdf_Svg /> View Document
                        </button>
                      </div>
                    )
                  );
                }
              )}
            </div>
          </div>

          <ul className="promotion-detail-images">
            {LightBoxData?.map((item, current) => {
              return (
                <li key={item.id} onClick={() => setIndex(current)}>
                  <img src={item.documentPath} alt="Product image" />
                </li>
              );
            })}
            <Lightbox
              index={index}
              slides={slides}
              open={index >= 0}
              currentImage={index}
              onClickNext={() => setIndex(index + 1)}
              onClickPrev={() => setIndex(index - 1)}
              close={() => setIndex(-1)}
            />
          </ul>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ MemberPromotions }) => {
  let memberPromotionData = [];
  if (
    MemberPromotions?.memberPromotionDetails &&
    MemberPromotions?.memberPromotionDetails?.data
  ) {
    memberPromotionData = MemberPromotions.memberPromotionDetails?.data;
  }
  return {
    memberPromotionData: memberPromotionData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getViewDetails: (data) =>
      dispatch(getMembersPromotionViewDetailsRequest(data)),
    getOptInStatusRequest: (data) => dispatch(getOptInStatusRequest(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionDetailsPage);
