import {

    GET_FORGOT_PASSWORD_FAILURE,
    GET_FORGOT_PASSWORD_REQUEST,
    GET_FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
} from "./type";

export const getForgotRequest = (cred) => {
    return {
        type: GET_FORGOT_PASSWORD_REQUEST,
        payload: cred,
    };
};
export const getForgotSuccess = (cred) => ({
    type: GET_FORGOT_PASSWORD_SUCCESS,
    payload: cred,
});
export const getForgotFailuer = (cred) => ({
    type: GET_FORGOT_PASSWORD_FAILURE,
    payload: cred,
});

export const resetPasswordRequest = (email) => ({
    type: RESET_PASSWORD_REQUEST,
    payload: email,
});

export const resetPasswordSuccess = (message) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
});

