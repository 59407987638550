import React from "react";
import { Col, Row } from "reactstrap";
import PromotionCard from "./PromotionCard";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { useState } from "react";
import { RoleWiseModule } from "../../../utils/generalUtils";
import {
    AdminAccess,
    MarketingAccess,
    StaffAccess,
} from "../../../constant/commonConstants";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";
import NoDataFound from "../../../components/NotFound/NoDataFound";
import AssignPromation from "./AssignPromation";

export default function PromotionData({
    dataPerPage,
    setCurrentPage,
    currentPage,
    setDataPerPage,
    modalView,
    totalRecords,
    AssignPromotionStatus,
    isAssigned,
    dispatchData,
    promationStatus,
    setModaleView,
    promationId,
    setPromationData,
    setPromationId,
    promationData,
    setIsAssigned,
    promotionsList,
}) {
    const [editPromationData, setEditPromationData] = useState([]);
    const isAccess = RoleWiseModule(AdminAccess, MarketingAccess) ? true : false;
    const isAccessedStaff = RoleWiseModule(StaffAccess, AdminAccess) ? true : false;

    return (
        <>
            <Row className="">
                <Col lg={12}>
                    <ul className="Promotion_section">
                        {promotionsList &&
                            promotionsList.map((data, index) => (
                                <PromotionCard
                                    key={index}
                                    setIsAssigned={setIsAssigned}
                                    promationData={promationData}
                                    setPromationId={setPromationId}
                                    promationId={promationId}
                                    setPromationData={setPromationData}
                                    data={data}
                                    setEditPromationData={setEditPromationData}
                                    isAccess={isAccess}
                                    isAccessedStaff={isAccessedStaff}
                                    setModaleView={setModaleView}
                                    promationStatus={promationStatus}
                                    dispatchData={dispatchData}
                                />
                            ))}
                    </ul>
                </Col>
            </Row>

            {modalView && (
                <CustomisedModal
                    modalView={modalView}
                    customClass={
                        promationId.length > 1
                            ? "category-modal modal-lg"
                            : "category-modal "
                    }
                    modalName={"Assign Promotion"}
                    onModalDismiss={() => {
                        setModaleView(false);
                        setPromationId([]);
                        setEditPromationData([])
                        setPromationData([]);
                    }}
                >
                    <AssignPromation
                        onModalDismiss={() => {
                            setModaleView(false);
                            setPromationId([]);
                            setPromationData([]);
                            setEditPromationData([])
                        }}
                        isAssigned={isAssigned}
                        setPromationData={setPromationData}
                        editPromationData={isAssigned === true ? editPromationData : []}
                        setEditPromationData={setEditPromationData}
                        promationData={promationData}
                        AssignPromotionStatus={AssignPromotionStatus}
                        promationId={promationId}
                        AssignPromationId={editPromationData[0]?.id}
                        dispatchData={dispatchData}
                    />
                </CustomisedModal>
            )}
            {totalRecords > 0 ? (
                <CommonPagination
                    dataPerPage={dataPerPage}
                    totalData={totalRecords}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setDataPerPage={setDataPerPage}
                />
            ) : (
                <NoDataFound />
            )}
        </>
    );
}
