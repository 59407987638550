import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  AddSvg,
  BackSvg,
  CloseSvg,
  FileIconSvg,
  FilterSvg,
  SearchSvg,
} from "../../CommonSvg";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PromationFilter from "../../../pages/Admin/Promotion/PromationFilter";
import UserFilter from "../../../pages/Admin/Users/UserFilter";
import MemberFilter from "../../../pages/Admin/Members/MemberFilter";
import AchFilter from "../../../pages/Admin/Ach/AchFilter";
import RebateFilter from "../../../pages/Admin/Rebate/RebateFilter";
import NewsLetterFilter from "../../../pages/Admin/NewsLetter/Filter";
import SupportFilter from "../../../pages/Admin/SupportRequest/SupportFilter";
export default function TableHeader(props) {
  const { selectedStatus, setSelectedStatus } = props;
  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const {
    setExportData,
    hedingName,
    buttonName = "Create",
    buttonNameModal,
    component,
    searchBar,
    updateData,
    dataPerPage,
    setSearchData,
    Filter,
    endDate,
    setEndDate,
    multipalAssign,
    multipalAssignName,
    multipalAssignlData,
    addModalData,
    linkUrl,
    filterType,
    setExportFilterData,
    currentPage,
    startDate,
    setStartDate,
    setCountyId,
    setCityId,
    setStateId,
    setAssignedValue,
    setRoleId,
    exportData,
    exportDataHandel,
    promotionModal,
    promotionModalButton,
    activeTab,
    data,
    setSelectedClass,
    selectedClass,
    selectedYear,
    setSelectedYear,
    selectedQuater,
    setSelectedQuater,
    searchData,
    cityId,
    back,
    settings,
    filterBody,
    filterView,
  } = props;
  const handelShowFIlter = () => {
    setIsFilter(!isFilter);
  };
  useEffect(() => {
    if (filterView === true) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filterView]);
  return (
    <>
      <div className="section-head">
        {hedingName && (
          <div className="back-arrow_button">
            {back && (
              <Button onClick={back} outline>
                <BackSvg />
              </Button>
            )}

            <h3>{hedingName}</h3>
          </div>
        )}

        <div className="header-action-item">
          {settings}
          {buttonNameModal && addModalData && (
            <button
              className="creat_btn btn btn-secondary"
              onClick={addModalData}
              component={component}
            >
              <AddSvg />
              {buttonNameModal}
            </button>
          )}

          {promotionModalButton && (
            <button
              className="creat_btn btn btn-secondary"
              onClick={promotionModal}
            >
              Create Store Group
            </button>
          )}

          {exportData && (
            <button
              className="creat_btn btn btn-secondary"
              onClick={exportDataHandel}
            >
              <FileIconSvg />
              Export Data
            </button>
          )}
          {multipalAssign > 1 && (
            <button
              className="creat_btn btn btn-secondary"
              onClick={multipalAssignlData}
            >
              {multipalAssignName}
            </button>
          )}
          {buttonName && linkUrl && (
            <Link
              className="creat_btn btn btn-secondary"
              to={linkUrl}
              component={component}
            >
              <AddSvg />
              {buttonName}
            </Link>
          )}
          {searchBar && (
            <div className="search_box">
              <Button
                color="link"
                className="p-0"
                onClick={() => {
                  setSearchData(search);
                }}
              >
                <SearchSvg />
              </Button>

              {search && (
                <Button
                  color="link"
                  className="p-0 close-btn"
                  onClick={() => {
                    setSearch("");
                    setSearchData("");
                    if (setExportFilterData) {
                      setExportFilterData(false);
                    }

                    if (setExportData) {
                      setExportData(false);
                    }
                    updateData({
                      searchText: "",
                      pagination: {
                        limit: dataPerPage,
                        page: currentPage,
                        orderKey: "createdDate",
                        orderBy: "ASC",
                      },
                      filter: {},
                    });
                  }}
                >
                  <CloseSvg />
                </Button>
              )}

              <input
                name="searchbox"
                onChange={(e) => {
                  setSearch(e.target.value);

                  if (setExportFilterData) {
                    setExportFilterData(true);
                  }

                  if (setExportData) {
                    setExportData(true);
                  }

                  if (e.target.value?.length <= 0) {
                    updateData({
                      searchText: "",
                    });
                    setSearch("");
                    setSearchData("");

                    if (setExportData) {
                      setExportData(false);
                    }

                    if (setExportFilterData) {
                      setExportFilterData(false);
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateData({
                      searchText: search,
                    });

                    if (setExportFilterData) {
                      setExportFilterData(true);
                    }

                    if (setExportData) {
                      setExportData(true);
                    }
                    setSearchData(search);
                  }
                }}
                placeholder="Search box"
                value={search}
              />
            </div>
          )}
          {Filter === true && (
            <Dropdown
              className="my-tooltip filter_btn"
              data-tooltip-content={"Apply Filter"}
              onClick={handelShowFIlter}
            >
              <Dropdown.Toggle id="dropdown-basic">
                <FilterSvg />
              </Dropdown.Toggle>
            </Dropdown>
          )}
        </div>
      </div>
      {filterType === "RebateFilter" && (
        <RebateFilter
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          selectedQuater={selectedQuater}
          setSelectedQuater={setSelectedQuater}
          searchData={searchData}
          setSearchData={setSearchData}
          setCityId={setCityId}
          setStateId={setStateId}
          cityId={cityId}
          setCountyId={setCountyId}
          data={data}
        />
      )}
      {isFilter === true && filterType === "promotion" && (
        <PromationFilter
          setCountyId={setCountyId}
          setAssignedValue={setAssignedValue}
          setCityId={setCityId}
          setStateId={setStateId}
          setStartDate={setStartDate}
          activeTab={activeTab}
          setEndDate={setEndDate}
          endDate={endDate}
          startDate={startDate}
          setExportData={setExportData}
          handelShowFIlter={handelShowFIlter}
        />
      )}
      {isFilter === true && filterType === "newsletter" && (
        <NewsLetterFilter
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
          startDate={startDate}
          handelShowFIlter={handelShowFIlter}
        />
      )}
      {isFilter === false && filterType === "Members" && (
        <MemberFilter
          setCountyId={setCountyId}
          setAssignedValue={setAssignedValue}
          setCityId={setCityId}
          setStateId={setStateId}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setExportFilterData={setExportFilterData}
          endDate={endDate}
          startDate={startDate}
          handelShowFIlter={handelShowFIlter}
        />
      )}
      {isFilter === true && filterType === "AchManagement" && (
        <AchFilter
          selectedStatus={selectedStatus}
          handelShowFIlter={handelShowFIlter}
          setSelectedStatus={setSelectedStatus}
        />
      )}
      {isFilter === true && filterType === "userHeader" && (
        <UserFilter
          setRoleId={setRoleId}
          handelShowFIlter={handelShowFIlter}
          setExportFilterData={setExportFilterData}
        />
      )}
      {/* {isFilter === true && filterType === "supportHeader" && (
   
      )} */}
      {isFilter === true &&  <>{filterBody}</>}
    </>
  );
}
