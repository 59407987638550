import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";
import { Card, CardBody, Col, Row } from "reactstrap";
import Iframe from "react-iframe";
import { useEffect, useState } from "react";
import { Date_Svg, PdfSvg } from "../../../components/CommonSvg";
import { isImage, isPDF } from "../../../utils/generalUtils";
import { connect } from "react-redux";
import { detailsNewsLetterRequest, getNewsLetterRequest } from "../../../redux/admin/newsletter/action";
import { getValueByKey } from "../../../utils/authUtils";

const NewsLetterDetail = ({ detail, detailsNewsLetter, NewsLetterData, GetNewsLetters }) => {
  const location = useLocation();
  const { id } = useParams();
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";
  const navigate = useNavigate();
  useEffect(() => {
    detailsNewsLetter({
      newsLetterId: id,
    });
    GetNewsLetters({
      offset:  0 ,
      hraMemberId: hraMemberId,
    })
  }, []);
  useEffect(() => {
    detailsNewsLetter({
      newsLetterId: id,
    });
  }, [id]);
  const additionalData = detail?.data;
  // const NewsLetterData = location.state.NewsLetterData;
  const [googleViewerUrl, setGoogleViewerUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const loadSuccess = () => {
    setIsLoading(false);
  };
  const screenWidth = window.screen.width;
  useEffect(() => {
    setRefresh(true);
    setIsLoading(true);
    if (additionalData?.url) {
      setGoogleViewerUrl(additionalData.url);
    }
    if (additionalData?.documentPath) {
      if (screenWidth > 786) {
        setGoogleViewerUrl(additionalData.documentPath);
      } else {
        const encodedUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(additionalData.documentPath)}`;
        setGoogleViewerUrl(encodedUrl);
      }
    }
    setRefresh(false);
  }, [additionalData?.documentPath]);
  return (
    <>
      <div className="container">
        <CommonSortHeader
          PromotionsName={
            additionalData?.title ? additionalData.title : "Newsletter Details"
          }
          BackTo={"/newsletter"}
        />
        <Row className="newsletter-detail">
          <Col className="col-12 col-md-12 col-sm-12 col-lg-8">
            {!isImage(googleViewerUrl) ? (
              <div id="pdfContainer" className="agreemet_pdf_container">
                {isLoading && (
                  <div className="w-100 text-center agreement_loader">
                    <div className="spinner-border" role="status"></div>
                  </div>
                )}
                {refresh === false && googleViewerUrl && (
                  <Iframe
                    type="application/pdf"
                    src={googleViewerUrl}
                    width="100%"
                    height="600px"
                    onLoad={loadSuccess}
                  >
                    <p>
                      Your browser does not support embedded PDFs. You can
                      download the PDF{" "}
                      <a href="" download>
                        here
                      </a>
                      .
                    </p>
                  </Iframe>
                )}
              </div>
            ) : (
              <div className="img-box">
                <img width="80" src={additionalData?.documentPath} />
              </div>
            )}

            <Card>
              <CardBody>
                <div className="card_date">
                  <Date_Svg />
                  <p>{additionalData?.createdDate}</p>
                </div>
                <h4>{additionalData?.title}</h4>
                <p>{additionalData?.description}</p>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-12 col-md-12 col-sm-12 col-lg-4">
            <div className="other-news-letter">
              <h4>Newsletter Catalog</h4>
              <ul>
                {NewsLetterData?.length > 0 &&
                  NewsLetterData?.slice(0, 5)?.map((data) => {
                    return (
                      <li>
                        <a 
                          onClick={() => {
                            navigate(`/newsletter/${data.Newsletter.id}`, {
                              state: {
                                NewsLetterData: NewsLetterData
                              },
                            });
                          }}
                          >
                          <div className="img-box">
                            {isImage(data.Newsletter.documentPath) ? (
                              <img width="80" src={data.Newsletter.documentPath} />
                            ) : (
                              <span className="docfile">
                                <PdfSvg />
                              </span>
                            )}
                          </div>
                          <div className="news-letter-content">
                            <h4>{data.Newsletter.title}</h4>
                            <p>{data.Newsletter.description}</p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                {/* <li>
                  <a href="">
                    <div className="img-box">
                      <span className="docfile">
                        <PdfSvg />
                      </span>
                    </div>
                    <div className="news-letter-content">
                      <h4>What is Lorem Ipsum?</h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                  </a>
                </li> */}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = ({ NewsLetter }) => {
  let detail;
  if (NewsLetter?.newsLetterDetail !== null) {
    detail = NewsLetter.newsLetterDetail;
  }
  let NewsLetterData = [];
  if (NewsLetter?.newsLetterList?.length > 0) {
    NewsLetterData = NewsLetter.newsLetterList;
  }

  return {
    detail, NewsLetterData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    detailsNewsLetter: (data) => dispatch(detailsNewsLetterRequest(data)),
    GetNewsLetters: (data) => dispatch(getNewsLetterRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterDetail);
