import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import {
  getClassRequest,
  getQuarterRequest,
  getYearRequest,
} from "../../../redux/admin/rebate/action";
import { getValueByKey } from "../../../utils/authUtils";
import { CloseSvg, Date_Svg, SearchSvg, SyncSvg } from "../../../components/CommonSvg";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import {
  getClassSyncReq,
  getRebateSyncReq,
} from "../../../redux/admin/sync/action";
import { SUCCESS } from "../../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import {
  getCityListRequest,
  getCountyListRequest,
  getStateListRequest,
} from "../../../redux/admin/location/action";
import { getDateTime } from "../../../helper/DataTimeHelpers";
import { getDateTimeZone } from "../../../helper/timeZoneBase";
function RebateFilter({
  getClassRequest,
  ClassList,
  QuarterList,
  YearList,
  setSelectedClass,
  selectedClass,
  getYearRequest,
  getQuarterRequest,
  selectedYear,
  setSelectedYear,
  selectedQuater,
  zohoToken,
  setSearchData,
  zohoTokenTime,
  setSelectedQuater,
  getClassSyncRequest,
  getRebateSyncRequest,
  setCityId,
  setStateId,
  setCountyId,
  CityList,
  StateList,
  CountyList,
  GetCityList,
  GetStateList,
  GetCountyList,
  SyncData,
  data, cityId
}) {
  const [search, setSearch] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  useEffect(() => {
    getYearRequest();
  }, []);
  useEffect(() => {
    if (selectedYear) {
      getClassRequest({
        rebateYear: selectedYear ? selectedYear : "",
      });
      getQuarterRequest();
      GetCountyList();
      GetCityList();
      GetStateList();
    }

  }, [selectedYear]);

  useEffect(() => {
    if (zohoToken || zohoTokenTime) {
      localStorage.setItem("zohoToken", zohoToken);
      localStorage.setItem("zohoTokenTime", zohoTokenTime);
    }
  }, [zohoToken, zohoTokenTime]);


  return (
    <>
      <Card className="filter_section rebatefilter_section">
        <CardBody className="py-2">
          <div className="filter-section-head">
            <h6 className="separate-head">Filter</h6>
            <div className="btn-box">
              {/* <div className="sync-box">
                <Button
                  onClick={async () => {
                    const response = await commonConfirmBox(
                      "Are you sure you want to start Class sync?"
                    );
                    if (response) {
                      getClassSyncRequest({
                        zohoToken: getValueByKey("zohoToken")
                          ? getValueByKey("zohoToken")
                          : "",
                        zohoTokenTime: getValueByKey("zohoTokenTime")
                          ? getValueByKey("zohoTokenTime")
                          : "",
                      });
                      ApiResponseMessage(
                        "Class Sync started successfully",
                        SUCCESS
                      );
                    }
                  }}
                  className="btn-link"
                >
                  {" "}
                  <SyncSvg /> 
                    <span>
                      Sync Rebate class
                    {SyncData?.zohoRebateClassHistory?.startTime && <small className="text-muted">Last sync: {getDateTime(SyncData?.zohoRebateClassHistory?.startTime)  }</small>}
                    </span>
                </Button>                
               </div> */}
              {selectedYear && data?.length > 0 && (
                <div className="sync-box ms-3">
                  <Button
                    color="primary"
                    onClick={async () => {
                      const response = await commonConfirmBox(
                        "Are you sure you want to start Rebate sync?"
                      );
                      if (response) {
                        getRebateSyncRequest({
                          rebateYear: selectedYear,
                          userId: getValueByKey("user"),
                        });
                        // ApiResponseMessage(
                        //   "Rebate Sync started successfully",
                        //   SUCCESS
                        // );
                      }
                    }}
                  >
                    {" "}
                    <div>
                      <SyncSvg />
                      <span>
                        Sync Rebates
                        {SyncData?.zohoRebateDetailsHistory?.startTime && (
                          <small className="text-muted">
                            Last sync:{" "}
                            {getDateTimeZone(
                              SyncData?.zohoRebateDetailsHistory?.startTime
                            )}
                          </small>
                        )}
                      </span>
                    </div>
                    <span>
                      <Date_Svg/>
                      {SyncData?.zohoRebateDetailsHistory?.year && <>{SyncData?.zohoRebateDetailsHistory?.year}</>}
                    </span>
                  </Button>
                </div>
              )}{" "}
              <div className="search_box rebate_search mb-0">
                <Button
                  color="link"
                  className="p-0"
                  onClick={() => {
                    setSearchData(search);
                  }}
                >
                  <SearchSvg />
                </Button>
                {search?.length > 0 && (
                  <Button
                    color="link"
                    className="p-0 close-btn"
                    onClick={() => {
                      setSearch("");
                      setSearchData("");
                    }}
                  >
                    <CloseSvg />
                  </Button>
                )}
                <input
                  name="searchbox"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value?.length <= 0) {
                      setSearch("");
                      setSearchData("");
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSearchData(search);
                    }
                  }}
                  placeholder="Search box"
                  value={search}
                />
              </div>
            </div>
          </div>
          <>
            <form className="hra-create">
              <Row>
                <Col className="no-floating-label">
                  <FormGroup className="mb-2">
                    <Select
                      type="text"
                      id="Year"
                      name="Year"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={YearList}
                      // value={
                      //   YearList?.length > 0 &&
                      //   YearList?.filter(
                      //     (d) => d.value === selectedYear && d.label
                      //   )
                      // }
                      value={YearList?.find((d) => d.value === selectedYear)}
                      onChange={(selectedOption) => {
                        setSelectedYear(selectedOption?.value || null);
                        setSelectedClass();
                        setCityId()
                        setSelectedCity()
                        setSelectedState();
                        setStateId();
                        setSelectedCounty();
                        setCountyId();
                        // if (!selectedClass && !selectedQuater) {
                        //   setSelectedQuater(QuarterList[0]?.value);
                        // }
                        // if (!selectedOption) {
                        //   setSelectedQuater();
                        // }
                      }}
                      isClearable
                    />
                    <Label className="form-label" htmlFor="Year">
                      Year
                    </Label>
                  </FormGroup>
                </Col>
                <Col className="no-floating-label">
                  <FormGroup className="mb-2">
                    <Select
                      type="text"
                      id="class"
                      name="class"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={ClassList}
                      value={ClassList?.length > 0 && selectedClass ? ClassList?.find((d) => d.value === selectedClass) : ""}
                      onChange={(selectedOption) => {
                        if (selectedOption?.value) {
                          setSelectedClass(selectedOption.value);
                        } else {
                          setSelectedClass("");
                        }

                      }}
                    />
                    <Label className="form-label" htmlFor="class">
                      Class
                    </Label>
                  </FormGroup>
                </Col>

                {/* <Col sm={3} className="no-floating-label">
                  <FormGroup className="mb-2">
                    <Select
                      type="text"
                      id="Quarter"
                      name="Quarter"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={QuarterList}
                      // value={
                      //   QuarterList?.length > 0 &&
                      //   QuarterList.filter(
                      //     (d) => d.value === selectedQuater && d.label
                      //   )
                      // }
                      value={QuarterList?.find(
                        (d) => d.value === selectedQuater
                      )}
                      onChange={(selectedOption) => {
                        setSelectedQuater(selectedOption?.value || null);
                      }}
                    // isClearable
                    />
                    <Label className="form-label" htmlFor="Quarter">
                      Quarter
                    </Label>
                  </FormGroup>
                </Col> */}

                {/* <Col sm={3}>
                  <div className="search_box rebate_search mb-2">
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() => {
                        setSearchData(search);
                      }}
                    >
                      <SearchSvg />
                    </Button>
                    {search?.length > 0 && (
                      <Button
                        color="link"
                        className="p-0 close-btn"
                        onClick={() => {
                          setSearch("");
                          setSearchData("");
                        }}
                      >
                        <CloseSvg />
                      </Button>
                    )}

                    <input
                      name="searchbox"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (e.target.value?.length <= 0) {
                          setSearch("");
                          setSearchData("");
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSearchData(search);
                        }
                      }}
                      placeholder="Search box"
                      value={search}
                    />
                  </div>
                </Col> */}
                <Col className="no-floating-label">
                  <FormGroup className="m-0">
                    <Select
                      type="text"
                      id="cityId"
                      name="cityId"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={CityList}
                      value={selectedCity ? selectedCity : ""}
                      onChange={(selectedOption) => {
                        setCityId(selectedOption ? selectedOption.label : "");
                        // setExportData(true);
                        setSelectedCity(selectedOption);
                      }}
                      isClearable
                    />
                    <Label className="form-label" htmlFor="stateId">
                      City
                    </Label>
                  </FormGroup>
                </Col>
                <Col className="no-floating-label">
                  <FormGroup className="m-0">
                    <Select
                      type="text"
                      id="stateId"
                      name="stateId"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={StateList}
                      value={selectedState ? selectedState : ""}
                      onChange={(selectedOption) => {
                        setSelectedState(selectedOption);
                        // setExportData(true);
                        setStateId(selectedOption ? selectedOption.label : "");
                      }}
                      isClearable
                    />
                    <Label className="form-label" htmlFor="stateId">
                      State
                    </Label>
                  </FormGroup>
                </Col>
                <Col className="no-floating-label">
                  <FormGroup className="m-0">
                    <Select
                      type="text"
                      id="countyId"
                      name="countyId"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={CountyList}
                      value={selectedCounty ? selectedCounty : ""}
                      onChange={(selectedOption) => {
                        setSelectedCounty(selectedOption);
                        setCountyId(selectedOption ? selectedOption.label : "");
                      }}
                      isClearable
                    />
                    <Label className="form-label" htmlFor="stateId">
                      County
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <span className="text-danger">
                <b>Note :</b> Filter Year is Mandatory.
              </span>
            </form>
          </>
        </CardBody>
      </Card>
    </>
  );
}
const mapStateToProps = ({ location, rebateManagement, SyncReducer }) => {
  let ClassList = [];
  let QuarterList = [];
  let YearList = [];
  let zohoToken = null;
  let zohoTokenTime = null;
  let CityList;
  let StateList;
  let CountyList;
  let SyncData;

  if (rebateManagement?.getYear?.year?.length > 0) {
    YearList = rebateManagement.getYear.year?.map((data) => ({
      value: data,
      label: data,
    }));
  }

  if (rebateManagement?.getClass?.getRebateClass?.length > 0) {
    ClassList = rebateManagement.getClass.getRebateClass?.map((data) => ({
      value: data.rebateClassId,
      label: data.rebateClassName,
    }));
  }

  if (rebateManagement?.getQuarter?.quarter?.length > 0) {
    QuarterList = rebateManagement.getQuarter.quarter?.map((data) => ({
      value: data,
      label: data,
    }));
  }
  SyncData = rebateManagement?.getRebateList;

  if (
    location?.getCity?.list?.length > 0 &&
    location?.getState?.list?.length > 0 &&
    location?.getCounty?.list?.length > 0
  ) {
    CityList = location.getCity.list?.map((city) => ({
      value: city.id,
      label: city.cityName,
    }));
    StateList = location.getState.list?.map((city) => ({
      value: city.id,
      label: city.stateName,
    }));
    CountyList = location.getCounty.list?.map((city) => ({
      value: city.id,
      label: city.countyName,
    }));
  }

  if (
    rebateManagement?.getClass?.zohoToken ||
    rebateManagement?.getClass?.zohoTokenTime
  ) {
    zohoToken = rebateManagement?.getClass?.zohoToken;
    zohoTokenTime = rebateManagement?.getClass?.zohoTokenTime;
  }

  return {
    ClassList: ClassList?.length > 0 ? [...ClassList] : [],
    YearList: YearList,
    QuarterList: QuarterList,
    zohoToken: zohoToken,
    zohoTokenTime: zohoTokenTime,
    CityList: CityList,
    StateList: StateList,
    CountyList: CountyList,
    SyncData: SyncData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClassRequest: (data) => dispatch(getClassRequest(data)),
    getYearRequest: (data) => dispatch(getYearRequest(data)),
    getQuarterRequest: (data) => dispatch(getQuarterRequest(data)),
    getClassSyncRequest: (data) => dispatch(getClassSyncReq(data)),
    getRebateSyncRequest: (data) => dispatch(getRebateSyncReq(data)),
    GetCityList: () => {
      dispatch(getCityListRequest());
    },
    GetStateList: () => {
      dispatch(getStateListRequest());
    },
    GetCountyList: () => {
      dispatch(getCountyListRequest());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RebateFilter);
