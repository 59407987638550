import React from "react";
import supportimg from "../../../assets/images/support-img.png";
import {
  BackSvg,
  CallSvg,
  EmailSvg,
  EmailSvg2,
} from "../../../components/CommonSvg";
import { useNavigate } from "react-router-dom";

const Tickets = (props) => {
  const { handleEditModalToggle, SupportDetails, RepresentiveDetails } = props;
  const navigate = useNavigate();
  return (
    <>
      <div className="section-head">
        <div>
          <button
            className="back_btn"
            title="back"
            onClick={() => {
              navigate("/home");
            }}
          >
            <BackSvg />
          </button>
          <h3>Support Request</h3>
        </div>
        <div>
          <button
            className="btn btn-danger"
            title="Service Request"
            onClick={handleEditModalToggle}
          >
            Service Request
          </button>
        </div>
      </div>

      <div className="row support-list g-3">
        {RepresentiveDetails && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="support-data">
              <div>
                <span className="support_img">
                  <img
                    src={
                      RepresentiveDetails?.imagePath
                        ? RepresentiveDetails?.imagePath
                        : supportimg
                    }
                    alt="Image"
                  />
                </span>
                <p>Representative Contact</p>
              </div>
              <div>
                <h6>{RepresentiveDetails?.userName}</h6>
                <a
                  href={"tel:" + RepresentiveDetails?.contactNumber}
                  title={RepresentiveDetails?.contactNumber}
                  className="d-inline-flex mt-1 w-100"
                >
                  <CallSvg /> {RepresentiveDetails?.contactNumber}
                </a>
                {RepresentiveDetails?.emailId && (
                  <a
                    href={"mailto:" + RepresentiveDetails?.emailId}
                    title={RepresentiveDetails?.emailId}
                    className="d-inline-flex w-100"
                  >
                    <EmailSvg2 /> {RepresentiveDetails?.emailId}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}{" "}
        {SupportDetails?.length > 0 &&
          SupportDetails.map((d, index) => (
            <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
              {/* {!d.alternateNumber &&   */}
              <div
                className={
                  d.alternateNumber ? "support-data corporate" : " support-data"
                }
              >
                <div>
                  <span className="support_img">
                    <img
                      src={d.imagePath ? d.imagePath : supportimg}
                      alt="Image"
                    />
                  </span>
                  <p>{d.serviceName}</p>
                </div>
                <div>
                  {d.name && <h6>{d.name}</h6>}
                  {d?.alternateNumber ? (
                    <a
                      href={"tel:" + d?.alternateNumber}
                      title={d?.alternateNumber}
                      className="d-inline-flex mt-1 w-100"
                    >
                      <CallSvg /> {d?.alternateNumber}
                    </a>
                  ) : (
                    <a
                      href={"tel:" + d?.contactNumber}
                      title={d?.contactNumber}
                      className="d-inline-flex mt-1 w-100"
                    >
                      <CallSvg /> {d?.contactNumber}
                    </a>
                  )}
                  {d?.emailId && (
                    <a
                      href={"mailto:" + d?.emailId}
                      title={d?.emailId}
                      className="d-inline-flex w-100"
                    >
                      <EmailSvg2 /> {d?.emailId}
                    </a>
                  )}
                </div>
              </div>
              {/* } */}
              {/* {d.alternateNumber && <div
                // href={"tel:" + d.contactNumber}
                // title={d.contactNumber}
                className="support-data"
              >
                <div>
                  <span className="support_img">
                    <img src={d.imagePath ? d.imagePath : supportimg} alt="Image" />
                  </span>
                  <p>{d.serviceName}</p>
                </div>
                <div className="alternate_number">
                  <h6>{d.name}</h6>
                  <a href={"tel:" + d.contactNumber}  className="d-block"> <span>{d.contactNumber}</span></a>
                  {d.alternateNumber && <a href={"tel:" + d.alternateNumber}  className="d-block"> <span>{d.alternateNumber}</span></a>}
                </div>
              </div>}
        */}
            </div>
          ))}
      </div>
    </>
  );
};

export default Tickets;
