import moment from "moment";
export const getDateObjectWithValueFromMoment = (value) => {
  const Date = moment(value, "YYYY-MM-DD").format("MM-DD-YYYY");
  return Date;
};


export const getDateObjectWithValueFromMomentMMDDYYYY = (value) => {
  const Date = moment(value, "DD-MM-YYYY").format("MM-DD-YYYY");
  return Date;
};

export const getDateObjectWithValueYYYYMoment = (value) => {
  const Date = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
  return Date;
};
export const getDateObjectWithValueFromMomentPayload = (value) => {
  const Date = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
  return Date;
};

export const getDateObjectWithValueFromMomentTovalue = (value) => {
  return moment(value, "YYYY-MM-DD").toDate();
};
export const formatDateToMMDDYYYY = (date) => {
  return moment(date).format("MM-DD-YYYY");
};

export const getAmericaNewYorkTimeZoneDate = (Date) => {
  const date = moment(Date).tz('America/New_York').format('MM-DD-YYYY');
  return date;
};
export const getTodayDate = () => {
  const today = moment().endOf('day').format("MM-DD-YYYY");
  return today;
};
export const todayDate = () => {
  const today = moment().format("MM-DD-YYYY");
  return today;
};


export const getDateTimezone = (value) => {
  const datetime = moment(value);
  const formattedTime = datetime.format("HH:mm A");
  return formattedTime;
};
export const getDateTime = (value) => {
  const originalMoment = moment(value, 'MM-DD-YYYY HH:mm:ss');
  const formattedDateTimeString = originalMoment.format('MM-DD-YYYY HH:mm').tz('Asia/Kolkata');
  return formattedDateTimeString;
};


export function formatDateForApi(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}
