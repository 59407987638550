import Swal from "sweetalert2";

export const commonConfirmBox = async (
  title,
  confirmButtonText,
  cancelButtonText,
  Html = undefined
) => {
  const result = await Swal.fire({
    title: title,
    icon: "info",
    html: Html,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: confirmButtonText ? confirmButtonText : "Yes",
    confirmButtonAriaLabel: "Thumbs up, great!",
    cancelButtonText: cancelButtonText ? cancelButtonText : "No",
    cancelButtonAriaLabel: "Thumbs down",
  });
  return result.isConfirmed;
};

export const commonAleartBox = async (title) => {
  const result = await Swal.fire({
    icon: "error",
    title: title,
    // showCancelButton: true,
  });
  return result.isConfirmed;
};

export const commonOopsBox = async (
  title,
) => {
  const result = await Swal.fire({
    icon: "error",
    title: title,
  });

  return result.isConfirmed;
};
