import React from 'react';
import { Nav, NavItem, Navbar } from 'reactstrap';
import { HomeSvg, PromotionsSvg, SupportSvg, UserSvg } from '../../../CommonSvg';
import { NavLink } from 'react-router-dom';
class Footer extends React.Component {
  render() {
    return <footer>
      <div className="backend-footer">
        <p className="mb-0">
          &#169; {new Date().getFullYear()} - All rights reserved.
        </p>
      </div>
      <Navbar className='fix-footer'>
        <Nav>
          <NavItem>
            <NavLink className="nav-link" to="/home"><HomeSvg /> Home</NavLink>
          </NavItem>
          <NavItem className='promotion-story'>
            <NavLink className="nav-link" to="/promotion"> <PromotionsSvg /> Promotions</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/support"><SupportSvg /> Member support</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/profile"><UserSvg /> Profile</NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </footer>;
  }
}
export default Footer;
