import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { ACTIVE_INACTIVE_USER_REQUEST, ADD_USER_LIST_REQUEST, GET_USER_LIST_REQUEST } from "./type";
import { activeInactiveUserSuccess, addUserListSuccess, getUserListRequest, getUserListSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";


function* UserList({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/userList", data);
        if (res.status) {
            yield put(getUserListSuccess(res.data));
        } else {
            yield put(getUserListSuccess([]));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}

function* addUserList({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/user", data.values);
        if (res.status) {
            ApiResponseMessage(res.message, SUCCESS)
            yield put(addUserListSuccess(res));
            yield put(getUserListRequest(data.dispatchData))
        } else {
            yield put(addUserListSuccess(res));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}
function* activeInactiveUserList({ payload: data }) {
    const UserData = {
        isActive: data.isActive,
        userId: data.userId
    }
    try {
        const res = yield call(invokeApi, "post", "/userStatus", UserData);
        if (res.status) {
            yield put(activeInactiveUserSuccess(res));
            ApiResponseMessage(res.message, SUCCESS)
            yield put(getUserListRequest(data.dispatchData))
        } else {
            yield put(activeInactiveUserSuccess(res));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)

    }
}

function* watchLoginRequest() {
    yield takeLatest(GET_USER_LIST_REQUEST, UserList);
    yield takeLatest(ADD_USER_LIST_REQUEST, addUserList);
    yield takeLatest(ACTIVE_INACTIVE_USER_REQUEST, activeInactiveUserList);
}

function* UserSaga() {
    yield all([fork(watchLoginRequest)]);
}

export default UserSaga;
