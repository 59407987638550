import { CLEAR_SUPPORT_REQ, SUPPORT_REQUEST, SUPPORT_SUCCESS } from "./type";

const initialState = {
    support: null,
};

const SupportMemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPORT_REQUEST:
            return {
                ...state,
                support: null
            };
        case SUPPORT_SUCCESS:
            return {
                ...state,
                support: action.payload
            };
        case CLEAR_SUPPORT_REQ:
            return {
                ...state,
                support: null
            };
        default:
            return state;
    }
};

export default SupportMemberReducer;
