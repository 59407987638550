import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Label, FormGroup, FormText } from "reactstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import resetbg from "../../../../assets/images/reset_02.jpg";
import logosm from "../../../../assets/images/logo-dark.webp";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../../i18n/translator";
import { resetPasswordRequest } from "../../../../redux/admin/auth/forgot/action";
import { Email_Svg } from "../../../../components/CommonSvg";

function ResetPassword(props) {
  const { isApiSuccess } = props;
  const token = useParams();
  const [goToLogin, setGoToLogin] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const initialValues = {
    
    password: "",
    confirmPassword: "",
    
  };
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      let ResetVal = {
        token: token.id,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      };
      props.resetPassword(ResetVal);
      setGoToLogin(true);
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    if (isApiSuccess === true) {
      navigate("/admin/login");
    }
  }, [isApiSuccess !== false]);

  return (
    <>
      <div className="login-page">
        <Row>
          <Col className="login-img">
            <img alt="Reset Password" src={resetbg} />
          </Col>

          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched, handleSubmit, values }) => (
              <Col className="login-form reset_password">
                <div className="login-main">
                  <div className="loging-head">
                    <img alt="Logo" src={logosm} />
                  </div>
                  <div className="loging-content">
                    <h2>Reset Password</h2>
                    <div className="reset_email">
                      <Email_Svg />
                      <h6>{token.email}</h6>
                    </div>
                    <Form>
                      <FormGroup className="col-12 sticky_label">
                        <Field
                          id="password"
                          name="password"
                          placeholder="Password must contain minimum 6 characters"
                          className="form-control"
                          type="password"
                          maxLength={99}
                          minLength={6}
                        />
                        <Label className="form-label" htmlFor="password">
                          Password
                        </Label>
                        {validator.current.message(
                          __t("validations.auth.password_required"),
                          values.password,
                          "required",
                          {
                            messages: {
                              required: __t("validations.auth.password"),
                            },
                          }
                        )}
                      </FormGroup>

                      <FormGroup className="col-12 sticky_label">
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Password must contain minimum 6 characters"
                          className="form-control"
                          type="password"
                          maxLength={99}
                          minLength={6}
                        />
                        <Label className="form-label" htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        {validator.current.message(
                          __t("validations.auth.confirm_password"),
                          values.confirmPassword,
                          "required",
                          {
                            messages: {
                              required: __t("validations.auth.password"),
                            },
                          }
                        )}
                        <FormText
                          className="mb-2"
                          style={{ fontSize: "0.75em", float: "inline-start" }}
                        >
                          Password must contain at least 1 uppercase, 1
                          lowercase, 1 digit and 1 special symbol with minimum
                          length of 6 characters.
                        </FormText>
                      </FormGroup>

                      <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        onClick={handleSubmit}
                        block
                      >
                        Reset Password
                      </Button>
                    </Form>
                  </div>
                  <div className="policy-links">
                    <Link
                      to="https://www.hraga.com/terms-of-service"
                      className="d-inline-block"
                      target="_blank"
                      title="Terms of Service"
                    >
                      Terms of Service
                    </Link>
                    <Link
                      to="https://www.hraga.com/mbr-app-privacy-policy"
                      className="d-inline-block"
                      target="_blank"
                      title="Privacy Policy"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </Col>
            )}
          </Formik>
        </Row>
        <div className="login-footer">
          <p className="mb-0">
            &#169; {new Date().getFullYear()} - All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ forgot }) => {
  let isApiSuccess = false;

  if (forgot?.resetPassword?.status) {
    isApiSuccess = forgot.resetPassword.status;
  }
  return {
    isApiSuccess: isApiSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data) => {
      dispatch(resetPasswordRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
