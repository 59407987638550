import React, { useEffect, useState } from "react";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import { Card, CardBody, Col, Row, Container, Button } from "reactstrap";
import { getVendorDetailsRequest } from "../../../redux/admin/vendor/action";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Iframe from "react-iframe";
import { isPDF, isValidHttpUrl } from "../../../utils/generalUtils";
import Logo from "../../../assets/images/logo-dark.webp";
import Logolight from "../../../assets/images/logo-light.webp";
import {
  BackSvg,
  EmailSvg,
  EmailSvg2,
  InterestSvg,
  InterestfillSvg,
  LikeFillSvg,
  LikeSvg,
  PhoneiconSvg,
} from "../../../components/CommonSvg";
import { likeVendorRequest } from "../../../redux/frontend/vendor/action";
import { getValueByKey, setValueByKey } from "../../../utils/authUtils";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import ServiceRequestModal from "../Support/ServiceRequestModal";
import ServiceRequestModalComponent from "./ServiceRequest";

function VendorDetails({ getVendor, vendorDetails, likeVendorRequest }) {
  const { id } = useParams();
  const [index, setIndex] = useState();
  const hraMemberId =
    JSON.parse(getValueByKey("hraMemberId")).toUpperCase() ?? "";
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      setValueByKey("selectedCategory", JSON.stringify(state));
    } else {
      setValueByKey("selectedCategory", "");
    }
  }, [state]);
  useEffect(() => {
    getVendor({ vendorId: id, hraMemberId: hraMemberId ? hraMemberId : "" });
  }, []);
  const slides = vendorDetails.VendorHtmlImages?.map((item) => ({
    src: item.imageName,
  }));
  const isLiked =
    vendorDetails?.InterestedVendors?.length > 0
      ? vendorDetails.InterestedVendors.find((data) => {
          return data.hraMemberId === hraMemberId;
        })
      : null;
  const [modalView, setModaleView] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="vendor_site_details">
        {vendorDetails.htmlCreate ? (
          <>
            <div className="vendor_site_back_btn">
              <Button
                color="link"
                className="my-tooltip back-button"
                data-tooltip-content={"Back"}
                onClick={() => navigate("/vendor")}
              >
                <BackSvg />
              </Button>
            </div>
            <section className="html_hero">
              <Container className="h-100 p-0" fluid>
                <Row className="h-100 m-0">
                  <Col lg={12} className="p-0">
                    <img
                      src={vendorDetails.VendorHtmlDetails?.[0]?.banner}
                      width={"100%"}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="html_hero_logos">
              <Container className="p-0">
                <Row className="m-0">
                  <Col lg={6} className="p-0">
                    <img
                      alt="Logo"
                      className="logo_main"
                      src={vendorDetails.logo}
                      fluid
                    />
                  </Col>
                  <Col lg={6} className="p-0">
                    <div className="html_main_head">
                      <h1>{vendorDetails.name}</h1>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="html_textarea">
              <Container className="h-100">
                <Row className="h-100">
                  <Col lg={12}>
                    <div className="like_btn">
                      <Button
                        className={
                          isLiked?.isActive
                            ? "btn-danger"
                            : "btn-success text-light"
                        }
                        data-tooltip-content={
                          isLiked?.isActive
                            ? "Remove interest from program"
                            : "Join the Program"
                        }
                        onClick={async () => {
                          const response = await commonConfirmBox(
                            isLiked?.isActive
                              ? `Are you sure you want to exit the program?`
                              : `Are you sure you want to join the program?`
                          );
                          if (response) {
                            setModaleView(!modalView);
                          }
                        }}
                      >
                        {isLiked?.isActive ? (
                          <span className="like_fill_svg">
                            <InterestfillSvg /> Interested
                          </span>
                        ) : (
                          <span className="like_svg">
                            <InterestSvg /> Interest
                          </span>
                        )}
                      </Button>
                      {/* <Button
                        color="link"
                        className={isLiked?.isActive ? "p-0 my-tooltip active" : "my-tooltip p-0"}                          
                        data-tooltip-content={isLiked?.isActive ? "Remove in interest from program" : "Join the Program"}
                        onClick={async () => {
                          const response = await commonConfirmBox(
                            isLiked?.isActive
                              ? `Are you sure you want to exit the program?`
                              : `Are you sure you want to join the program?`
                          );
                          if (response) {
                            setModaleView(!modalView);
                          }
                        }}
                      >
                        {isLiked?.isActive ? (
                          <span className="like_fill_svg">
                            <InterestfillSvg/>
                            REMOVE INTEREST FROM PROGRAM
                          </span>
                        ) : (
                          <span className="like_svg">
                            <InterestSvg/>
                            Join the Program
                          </span>
                        )}
                      </Button> */}
                    </div>
                    <div
                      className="product_text"
                      dangerouslySetInnerHTML={{
                        __html:
                          vendorDetails.VendorHtmlDetails?.[0]?.htmlTemplate,
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="html_lightbox">
              <Container className="h-100">
                <Row className="h-100">
                  <Col lg={12}>
                    <div className="promotion-detail-box">
                      <ul className="promotion-detail-images">
                        {vendorDetails.VendorHtmlImages?.map(
                          (item, current) => {
                            return (
                              <li
                                key={item.id}
                                onClick={() => setIndex(current)}
                              >
                                <img src={item.imageName} alt="Product image" />
                              </li>
                            );
                          }
                        )}
                        <Lightbox
                          index={index}
                          slides={slides}
                          open={index >= 0}
                          currentImage={index}
                          onClickNext={() => setIndex(index + 1)}
                          onClickPrev={() => setIndex(index - 1)}
                          close={() => setIndex(-1)}
                        />
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        ) : isPDF(vendorDetails.pdfAttachment) ? (
          <>
            <div className="vendor_site_back_btn pdf_back">
              <Button
                color="link"
                className="my-tooltip back-button"
                data-tooltip-content={"Back"}
                onClick={() => navigate(-1)}
              >
                <BackSvg />
              </Button>
            </div>
            <div className="row">
              {/* <h6 className="separate-head">PDF</h6> */}
              {vendorDetails.pdfAttachment &&
                isValidHttpUrl(vendorDetails.pdfAttachment) && (
                  <>
                    <IfremView src={vendorDetails.pdfAttachment} />
                  </>
                )}
            </div>
          </>
        ) : (
          <>
            <div className="vendor_site_back_btn pdf_back">
              <Button
                color="link"
                className="my-tooltip back-button"
                data-tooltip-content={"Back"}
                onClick={() => navigate(-1)}
              >
                <BackSvg />
              </Button>
            </div>
            <div className="row">
              {/* <h6 className="separate-head">URL</h6> */}
              {vendorDetails.htmlFileUrl &&
                isValidHttpUrl(vendorDetails.htmlFileUrl) && (
                  <>
                    {/* <IfremView src={vendorDetails.htmlFileUrl} /> */}
                    <Iframe
                      type="URL"
                      width="100%"
                      height="600px"
                      loading="lazy"
                      className="mt-3"
                      src={vendorDetails.htmlFileUrl}
                    >
                      <p>
                        Your browser does not support embedded URL. You can
                        download the PDF{" "}
                      </p>
                    </Iframe>
                  </>
                )}
            </div>
          </>
        )}
        <div className="sub-footer">
          <Container>
            <Row>
              <Col sm="12" md="4" lg="4">
                <a href="javascript:void(0)" className="btn btn-link"><span><PhoneiconSvg /></span> {vendorDetails.phoneNumber}</a>
              </Col>
              <Col sm="12" md="8" lg="8" className="sub-footer-email">
                <a href="javascript:void(0)" className="btn btn-link"><span><EmailSvg2 /></span> {vendorDetails.email}</a>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="html_footer">
          <div>
            <Container className="h-100">
              <Row className="h-100 p-4">
                <Col lg={4}>
                  <Link
                    className="btn btn-link"
                    to={"https://us02web.zoom.us/join"}
                    //   component={component}
                  >
                    © 2024 Horizon Retailers Association, LLC
                  </Link>
                  <div>
                    <Link
                      className="btn btn-link"
                      to={"https://www.hraga.com/terms-of-service-agreement"}
                      //   component={component}
                    >
                      Terms of Service
                    </Link>
                    <Link
                      className="btn btn-link"
                      to={"https://www.hraga.com/privacy-policy"}
                      //   component={component}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="d-flex justify-content-center">
                    <img alt="Logo" className="" src={Logolight} fluid />
                  </div>
                </Col>
                <Col lg={6}>
                  <ul>
                    <li>
                      {/* <p>Address: {vendorDetails.address}</p> */}
                      <p>Address: 888 Concord Road SE, Smyrna, GA 30080 </p>
                    </li>
                    <li>
                      <Link
                        className="btn btn-link"
                        to={"mailto:admin@hraga.com"}
                        //   component={component}
                      >
                        Email: admin@hraga.com
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="btn btn-link"
                        to={"tel:1-866-482-6026"}
                        //   component={component}
                      >
                        Telephone: 1-866-482-6026
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="btn btn-link"
                        to={"tel:1-866-638-0931    "}
                        //   component={component}
                      >
                        Fax: 1-866-638-0931
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
      </div>

      <ServiceRequestModalComponent
        modalView={modalView}
        setModalView={setModaleView}
        hraMemberId={hraMemberId}
        vendorDetails={vendorDetails}
        isLiked={isLiked}
        likeVendorRequest={likeVendorRequest}

        // memberData={memberData}
      />
    </>
  );
}
const mapStateToProps = ({ vendor }) => {
  let vendorDetails = vendor.vendor?.list ? vendor.vendor.list : [];
  return { vendorDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendor: (data) => dispatch(getVendorDetailsRequest(data)),
    likeVendorRequest: (data) => dispatch(likeVendorRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);

export const IfremView = ({ src }) => {
  const [googleViewerUrl, setGoogleViewerUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const screenWidth = window.screen.width;

  const loadSuccess = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setRefresh(true);
    setIsLoading(true);

    if (src) {
      if (screenWidth > 786) {
        setGoogleViewerUrl(src);
      } else {
        const encodedUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
          src
        )}`;
        setGoogleViewerUrl(encodedUrl);
      }
    }
    setRefresh(false);
  }, [src, screenWidth]);
  useEffect(() => {
    if (src === null || src === "") {
      setIsLoading(false);
    }
  }, [src === null || src === ""]);
  return (
    <>
      {screenWidth < 786 && isLoading && (
        <div className="w-100 text-center agreement_loader">
          <div className="spinner-border" role="status"></div>
        </div>
      )}
      {refresh === false && googleViewerUrl && (
        <Iframe
          type="URL"
          // src={googleViewerUrl}
          // width="100%"
          // height="600px"
          loading="lazy"
          className="mt-3"
          // type="application/pdf"
          src={googleViewerUrl}
          width="100%"
          height="600px"
          onLoad={loadSuccess}
        >
          <p>
            Your browser does not support embedded URL. You can download the PDF{" "}
          </p>
        </Iframe>
      )}
    </>
  );
};
