import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getDocumentListRequest } from "../../../redux/frontend/agreementForms/action";
import { getValueByKey } from "../../../utils/authUtils";
import Noagreementfound from "../../../assets/images/agreement_not_available.png";
import { useNavigate } from "react-router-dom";
import { BackSvg, DownloadsSvg } from "../../../components/CommonSvg";
import { useState } from "react";
import Iframe from "react-iframe";

const AgreementPage = (props) => {
  useEffect(() => {
    let hraMemberId = JSON.parse(getValueByKey("hraMemberId"));

    if (hraMemberId) {
      props.getDocumentList({
        hraMemberId: hraMemberId,
      });
    }
  }, []);
  const screenWidth = window.screen.width;
  const navigate = useNavigate();
  const [googleViewerUrl, setGoogleViewerUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const loadSuccess = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setRefresh(true);
    setIsLoading(true);

    if (props.documentDetails?.agreement) {
      if (screenWidth > 786) {
        setGoogleViewerUrl(props.documentDetails?.agreement);
      } else {
        const encodedUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(props.documentDetails?.agreement)}`;
        setGoogleViewerUrl(encodedUrl);
      }
    }
    setRefresh(false);
  }, [props.documentDetails?.agreement, screenWidth]);
  useEffect(() => {
    if (props.documentDetails?.agreement === null || props.documentDetails?.agreement === "") {
      setIsLoading(false);
    }
  }, [props.documentDetails?.agreement === null || props.documentDetails?.agreement === ""])
  return (
    <>
      <div className="container agreement_container">
        <div className="section-head">
          <div>
            <button
              className="back_btn"
              title="back"
              onClick={() => {
                navigate("/home");
              }}
            >
              <BackSvg />
            </button>
            <h3>Agreement & Forms</h3>
          </div>
          {screenWidth < 786 && props.documentDetails?.agreement && <a
            className="download_button btn"
            download="download"
            target="_blank"
            rel="noreferrer"
            href={props.documentDetails?.agreement} >
            <DownloadsSvg />
          </a>}
          <div className="agreement_head">
            {props.documentDetails?.agreementStartDate && (
              <div className="hra-light-form-box">
                <label>Start Date :</label>
                <span>
                  {
                    props.documentDetails?.agreementStartDate
                  }
                </span>
              </div>
            )}
            {props.documentDetails?.agreementEndDate && (
              <div className="hra-light-form-box">
                <label>End Date :</label>
                <span>
                  {props.documentDetails?.agreementEndDate}
                </span>
              </div>
            )}
          </div>
        </div>
        <div id="pdfContainer" className="agreemet_pdf_container">

          {screenWidth < 786 && isLoading && (
            <div className="w-100 text-center agreement_loader">
              <div className="spinner-border" role="status">
              </div>
            </div>
          )}
          {refresh === false && googleViewerUrl && (
            <Iframe
              type="application/pdf"
              src={googleViewerUrl}
              width="100%"
              height="600px"
              onLoad={loadSuccess}
            >
              <p>
                Your browser does not support embedded PDFs. You can download
                the PDF{" "}
                <a href={props.documentDetails?.agreement} download>
                  here
                </a>
                .
              </p>
            </Iframe>
          )}
        </div>
      </div>
      {props.documentDetails?.agreement === null ||
        (props.documentDetails?.agreement === "" && (
          <div className="no-content-img">
            <span>
              <img src={Noagreementfound} alt="Image Agreement" />
            </span>
          </div>
        ))}
    </>
  );
};

const mapStateToProps = ({ AgreementForms }) => {
  let documentDetails = [];
  if (AgreementForms.documentList) {
    documentDetails = AgreementForms.documentList;
  }
  return {
    documentDetails: documentDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentList: (data) => dispatch(getDocumentListRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgreementPage);
