import React from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { CloseSvg } from "../../../../components/CommonSvg";

export default function MemberFilter({ setFilter, filter, setFilterView }) {
  const filterStatus = [
    {
      value: "",
      label: "All",
    },
    {
      value: "APPROVED",
      label: "Approved",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
  ];

  return (
    <>
      <Card className="filter_section user_filter">
        <CardBody>
          <h6 className="separate-head">Filter</h6>
          <>
            <form className="hra-create">
              <Row>
                <Col sm={12} className="no-floating-label">
                  <FormGroup>
                    <Select
                      type="text"
                      id="Role"
                      name="Role"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={filterStatus}
                      value={filter}
                      onChange={(selectedOption) => {
                        setFilter(selectedOption);
                        //   setRoleId(selectedOption ? selectedOption.value : "")
                        //   setExportFilterData(true)
                      }}
                      // isClearable
                    />
                    <Label className="form-label" htmlFor="assignStatus">
                      Status
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </>
          <Button
            className="my-tooltip cancel_filter"
            color="link"
            onClick={() => setFilterView(true)}
          >
            <CloseSvg />
          </Button>
        </CardBody>
      </Card>
    </>
  );
}
