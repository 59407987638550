import * as type from "./type";

const initialState = {
    getUsers: null,
    addUser: null,
    deleteUser: null,
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_USER_LIST_REQUEST:
            return { ...state, getUsers: null };
        case type.GET_USER_LIST_SUCCESS:
            return { ...state, getUsers: action.payload };
        case type.ADD_USER_LIST_REQUEST:
            return { ...state, addUser: null };
        case type.ADD_USER_LIST_SUCCESS:
            return { ...state, addUser: action.payload };
        case type.ACTIVE_INACTIVE_USER_REQUEST:
            return { ...state, deleteUser: null };
        case type.ACTIVE_INACTIVE_USER_SUCCESS:
            return { ...state, deleteUser: action.payload };
        default:
            return {
                ...state,
            };
    }
};
export default UserReducer;
