import { GET_LATEST_NOTIFICATION_REQUEST, GET_LATEST_NOTIFICATION_SUCCESS, GET_READ_NOTIFICATION_REQUEST, GET_READ_NOTIFICATION_SUCCESS, PROFILE_DETAILS_REQUEST, PROFILE_DETAILS_SUCCESS, STORE_DETAILS_REQUEST, STORE_DETAILS_SUCCESS, STORE_DETAIL_REQUEST, STORE_DETAIL_SUCCESS, UPDATE_PROFILE_DETAILS_REQUEST, UPDATE_PROFILE_DETAILS_SUCCESS, UPDATE_STORE_DETAILS_REQUEST, UPDATE_STORE_DETAILS_SUCCESS, UPDATE_VENDOR_ACCOUNT_DETAILS_REQUEST, UPDATE_VENDOR_ACCOUNT_DETAILS_SUCCESS } from "./type";

export const StoreDetailRequest = () => {
    return {
        type: STORE_DETAILS_REQUEST,
    };
};
export const StoreDetailSuccess = (data) => {
    return {
        type: STORE_DETAILS_SUCCESS,
        payload: data,
    }
};
export const profileDetailRequest = (data) => {
    return {
        type: PROFILE_DETAILS_REQUEST,
        payload: data,
    };
};
export const profileDetailSuccess = (data) => {
    return {
        type: PROFILE_DETAILS_SUCCESS,
        payload: data,
    }
}; export const ownStoreDetailRequest = (data) => {
    return {
        type: STORE_DETAIL_REQUEST,
        payload: data,
    };
};
export const ownStoreDetailSuccess = (data) => {
    return {
        type: STORE_DETAIL_SUCCESS,
        payload: data,
    }
};
export const upadteProfileDetailRequest = (data) => {
    return {
        type: UPDATE_PROFILE_DETAILS_REQUEST,
        payload: data,
    };
};
export const upadteProfileDetailSuccess = (data) => {
    return {
        type: UPDATE_PROFILE_DETAILS_SUCCESS,
        payload: data,
    }
};
export const upadteStoreDetailsRequest = (data) => {
    return {
        type: UPDATE_STORE_DETAILS_REQUEST,
        payload: data,
    };
};
export const upadteStoreDetailsSuccess = (data) => {
    return {
        type: UPDATE_STORE_DETAILS_SUCCESS,
        payload: data,
    }
};
export const getLatestNotificationRequest = (data) => {
    return {
        type: GET_LATEST_NOTIFICATION_REQUEST,
        payload: data,
    };
};
export const getLatestNotificationSuccess = (data) => {
    return {
        type: GET_LATEST_NOTIFICATION_SUCCESS,
        payload: data,
    };
};
export const getReadNotificationRequest = (data) => {
    return {
        type: GET_READ_NOTIFICATION_REQUEST,
        payload: data,
    };
};
export const getReadNotificationSuccess = (data) => {
    return {
        type: GET_READ_NOTIFICATION_SUCCESS,
        payload: data,
    };
};
export const upadteVendorAccountDetailsRequest = (data) => {
    return {
        type: UPDATE_VENDOR_ACCOUNT_DETAILS_REQUEST,
        payload: data,
    };
};
export const upadteVendorAccountDetailsSuccess = (data) => {
    return {
        type: UPDATE_VENDOR_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
    }
};