import React, { useEffect, useState } from "react";
import {
    BackSvg,
    CallSvg,
    EditbtnSvg,
    EmailSvg2,
    LocationSvg,
    UserSvg2,
} from "../../../components/CommonSvg";
import { connect } from "react-redux";
import { StoreDetailRequest, ownStoreDetailRequest } from "../../../redux/frontend/profile/action";
import { getValueByKey } from "../../../utils/authUtils";
import EditStoreDetails from "./EditStoreDetails";



function StoreDetails({ StoreDetailRequest, OwnerDeatils, ownStoreDetail,setActive, upadteStoreDetailsRequest, StoreDetail, profileDetail }) {

    const [isEditSec, setIsEditSec] = useState(false);
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) || "";
    const parentHraMemberId = JSON.parse(getValueByKey("parentHraMemberId")) || "";
    useEffect(() => {
        StoreDetailRequest({ hraMemberId: hraMemberId });
        ownStoreDetail({ hraMemberId: hraMemberId })
    }, []);


    return (
        <>
            <div className="owner_view_section tab-card mb-4">
                {!isEditSec && (
                    <>
                        <div className="tab_content_head">
                            <div>
                                <button className="back_btn" title="back" onClick={() => setActive(true)}>
                                    <BackSvg />
                                </button>
                                <h4>Business Details</h4>
                            </div>
                            <div>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        title="Edit"
                                        onClick={() => setIsEditSec(true)}
                                    >
                                        <EditbtnSvg />
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="profile-field-list row">
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>Name of Business</label>
                                    <span>{OwnerDeatils?.storeNameRegular}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>Name of Corporation</label>
                                    <span>{OwnerDeatils?.accountName}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>EIN Number</label>
                                    <span>{OwnerDeatils?.ein}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>City</label>
                                    <span>{OwnerDeatils?.city}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>State</label>
                                    <span>{OwnerDeatils?.state}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>Zip Code</label>
                                    <span>{OwnerDeatils?.mailingZip}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="hra-light-form-box">
                                    <label>Business Phone Number</label>
                                    <span>{OwnerDeatils?.businessPhone}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-xl-8">
                                <div className="hra-light-form-box">
                                    <label>Address of Business</label>
                                    <span>
                                        {OwnerDeatils?.mailingStreetAddress + ", " + OwnerDeatils?.mailingAddressCity + ", " + OwnerDeatils?.mailingState}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>

                )}

                {isEditSec && (
                    <EditStoreDetails profileDetail={profileDetail} setIsEditSec={setIsEditSec} upadteStoreDetailsRequest={upadteStoreDetailsRequest} hraMemberId={hraMemberId} />
                )
                }
            </div>

            {parentHraMemberId === hraMemberId && StoreDetail.length > 0 &&
                <div className="tab-card">
                    <div className="tab_content_head">
                        <h4>Store Details</h4>
                    </div>
                    {StoreDetail?.map((data, index) => {
                        return (
                            <div key={index} className="profile-store-field-list row m-0">
                                <div className="col-12">
                                    <div className="profile-store-field-head">
                                        <h5>{data.subHra.storeNameRegular}</h5>
                                        <span className="store-hraid">{data.subHraMemberId}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                    <div className="profile-store-data">
                                        <div>
                                            <span className="profile-store-icon-box">
                                                <UserSvg2 />
                                            </span>
                                            <p>{data.subHra.firstName + ' ' + data.subHra.lastName}</p>
                                        </div>
                                        <span className="badge badge-info">{data.subHra.relationship}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                    <div className="profile-store-data">
                                        <div>
                                            <span className="profile-store-icon-box">
                                                <CallSvg />
                                            </span>
                                            <p>{data.subHra.phoneNumberMobileNumber}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                                    <div className="profile-store-data">
                                        <div>
                                            <span className="profile-store-icon-box">
                                                <EmailSvg2 />
                                            </span>
                                            <p>{data.subHra.emailAddress}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-xl-12">
                                    <div className="profile-store-data">
                                        <div>
                                            <span className="profile-store-icon-box">
                                                <LocationSvg />
                                            </span>
                                            <p>
                                                {data.subHra.streetAddress + ', ' + data.subHra.county + ', ' + data.subHra.city + ', ' + data.subHra.state}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>}
        </>
    );
}
const mapStateToProps = ({ profileData }) => {
    let StoreDetail =
        profileData?.StoreDetail?.list?.length > 0
            ? profileData.StoreDetail.list
            : [];
    let OwnerDeatils = profileData?.ownStoreDetail?.details ? profileData?.ownStoreDetail?.details : null
    return {
        StoreDetail: StoreDetail, OwnerDeatils: OwnerDeatils
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        ownStoreDetail: (data) => dispatch(ownStoreDetailRequest(data)),
        StoreDetailRequest: (data) => dispatch(StoreDetailRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails);
