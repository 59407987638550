import {
    GET_FORGOT_PASSWORD_FAILURE,
    GET_FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
} from "./type";

const initialState = {
    forgotUser: null,
    error: null,
    loading: false,
    resetPassword: null,
};

const ForgotReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotUser: action.payload,
                loading: false,
                error: null,
            };
        case GET_FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                resetPassword: null,
                loading: false,
                error: action.payload,
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: action.payload,
                error: null,
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                message: null,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default ForgotReducer;
