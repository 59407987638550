import { CHANGE_STATUS_ACH_REQUEST, CHANGE_STATUS_ACH_SUCCESS, GET_ACH_LIST_REQUEST, GET_ACH_LIST_SUCCESS } from "./type"

export const getAchListRequest = (data) => {
    return {
        type: GET_ACH_LIST_REQUEST,
        payload: data
    }
}
export const getAchListSuccess = (data) => {
    return {
        type: GET_ACH_LIST_SUCCESS,
        payload: data
    }
}
export const changeStatusAchRequest = (data) => {
    return {
        type: CHANGE_STATUS_ACH_REQUEST,
        payload: data
    }
}
export const changeStatusAchSuccess = (data) => {
    return {
        type: CHANGE_STATUS_ACH_SUCCESS,
        payload: data
    }
}