import React, { useEffect, useState } from "react";
import ViewTable from "../../../components/Common/ViewTable";
import { connect } from "react-redux";
import {
  activeInactiveUserRequest,
  getUserListRequest,
} from "../../../redux/admin/user/action";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import AddUser from "./AddUser";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { setRoleStatusColor } from "../../../utils/generalUtils";
import { EditSvg, EmailSvg, UserSvg } from "../../../components/CommonSvg";
import { getForgotRequest } from "../../../redux/admin/auth/forgot/action";
import Switch from "react-switch";
import { exportDataExportRequest } from "../../../redux/exportData/action";
function UserView(props) {
  const { getDataUserList, getUserList, totalUserList } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [modalView, setModaleView] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [exportFilterData, setExportFilterData] = useState(false);
  const [roleId, setRoleId] = useState("");
  const handleModalToggle = () => {
    setModaleView(!modalView);
  };

  const handleEditModalToggle = (data) => {
    setIsEdit(data);
    setModaleView(!modalView);
  };
  const dispatchData = {
    searchText: searchData,
    roleId: roleId,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };
  const columns = [
    {
      dataField: "fullName",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Created Date",
      sort: true,
      formatter: (cell, row) => <>{row.createdDate}</>,
    },
    {
      dataField: "role.roleName",
      text: "Role",
      headerClasses: "text-center",
      sort: true,
      formatter: (cell, row) => (
        <div className="text-center">
          <span
            className={`badge role-badge ${setRoleStatusColor(
              row.role.roleName
            )}`}
          >
            {row.role.roleName}
          </span>
        </div>
      ),
    },

    {
      dataField: "isActive",
      text: "Status",
      headerClasses: "text-center",
      sort: true,

      formatter: (cell, row) => (
        <div
          className="text-center"
          onClick={(e) => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
          }}
        >
          <Switch
            className={`react-switch justify-content-center switch-sm ${
              row.isActive && "isActive"
            }`}
            onChange={async (e) => {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }
              const response = await commonConfirmBox(
                "",
                undefined,
                undefined,
                !row.isActive
                  ? `<h5 className="w-100">Do you want to activate this user?</h5> Name: ${row.fullName} <br/> Email: ${row.email}`
                  : `<h5 className="w-100">Do you want to deactivate this user?</h5> Name: ${row.fullName}  <br/> Email: ${row.email}`
              );
              if (response) {
                props.RemoveDataUser({
                  userId: row.id,
                  isActive: !row.isActive,
                  dispatchData,
                });
              }
            }}
            type="checkbox"
            id="custom-switch"
            checked={row.isActive}
            disabled={row.fullName === "Super Admin"}
          />
        </div>
      ),
    },
    {
      dataField: "Action",
      text: "",
      headerClasses: "action_col",
      formatter: (cell, row) => (
        <div className="action_col text-center">
          <button
            className="my-tooltip btn btn-link resend_btn"
            data-tooltip-content={"Resend Email"}
            onClick={(e) => {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }
              props.getPasswordResetByEmail({ email: row.email });
            }}
          >
            <EmailSvg />
          </button>

          <button
            className="my-tooltip btn btn-link edit_btn"
            data-tooltip-content={"Edit"}
            onClick={(e) => {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }
              handleEditModalToggle(row);
            }}
          >
            <EditSvg />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getDataUserList(dispatchData);
  }, [dataPerPage, currentPage, searchData, roleId]);

  return (
    <>
      <ViewTable
        data={getUserList ? getUserList : []}
        columns={columns}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
        setDataPerPage={setDataPerPage}
        setCurrentPage={setCurrentPage}
        pagination={true}
        Filter={true}
        setExportFilterData={setExportFilterData}
        totalRecords={totalUserList}
        filterType={"userHeader"}
        rowClassName={"cursor-pointer"}
        Header={true}
        rowEvents={(e, row, rowIndex) => {
          handleEditModalToggle(row);
        }}
        setSearchData={setSearchData}
        updateData={() =>
          getDataUserList({
            searchText: "",
            pagination: {
              limit: dataPerPage,
              page: 1,
              orderKey: "createdDate",
              orderBy: "ASC",
            },
          })
        }
        setRoleId={setRoleId}
        hedingName="User"
        searchBar={true}
        addModalData={handleModalToggle}
        buttonNameModal={"Add User"}
        exportData={true}
        exportDataHandel={() => {
          props.exportDataExportRequest({
            type: "POST",
            url: "/userDataExport",
            data: { ...dispatchData, isFilteredDataExport: exportFilterData },
          });
        }}
      />
      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={"category-modal"}
          modalName={isEdit ? "Edit User" : "Add User"}
          onModalDismiss={() => {
            setModaleView(false);
            setIsEdit(null);
          }}
        >
          <AddUser
            onModalDismiss={() => {
              setModaleView(false);
            }}
            data={isEdit}
            setIsEdit={setIsEdit}
            modalView={modalView}
            dispatchData={dispatchData}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = ({ userList }) => {
  let getUserList = [];
  let totalUserList = [];
  if (
    userList?.getUsers?.list &&
    userList?.getUsers?.list?.length > 0 &&
    userList?.getUsers?.totalPages > 0
  ) {
    getUserList = userList.getUsers.list;
    totalUserList = userList.getUsers.totalRecords;
  }
  return {
    getUserList: getUserList,
    totalUserList: totalUserList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataUserList: (data) => dispatch(getUserListRequest(data)),
    RemoveDataUser: (data) => dispatch(activeInactiveUserRequest(data)),
    getPasswordResetByEmail: (data) => dispatch(getForgotRequest(data)),
    exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
