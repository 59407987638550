import { GET_STORE_REBATE_REQUEST, GET_STORE_REBATE_SUCCESS, REBATE_REQUEST, REBATE_SUCCESS } from "./type";

export const rebateRequest = (data) => ({
    type: REBATE_REQUEST,
    payload: data
});

export const rebateSuccess = (data) => ({
    type: REBATE_SUCCESS,
    payload: data
});
export const getStoreRebatesRequest = (data) => ({
    type: GET_STORE_REBATE_REQUEST,
    payload: data
});

export const getStoreRebatesSuccess = (data) => ({
    type: GET_STORE_REBATE_SUCCESS,
    payload: data
});
