import React from 'react'
import nodatafound from "../../assets/images/No-data-found.png";

export default function NoDataFound() {
    return (
        <>
            <div className="no_data_img w-100  d-flex justify-content-center align-items-center">
                <img alt="No-data" className="nodata" src={nodatafound} />
            </div>
        </>
    )
}
