import React from "react";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";
import { getLatestPromotionListRequest } from "../../../redux/frontend/promotion/action";
import { useEffect } from "react";
import { connect } from "react-redux";
import CommonPromotionCard from "../../../components/Common/Frontend/Promotion/CommonPromotionCard";
import { getValueByKey } from "../../../utils/authUtils";
import NoPromotionFound from "../../../components/Common/Frontend/Promotion/NoPromotionFound";
import { getOptInStatusRequest } from "../../../redux/admin/optinhistory/action";

function ExpireSoon({ getDetails, expireSoonPromotions, getOptInStatusRequest }) {
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    useEffect(() => {
        getDetails({ hraMemberId: hraMemberId });
    }, []);
    return (
        <>
            <div className="container">
                <CommonSortHeader PromotionsName="Expire Soon" BackTo={"/home"} />
                {expireSoonPromotions?.filter((product) => product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true)?.length > 0 ? (
                    <div className="product-card-list">
                        {expireSoonPromotions?.filter((product) => product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true).map((item, index) => {
                            return <CommonPromotionCard key={index} getOptInStatusRequest={getOptInStatusRequest} ApiUpdateReq={"membersLatestPromotions"} item={item} />;
                        })}
                    </div>
                ) : (
                    <NoPromotionFound />
                )}
            </div>
        </>
    );
}
const mapStateToProps = ({ MemberPromotions }) => {
    let expireSoonPromotions = [];

    if (MemberPromotions?.latestPromotion?.data?.expireSoonPromotions) {
        expireSoonPromotions =
            MemberPromotions.latestPromotion.data.expireSoonPromotions;
    }
    return { expireSoonPromotions: expireSoonPromotions };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOptInStatusRequest: (data) => dispatch(getOptInStatusRequest(data)),
        getDetails: (data) => dispatch(getLatestPromotionListRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpireSoon);
