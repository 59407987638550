import { combineReducers } from "redux";
import UserReducer from "./admin/user/reducer";
import LoginReducer from "./admin/auth/login/reducer";
import ForgotReducer from "./admin/auth/forgot/reducer";
import sharedReducer from "./shared/reducer";
import RoleReducer from "./admin/role/reducer";
import PromotionReducer from "./admin/promotion/reducer";
import LocationReducer from "./admin/location/reducer";
import MembersReducer from "./admin/members/reducer";
import OptInReducer from "./admin/optinhistory/reducer";
import InquiryReducer from "./admin/inquiry/reducer";
import MemberLoginReducer from "./frontend/auth/login/reducer";
import MemberForgotReducer from "./frontend/auth/forgot/reducer";
import InquirysendReducer from "./frontend/inquiry/reducer";
import ExportReducer from "./exportData/reducer";
import SupportReducer from "./admin/support/reducer";
import MemberPromotionReducer from "./frontend/promotion/reducer";
import AgreementFormsReducer from "./frontend/agreementForms/reducer";
import SupportMemberReducer from "./frontend/support/reducer";
import profileReducer from "./frontend/profile/reducer";
import AchManagementReducer from "./frontend/ach/reducer";
import AchReducer from "./admin/Ach/reducer";
import rebateReducer from "./frontend/rebate/reducer";
import dashboardReducer from "./admin/dashboard/reducer";
import RebateManageReducer from "./admin/rebate/reducer";
import NewsLetterReducer from "./admin/newsletter/reducer";
import SyncReducer from "./admin/sync/reducer";
import CategoryReducer from "./admin/category/reducer";
import VendorReducer from "./admin/vendor/reducer";
import VendorUserReducer from "./frontend/vendor/reducer";
import SupportTicketReducer from "./admin/supportTickets/reducer";

export const rootReducer = combineReducers({
  sharedReducer: sharedReducer,
  login: LoginReducer,
  forgot: ForgotReducer,
  userList: UserReducer,
  roleList: RoleReducer,
  Promotions: PromotionReducer,
  location: LocationReducer,
  Members: MembersReducer,
  optInList: OptInReducer,
  inquiryList: InquiryReducer,
  rebateManagement: RebateManageReducer,
  AchManagementList: AchReducer,
  NewsLetter: NewsLetterReducer,
  SyncReducer: SyncReducer,
  vendor: VendorReducer,
  SupportTicket: SupportTicketReducer,
  //frontend reducer
  MemberLogin: MemberLoginReducer,
  MemberForgot: MemberForgotReducer,
  inquiry: InquirysendReducer,
  exportData: ExportReducer,
  support: SupportReducer,
  MemberPromotions: MemberPromotionReducer,
  AgreementForms: AgreementFormsReducer,
  supportData: SupportMemberReducer,
  profileData: profileReducer,
  AchManagement: AchManagementReducer,
  rebate: rebateReducer,
  dashboardReducer: dashboardReducer,
  category: CategoryReducer,
  vendorUser: VendorUserReducer,
});
