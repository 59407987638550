import * as types from "./type";

export const addNewsLetterRequest = (data) => {
    return {
        type: types.ADD_NEWS_LETTER_REQUEST,
        payload: data
    };
};
export const addNewsLetterSuccess = (data) => {
    return {
        type: types.ADD_NEWS_LETTER_SUCCESS,
        payload: data
    };
};
export const editNewsLetterRequest = (data) => {
    return {
        type: types.EDIT_NEWS_LETTER_REQUEST,
        payload: data
    };
};
export const  editNewsLetterSuccess = (data) => {
    return {
        type: types.EDIT_NEWS_LETTER_SUCCESS,
        payload: data
    };
};
export const newsLetterRequest = (data) => {
    return {
        type: types.NEWS_LETTER_REQUEST,
        payload: data
    };
};
export const newsLetterSuccess = (data) => {
    return {
        type: types.NEWS_LETTER_SUCCESS,
        payload: data
    };
};
export const getNewsLetterRequest = (data) => {
    return {
        type: types.GET_NEWS_LETTER_REQUEST,
        payload: data
    };
};
export const getNewsLetterSuccess = (data, offset) => {
    return {
        type: types.GET_NEWS_LETTER_SUCCESS,
        payload: { data, offset }
    };
};
export const deleteNewsLetterRequest = (data) => {
    return {
        type: types.DELETE_NEWS_LETTER_REQUEST,
        payload: data
    };
};
export const deleteNewsLetterSuccess = (data) => {
    return {
        type: types.DELETE_NEWS_LETTER_SUCCESS,
        payload: data
    };
};

export const assignNewsLetterRequest = (data) => {
    return {
        type: types.ASSIGN_NEWS_LETTER_REQUEST,
        payload: data,
    };
};
export const assignNewsLetterSuccess = (data) => {
    return {
        type: types.ASSIGN_NEWS_LETTER_SUCCESS,
        payload: data,
    };
};
export const clearMemberNewsLetterOnScrollResponse = (data) => {
    return {
        type: types.CLEAR_RESPONSE,
        payload: data,
    };
};
export const detailsNewsLetterRequest = (data) => {
    return {
        type: types.DETAILS_NEWS_LETTER_REQUEST,
        payload: data
    };
};
export const detailsNewsLetterSuccess = (data) => {
    return {
        type: types.DETAILS_NEWS_LETTER_SUCCESS,
        payload: data
    };
};