import React, { useEffect, useState } from "react";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getVendorDetailsRequest } from "../../../redux/admin/vendor/action";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Iframe from "react-iframe";
import { isPDF, isValidHttpUrl } from "../../../utils/generalUtils";

function ViewVenderDetails({ getVendor, vendorDetails }) {
  const { id } = useParams();
  const [index, setIndex] = useState();
  useEffect(() => {
    getVendor({ vendorId: id });
  }, []);
  const slides = vendorDetails.VendorHtmlImages?.map((item) => ({
    src: item.imageName,
  }));
  return (
    <div>
      <CommonBackHeader
        backPath={"/admin/vendor"}
        HeaderName={"View Vendor"}
        //   isEditButton={
        //       RoleWiseModule(AdminAccess, MarketingAccess) && "Edit Promotion"
        //   }
        //   isEditUrl={
        //       RoleWiseModule(AdminAccess, MarketingAccess) && EditPromotionButton
        //   }
        //   createdDate={promotionsData.createdDate}
      />
      <div className="container hra-view-detail vendor_view_details ">
        <Row>
          <Col lg={3}>
            <div className="hra-view-detail-inner-box">
              <Card className="p-0 mb-3">
                <CardBody className="row">
                  <h6 className="separate-head">Vendor Logo</h6>
                  <div className="col-12">
                    <div className="hra-form-box logo_box mb-0">
                      <img src={vendorDetails.logo} width={"100%"} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg={9}>
            <div className="hra-view-detail-inner-box">
              <Card className="p-0 mb-3">
                <CardBody className="row">
                  <h6 className="separate-head">Vendor Details</h6>
                  <div className="col">
                    <div className="hra-form-box">
                      <label>Name</label>
                      <span>{vendorDetails.name}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="hra-form-box">
                      <label>Phone Number</label>
                      <span>{vendorDetails.phoneNumber}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="hra-form-box">
                      <label>Email Address</label>
                      <span>{vendorDetails.email}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="hra-form-box">
                      <label>Category</label>
                      <span>{vendorDetails?.category?.catName}</span>
                    </div>
                  </div>
                  {vendorDetails?.vendorZohoId && (
                    <div className="col">
                      <div className="hra-form-box">
                        <label>Zoho ID</label>
                        <span>{vendorDetails?.vendorZohoId}</span>
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="hra-form-box mb-0">
                      <label>Address</label>
                      <span>{vendorDetails.address}</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="hra-view-detail-inner-box">
              <Card className="p-0 mb-3">
                {vendorDetails.htmlCreate ? (
                  <CardBody className="row vendor_details_view_row">
                    <h6 className="separate-head">HTML Template</h6>
                    <img
                      src={vendorDetails.VendorHtmlDetails?.[0]?.banner}
                      width={"100%"}
                    />
                    {/* <div>
                                        {vendorDetails.VendorHtmlDetails?.[0]?.htmlTemplate}
                                    </div> */}
                    <div className="text_editer_view">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            vendorDetails.VendorHtmlDetails?.[0]?.htmlTemplate,
                        }}
                      />
                    </div>
                    <div className="promotion-detail-box">
                      <ul className="promotion-detail-images">
                        {vendorDetails.VendorHtmlImages?.map(
                          (item, current) => {
                            return (
                              <li
                                key={item.id}
                                onClick={() => setIndex(current)}
                              >
                                <img src={item.imageName} alt="Product image" />
                              </li>
                            );
                          }
                        )}
                        <Lightbox
                          index={index}
                          slides={slides}
                          open={index >= 0}
                          currentImage={index}
                          onClickNext={() => setIndex(index + 1)}
                          onClickPrev={() => setIndex(index - 1)}
                          close={() => setIndex(-1)}
                        />
                      </ul>
                    </div>
                  </CardBody>
                ) : isPDF(vendorDetails.pdfAttachment) ? (
                  <CardBody className="row">
                    <h6 className="separate-head">PDF</h6>
                    {vendorDetails.pdfAttachment &&
                      isValidHttpUrl(vendorDetails.pdfAttachment) && (
                        <Iframe
                          type="URL"
                          src={vendorDetails.pdfAttachment}
                          width="100%"
                          height="600px"
                          loading="lazy"
                          className="mt-4"
                        >
                          <p>
                            Your browser does not support embedded URL. You can
                            download the PDF{" "}
                          </p>
                        </Iframe>
                      )}
                  </CardBody>
                ) : (
                  <CardBody className="row">
                    <h6 className="separate-head">URL</h6>
                    {vendorDetails.htmlFileUrl &&
                      isValidHttpUrl(vendorDetails.htmlFileUrl) && (
                        <Iframe
                          type="URL"
                          src={vendorDetails.htmlFileUrl}
                          width="100%"
                          height="600px"
                          loading="lazy"
                          className="mt-4"
                        >
                          <p>
                            Your browser does not support embedded URL. You can
                            download the PDF{" "}
                          </p>
                        </Iframe>
                      )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = ({ vendor }) => {
  let vendorDetails = vendor.vendor?.list ? vendor.vendor.list : [];
  return { vendorDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendor: (data) => dispatch(getVendorDetailsRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewVenderDetails);
