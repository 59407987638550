import { GET_STORE_REBATE_REQUEST, GET_STORE_REBATE_SUCCESS, REBATE_SUCCESS } from "./type";
const initialState = {
    rebateData: null,
    getStoreRebates:null
};
const rebateReducer = (state = initialState, action) => {
    switch (action.type) {
        // case REBATE_REQUEST:
        //     return {
        //         ...state,
        //         rebateData: null,
        //     };
        case REBATE_SUCCESS:
            return {
                ...state,
                rebateData: action.payload,
            };
        case GET_STORE_REBATE_REQUEST:
            return {
                ...state,
                getStoreRebates: null,
            };

        case GET_STORE_REBATE_SUCCESS:
            return {
                ...state,
                getStoreRebates: action.payload,
            };

        default:
            return { ...state };
    }
};
export default rebateReducer 