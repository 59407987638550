export const config = {

    // buttons: [
    //     'bold',
    //     'italic',
    //     'underline',
    //     '|',
    //     'ul',
    //     'ol',
    //     '|',
    //     'link',
    //     'align',
    //     '|',
    //     // Exclude 'undo' and 'redo' buttons
    // ],

    removeButtons: [
        'cut',
        'copy',
        'paste',
        'changeMode', // Note: this might be 'source' or 'fullsize' depending on the version of Jodit
        'print',
        'table',
        'about',
        'subscript',
        'superscript',
        'source',
        'classSpan',
        'copyformat',
        'video',
        'image',
        'file', 
    ],
    cleanHTML: {
        denyTags: {
            script: true,
            img: true
        }
    },
    readonly: false,
    uploader: { insertImageAsBase64URI: false, url: 'none' },
    showXPathInStatusbar: false,
    showCharsCounter: false,
    disableDragAndDrop: true,
    showWordsCounter: false,
    // toolbarAdaptive: false,
};
export const dispatchData = {
    searchText: "",
    pagination: {
        limit: 10,
        page: 0,
        orderKey: "createdDate",
        orderBy: "ASC",
    },
};
export const generateInitialData = (state) => {
    return {
        vendorId: state?.id || "",
        name: state?.name || "",
        phoneNumber: state?.phoneNumber || "",
        email: state?.email || "",
        address: state?.address || "",
        vendorLogo: state?.logo || {},
        catId: state?.categoryId || "",
        htmlFileUrl: state?.htmlFileUrl || "",
        pdfAttachment: state?.pdfAttachment || "",
        htmlBannerImg: state?.VendorHtmlDetails?.[0]?.banner || "",
        htmlTemplateDescription: state?.VendorHtmlDetails?.[0]?.htmlTemplate || "",
        htmlFiles: state?.VendorHtmlImages?.length > 0 ? state.VendorHtmlImages : [],
        htmlTemplate: state?.htmlCreate || false,
        vendorZohoId: state?.vendorZohoId || ""
    };
};
