import React, { useState } from "react";
import ViewTable from "../../../components/Common/ViewTable";
import { connect } from "react-redux";
import {
  deleteInquiryRequest,
  getInquiryRequest,
} from "../../../redux/admin/inquiry/action";
import { useEffect } from "react";
import { Badge } from "reactstrap";
import { DeleteSvg } from "../../../components/CommonSvg";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import InquiryStatus from "./InquiryStatus";
import InquiryView from "./InquiryView";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import { Link } from "react-router-dom";
import { commonConfirmBox } from "../../../helper/messagehelpers";
function InquiryList(props) {
  const { InquiryList, totalInquiryList, getInquiryListReq } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [modalView, setModaleView] = useState(false);
  const [inquiryData, setInquiryData] = useState(null);
  const [exportFilterData, setExportFilterData] = useState(false);
  const [cahngeStatusId, setCahngeStatusId] = useState(null);
  const handleModalToggle = () => {
    setModaleView(!modalView);
  };
  const dispatchData = {
    searchText: searchData,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };

  const columns = [
    {
      dataField: "name",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "businessName",
      text: "Business Name",
      sort: true,
    },

    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      headerClasses: "text-center",
      classes: "text-center",
      sort: true,
      formatter: (cell, row) => {
        return (
          <span
            onClick={(e) => {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }
            }}
          >
            <InquiryStatus
              row={row}
              onModalDismiss={() => {
                setModaleView(false);
                setCahngeStatusId(null);
              }}
              dispatchData={dispatchData}
            />
          </span>
        );
      },
    },
    {
      dataField: "status",
      text: "",
      classes: "text-center",
      formatter: (cell, row) => {
        return (
          <div className="action_col d-flex align-items-center justify-content-center">
            {/* <a
              className="view_btn_icon mx-2"
              title="View inquiry"
              onClick={(e) => {
                if (e && e.stopPropagation) {
                  e.stopPropagation()
                }
                handleModalToggle();
                setInquiryData(row);
              }}
            >
              <ViewSvg />
            </a> */}

            <Link
              className="delete_btn_icon mx-2"
              title="Delete inquiry"
              onClick={async (e) => {
                if (e && e.stopPropagation) {
                  e.stopPropagation();
                }
                const response = await commonConfirmBox(
                  "Are you sure you want to delete this inquiry?"
                );
                if (response) {
                  const deleteId = { inquiryId: row.id };
                  props.deleteInquiryReq({ deleteId, dispatchData });
                }
              }}
            >
              <DeleteSvg />
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getInquiryListReq(dispatchData);
  }, [dataPerPage, currentPage, searchData]);

  return (
    <>
      <ViewTable
        data={InquiryList ? InquiryList : []}
        columns={columns}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
        setDataPerPage={setDataPerPage}
        setExportFilterData={setExportFilterData}
        setCurrentPage={setCurrentPage}
        pagination={true}
        totalRecords={totalInquiryList}
        Header={true}
        rowClassName={"cursor-pointer"}
        setSearchData={setSearchData}
        updateData={() =>
          getInquiryListReq({
            searchText: "",
            pagination: {
              limit: dataPerPage,
              page: 1,
              orderKey: "createdDate",
              orderBy: "ASC",
            },
          })
        }
        rowEvents={(e, row, rowIndex) => {
          handleModalToggle();
          setInquiryData(row);
        }}
        hedingName="Inquiry"
        searchBar={true}
        exportData={true}
        exportDataHandel={() => {
          props.exportDataExportRequest({
            type: "post",
            url: "/inquiryDataExport",
            data: { ...dispatchData, isFilteredDataExport: exportFilterData },
          });
        }}
      />
      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={"category-modal modal-lg inquiry_view_modal"}
          modalName={cahngeStatusId ? "Inquiry Status" : "Inquiry"}
          onModalDismiss={() => {
            setCahngeStatusId(null);
            setModaleView(false);
          }}
        >
          <InquiryView
            onModalDismiss={() => {
              setModaleView(false);
              setCahngeStatusId(null);
            }}
            dispatchData={dispatchData}
            inquiryData={inquiryData}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = ({ inquiryList }) => {
  let InquiryList =
    inquiryList?.getInquiry?.list?.length > 0 && inquiryList.getInquiry.list;
  let totalInquiryList =
    inquiryList?.getInquiry?.totalRecords &&
    inquiryList.getInquiry.totalRecords;
  return {
    InquiryList,
    totalInquiryList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
    getInquiryListReq: (data) => dispatch(getInquiryRequest(data)),
    deleteInquiryReq: (data) => dispatch(deleteInquiryRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InquiryList);
