import { GET_MEMBER_LOGIN_REQUEST, GET_MEMBER_LOGIN_SUCCESS } from "./type";

export const getMemberLoginRequest = (cred) => {
    return {
        type: GET_MEMBER_LOGIN_REQUEST,
        payload: cred,
    };
};
export const getMemberLoginSuccess = (cred) => {

    return {
        type: GET_MEMBER_LOGIN_SUCCESS,
        payload: cred,
    };
};
