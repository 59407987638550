import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_OPT_IN_LIST_REQUEST,
  GET_OPT_IN_REQUEST,
  GET_OPT_IN_STATUS_REQUEST,
} from "./type";
import {
  getOptInListSuccess,
  getOptInStatusSuccess,
  getOptInSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";
import { clearMemberPromotionListOnScrollResponse, getLatestPromotionListRequest, getMemberPromotionListOnScrollRequest, getMembersPromotionListRequest, getMembersPromotionViewDetailsRequest } from "../../frontend/promotion/action";

function* OptInList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/optInHistory", data);
    if (response.status) {
      yield put(getOptInSuccess(response.data));
    } else {
      yield put(getOptInSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* OptInMemberList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/optInDetails", data);
    if (response.status) {
      yield put(getOptInListSuccess(response.data));
    } else {
      yield put(getOptInListSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* OtpInStatus({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/optIn", data.optin);
    if (response.status) {
      yield put(getOptInStatusSuccess(response));
      ApiResponseMessage(response.message, SUCCESS);
      if (data.status === "promation") {
        yield put(clearMemberPromotionListOnScrollResponse());
        yield put(getMembersPromotionListRequest({ hraMemberId: data.optin.hraMemberId, sortBy: data.sort, }));
      } else if (data.status === "promationDeatils") {
        yield put(getMembersPromotionViewDetailsRequest(data.calldata));
      } else if (data.status === "membersLatestPromotions") {
        yield put(getLatestPromotionListRequest({ hraMemberId: data.optin.hraMemberId }));
        yield put(clearMemberPromotionListOnScrollResponse());
        yield put(getMemberPromotionListOnScrollRequest({
          offset: 0,
          hraMemberId: data.optin.hraMemberId
        }));
      } else if (data.status === "memberPromotionListScroll") {
        yield put(clearMemberPromotionListOnScrollResponse());
        yield put(getMemberPromotionListOnScrollRequest({
          offset: 0,
          hraMemberId: data.optin.hraMemberId
        }));
        yield put(getLatestPromotionListRequest({ hraMemberId: data.optin.hraMemberId }));
      }
    } else {
      yield put(getOptInStatusSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* watchOptInRequest() {
  yield takeLatest(GET_OPT_IN_REQUEST, OptInList);
  yield takeLatest(GET_OPT_IN_LIST_REQUEST, OptInMemberList);
  yield takeLatest(GET_OPT_IN_STATUS_REQUEST, OtpInStatus);
}

function* OptInSaga() {
  yield all([fork(watchOptInRequest)]);
}

export default OptInSaga;
