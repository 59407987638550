import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { CloseSvg } from "../../../components/CommonSvg";
import DatePicker from "react-datepicker";
import { getCategoryListRequest } from "../../../redux/admin/category/action";
import { optionsIsuue, optionsPriority, optionsStatus } from "../../Frontend/Support/common";
import { getVendorListRequest } from "../../../redux/admin/vendor/action";
import { getMemberListRequest } from "../../../redux/admin/members/action";
import { getStatusRequest } from "../../../redux/admin/supportTickets/action";
function UserFilter({
  setExportFilterData,
  handelShowFIlter,
  getCategoryList,
  categoryList,
  useFilter,
  getMemberListReq,
  getVendorList,
  vendorList,
  allMemberList,
  getStatusRequest,
  statusList,
}) {
  const {
    store,
    setStore,
    category,
    setCategory,
    priority,
    setPriority,
    vendor,
    setVendor,
    status,
    setStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useFilter;
  const dispatchData = {
    searchText: "",
    pagination: {
      limit: 0,
      page: 0,
      orderKey: "",
      orderBy: "",
    },
  };

  useEffect(() => {
    getMemberListReq(dispatchData);
  }, []);
  useEffect(() => {
    getStatusRequest();
    getCategoryList();
  }, []);
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.background,
      color: state.data.color, // You can customize the text color here
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color, // You can customize the text color here
    }),
  };

  useEffect(() => {
    getVendorList({
      searchText: "",
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 0,
        orderBy: 0,
      },
    });
  }, []);
  const inputChangeTimeoutRef = useRef(null);

  return (
    <>
      <Card className="filter_section card">
        <CardBody>
          <h6 className="separate-head">Filter</h6>
          <>
            <form className="hra-create">
              <Row>
                <Col sm={12} className="no-floating-label">
                  <Row>
                    <FormGroup className="col mb-0">
                      <Select
                        type="text"
                        id="allMemberList"
                        name="allMemberList"
                        className="form-control selectbox"
                        menuPlacement="auto"
                        options={allMemberList}
                        isClearable={true}
                        value={store}
                        onChange={(selectedOption) => {
                          // setStore(selectedOption);
                          setStore(selectedOption ? selectedOption : "");
                          setExportFilterData(true);
                        }}
                        onInputChange={(newValue) => {
                          if (inputChangeTimeoutRef.current) {
                            clearTimeout(inputChangeTimeoutRef.current);
                          }
                          inputChangeTimeoutRef.current = setTimeout(() => {
                            if (newValue?.length > 0) {
                              getMemberListReq({
                                ...dispatchData,
                                searchText: newValue,
                              });
                            } else {
                              getMemberListReq({...dispatchData});
                            }
                          }, 900);
                        }}
                      />
                      <Label className="form-label" htmlFor="assignStatus">
                        Store
                      </Label>
                    </FormGroup>
                    <FormGroup className="col mb-0">
                      <Select
                        type="text"
                        id="Status"
                        name="Status"
                        className="form-control selectbox"
                        menuPlacement="auto"
                        options={statusList}
                        value={status}
                        onChange={(selectedOption) => {
                          // setSelectedRole(selectedOption);
                          setStatus(selectedOption ? selectedOption : "");
                          setExportFilterData(true);
                        }}
                        isClearable
                      />
                      <Label className="form-label" htmlFor="assignStatus">
                        Status
                      </Label>
                    </FormGroup>
                    <FormGroup className="col mb-0">
                      <Select
                        type="text"
                        id="category"
                        name="category"
                        className="form-control selectbox"
                        menuPlacement="auto"
                        options={optionsIsuue}
                        value={category}
                        onChange={(selectedOption) => {
                          // setSelectedRole(selectedOption);
                          setCategory(selectedOption || "");
                          setExportFilterData(true);
                        }}
                        isClearable
                      />
                      <Label className="form-label" htmlFor="category">
                        Category
                      </Label>
                    </FormGroup>
                    <FormGroup className="col mb-0">
                      <Select
                        value={priority}
                        menuPlacement="auto"
                        onChange={(selectedOption) => {
                          // setSelectedCategory(selectedOption);
                          setPriority(selectedOption);
                          setExportFilterData(true);
                        }}
                        options={optionsPriority}
                        className={"form-control selectbox"}
                        isClearable={true}
                        styles={colourStyles}
                        id="priority"
                        name="priority"
                      />
                      <Label className="form-label" htmlFor="assignStatus">
                        Priority
                      </Label>
                    </FormGroup>
                    <FormGroup className="col mb-0">
                      <Select
                        type="text"
                        id="Vendor"
                        name="Vendor"
                        className="form-control selectbox"
                        menuPlacement="auto"
                        options={vendorList}
                        value={vendor}
                        onChange={(selectedOption) => {
                          // setSelectedRole(selectedOption);
                          setVendor(selectedOption ? selectedOption : "");
                          setExportFilterData(true);
                        }}
                        isClearable
                      />
                      <Label className="form-label" htmlFor="assignStatus">
                        Vendor
                      </Label>
                    </FormGroup>
                    <FormGroup className="col mb-0">
                      <DatePicker
                        selected={startDate}
                        onChange={(dates) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        placeholderText="Select a date range"
                        className="form-control"
                      />
                      <Label className="form-label">Date</Label>
                    </FormGroup>
                  </Row>
                  <Button
                    className="my-tooltip cancel_filter"
                    data-tooltip-content={"Close filter"}
                    color="link"
                    onClick={handelShowFIlter}
                  >
                    <CloseSvg />
                  </Button>
                </Col>
              </Row>
            </form>
          </>
        </CardBody>
      </Card>
    </>
  );
}
const mapStateToProps = ({ category, vendor, Members, SupportTicket }) => {
  let categoryList =
    category?.getCategory?.list?.length > 0
      ? category.getCategory.list.map((data) => {
          return {
            label: data.catName,
            value: data.id,
          };
        })
      : [];
  let vendorList =
    vendor?.getVendor?.list?.length > 0
      ? vendor.getVendor.list.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
  let statusList = [];
  if (
    SupportTicket.getStatus?.list &&
    SupportTicket.getStatus?.list?.length > 0
  ) {
    statusList = SupportTicket.getStatus.list.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }
  let allMemberList = [];
  if (Members?.getMembers?.list && Members?.getMembers?.list?.length > 0) {
    allMemberList = Members.getMembers.list.map((data) => {
      return {
        label: data.storeNameRegular + ` - ` + data.hraMemberId,
        value: data.hraMemberId,
      };
    });
  }

  return {
    allMemberList,
    vendorList,
    statusList,
    categoryList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorList: (data) => dispatch(getVendorListRequest(data)),
    getStatusRequest: () => dispatch(getStatusRequest()),
    getMemberListReq: (data) => dispatch(getMemberListRequest(data)),
    getCategoryList: () => dispatch(getCategoryListRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFilter);
