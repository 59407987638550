import { DANGER } from "../constant/commonConstants";
import { __t } from "../i18n/translator";
import { ApiResponseMessage } from "./tosterHelper";

export const handleSingleFileUpload = (
  setImageFile,
  setFieldValue,
  id,
  setImageFileData,
  e,
  acceptAll
) => {
  const chosenFile = e.target.files[0];


  const isValidImage =
    chosenFile?.type === "image/png" || chosenFile?.type === "image/jpeg";
  if (!isValidImage && !acceptAll) {
    ApiResponseMessage(__t("validations.common.invalid_image"), DANGER);
    return false;
  }
  if (isValidImage || acceptAll ) {
    if (chosenFile) {
      setImageFile(chosenFile);
      if (setFieldValue)setFieldValue(id, chosenFile);
      const imageUrl = URL.createObjectURL(chosenFile);
      const fileNames = chosenFile.name;
      const imageFile = {
        fileNames,
        imageUrl: imageUrl,
      };
      setImageFileData(imageFile);
    }
  }
};
