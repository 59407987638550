import React, { useEffect, useRef, useState } from "react";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Label,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { DATE_FORMATER } from "../../../constant/commonConstants";
import { formatDateToMMDDYYYY } from "../../../helper/DataTimeHelpers";
import {
  addNewsLetterRequest,
  editNewsLetterRequest,
} from "../../../redux/admin/newsletter/action";
import { connect } from "react-redux";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import { handleSingleFileUpload } from "../../../helper/imageSingalHelper";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  DeleteSvg,
  FileImageSvg,
  ImageSvg,
  PdfSvg,
  ThumbnailSvg,
} from "../../../components/CommonSvg";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { isImage, isPDF } from "../../../utils/generalUtils";
import { getFileNameFromUrl } from "../../../utils/authUtils";
function AddNewsLetter({
  addNewsLetterRequest,
  editNewsLetterRequest,
  StatusNewsLetter,
  StatusEditNewsLetter,
}) {
  const location = useLocation();
  const value = location.state?.data;

  const initialValues = {
    title: value?.title ? value.title : "",
    description: value?.description ? value.description : "",
    URL: value?.url ? value.url : "",
    startDate: value?.startDate ? value.startDate : "",
    endDate: value?.endDate ? value.endDate : "",
    imageFile: value?.documentPath ? value.documentPath : "",
    thumbnail: value?.thumbnailPath ? value.thumbnailPath : "",
  };

  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState();
  const [imageFileData, setImageFileData] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState();
  const [thumbnailFileData, setThumbnailFileData] = useState(null);

  const [addSuccess, setAddSuccess] = useState(false);
  const [tab, setTab] = useState("files");
  useEffect(() => {
    if (value?.documentPath) {
      setImageFileData({
        fileNames: getFileNameFromUrl(value?.documentPath),
        imageUrl: value?.documentPath,
      });
    }
    setImageFile(value?.documentPath);
  }, [value?.documentPath]);
  useEffect(() => {
    if (value?.thumbnailPath) {
      setThumbnailFileData({
        fileNames: getFileNameFromUrl(value?.thumbnailPath),
        imageUrl: value?.thumbnailPath,
      });
    }
    setThumbnailFile(value?.thumbnailPath);
  }, [value?.thumbnailPath]);
  useEffect(() => {
    if (value?.url) {
      setTab("url");
    }
  }, [value?.url]);
  const hendalerNewsLaterr = async (values) => {
    if (validator.current.allValid() && (values.imageFile || values.URL)) {
      const formData = new FormData();
      if (value?.id) {
        formData.append("newsletterId", value.id);
      }
      formData.append("title", values.title);
      formData.append("description", values.description);
      // formData.append("imageFile", values.imageFile);
      formData.append("endDate", formatDateToMMDDYYYY(values.endDate));
      formData.append("startDate", formatDateToMMDDYYYY(values.startDate));
      formData.append("thumbnail", thumbnailFile);

      if (values.URL) {
        formData.append("url", values.URL);
        formData.append("imageFile", "");
      } else if (values.imageFile) {
        formData.append("url", "");
        formData.append("imageFile", imageFile);
      }
      if (value?.id) {
        editNewsLetterRequest(formData);
        setAddSuccess(true);
      } else {
        addNewsLetterRequest(formData);
        setAddSuccess(true);
      }
    } else {
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    if (addSuccess === true && StatusNewsLetter === true) {
      navigate("/admin/newsletter");
      setAddSuccess(false);
    }
  }, [StatusNewsLetter]);
  useEffect(() => {
    if (addSuccess === true && StatusEditNewsLetter === true) {
      navigate("/admin/newsletter");
      setAddSuccess(false);
    }
  }, [StatusEditNewsLetter]);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const clearFileInput = (data) => {
    if (data === "Thumbnail") {
      inputRef.current.value = "";
    } else {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <CommonBackHeader
        backPath={`/admin/newsletter`}
        HeaderName={value?.title ? value?.title : "Add Newsletter"}
      />
      <Container>
        <Formik initialValues={initialValues} onSubmit={hendalerNewsLaterr}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="add-newsletter">
              <Row>
                <Col lg={8}>
                  <Card className="mb-3">
                    <CardBody>
                      <h6 className="separate-head">Newsletter Details</h6>
                      <Row>
                        {!value?.title && (
                          <Col>
                            <div className="form-group">
                              <Field
                                className="form-control"
                                type="text"
                                placeholder="Title"
                                id="title"
                                name="title"
                              />
                              <Label className="form-label" htmlFor="title">
                                Title{" "}
                                <span className="text-danger required">*</span>
                              </Label>
                              {validator.current.message(
                                __t("validations.promotion.title"),
                                values.title,
                                "required",
                                {
                                  messages: {
                                    required: __t(
                                      "validations.promotion.title"
                                    ),
                                  },
                                }
                              )}
                            </div>
                          </Col>
                        )}
                        <Col className="no-floating-label">
                          <FormGroup>
                            <ReactDatePicker
                              selected={
                                values.startDate
                                  ? new Date(values.startDate)
                                  : null
                              }
                              className="form-control"
                              name="startDate"
                              placeholderText="Select Start Date"
                              dateFormat={DATE_FORMATER}
                              // customInput={<CustomInput />}
                              minDate={new Date()}
                              onChange={(opt) => {
                                if (values.endDate) {
                                  if (opt && opt > new Date(values.endDate)) {
                                    setFieldValue("endDate", null);
                                  }
                                }
                                setFieldValue("startDate", opt);
                              }}
                              isClearable
                            />
                            <Label className="form-label" htmlFor="title">
                              Start Date{" "}
                              <span className="text-danger required">*</span>
                            </Label>
                            {validator.current.message(
                              __t("validations.promotion.startDate"),
                              values.startDate,
                              "required",
                              {
                                messages: {
                                  required: __t(
                                    "validations.promotion.startDate"
                                  ),
                                },
                              }
                            )}
                          </FormGroup>
                        </Col>
                        <Col className="no-floating-label">
                          <FormGroup>
                            <ReactDatePicker
                              minDate={
                                values.startDate
                                  ? new Date(values.startDate)
                                  : new Date()
                              }
                              placeholderText="Select End Date"
                              selected={
                                values.endDate
                                  ? new Date(values.endDate)
                                  : undefined
                              }
                              className="form-control"
                              name="endDate"
                              dateFormat={DATE_FORMATER}
                              onChange={(opt) => {
                                const formattedOpt = opt ? opt : null; // Ensure formatDateToMMDDYYYY returns a valid date
                                if (values.startDate) {
                                  if (opt && opt < new Date(values.startDate)) {
                                    setFieldValue("endDate", null);
                                  }
                                }
                                setFieldValue("endDate", formattedOpt);
                              }}
                              isClearable
                            />
                            <Label className="form-label" htmlFor="title">
                              End Date{" "}
                              <span className="text-danger required">*</span>
                            </Label>
                            {validator.current.message(
                              __t("validations.promotion.endDate"),
                              values.endDate,
                              "required",
                              {
                                messages: {
                                  required: __t(
                                    "validations.promotion.endDate"
                                  ),
                                },
                              }
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="form-group mb-0">
                        <Field
                          as="textarea"
                          rows={4}
                          className="form-control"
                          id="description"
                          placeholder="description.."
                          name="description"
                        />
                        <Label className="form-label" htmlFor="description">
                          Description{" "}
                          <span className="text-danger required">*</span>
                        </Label>
                        {validator.current.message(
                          __t("validations.promotion.description"),
                          values.description,
                          "required",
                          {
                            messages: {
                              required: __t(
                                "validations.promotion.description"
                              ),
                            },
                          }
                        )}
                      </div>
                    </CardBody>
                  </Card>
                  <Button type="submit" color="primary" className="float-end">
                    Submit
                  </Button>
                </Col>
                <Col lg={4}>
                  <Card className="mb-3">
                    <CardBody>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h6 className="separate-head mb-0">Newsletter Files</h6>
                        <Nav tabs>
                          <NavItem>
                            <span
                              className={tab === "files" ? "active" : ""}
                              onClick={async () => {
                                if (tab !== "files") {
                                  if (tab) {
                                    const response = await commonConfirmBox(
                                      `Are you sure you want to remove the URL and add the files?`
                                    );
                                    if (response) {
                                      setFieldValue("URL", "");
                                      setTab("files");
                                    }
                                  }
                                }
                              }}
                            >
                              Files
                            </span>
                          </NavItem>
                          <NavItem>
                            <span
                              className={tab === "url" ? "active" : ""}
                              onClick={async () => {
                                if (tab !== "url") {
                                  if (tab) {
                                    const response = await commonConfirmBox(
                                      `Are you sure you want to remove the file and add the URL?`
                                    );
                                    if (response) {
                                      setFieldValue("imageFile", "");
                                      setTab("url");
                                      setImageFileData("");
                                      setImageFile("");
                                      clearFileInput();
                                    }
                                  }
                                }
                              }}
                            >
                              URL
                            </span>
                          </NavItem>
                        </Nav>
                      </div>
                      {tab === "files" && (
                        <div className="dropzone dropzone_img">
                          <label
                            htmlFor="imageFiles"
                            className="dropzone-container mb-2"
                          >
                            <div className="file-icon">
                              <FileImageSvg />
                            </div>
                            <div className="dropzone-title text-center">
                              Drag and drop your files or{" "}
                              <span className="browse">browse</span> your files{" "}
                              <span className="text-danger required">*</span>
                              <small className="w-100 d-inline-block text-center">
                                Accepted file types : .jpeg, .jpg, .png, .pdf
                              </small>
                              <small className="w-100 d-inline-block text-center">
                                Image size upto 5 MB
                              </small>
                            </div>
                            <input
                              id="imageFile"
                              name="imageFile"
                              type="file"
                              accept=".pdf, image/*"
                              className="form-control"
                              ref={fileInputRef}
                              onChange={(e) => {
                                handleSingleFileUpload(
                                  setImageFile,
                                  setFieldValue,
                                  "imageFile",
                                  setImageFileData,
                                  e,
                                  true
                                );
                                setFieldValue("URL", "");
                              }}
                            />
                          </label>

                          {validator.current.message(
                            __t("validations.common.file"),
                            values.imageFile,
                            "required",
                            {
                              messages: {
                                required: __t("validations.common.file"),
                              },
                            }
                          )}
                          {imageFileData && (
                            <>
                              {imageFileData &&
                              isImage(imageFileData?.fileNames) ? (
                                <div className={"attachment-data"}>
                                  <div className="attachment-head">
                                    <a
                                      href={imageFileData?.imageUrl}
                                      target="_blank"
                                      className="file-box"
                                    >
                                      <img
                                        alt="News Letter Cover image"
                                        className="img_pre"
                                        src={imageFileData?.imageUrl}
                                      />
                                    </a>

                                    <a
                                      onClick={async () => {
                                        const response = await commonConfirmBox(
                                          "Do you want to remove this image?"
                                        );
                                        if (response) {
                                          setFieldValue("imageFile", "");
                                          setImageFileData("");
                                          setImageFile("");
                                          clearFileInput();
                                        }
                                      }}
                                      className="remove-box"
                                      title="remove"
                                    >
                                      <DeleteSvg />
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <div className={"attachment-data"}>
                                  <span className="docfile w-100">
                                    <PdfSvg /> {imageFileData?.fileNames}
                                  </span>
                                  <a
                                    onClick={async () => {
                                      const response = await commonConfirmBox(
                                        isImage(imageFileData?.fileNames)  ?        "Do you want to remove this image?" : "Do you want to remove this pdf?" 
                                      );
                                      if (response) {
                                        setFieldValue("imageFile", null);
                                        setImageFileData("");
                                        setImageFile("");
                                        clearFileInput();
                                      }
                                    }}
                                    className="remove-box"
                                    title="remove"
                                  >
                                    <DeleteSvg />
                                  </a>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                      {tab === "url" && (
                        <div className="form-group mb-0">
                          <Field
                            rows={4}
                            className="form-control"
                            id="URL"
                            placeholder="URL.."
                            name="URL"
                            type={"url"}
                          />
                          <Label className="form-label" htmlFor="URL">
                            PDF url
                            <span className="text-danger required">*</span>
                          </Label>
                          {validator.current.message(
                            __t("validations.common.file"),
                            values.URL,
                            "required",
                            {
                              messages: {
                                required: __t("validations.common.file"),
                              },
                            }
                          )}
                        </div>
                      )}
                      {/* <div className="form-group">
                        <Label htmlFor="imageFile">Image File:</Label>
                        <input
                          id="imageFile"
                          name="imageFile"
                          type="file"
                          className="form-control"
                          accept="image/*"
                          onChange={(e) =>
                            handleSingleFileUpload(
                              setImageFile,
                              setFieldValue,
                              "imageFile",
                              setImageFileData,
                              e
                            )
                          }
                        />
                        
                      </div> */}
                      {/* {imageFileData && <img src={imageFileData?.imageUrl} />} */}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <h6 className="separate-head mb-2">Thumbnail</h6>
                      <div className="dropzone dropzone_img thumbnail-dropzone">
                        <label
                          htmlFor="thumbnail"
                          className="dropzone-container mb-2"
                        >
                          <div className="file-icon">
                            <ThumbnailSvg />
                          </div>
                          <div className="dropzone-title text-center">
                            Drag and drop your files or{" "}
                            <span className="browse">browse</span> your files{" "}
                            <span className="text-danger required">*</span>
                            <small className="w-100 d-inline-block text-center">
                              Accepted file types : .jpeg, .jpg, .png
                            </small>
                            <small className="w-100 d-inline-block text-center">
                              Image size upto 5 MB
                            </small>
                          </div>
                          <input
                            id="thumbnail"
                            name="thumbnail"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            ref={inputRef}
                            onChange={(e) => {
                              handleSingleFileUpload(
                                setThumbnailFile,
                                setFieldValue,
                                "thumbnail",
                                setThumbnailFileData,
                                e,
                                true
                              );
                            }}
                          />
                        </label>

                        {thumbnailFileData && (
                          <div className={"attachment-data"}>
                            <div className="attachment-head">
                              <a
                                href={thumbnailFileData?.imageUrl}
                                target="_blank"
                                className="file-box"
                              >
                                <img
                                  alt="Newsletter Cover image"
                                  className="img_pre"
                                  src={thumbnailFileData?.imageUrl}
                                />
                              </a>

                              <a
                                onClick={async () => {
                                  const response = await commonConfirmBox(
                                    "Do you want to remove this image?"
                                  );
                                  if (response) {
                                    setFieldValue("thumbnail", null);
                                    setThumbnailFileData("");
                                    setThumbnailFile("");
                                    clearFileInput("Thumbnail");
                                  }
                                }}
                                className="remove-box"
                                title="remove"
                              >
                                <DeleteSvg />
                              </a>
                            </div>
                          </div>
                        )}
                        {validator.current.message(
                          __t("validations.common.thumbnail"),
                          values.thumbnail,
                          "required",
                          {
                            messages: {
                              required: __t("validations.common.thumbnail"),
                            },
                          }
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
const mapStateToProps = ({ NewsLetter }) => {
  let StatusNewsLetter = NewsLetter?.addNewsLetter?.status
    ? NewsLetter?.addNewsLetter?.status
    : false;
  let StatusEditNewsLetter = NewsLetter?.editNewsLetter?.status
    ? NewsLetter?.editNewsLetter?.status
    : false;
  return { StatusNewsLetter, StatusEditNewsLetter };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNewsLetterRequest: (data) => dispatch(addNewsLetterRequest(data)),
    editNewsLetterRequest: (data) => dispatch(editNewsLetterRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewsLetter);
