import React, { useRef, useState } from "react";
import { Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import {
  AddimageSvg,
  DeleteSvg,
  ImageSvg,
} from "../../../components/CommonSvg";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { handleSingleFileUpload } from "../../../helper/imageSingalHelper";
import { Field, Form, Formik } from "formik";
import { config } from "./venderHelper";
import Vendorimg from "../../../assets/images/contact_bg.jpg";
import JoditEditor from "jodit-react";
import { useEffect } from "react";
import {
  getFileNameFromURL,
  isValidHttpUrl,
} from "../../../utils/generalUtils";
import { __t } from "../../../i18n/translator";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER } from "../../../constant/commonConstants";

export default function HTMLView({ dataValue, setRemovedImages }) {
  const [imageFile, setImageFile] = useState();
  const [imageFileData, setImageFileData] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { values, setFieldValue, validator } = dataValue;
  const editor = useRef(null);
  const fileInputRef = useRef(null);
  const clearFileInput = () => {
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = "";
    }
  };
  const [allImages, setAllImages] = useState([]);
  const handleFileChange = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    const isValidImage = chosenFiles.every((file) =>
      file.type.startsWith("image/")
    );
    if (!isValidImage) {
      ApiResponseMessage(
        "Invalid image file(s). Please select only image files.",
        DANGER
      );
      return false;
    } else {
      if (chosenFiles.length > 0) {
        const validImageData = chosenFiles.map((file) => ({
          fileName: file.name,
          imageUrl: URL.createObjectURL(file),
        }));
        setAllImages([...allImages, ...chosenFiles]);
        setSelectedFiles((prevSelectedFiles) => [
          ...prevSelectedFiles,
          ...validImageData,
        ]);
        setFieldValue("htmlFiles", [...allImages, ...chosenFiles]);
      }
    }
  };

  const handleRemoveImage = (index, data) => {
    if (data?.id) {
      setRemovedImages((prevRemovedImages) => [
        ...prevRemovedImages,
        { id: data.id, vendorId: data.vendorId },
      ]);
    }

    // Remove the image at the specified index from selectedFiles
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    // Remove the image at the specified index from allImages
    const updatedAllImages = [...allImages];
    updatedAllImages.splice(index, 1);
    setAllImages(updatedAllImages);
    setFieldValue("htmlFiles", updatedAllImages);
  };

  useEffect(() => {
    if (values?.vendorId) {
      if (values?.htmlBannerImg && isValidHttpUrl(values?.htmlBannerImg)) {
        setImageFileData({
          fileNames: getFileNameFromURL(values?.htmlBannerImg),
          imageUrl: values?.htmlBannerImg,
        });
      }
      if (Array.isArray(values?.htmlFiles) && values.vendorId) {
        const imageFilesData = values.htmlFiles.map((url) => ({
          fileNames: getFileNameFromURL(url.imageName),
          imageUrl: url.imageName,
          id: url.id,
          vendorId: url.vendorId,
        }));
        setAllImages(imageFilesData);
        setSelectedFiles(imageFilesData);
      }
    }
  }, [values?.vendorId]);
  useEffect(() => {
    setFieldValue("htmlFileUrl", "");
    setFieldValue("pdfAttachment", "");
  }, []);
  return (
    <div>
      <Card className="html_card">
        <CardBody>
          <span className="html_title">
            Picture <span className="text-danger required">*</span>
          </span>
          <div className="dropzone banner_dropzone">
            <label htmlFor="htmlBannerImg" className="dropzone-container mb-2">
              {!imageFileData && (
                <>
                  {" "}
                  <div className="file-icon">
                    <ImageSvg />
                  </div>
                  <div className="dropzone-title text-center">
                    Drag and drop your picture or{" "}
                    <span className="browse">browse</span> your picture{" "}
                    <small className="w-100 d-inline-block text-center">
                      Accepted file types : .jpeg, .jpg, .png{" "}
                    </small>
                    <small className="w-100 d-inline-block text-center">
                      File size upto 1 MB (Min width: 1280px){" "}
                    </small>
                  </div>
                  <input
                    id="htmlBannerImg"
                    name="htmlBannerImg"
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleSingleFileUpload(
                        setImageFile,
                        setFieldValue,
                        "htmlBannerImg",
                        setImageFileData,
                        e,
                        true
                      );
                      setFieldValue("htmlTemplate", true);
                    }}
                    className="form-control"
                  />
                </>
              )}
              {imageFileData && (
                <div className={"attachment-data"}>
                  <div className="attachment-head">
                    <a
                      href={imageFileData?.imageUrl}
                      target="_blank"
                      className="file-box"
                    >
                      <img
                        alt="News Letter Cover image"
                        className="img_pre"
                        src={imageFileData?.imageUrl}
                      />
                    </a>

                    <a
                      onClick={async () => {
                        const response = await commonConfirmBox(
                          "Do you want to remove this image?"
                        );
                        if (response) {
                          setFieldValue("htmlBannerImg", null);
                          setImageFileData("");
                          setImageFile("");
                          clearFileInput();
                        }
                      }}
                      className="remove-box"
                      title="remove"
                    >
                      <DeleteSvg />
                    </a>
                  </div>
                </div>
              )}
              {validator.current.message(
                __t("validations.vendor.select_image"),
                values.htmlBannerImg,
                "required",
                {
                  messages: {
                    required: __t("validations.vendor.select_image"),
                  },
                }
              )}
            </label>
          </div>

          <FormGroup className="col-12 mb-0 mt-3 mb-3 html_text_editor">
            <span className="html_title text-light">
              Text Editor <span className="text-danger required">*</span>
            </span>
            <JoditEditor
              ref={editor}
              config={config}
              rows="2"
              name="htmlTemplateDescription "
              value={values?.htmlTemplateDescription}
              onBlur={(newContent) => {
                setFieldValue("htmlTemplateDescription", newContent);
              }}
            />
            {validator.current.message(
              __t("validations.vendor.html_template_description"),
              values.htmlTemplateDescription,
              "required",
              {
                messages: {
                  required: __t("validations.vendor.html_template_description"),
                },
              }
            )}
          </FormGroup>

          <div className="vendor_images">
            <span className="html_title">Vendor images</span>
            <div className="dropzone dropzone_img vendors_dropzone">
              <>
                <ul>
                  {selectedFiles?.length > 0 &&
                    selectedFiles.map((data, index) => {
                      return (
                        <li key={index}>
                          <div className="attachment-data">
                            <div className="attachment-head">
                              <a
                                href={data.imageUrl}
                                target="_blank"
                                className="file-box"
                              >
                                <img
                                  alt="promotion_img"
                                  className="img_pre"
                                  src={data.imageUrl} // Make sure to use the correct image source here
                                />
                              </a>
                              <a
                                className="remove-box"
                                title="remove"
                                onClick={() => handleRemoveImage(index, data)}
                              >
                                <DeleteSvg />
                              </a>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  <li>
                    <label
                      htmlFor="imageFiles"
                      className="dropzone-container mb-2"
                    >
                      <div className="file-icon">
                        <AddimageSvg />
                      </div>
                      <div className="dropzone-title text-center">
                        Drag and drop images or{" "}
                        <span className="browse">browse</span> images{" "}
                        {/* <small className="w-100 d-inline-block text-center">
                            Accepted file types : .jpeg, .jpg, .png{" "}
                          </small>
                          <small className="w-100 d-inline-block text-center">
                            File size upto 1 MB (Min width: 1280px){" "}
                          </small> */}
                      </div>
                      <input
                        id="imageFiles"
                        name="imageFiles"
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="form-control"
                        multiple
                      />
                    </label>
                  </li>
                </ul>
              </>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button type="submit" color="primary">
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
