import * as type from "./type";

const initialState = {
  getTickets: null,
  ticketsColoumn: null,
  getColoumn: null,
  getStatus: null,
  syncTicket: null,
  updateStatus: null,
};

const SupportTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_SUPPORT_TICKET_REQUEST:
      return { ...state, getTickets: null };
    case type.GET_SUPPORT_TICKET_SUCCESS:
      return { ...state, getTickets: action.payload };
    case type.SET_COLUMNS_REQUEST:
      return { ...state, ticketsColoumn: null };
    case type.SET_COLUMNS_SUCCESS:
      return { ...state, ticketsColoumn: action.payload };
    case type.GET_COLUMNS_REQUEST:
      return { ...state, getColoumn: null };
    case type.GET_COLUMNS_SUCCESS:
      return { ...state, getColoumn: action.payload };
    case type.GET_STATUS_REQUEST:
      return { ...state, getStatus: null };
    case type.GET_STATUS_SUCCESS:
      return { ...state, getStatus: action.payload };
    case type.SYNC_SUPPORT_TICKET_REQUEST:
      return { ...state, syncTicket: null };
    case type.SYNC_SUPPORT_TICKET_SUCCESS:
      return { ...state, syncTicket: action.payload };
    case type.UPDATE_STATUS_REQUEST:
      return { ...state, updateStatus: null };
    case type.UPDATE_STATUS_SUCCESS:
      return { ...state, updateStatus: action.payload };
    default:
      return state;
  }
};

export default SupportTicketReducer;
