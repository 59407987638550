export const GET_MEMBER_PROMOTION_VIEW_DETAILS_REQUEST =
  "GET_MEMBER_PROMOTION_VIEW_DETAILS_REQUEST";
export const GET_MEMBER_PROMOTION_VIEW_DETAILS_SUCCESS =
  "GET_MEMBER_PROMOTION_VIEW_DETAILS_SUCCESS";

export const GET_MEMBER_PROMOTION_LIST_REQUEST =
  "GET_MEMBER_PROMOTION_LIST_REQUEST";
export const GET_MEMBER_PROMOTION_LIST_SUCCESS =
  "GET_MEMBER_PROMOTION_LIST_SUCCESS";

export const GET_LATEST_PROMOTION_LIST_REQUEST =
  "GET_LATEST_PROMOTION_LIST_REQUEST";
export const GET_LATEST_PROMOTION_LIST_SUCCESS =
  "GET_LATEST_PROMOTION_LIST_SUCCESS";

export const GET_MEMBER_PROMOTION_LIST_ON_SCROLL_REQUEST =
  "GET_MEMBER_PROMOTION_LIST_ON_SCROLL_REQUEST";
export const GET_MEMBER_PROMOTION_LIST_ON_SCROLL_SUCCESS =
  "GET_MEMBER_PROMOTION_LIST_ON_SCROLL_SUCCESS";

export const CLEAR_REPONSE = "CLEAR_REPONSE";
