export const GET_MEMBER_FORGOT_PASSWORD_REQUEST =
  "GET_MEMBER_FORGOT_PASSWORD_REQUEST";
export const GET_MEMBER_FORGOT_PASSWORD_SUCCESS =
  "GET_MEMBER_FORGOT_PASSWORD_SUCCESS";
export const GET_MEMBER_FORGOT_LOGIN_REQUEST =
  "GET_MEMBER_FORGOT_LOGIN_REQUEST";
export const GET_MEMBER_FORGOT_LOGIN_SUCCESS =
  "GET_MEMBER_FORGOT_LOGIN_SUCCESS";
export const MEMBER_RESET_PASSWORD_REQUEST = "MEMBER_RESET_PASSWORD_REQUEST";
export const MEMBER_RESET_PASSWORD_SUCCESS = "MEMBER_RESET_PASSWORD_SUCCESS";
export const CLEAR_MEMBER_FORGOT_PASSWORD_REPONSE =
  "CLEAR_MEMBER_FORGOT_PASSWORD_REPONSE";
