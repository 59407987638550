import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Formik, Form } from "formik";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import DatePicker from "react-datepicker";
import {
  Add_Svg,
  DeleteSvg,
  ImageSvg,
  PdfSvg,
} from "../../../components/CommonSvg";
import SimpleReactValidator from "simple-react-validator";
import {
  formatDateToMMDDYYYY,
  getTodayDate,
} from "../../../helper/DataTimeHelpers";
import {
  addPromotionListRequest,
  deleteImageRequest,
  editPromotionListRequest,
} from "../../../redux/admin/promotion/action";
import { connect } from "react-redux";
import { __t } from "../../../i18n/translator";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import { pdfUploadHelper } from "../../../helper/pdfUploadHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomInput, promotionInitialState } from "./CommonFunction";
import { getImageFileNameFromURL } from "../../../utils/generalUtils";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, DATE_FORMATER } from "../../../constant/commonConstants";
import _ from "lodash";
import { commonConfirmBox } from "../../../helper/messagehelpers";
function AddPromotion(props) {
  const {
    AddPromoRequest,
    AddPromotionStatus,
    EditPromotionStatus,
    editPromoRequest,
    deleteImageRequest,
    deleteImageStatus,
  } = props;
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [imgUpload, setImgUpload] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [addSuccess, setAddSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [bannerUrl, setBannerUrl] = useState(null);
  const [bannerName, setBannerName] = useState();
  const [showCustomField, setShowCustomField] = useState(true);
  const [otherFileImage, setOtherFileImage] = useState([]);
  const [pdfFile, setPdfFile] = useState();
  const [pdfFileData, setPdfFileData] = useState(null);
  const promotionData = promotionInitialState(location?.state?.promotionsData);
  const [pdfFileEdit, setPdfFileEdit] = useState(
    location?.state?.promotionsData?.PromotionalOffersOthers.filter(
      (d) => d.documentType === "DOCUMENT" && d
    ) || []
  );

  const [uploadFiles, setUploadFiles] = useState(
    location?.state?.promotionsData?.PromotionalOffersOthers
  );
  const [customfiledData, setCustomfiledData] = useState([
    { label: "Vendor", value: "" },
    { label: "Brand", value: "" },
    { label: "Item", value: "" },
    { label: "Promotion", value: "" },
    { label: "Suggested Retail", value: "" },
    { label: "", value: "" },
  ]);
  const [deleteFile, setDeleteFile] = useState([]);
  const dispatchData = {
    searchText: "",
    startDate: "",
    endDate: "",
    pagination: {
      limit: 10,
      page: 1,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };
  const removeCustomfiledData = (index) => {
    const newCustomfiledData = customfiledData.filter((_, i) => i !== index);
    setCustomfiledData(newCustomfiledData);
  };
  const handleRemoveImage = (removedImageName, index, setFieldValue) => {
    const updatedImgUpload = [...imgUpload];
    const otherFileImages = [...otherFileImage];

    if (id !== undefined) {
      if (removedImageName?.promotionalOfferId) {
        setDeleteFile([...deleteFile, removedImageName]);
      }
      if (deleteImageStatus === true || !removedImageName?.promotionalOfferId) {
        const updatedUploadFiles = [...uploadFiles];
        updatedUploadFiles.splice(index, 1);
        setUploadFiles(updatedUploadFiles);
      }
    }
    if (bannerUrl === index) {
      setBannerUrl(null);
      setBannerName(null);
    }
    const filteredOtherFileImages = otherFileImages.filter(
      (image) => image.name !== removedImageName?.fileName
    );
    updatedImgUpload.splice(index, 1);
    setFieldValue("imageFiles", updatedImgUpload);
    setImgUpload(updatedImgUpload);
    setOtherFileImage(filteredOtherFileImages);
  };
  // const showHandel = () => {
  //   setShowCustomField(!showCustomField);
  //   if (customfiledData.length === 0) {
  //     setCustomfiledData([{ label: "", value: "" }]);
  //   }
  // };
  const updateSelectedFiles = (index, fileName) => {
    setBannerUrl(index);
    setBannerName(fileName);
    if (bannerUrl === index) {
      setBannerUrl(null);
      setBannerName(null);
    }
  };

  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      if (id !== undefined) {
        if (values === promotionData) {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        } else {
          const formData = new FormData();
          formData.append("promotionalOfferId", id);
          formData.append("bannerFile", bannerUrl);
          formData.append("description", values.description);
          formData.append("endDate", formatDateToMMDDYYYY(values.endDate));
          formData.append("startDate", formatDateToMMDDYYYY(values.startDate));
          formData.append(
            "documentFiles",
            pdfFileEdit && JSON.stringify(pdfFileEdit)
          );
          formData.append("customFields", JSON.stringify(customfiledData));
          otherFileImage.forEach((file) =>
            formData.append("newImageUploads", file)
          );
          formData.append("bannerName", bannerName);
          formData.append("documentFiles", pdfFile);
          formData.append("imageFiles", JSON.stringify(uploadFiles));
          formData.append("title", values.title);
          editPromoRequest({ formData, dispatchData });
          setEditSuccess(true);
          if (deleteFile.length > 0) {
            deleteImageRequest({
              deleteFiles: deleteFile,
            });
          }
        }
      } else {
        const formData = new FormData();
        formData.append("bannerFile", bannerUrl);
        formData.append("description", values.description);
        formData.append("endDate", formatDateToMMDDYYYY(values.endDate));
        formData.append("startDate", formatDateToMMDDYYYY(values.startDate));
        formData.append("documentFiles", pdfFile);
        formData.append("customFields", JSON.stringify(customfiledData));
        otherFileImage.forEach((file) => formData.append("imageFiles", file));
        formData.append("title", values.title);
        AddPromoRequest({ formData, dispatchData });
        setAddSuccess(true);
      }
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      const editPdfData = {
        fileNames: getImageFileNameFromURL(pdfFileEdit[0]?.documentPath),
        pdfUrl: pdfFileEdit[0]?.documentPath,
        promotionalOfferId: pdfFileEdit[0]?.promotionalOfferId,
        id: pdfFileEdit[0]?.id,
      };
      setPdfFileData(editPdfData);
      const stringifyFields = location.state.promotionsData.customFields;
      setCustomfiledData(stringifyFields);
      if (stringifyFields.length > 0) {
        setShowCustomField(true);
      }
    }
    setBannerUrl(promotionData.bannerFile);
    setBannerName(promotionData.isBannerName);
    setPdfFile(promotionData.offerType);
    setImgUpload(promotionData.imageFiles);
    setUploadProgress(100);
  }, [id !== undefined && id !== null]);

  useEffect(() => {
    if (addSuccess === true && AddPromotionStatus === true) {
      navigate("/admin/promotion");
      setAddSuccess(false);
    }
  }, [AddPromotionStatus]);

  useEffect(() => {
    if (EditPromotionStatus === true && editSuccess === true) {
      navigate("/admin/promotion");
      setEditSuccess(false);
    }
  }, [EditPromotionStatus === undefined && editSuccess === true]);
  const handleCustomFiledsAdd = () => {
    setCustomfiledData([...customfiledData, { label: "", value: "" }]);
  };
  const handleLabelChange = (index, value) => {
    const updatedData = [...customfiledData];
    updatedData[index].label = value;
    setCustomfiledData(updatedData);
  };
  const handleValueChange = (index, value) => {
    const updatedData = [...customfiledData];
    updatedData[index].value = value;
    setCustomfiledData(updatedData);
  };
  const HandelAllClear = () => {
    setImgUpload([]);
    setOtherFileImage([]);
    if (id !== undefined) {
      setDeleteFile([
        ...deleteFile,
        ...uploadFiles.filter((i) => i.documentType !== "DOCUMENT"),
      ]);
    }
  };
  const fileInputRef = useRef(null);

  const clearFileInput = () => {
    fileInputRef.current.value = "";
  };
  return (
    <>
      <CommonBackHeader
        backPath={id ? `/admin/promotion/view/${id}` : `/admin/promotion`}
        HeaderName={id ? "Edit Promotion" : "Add Promotion"}
      />

      <Formik initialValues={promotionData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="form-horizontal form-login">
            <div className="container">
              <Row>
                <Col lg={8}>
                  <Card className=" p-0 mb-4">
                    <CardBody className="row">
                      <h6 className="separate-head">Product Details</h6>
                      <FormGroup className="col-6">
                        <Field
                          id="title"
                          name="title"
                          placeholder="Title"
                          className="form-control"
                          type="text"
                        />
                        <Label className="form-label" htmlFor="title">
                          Title <span className="text-danger required">*</span>
                        </Label>
                        {validator.current.message(
                          __t("validations.promotion.title"),
                          values.title,
                          "required",
                          {
                            messages: {
                              required: __t("validations.promotion.title"),
                            },
                          }
                        )}
                      </FormGroup>
                      <FormGroup className="col-6">
                        <div
                          className={`dropzone dropzone_pdf dropzone_hear ${pdfFileData !== null && pdfFileData !== undefined
                              ? "uploaded_pdf"
                              : ""
                            }`}
                        >
                          <label
                            htmlFor="documentFiles"
                            className="dropzone-container "
                          >
                            <div className="file-icon">
                              <PdfSvg />
                            </div>
                            {pdfFileData !== null &&
                              pdfFileData !== undefined ? (
                              <div className="attachment-data">
                                <div className="attachment-head">
                                  <a
                                    href={pdfFileData.pdfUrl}
                                    title={pdfFileData.fileNames}
                                    target="_blank"
                                    className="file-box"
                                  >
                                    <h6>{pdfFileData.fileNames}</h6>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="dropzone-title">
                                  <span className="browse">Choose file</span> to
                                  upload PDF <span className="text-danger required">*</span>
                                </div>
                                <input
                                  id="documentFiles"
                                  name="documentFiles"
                                  className="form-control pdf-upload"
                                  type="file"
                                  accept="application/pdf"
                                  onChange={(e) =>
                                    pdfUploadHelper(
                                      setPdfFile,
                                      setFieldValue,
                                      "documentFiles",
                                      setPdfFileData,
                                      e
                                    )
                                  }
                                />
                              </>
                            )}
                          </label>
                          {pdfFileData !== null &&
                            pdfFileData !== undefined && (
                              <a
                                onClick={async () => {
                                  const response = await commonConfirmBox(
                                    "Do you want to remove this document?"
                                  );
                                  if (response) {
                                    setPdfFileData(null);
                                    setFieldValue("documentFiles", null);
                                    setPdfFile(null);
                                    if (id !== undefined) {
                                      setDeleteFile([
                                        ...deleteFile,
                                        { ...pdfFileData },
                                      ]);
                                    }
                                    if (pdfFileData?.pdfUrl) {
                                      setUploadFiles(
                                        uploadFiles?.filter(
                                          (item) =>
                                            item.documentPath !==
                                            pdfFileData?.pdfUrl
                                        )
                                      );
                                    }
                                  }
                                }}
                                className="remove-box"
                                title="remove"
                              >
                                <DeleteSvg />
                              </a>
                            )}
                          {validator.current.message(
                            __t("validations.promotion.offerType"),
                            values.documentFiles,
                            "required",
                            {
                              messages: {
                                required: __t(
                                  "validations.promotion.offerType"
                                ),
                              },
                            }
                          )}
                        </div>
                      </FormGroup>

                      <Col lg={6} className="no-floating-label">
                        <FormGroup className="mb-0">
                          <DatePicker
                            selected={
                              values.startDate
                                ? new Date(values.startDate)
                                : null
                            }
                            className="form-control"
                            name="startDate"
                            placeholderText="Select Start Date"
                            dateFormat={DATE_FORMATER}
                            customInput={<CustomInput />}
                            minDate={getTodayDate()}
                            onChange={(opt) => {
                              if (values.endDate) {
                                if (opt && opt > new Date(values.endDate)) {
                                  setFieldValue("endDate", null);
                                }
                              }
                              setFieldValue("startDate", opt);
                            }}
                            isClearable
                          />
                          <Label className="form-label" htmlFor="title">
                            Start Date <span className="text-danger required">*</span>
                          </Label>
                          {validator.current.message(
                            __t("validations.promotion.startDate"),
                            values.startDate,
                            "required",
                            {
                              messages: {
                                required: __t(
                                  "validations.promotion.startDate"
                                ),
                              },
                            }
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="no-floating-label">
                        <FormGroup className="mb-0">
                          <Field
                            component={DatePicker}
                            placeholderText="Select End Date"
                            selected={
                              values.endDate
                                ? new Date(values.endDate)
                                : undefined
                            }
                            className="form-control"
                            name="endDate"
                            minDate={
                              values.startDate
                                ? new Date(values.startDate)
                                : getTodayDate()
                            }
                            dateFormat={DATE_FORMATER}
                            onChange={(opt) => {
                              const formattedOpt = opt ? opt : null; // Ensure formatDateToMMDDYYYY returns a valid date
                              if (values.startDate) {
                                if (opt && opt < values.startDate) {
                                  setFieldValue("endDate", null);
                                }
                              }
                              setFieldValue("endDate", formattedOpt);
                            }}
                            isClearable
                          />
                          <Label className="form-label" htmlFor="title">
                            End Date <span className="text-danger required">*</span>
                          </Label>
                          {validator.current.message(
                            __t("validations.promotion.endDate"),
                            values.endDate,
                            "required",
                            {
                              messages: {
                                required: __t("validations.promotion.endDate"),
                              },
                            }
                          )}
                        </FormGroup>
                      </Col>

                      <FormGroup className="col-12 mb-0 mt-3">
                        <Field
                          as="textarea"
                          rows={4}
                          name="description"
                          placeholder="description.."
                          className="form-control"
                          maxLength="1050"
                        />
                        <Label className="form-label" htmlFor="description">
                          Description <span className="text-danger required">*</span>
                        </Label>

                        <span
                          className={
                            1050 - values.description.length >= 0
                              ? "form-text  text-muted text-end w-100 d-inline-block "
                              : "form-text  text-end w-100 d-inline-block text-danger"
                          }
                        >
                          {1050 - values.description.length >= 0
                            ? 1050 - values.description.length
                            : 0}{" "}
                          characters remaining
                        </span>
                        {validator.current.message(
                          __t("validations.promotion.description"),
                          values.description,
                          "required",
                          {
                            messages: {
                              required: __t(
                                "validations.promotion.description"
                              ),
                            },
                          }
                        )}
                      </FormGroup>
                      <Col
                        lg={12}
                      // className="d-flex justify-content-end add-customfiled"
                      >
                        <h5>Product Specifications </h5>
                        {/* <Button color="link" onClick={showHandel}>
                          <Add_Svg />
                          Add custom field
                        </Button> */}
                      </Col>

                      {showCustomField &&
                        customfiledData.map((item, index) => (
                          <div key={index} className="add_custom_field mb-2">
                            <Row>
                              <Col>
                                <FormGroup className="mb-0">
                                  <Field
                                    id={`label${index}`}
                                    name={`label${index}`}
                                    placeholder="Label"
                                    className="form-control"
                                    type={"text"}
                                    readOnly={index !== 5}
                                    value={item.label}
                                    onChange={(e) =>
                                      handleLabelChange(index, e.target.value)
                                    }
                                  />
                                  {/* {index === 5 && validator.current.message(
                                    __t("validations.common.label"),
                                    item.label ? item.value !== "" : undefined,
                                    "required",
                                    {
                                      messages: {
                                        required: __t(
                                          "validations.common.label"
                                        ),
                                      },
                                    }
                                  )} */}
                                </FormGroup>
                              </Col>
                              <Col>
                                <div className="w-100 d-flex align-items-center mb-0">
                                  <FormGroup className="w-100 mb-0">
                                    <Field
                                      id={`value${index}`}
                                      name={`value${index}`}
                                      placeholder="Value"
                                      className="form-control"
                                      type="text"
                                      value={item.value}
                                      onChange={(e) =>
                                        handleValueChange(index, e.target.value)
                                      }
                                    />

                                    {/* {index === 5 && validator.current.message(
                                      __t("validations.common.value"),
                                      item.value ? item.value : undefined,
                                      "required",
                                      {
                                        messages: {
                                          required: __t(
                                            "validations.common.value"
                                          ),
                                        },
                                      }
                                    )} */}
                                  </FormGroup>

                                  {index === 5 && <Button
                                    className="text-danger"
                                    type="button"
                                    color="link"
                                    onClick={() => {
                                      removeCustomfiledData(index);
                                      validator.current.purgeFields();
                                    }}
                                  >
                                    <DeleteSvg />
                                  </Button>
                                  }
                                  {index === customfiledData.length - 1 &&
                                    customfiledData.length < 6 &&
                                    item.label !== "" &&
                                    // item.value !== "" &&
                                    (
                                      <Button
                                        color="link"
                                        className="text-primary"
                                        type="button"
                                        onClick={handleCustomFiledsAdd}
                                      >
                                        <Add_Svg />
                                      </Button>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </CardBody>
                  </Card>

                  <div className="d-flex justify-content-end my-3 p-0">
                    <FormGroup className="d-flex m-0 p-0">
                      <Button
                        type="button"
                        color="outline-primary"
                        className="w-100 waves-effect waves-light ms-4"
                        onClick={() => {
                          navigate("/admin/promotion");
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                        className="w-100 waves-effect waves-light ms-4"
                      >
                        Submit
                      </Button>
                    </FormGroup>
                  </div>
                </Col>
                <Col lg={4}>
                  <Card className="promotion_img_upload">
                    <h6 className="separate-head ps-3 pt-3 mb-0">
                      Promotion images
                    </h6>
                    <CardBody>
                      <div className="dropzone dropzone_img">
                        <label
                          htmlFor="imageFiles"
                          className="dropzone-container mb-2"
                        >
                          <div className="file-icon">
                            <ImageSvg />
                          </div>
                          <div className="dropzone-title text-center">
                            Drag and drop your files or{" "}
                            <span className="browse">browse</span> your files <span className="text-danger required">*</span>
                            <small className="w-100 d-inline-block text-center">
                              Accepted file types : .jpeg, .jpg, .png{" "}
                            </small>
                            <small className="w-100 d-inline-block text-center">
                              File size upto 1 MB (Min width: 1280px){" "}
                            </small>
                          </div>
                          <input
                            id="imageFiles"
                            name="imageFiles"
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={handleFileUpload(
                              setFieldValue,
                              "imageFiles",
                              setImgUpload,
                              setUploadProgress,
                              setOtherFileImage,
                              otherFileImage,
                              {
                                errorMessage:
                                  "Invalid image file(s). Please select only image files.",
                                validateFileType: "image/",
                                isMultiple: true,
                              }
                            )}
                            className="form-control"
                            multiple
                          />
                        </label>

                        {imgUpload.length > 0 && (
                          <>
                            <div className="uploads_btn">
                              <span>
                                * Please select anyone image to set as banner
                                image
                              </span>
                              <Button
                                color="link"
                                onClick={async () => {
                                  const response = await commonConfirmBox(
                                    "Do you want to remove all image?"
                                  );
                                  if (response) {
                                    setFieldValue("imageFiles", null);
                                    HandelAllClear();
                                    clearFileInput();
                                  }
                                }}
                              >
                                Clear All
                              </Button>
                            </div>
                            <ul>
                              {imgUpload.map((d, index) => {
                                return (
                                  <li key={index}>
                                    <div
                                      className={
                                        d?.fileName === bannerName
                                          ? "attachment-data setbanner"
                                          : "attachment-data"
                                      }
                                    >
                                      <div className="attachment-head">
                                        <a
                                          href={id ? d.imageUrl : d.imageUrl}
                                          target="_blank"
                                          className="file-box"
                                        >
                                          <img
                                            alt="promotion_img"
                                            className="img_pre"
                                            src={id ? d.imageUrl : d.imageUrl}
                                          />
                                        </a>
                                        <FormGroup className="check_file">
                                          <Field
                                            type="checkbox"
                                            name={`selectedFiles[${index}]`}
                                            checked={
                                              d?.fileName === bannerName && true
                                            }
                                            onChange={() =>
                                              updateSelectedFiles(
                                                index,
                                                d.fileName,
                                                setFieldValue
                                              )
                                            }
                                          />
                                        </FormGroup>
                                        <a
                                          onClick={async () => {
                                            const response =
                                              await commonConfirmBox(
                                                "Do you want to remove this image?"
                                              );
                                            if (response) {
                                              handleRemoveImage(
                                                d,
                                                index,
                                                setFieldValue
                                              );
                                            }
                                          }}
                                          className="remove-box"
                                          title="remove"
                                        >
                                          <DeleteSvg />
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        )}
                      </div>
                      {validator.current.message(
                        __t("validations.common.one_image"),
                        values.imageFiles,
                        "required",
                        {
                          messages: {
                            required: __t("validations.common.one_image"),
                          },
                        }
                      )}
                      {imgUpload.length > 0 &&
                        validator.current.message(
                          __t("validations.promotion.banner"),
                          bannerUrl,
                          "required",
                          {
                            messages: {
                              required: __t("validations.promotion.banner"),
                            },
                          }
                        )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = ({ Promotions }) => {
  return {
    AddPromotions: Promotions,
    EditPromotion: Promotions.editPromotion,
    EditPromotionStatus:
      Promotions.editPromotion?.status && Promotions.editPromotion.status,
    AddPromotionStatus:
      Promotions.addPromotion?.status && Promotions.addPromotion.status,
    deleteImageStatus:
      Promotions?.deleteImage?.status && Promotions.deleteImage.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddPromoRequest: (data) => {
      dispatch(addPromotionListRequest(data));
    },
    editPromoRequest: (data) => {
      dispatch(editPromotionListRequest(data));
    },
    deleteImageRequest: (data) => {
      dispatch(deleteImageRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPromotion);
