import React, { useEffect, useRef, useState } from "react";
import { DeleteSvg, EyeSvg, FileIconSvg } from "../../../components/CommonSvg";
import DatePicker from "react-datepicker";
import { DATE_FORMATER } from "../../../constant/commonConstants";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import { Formik } from "formik";
import { Button, Col, Form } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import {
  formatDateForApi,
  getTodayDate,
} from "../../../helper/DataTimeHelpers";
import { __t } from "../../../i18n/translator";
import { connect } from "react-redux";
import {
  clearUploadAgreementSuccess,
  createUploadAgreementRequest,
} from "../../../redux/admin/members/action";
import { serviceInitialValue } from "./CommonFunction";
import { CustomInput } from "../Promotion/CommonFunction";
import { pdfUploadHelper } from "../../../helper/pdfUploadHelper";
import { deleteImageRequest } from "../../../redux/admin/promotion/action";
import { getImageFileNameFromURL } from "../../../utils/generalUtils";

const ServiceRequest = (props) => {
  const { isEditModal, deleteImageRequest } = props;
  const [servicRequestInitialValue, setServicRequestInitialValue] = useState(
    serviceInitialValue(isEditModal)
  );
  const [pdfFile, setPdfFile] = useState();
  const [isPdfFile, setIsPdfFile] = useState();
  const [uploadFiles, setUploadFiles] = useState(
    isEditModal?.subHra?.agreement
  );
  const [pdfFileData, setPdfFileData] = useState(null);
  const validator = useRef(new SimpleReactValidator());
  const [pdfFileEdit] = useState(
    isEditModal?.subHra ? isEditModal.subHra : isEditModal
  );
  const fileInputRef = useRef(null);
  const clearFileInput = () => {
    fileInputRef.current.value = "";
  };
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      const formData = new FormData();
      if (isEditModal) {
        formData.append("imageUploads", pdfFile);
        formData.append("endDate", formatDateForApi(values.endDate));
        formData.append("startDate", formatDateForApi(values.startDate));
        formData.append("hraMemberId", props.hraMemberId);
        props.createUploadAgreement(formData);
      } else {
        formData.append("endDate", formatDateForApi(values.endDate));
        formData.append("startDate", formatDateForApi(values.startDate));
        formData.append("imageUploads", pdfFile);
        formData.append("hraMemberId", props.hraMemberId);
        props.createUploadAgreement(formData);
      }
    } else {
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    if (props.isCreateRecord) {
      setServicRequestInitialValue(servicRequestInitialValue);
      props.clearUploadAgreement();
      props.onModalDismiss();
    }
  }, [props.isCreateRecord]);

  useEffect(() => {
    if (isEditModal) {
      let editPdfData;

      if (isEditModal?.agreement) {
        editPdfData = {
          fileNames: getImageFileNameFromURL(
            pdfFileEdit?.agreement && pdfFileEdit.agreement
          ),
          pdfUrl: pdfFileEdit?.agreement && pdfFileEdit.agreement,
          id: pdfFileEdit?.id,
        };
        setIsPdfFile(true);
      }
      if (isEditModal?.subHra?.agreement) {
        editPdfData = {
          fileNames: getImageFileNameFromURL(
            pdfFileEdit?.agreement && pdfFileEdit.agreement
          ),
          pdfUrl: pdfFileEdit?.agreement && pdfFileEdit.agreement,
          id: pdfFileEdit?.id,
        };
        setIsPdfFile(true);
      }
      setServicRequestInitialValue((prev) => ({
        ...prev,
        pdfUrl: pdfFileEdit.agreement,
      }));
      setPdfFileData(editPdfData);
    }
  }, [isEditModal?.subHra?.agreement, isEditModal?.agreement]);
  return (
    <Formik initialValues={servicRequestInitialValue} onSubmit={handleSubmit}>
      {({ values, setFieldValue, handleSubmit }) => (
        <Form className="form-horizontal form-login">
          <div className="support-request-modal-list row">
            <div className="col-12">
              <div className="dropzone">
                <label htmlFor="browserfile" className="dropzone-container">
                  <div className="file-icon">
                    <FileIconSvg />
                  </div>
                  <div className="dropzone-title">
                    Drag and drop your files or&nbsp;
                    <span className="browse  d-inline-block text-center">
                      {" "}
                      browse
                    </span>{" "}
                    your files
                    <small className="w-100 d-inline-block text-center">
                      Only .pdf files are allowed
                    </small>
                    <small className="w-100 d-inline-block text-center">
                      File size upto 5 MB
                    </small>
                  </div>
                  <input
                    id="browserfile"
                    name="imageFiles"
                    type="file"
                    className="form-control"
                    accept=".pdf"
                     ref={fileInputRef}
                    onChange={(e) =>
                      pdfUploadHelper(
                        setPdfFile,
                        setFieldValue,
                        "imageFiles",
                        setPdfFileData,
                        e
                      )
                    }
                  />
                </label>
              </div>
              {validator.current.message(
                __t("validations.promotion.aggrement"),
                pdfFile || isPdfFile,
                "required",
                {
                  messages: {
                    required: __t("validations.promotion.aggrement"),
                  },
                }
              )}
            </div>
            <div className="col-12">
              {pdfFileData !== null && pdfFileData !== undefined && (
                <div className="attachment-data-list  mt-2 mb-0">
                  <div className="attachment-data">
                    <a
                      href={pdfFileData?.pdfUrl}
                      target="_blank"
                      title={pdfFileData?.fileNames}
                      className="file-box"
                    >
                      <FileIconSvg />
                      <h6>{pdfFileData?.fileNames}</h6>
                    </a>
                    <a
                      href={pdfFileData?.pdfUrl}
                      className="vieweye-box"
                      title="view"
                      target="_blank"
                    >
                      <EyeSvg />
                    </a>
                  </div>
                  <a
                    onClick={async () => {
                      const response = await commonConfirmBox(
                        "Do you want to remove this document?"
                      );
                      if (response) {
                        setPdfFileData(null);
                        setFieldValue("documentFiles", null);
                        setPdfFile(null);
                        setIsPdfFile(); clearFileInput();
                        if (pdfFileData?.pdfUrl) {
                          setUploadFiles(uploadFiles !== pdfFileData?.pdfUrl);
                        }
                      }
                    }}
                    className="remove-box"
                    title="remove"
                  >
                    <DeleteSvg />
                  </a>
                </div>
              )}
            </div>
            <div className="col-6 mt-2">
              <DatePicker
                selected={values?.startDate ? new Date(values.startDate) : null}
                className="form-control"
                name="startDate"
                placeholderText="Select Start Date"
                dateFormat={DATE_FORMATER}
                minDate={getTodayDate()}
                onChange={(opt) => {
                  if (values.endDate && opt && opt > values.endDate) {
                    setFieldValue("endDate", null);
                  }
                  setFieldValue("startDate", opt);
                }}
                isClearable
              />
              {validator.current.message(
                __t("validations.promotion.startDate"),
                values.startDate,
                "required",
                {
                  messages: {
                    required: __t("validations.promotion.startDate"),
                  },
                }
              )}
            </div>
            <div className="col-6 mt-2">
              <DatePicker
                placeholderText="Select End Date"
                selected={values?.endDate ? new Date(values?.endDate) : null}
                className="form-control"
                name="endDate"
                minDate={
                  values.startDate ? new Date(values.startDate) : getTodayDate()
                }
                customInput={<CustomInput />}
                dateFormat={DATE_FORMATER}
                onChange={(opt) => {
                  if (opt && values.startDate && values.startDate > opt) {
                    setFieldValue("endDate", null);
                  }
                  setFieldValue("endDate", opt);
                }}
                isClearable
              />

              {validator.current.message(
                __t("validations.promotion.endDate"),
                values.endDate,
                "required",
                {
                  messages: {
                    required: __t("validations.promotion.endDate"),
                  },
                }
              )}
            </div>
          </div>
          <div className="btn-box mt-3">
            <button
              color="primary"
              className="btn btn-outline-secondary me-3"
              title="Cancel"
              type="button"
              onClick={props.onModalDismiss}
            >
              Cancel
            </button>
            <Button
              color="primary"
              className="btn btn-primary"
              title="Submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ Members }) => {
  let isCreateRecord = "";

  isCreateRecord = Members?.createUploadAgreementApiResponse;

  return {
    isCreateRecord: isCreateRecord,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createUploadAgreement: (data) => {
      dispatch(createUploadAgreementRequest(data));
    },
    clearUploadAgreement: (data) => {
      dispatch(clearUploadAgreementSuccess());
    },
    deleteImageRequest: (data) => {
      dispatch(deleteImageRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
