import { Field, Formik, Form } from "formik";
import React, { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../../i18n/translator";
import { Button, FormGroup, Label } from "reactstrap";
import { connect } from "react-redux";
import { emailSettingRequest } from "../../../../redux/admin/setting/action";

function SettingView({
  emailSettingRequest,
  emailSettingData,
  onModalDismiss,
}) {
  const validator = useRef(new SimpleReactValidator());
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      emailSettingRequest(values);
      onModalDismiss();
    } else {
      validator.current.showMessages();
    }
  };
  const initialValues = {
    hours: "",
    email: emailSettingData?.email || "",
    type: emailSettingData?.type || 2,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <>
          <Form className="form-horizontal form-login">
            <div className="category-head">
              <FormGroup className=" mb-3">
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                />
                <Label className="form-label" htmlFor="email">
                  Support Email Address
                  <span className="text-danger required">*</span>
                </Label>
                {validator.current.message(
                  __t("validations.user.email"),
                  values.email,
                  "required|emails",
                  {
                    messages: {
                      required: __t("validations.user.email"),
                    },
                  }
                )}
              </FormGroup>
            </div>

            <div className="btn-box">
              <Button
                color="primary"
                className=""
                title="user data"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
                {/* {IsEditData ? "Update" : ""} */}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    emailSettingRequest: (data) => dispatch(emailSettingRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingView);
