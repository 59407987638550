import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import loginbg from "../../../../assets/images/login-img.webp";
import logosm from "../../../../assets/images/logo-dark.webp";
import { connect } from "react-redux";
import { __t } from "../../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import { getLoginRequest } from "../../../../redux/admin/auth/login/action";
import Forgot from "../Forgot/Forgot";

const LoginHandle = (props) => {
    const { loginUserData, loginData } = props;
    const navigate = useNavigate();
    const validator = useRef(new SimpleReactValidator());

    const initialValues = {
        email: "",
        password: "",
    };

    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            loginUserData(values);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        if (loginData?.status === true && loginData?.status !== undefined && localStorage.getItem("adminAccessToken")) {
            navigate(loginData?.data?.user?.favouriteMenu ? loginData.data.user.favouriteMenu : "/admin/home");
        }
    }, [loginData?.status]);

    return (
        <div className="login-page">
            <Row>
                <Col className="login-img">
                    <img alt="Sign in" src={loginbg} />
                </Col>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ values }) => {
                        return (
                            <Col className="login-form">
                                <div className="login-main small-scroll">
                                    <div className="loging-head">
                                        <img alt="Logo" src={logosm} />
                                    </div>

                                    <div className="loging-content">
                                        <h2>Sign in</h2>
                                        <p>Welcome back! Please enter your details</p>

                                        <Form role={"form"} className="form-horizontal form-login">
                                            <FormGroup className="col-12 sticky_label">
                                                <Field
                                                    id="email"
                                                    name="email"
                                                    placeholder="example@example.com"
                                                    className="form-control"
                                                    type="email"
                                                    maxlength="99"
                                                />
                                                <Label className="form-label" htmlFor="email">
                                                    Email Address
                                                    <span className="text-danger required">*</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.auth.email"),
                                                    values.email,

                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.auth.email"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-12 sticky_label">
                                                <Field
                                                    id="password"
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="password"
                                                    type="password"
                                                    // maxlength="99"
                                                    maxLength={99}
                                                    minLength={6}
                                                />
                                                <Label className="form-label" htmlFor="password">
                                                    Password
                                                    <span className="text-danger required">*</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.auth.password"),
                                                    values.email,
                                                      "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.auth.password"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <Col lg="12">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    block
                                                    className="w-100"
                                                >
                                                    Log in
                                                </Button>
                                            </Col>
                                            <Col lg="12">
                                                <Link
                                                    to="/admin/forgot"
                                                    className="pt-3 d-inline-block w-100 text-center"
                                                    component={<Forgot />}
                                                >
                                                    Reset Password 
                                                </Link>
                                            </Col>
                                        </Form>
                                    </div>
                                    <div className="policy-links">
                                        <Link
                                        to="https://www.hraga.com/terms-of-service"
                                        className="d-inline-block"
                                target="_blank"
                                            title="Terms of Service"
                                        >
                                        Terms of Service
                                        </Link>
                                        <Link
                                        to="https://www.hraga.com/mbr-app-privacy-policy"
                                        className="d-inline-block"
                                target="_blank"
                                            title="Privacy Policy"
                                        >
                                        Privacy Policy
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        )
                    }}
                </Formik>
            </Row>
            <div className="login-footer">
                <p className="mb-0">
                    &#169; {new Date().getFullYear()} - All rights reserved.
                </p>
            </div>
        </div>
    );
};
const mapStateToProps = ({ login }) => {
    let loginData = null;
    if (login?.loginUser?.status) {
        loginData = login.loginUser;
    }
    return { loginData: loginData };
};
const mapDispatchToProps = (dispatch) => {
    return {
        loginUserData: (cred) => dispatch(getLoginRequest(cred)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginHandle);
