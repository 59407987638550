import { INQUIRY_REQUEST, INQUIRY_SUCCESS } from "./type";

const initialState = {
    inquiry: null,
};

const InquirysendReducer = (state = initialState, action) => {
    switch (action.type) {
        case INQUIRY_REQUEST:
            return {
                ...state,
                inquiry: null
            };
        case INQUIRY_SUCCESS:
            return {
                ...state,
                inquiry: action.payload
            };

        default:
            return state;
    }
};

export default InquirysendReducer;
