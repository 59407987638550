import * as type from "./type";

export const getOptInRequest = (data) => {
    return {
        type: type.GET_OPT_IN_REQUEST,
        payload: data
    };
};
export const getOptInSuccess = (data) => {
    return {
        type: type.GET_OPT_IN_SUCCESS,
        payload: data
    };
};
export const getOptInListRequest = (data) => {
    return {
        type: type.GET_OPT_IN_LIST_REQUEST,
        payload: data
    };
};
export const getOptInListSuccess = (data) => {
    return {
        type: type.GET_OPT_IN_LIST_SUCCESS,
        payload: data
    };
};

export const getOptInStatusRequest = (data) => {
    return {
        type: type.GET_OPT_IN_STATUS_REQUEST,
        payload: data
    };
};
export const getOptInStatusSuccess = (data) => {
    return {
        type: type.GET_OPT_IN_STATUS_SUCCESS,
        payload: data
    };
};