import React from "react";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";
import { getMemberPromotionListOnScrollRequest } from "../../../redux/frontend/promotion/action";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getValueByKey } from "../../../utils/authUtils";
import { useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import CommonPromotionCard from "../../../components/Common/Frontend/Promotion/CommonPromotionCard";
import NoPromotionFound from "../../../components/Common/Frontend/Promotion/NoPromotionFound";
import { getOptInStatusRequest } from "../../../redux/admin/optinhistory/action";

function AllPromotion({
    getmemberPromotionList,
    getOptInStatusRequest,
    memberListOnScrollRequest,
}) {
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    const loader = useRef(null);
    const [page, setPage] = useState(0);
    const [promotionList, setPromotionList] = useState([]);
    useEffect(() => {
        if (getmemberPromotionList) {
            setPromotionList(getmemberPromotionList);
        }
    }, [getmemberPromotionList]);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    const getSearchRequest = (newSearch = false, searchvalues, filterValues) => {
        let SearchRequest = {
            offset: newSearch ? 0 : getmemberPromotionList?.length,
            hraMemberId: hraMemberId,
        };
        return SearchRequest;
    };

    useEffect(() => {
        if (hraMemberId) {
            async function fetchData() {
                memberListOnScrollRequest(getSearchRequest(false, null));
            }
            fetchData();
        }
    }, [hraMemberId, page]);

    return (
        <>
            <div className="container">
                <CommonSortHeader PromotionsName="Promotions" BackTo={"/home"} />
                {promotionList?.filter((product) => product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true)?.length > 0 ? (
                    <div className="product-card-list">
                        {promotionList.filter((product) => product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true).map((item, index) => (
                            <>
                                <CommonPromotionCard
                                    key={index}
                                    getOptInStatusRequest={getOptInStatusRequest}
                                    ApiUpdateReq={"memberPromotionListScroll"}
                                    item={item}
                                />
                            </>
                        ))}
                    </div>
                ) : (
                    <NoPromotionFound />
                )}
                <div ref={loader} />
            </div>
        </>
    );
}
const mapStateToProps = ({ MemberPromotions }) => {
    let getmemberPromotionList = [];

    if (
        MemberPromotions &&
        MemberPromotions?.memberPromotionScrollData?.length > 0
    ) {
        getmemberPromotionList = MemberPromotions?.memberPromotionScrollData;
    }

    return { getmemberPromotionList: getmemberPromotionList };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOptInStatusRequest: (data) => dispatch(getOptInStatusRequest(data)),
        memberListOnScrollRequest: (data) =>
            dispatch(getMemberPromotionListOnScrollRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPromotion);
