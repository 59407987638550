import * as type from "./type";

export const getVendorRequest = (data) => {
    return {
        type: type.GET_VENDER_REQUEST,
        payload: data

    };
};
export const getVendorSuccess = (data) => {
    return {
        type: type.GET_VENDER_SUCCESS,
        payload: data
    };
};


export const clearResp = () => {
    return {
        type: type.CLEAR_REPONSE
    };
};
export const likeVendorRequest = (data) => {
    return {
        type: type.LIKE_VENDER_REQUEST,
        payload: data
    };
};
export const likeVendorSuccess = (data) => {
    return {
        type: type.LIKE_VENDER_SUCCESS,
        payload: data
    };
};