import * as type from "./type";

const initialState = {
  getPromotions: null,
  addPromotion: null,
  deletePromotion: null,
  viewPromotions: null,
  editPromotion: null,
  assignPromotion: null,
  deleteImage: null
};

const PromotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PROMOTION_LIST_REQUEST:
      return { ...state, getPromotions: null };
    case type.GET_PROMOTION_LIST_SUCCESS:
      return { ...state, getPromotions: action.payload };
    case type.VIEW_PROMOTION_LIST_REQUEST:
      return { ...state, viewPromotions: null };
    case type.VIEW_PROMOTION_LIST_SUCCESS:
      return { ...state, viewPromotions: action.payload };
    case type.ADD_PROMOTION_LIST_REQUEST:
      return { ...state, addPromotion: null };
    case type.ADD_PROMOTION_LIST_SUCCESS:
      return { ...state, addPromotion: action.payload };
    case type.EDIT_PROMOTION_LIST_REQUEST:
      return { ...state, editPromotion: null };
    case type.EDIT_PROMOTION_LIST_SUCCESS:
      return { ...state, editPromotion: action.payload };
    case type.ASSIGN_PROMOTION_REQUEST:
      return { ...state, assignPromotion: null };
    case type.ASSIGN_PROMOTION_SUCCESS:
      return { ...state, assignPromotion: action.payload };
    case type.ACTIVE_INACTIVE_PROMOTION_SUCCESS:
      return { ...state, deletePromotion: action.payload };
    case type.DELETE_IMAGE_REQUEST:
      return { ...state, deleteImage: null };
    case type.DELETE_IMAGE_SUCCESS:
      return { ...state, deleteImage: action.payload };
    default:
      return {
        ...state,
      };
  }
};
export default PromotionReducer;
