import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_CLASS_SYNC_REQ,  GET_REBATES_SYNC_REQ } from "./type";
import { getClassSyncSuc, getRebateSyncSuc  } from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* getRebateSync({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/syncZohoRebates", data);
    if (response.status) {
      yield put(getRebateSyncSuc(response.data));
      ApiResponseMessage(response.message, SUCCESS);

    } else {
      yield put(getRebateSyncSuc(response));
      ApiResponseMessage(response.message, DANGER);
      // removeZohoToken()
      
      handleApiCallException(response.message);
    }
  } catch (error) {
    yield put(getRebateSyncSuc([]));
    // removeZohoToken()
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getClassSync({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/syncRebateClass", data);
    if (response.status) {
      yield put(getClassSyncSuc(response.data));
      ApiResponseMessage(response.message, SUCCESS);
    } else {
      yield put(getClassSyncSuc(response));
      ApiResponseMessage(response.message, DANGER);
      // removeZohoToken()
      handleApiCallException(response.message);
    }
  } catch (error) {
    yield put(getClassSyncSuc([]));
    // removeZohoToken()
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
// function* getSync() {
//   try {
//     const response = yield call(invokeApi, "get", "/zohoSyncHistory");
//     if (response.status) {
//       yield put(getSyncSuc(response.data));
//     } else {
//       yield put(getSyncSuc(response));
//       handleApiCallException(response.message);
//     }
//   } catch (error) {
//     yield put(getSyncSuc([]));
//     ApiResponseMessage(error, DANGER);
//     handleApiCallException(error);
//   }
// }
function* watchRebateSyncRequest() {
  yield takeLatest(GET_REBATES_SYNC_REQ, getRebateSync);
  yield takeLatest(GET_CLASS_SYNC_REQ, getClassSync);
  // yield takeLatest(GET_SYNC_REQ, getSync);
}

function* RebateSyncSaga() {
  yield all([fork(watchRebateSyncRequest)]);
}

export default RebateSyncSaga;
