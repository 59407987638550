import React, { useState } from "react";
import ViewTable from "../../../components/Common/ViewTable";
import { connect } from "react-redux";
import {
    changeStatusAchRequest,
    getAchListRequest,
} from "../../../redux/admin/Ach/action";
import { useEffect } from "react";
import { CloseSvg, RightSvg, ViewSvg } from "../../../components/CommonSvg";
import { Badge, Button } from "reactstrap";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import ViewAchDetails from "./ViewAchDetails";


function AchManagement({ getAchListRequest, AchList, totalAchList, changeStatusAchRequest }) {
    const [modalView, setModalView] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [searchData, setSearchData] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("")

    const ModalHeaderName = modalData !== null &&
        modalData.Member.firstName + " " + modalData.Member.lastName;
    const handleModalToggle = () => {
        setModalView(!modalView);
    };
    const dispatchData = {
        status: selectedStatus,
        searchText: searchData,
        pagination: {
            limit: dataPerPage,
            page: currentPage,
            orderKey: "createdDate",
            orderBy: "ASC",
        },
    };

    useEffect(() => {
        getAchListRequest(dispatchData);
    }, [dataPerPage, currentPage, searchData, selectedStatus]);


    const columns = [
        {
            dataField: "Member.firstName",
            text: "User",
            sort: true,
            formatter: (cell, row) => {
                return row.Member.firstName + " " + row.Member.lastName;
            },
        },
        {
            dataField: "hraMemberId",
            text: "HRA #",
            sort: true,
        },
        {
            dataField: "routingNumber",
            text: "Routing No.",
            sort: true,
            formatter: (cell, row) => {
                return row.previousRoutingNumber ? row.previousRoutingNumber : row.routingNumber;
            },
        },

        {
            dataField: "accountNumber",
            text: "Account No.",
            sort: true,
            formatter: (cell, row) => {
                return row.previousAccountNumber ? row.previousAccountNumber : row.accountNumber;
            },
        },
        {
            dataField: "previousBankName",
            text: "Bank Name",
            sort: true,
            formatter: (cell, row) => {
                return row.previousBankName ? row.previousBankName : row.bankName;
            },
        },
        {
            dataField: "status",
            text: "",
            classes: "ach_action",
            headerEvents: {
                onClick: (e) => {
                    if (e && e.stopPropagation) {
                        e.stopPropagation()
                    }
                },
            },
            formatter: (cell, row) => {
                return (
                    <>
                        {/* <Button
                            className="view_btn_icon btn-link"
                            onClick={() => {
                                setModalData(row);
                                handleModalToggle();
                            }}
                        >
                            <ViewSvg />
                        </Button> */}
                        {row.achManagement[0]?.status === "PENDING" && (
                            <>
                                <Button
                                    size="sm"
                                    className="approved_btn"
                                    color="primary"
                                    onClick={(e) => {
                                        if (e && e.stopPropagation) {
                                            e.stopPropagation()
                                        }
                                        changeStatusAchRequest({ value: { status: "APPROVED", achManagementId: row.achManagement[0].achManagementId }, dispatchData: dispatchData });
                                    }}
                                >
                                    <RightSvg />
                                </Button>
                                <Button size="sm" color="primary"
                                    className="reject_btn"
                                    onClick={(e) => {
                                        if (e && e.stopPropagation) {
                                            e.stopPropagation()
                                        }
                                        changeStatusAchRequest({ value: { status: "REJECTED", achManagementId: row.achManagement[0].achManagementId }, dispatchData: dispatchData });
                                    }}>
                                    <CloseSvg />
                                </Button>
                            </>
                        )
                        }
                        {
                            row.achManagement[0]?.status === "APPROVED" && (<Badge className="confirmed_badge" color="primary">
                                <RightSvg />
                                APPROVED
                            </Badge>)
                        }
                        {
                            row.achManagement[0]?.status === "REJECTED" && (<Badge className="reject_badge" color="danger">
                                <CloseSvg />
                                REJECTED
                            </Badge>)
                        }
                    </>
                );
            },
        },
    ];

    const rowClassName = (row) => {
        let classes = null;
        if (row.achManagement[0]?.status === "PENDING") {
            classes = 'pending_ach cursor-pointer';
        } else if (row.achManagement[0]?.status === "APPROVED") {
            classes = 'approved_ach cursor-pointer';
        } else if (row.achManagement[0]?.status === "REJECTED") {
            classes = 'rejected_ach cursor-pointer';
        }
        return classes;
    };

    return (
        <div className="ach_table">
            <ViewTable
                data={AchList ? AchList : []}
                columns={columns}
                dataPerPage={dataPerPage}
                currentPage={currentPage}
                setDataPerPage={setDataPerPage}
                setCurrentPage={setCurrentPage}
                pagination={true}
                filterType={"AchManagement"}
                Filter={true}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                totalRecords={totalAchList}
                Header={true}
                setSearchData={setSearchData}
                updateData={() =>
                    getAchListRequest({
                        searchText: "",
                        pagination: {
                            limit: dataPerPage,
                            page: 1,
                            orderKey: "createdDate",
                            orderBy: "ASC",
                        },
                    })
                }
                rowEvents={(e, row, rowIndex) => {
                    setModalData(row);
                    handleModalToggle();
                }}
                hedingName="ACH Management"
                searchBar={true}
                rowClassName={rowClassName}
            />
            {modalView && (
                <CustomisedModal
                    modalView={modalView}
                    customClass={"category-modal modal-xl inquiry_view_modal"}
                    modalName={
                        <div className="ach_modal_header">
                            {ModalHeaderName}
                            <span>{modalData.hraMemberId}</span>
                        </div>
                    }
                    onModalDismiss={() => {
                        setModalView(false);
                    }}
                >
                    <ViewAchDetails
                        modalData={modalData}
                        changeStatusAchRequest={changeStatusAchRequest}
                        onModalDismiss={() => {
                            setModalView(false);
                        }}
                        dispatchData={dispatchData}
                    />
                </CustomisedModal>
            )}
        </div>
    );
}
const mapStateToProps = ({ AchManagementList }) => {
    let AchList = [];
    let totalAchList = null
    if (AchManagementList?.getAchList?.list?.length > 0) {
        AchList = AchManagementList.getAchList.list;
        totalAchList = AchManagementList.getAchList.totalRecords
    }
    return {
        AchList: AchList,
        totalAchList: totalAchList
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAchListRequest: (data) => dispatch(getAchListRequest(data)),
        changeStatusAchRequest: (data) => dispatch(changeStatusAchRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AchManagement);
