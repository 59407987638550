import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import logosm from "../../../../src/assets/images/logo-dark.webp";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { connect } from "react-redux";
import { inquiryRequest } from "../../../redux/frontend/inquiry/action";
import { useEffect } from "react";
import { useState } from "react";
import { __t } from "../../../i18n/translator";
import { CallSvg, WhatsAppSvg } from "../../../components/CommonSvg";

function InquiryForm(props) {
    const { inquiryRequestSend, isApiSuccess } = props;

    const validator = useRef(new SimpleReactValidator());
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(false);
    const initialValues = {
        city: "",
        state: "",
        address: "",
        businessName: "",
        county: "",
        email: "",
        mailingZip: "",
        mobileNo: "",
        phoneNo: "",
        name: "",
    };
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            inquiryRequestSend(values);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        setRedirect(true);
        if (redirect === true) {
            setRedirect(false);
            navigate("/login");
        }
    }, [isApiSuccess === true]);
    const formLinks = [
        {
            icon: '',
            name: "Membership Application",
            link: "https://form.jotform.com/ShahNirav/HRAMemberInformation",
        },
        {
            icon: '',
            name: "Member Portal Support",
            // link: "https://form.jotform.com/ShahNirav/HRADirectDepositACH",
            link: "https://www.hraga.com/memberportal",
        },
        {
            icon: <WhatsAppSvg />,
            name: "WhatsApp Chat",
            // link: "https://form.jotform.com/ShahNirav/HRA_BBGFeloniousAssaultWaiver",
            link: "https://wa.me/message/XZN2VH4JPU7CK1",
        },
        {
            icon: <CallSvg />,
            name: "24/7 Support Call",
            // link: "https://form.jotform.com/ShahNirav/HRAMembershipVerification",
            link: "tel:14702852490"
        },
    ];
    const openInNewTab = (url) => {
        window.open(url);
        // window.open(url, "_blank", "noreferrer");
    };

    return (
        <div className="front_login-page">
            <Row>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ values }) => (
                        <Col className="login-form">
                            <div className="loging-head">
                                <img alt="Logo" src={logosm} />
                                <h2>Interested In Becoming A Member</h2>
                            </div>
                            <Form className="form-horizontal form-login row">
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="businessName"
                                        name="businessName"
                                        className="form-control"
                                        placeholder="Business Name"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="businessName">
                                        Business Name{" "}
                                        <span className="text-danger required">*</span>
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.businessName"),
                                        values.businessName,
                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.businessName"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        placeholder="Name"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="name">
                                        Name
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.name"),
                                        values.name,
                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.name"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="email"
                                        name="email"
                                        placeholder="example@example.com"
                                        className="form-control"
                                        type="email"
                                    />
                                    <Label className="form-label" htmlFor="email">
                                        Email Address
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.auth.email"),
                                        values.email,

                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.auth.email"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="mobileNo"
                                        name="mobileNo"
                                        className="form-control"
                                        placeholder="Mobile No."
                                        type="text"
                                        maxLength={10}
                                        minLength={10}
                                    />
                                    <Label className="form-label" htmlFor="mobileNo">
                                        Mobile No.
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.mobileNo"),
                                        values.mobileNo,
                                        "required|matches:^[0-9]{10}$|numeric",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.mobileNo"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="phoneNo"
                                        name="phoneNo"
                                        className="form-control"
                                        placeholder="Phone No."
                                        type="number"
                                    />
                                    <Label className="form-label" htmlFor="phoneNo">
                                        Phone No.
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.phoneNo"),
                                        values.phoneNo,
                                        "required|matches:^[0-9]{9}$|numeric",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.phoneNo"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="state"
                                        name="state"
                                        className="form-control"
                                        placeholder="state"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="state">
                                        State
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.state"),
                                        values.state,

                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.state"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="city"
                                        name="city"
                                        className="form-control"
                                        placeholder="Name"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="city">
                                        City
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.city"),
                                        values.city,

                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.city"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="county"
                                        name="county"
                                        className="form-control"
                                        placeholder="county"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="county">
                                        County
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.county"),
                                        values.county,

                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.county"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="address"
                                        name="address"
                                        className="form-control"
                                        placeholder="Address"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="address">
                                        Address
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.address"),
                                        values.address,

                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.address"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-6">
                                    <Field
                                        id="mailingZip"
                                        name="mailingZip"
                                        className="form-control"
                                        placeholder="Mailing Zip"
                                        type="text"
                                    />
                                    <Label className="form-label" htmlFor="mailingZip">
                                        Mailing zip
                                        <span className="text-danger required"> *</span>{" "}
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.inquiry.mailingZip"),
                                        values.mailingZip,

                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.inquiry.mailingZip"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        block
                                        className="w-auto"
                                        title="Send"
                                    >
                                        Send
                                    </Button>
                                    <Link
                                        to="/login"
                                        className="pt-3 d-inline-block w-100 text-center"
                                        title="back to login"
                                    >
                                        Back to login
                                    </Link>
                                    <div className="member_form_btn">
                                        {formLinks?.map((d) => {
                                            return (
                                                <Button
                                                    role="link"
                                                    color="primary"
                                                    // target="_blank"
                                                    onClick={() => openInNewTab(d.link)}
                                                >
                                                    {d?.icon}{d.name}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    )}
                </Formik>
            </Row>
        </div>
    );
}
const mapStateToProps = ({ inquiry }) => {
    let isApiSuccess = inquiry?.inquiry?.status ? inquiry.inquiry.status : false;
    return {
        isApiSuccess: isApiSuccess,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        inquiryRequestSend: (data) => dispatch(inquiryRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InquiryForm);
