import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { detailsNewsLetterRequest } from "../../../redux/admin/newsletter/action";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Date_Svg } from "../../../components/CommonSvg";
import Iframe from "react-iframe";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import { isImage, isPDF } from "../../../utils/generalUtils";

const NewsLetterDetailPage = ({ detailsNewsLetter, detail }) => {
  const { id } = useParams();
  const data = detail?.data;
  const navigate = useNavigate();

  useEffect(() => {
    detailsNewsLetter({
      newsLetterId: id,
    });
  }, []);
  const [googleViewerUrl, setGoogleViewerUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const loadSuccess = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setRefresh(true);
    setIsLoading(true);
    if (data?.url) {
      setGoogleViewerUrl(data.url);
    }
    if (data?.documentPath) {
      setGoogleViewerUrl(data.documentPath);
    }
    setRefresh(false);
  }, [data?.documentPath]);
  const EditNewsLetterButton = () => {
    navigate(`/admin/newsletter/edit/${id}`, { state: { data: detail?.data } });
  };
  return (
    <>
      <div className="container">
        <CommonBackHeader
          backPath={"/admin/newsletter"}
          HeaderName={data?.title}
          isEditButton={"Edit Newsletter"}
          startDate={data?.startDate}
          endDate={data?.endDate}
          isEditUrl={EditNewsLetterButton}
        />

        <div className="container">
          <Row className="newsletter-detail">
            <Col lg="9">
              <Card className="overflow-hidden">
                <CardBody className="p-0">
                  {!isImage(googleViewerUrl) ? (
                    <div id="pdfContainer" className="agreemet_pdf_container">
                      {isLoading && (
                        <div className="w-100 text-center agreement_loader">
                          <div className="spinner-border" role="status"></div>
                        </div>
                      )}

                      {refresh === false && googleViewerUrl && (
                        <Iframe
                          type="application/pdf"
                          src={googleViewerUrl}
                          width="100%"
                          height="600px"
                          onLoad={loadSuccess}
                        >
                          <p>
                            Your browser does not support embedded PDFs. You can
                            download the PDF{" "}
                            <a href="" download>
                              here
                            </a>
                            .
                          </p>
                        </Iframe>
                      )}
                    </div>
                  ) : (
                    <div className="img-box">
                      <img width="80" src={googleViewerUrl} />
                    </div>
                  )}
                  <div className="p-3">
                    <p>{data?.description}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="m-0 newsletter_assigned">
                <CardHeader>
                  <h6 className="m-0">Assigned to</h6>
                </CardHeader>
                <CardBody>
                  <ul>
                    {detail?.city?.length > 0 && (
                      <li>
                        <label>city:</label>{" "}
                        {detail?.city?.map((data) => {
                          return <span>{data}
                          {/* <span>,</span> */}
                          </span>;
                        })}
                      </li>
                    )}
                    {detail?.county?.length > 0 && (
                      <li>
                        <label>county:</label>{" "}
                        {detail?.county?.map((data) => {
                          return <span>{data}
                          {/* <span>,</span> */}
                          </span>;
                        })}
                      </li>
                    )}
                    {detail?.state?.length > 0 && (
                      <li>
                        <label>state:</label>{" "}
                        {detail?.state?.map((data) => {
                          return <span>{data}
                          {/* <span>,</span> */}
                          </span>;
                        })}
                      </li>
                    )}
                    {data?.AllocateNewsletters[0]?.mailingZip?.length > 0 && (
                      <li>
                        <label>mailingZip:</label>{" "}
                        {data?.AllocateNewsletters[0]?.mailingZip?.map(
                          (data) => {
                            return <span>{data}
                            {/* <sma>,</sma> */}
                            </span>;
                          }
                        )}
                      </li>
                    )}
                    {detail?.city?.length === 0 &&
                      detail?.county?.length === 0 &&
                      detail?.state?.length === 0 &&
                      !data?.AllocateNewsletters[0]?.mailingZip?.length > 0 && (
                        <li>
                        <label>Newsletter Not Assigned</label>
                        </li>
                      )}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ NewsLetter }) => {
  let detail;
  if (NewsLetter?.newsLetterDetail !== null) {
    detail = NewsLetter.newsLetterDetail;
  }
  return {
    detail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    detailsNewsLetter: (data) => dispatch(detailsNewsLetterRequest(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsLetterDetailPage);
