import React, { useEffect } from "react";
import ViewTable from "../../../components/Common/ViewTable";
import ChildTableView from "../../../components/Common/TableView/ChildTableView";
import BootstrapTable from "react-bootstrap-table-next";
function RebateView({
  RebateData,
  totalAmount,
  responsiveClass,
  selectedYear,
}) {
  useEffect(() => {
    var newRow = document.createElement("tr");
    newRow.className = "rebate-detail-total";
    var values = [
      "",
      "",
      "",
      "",
      "Q1: $" + (totalAmount?.Q1 || "0"),
      "Q2: $" + (totalAmount?.Q2 || "0"),
      "Q3: $" + (totalAmount?.Q3 || "0"),
      "Q4: $" + (totalAmount?.Q4 || "0"),
      "Total: $" + (totalAmount?.finalTotal || "0"),
    ];
    for (var i = 0; i < values.length; i++) {
      var newCell = document.createElement("td");
      newCell.className = "text-end";
      newCell.innerHTML = values[i];
      newRow.appendChild(newCell);
    }

    // Create a <tfoot> element and append the new row to it
    var tfoot = document.createElement("tfoot");
    tfoot.appendChild(newRow);

    // Append the <tfoot> to the table
    var table = document.getElementById("rebate-table");
    var existingTfoot = table.getElementsByTagName("tfoot")[0];
    if (existingTfoot) {
      table.removeChild(existingTfoot);
    }
    table.appendChild(tfoot);
  }, [totalAmount]);
  // useEffect(() => {
  //   var newRow = document.createElement("tr");
  //   newRow.className = "rebate-detail-total";
  //   var values = [
  //     "",
  //     "",
  //     "",
  //     "",
  //     "Q1: $" + totalAmount?.Q1 || "0",
  //     "Q2: $" + totalAmount?.Q2 || "0",
  //     "Q3: $" + totalAmount?.Q3 || "0",
  //     "Q4: $" + totalAmount?.Q4 || "0",
  //     "Total: $" + totalAmount?.finalTotal || "0",
  //   ];
  //   for (var i = 0; i < values.length; i++) {
  //     var newCell = document.createElement("td");
  //     newCell.className = "text-end";
  //     newCell.innerHTML = values[i];
  //     newRow?.appendChild(newCell);
  //   }
  //   document
  //     .getElementById("rebate-table")
  //     .getElementsByTagName("tbody")[0]
  //     ?.appendChild(newRow);
  // }, [totalAmount]);
  const columns = [
    {
      dataField: "year",
      headerAttrs: { scope: "col", className: "year_column" },
      text: "Year",
      classes: "rebates_year",
      sort: false,
    },

    {
      dataField: "rebateClass",
      headerAttrs: { scope: "col", className: "class_column" },
      text: "Class",
      classes: "rebates_name",
      sort: true,
    },
    {
      dataField: "volumeType",
      headerAttrs: { scope: "col" },
      text: "Volume type",
      classes: "volume_type",
      sort: false,
    },
    {
      dataField: "cYVolume",
      headerAttrs: { scope: "col" },
      text: "Total CY-Volume",
      classes: "total_volume",
      sort: false,
    },
    {
      dataField: "Q1",
      text: "Q1",
      headerAttrs: { className: "q1_column" },
      classes: "text-end q1_rebate",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q1}
              <div>
                {row.checkNoQ1 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ1}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q2",
      text: "Q2",
      headerAttrs: { className: "q2_column" },
      classes: "text-end q2_rebate",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q2}
              <div>
                {row.checkNoQ2 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ2}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q3",
      text: "Q3",
      headerAttrs: { className: "q3_column" },
      classes: "text-end q3_rebate",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q3}
              <div>
                {row.checkNoQ3 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ3}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q4",
      text: "Q4",
      headerAttrs: { className: "q4_column" },
      classes: "text-end q4_rebate",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q4}
              <div>
                {row.checkNoQ4 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ4}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
      classes: "text-end total_amount",
      headerAttrs: { scope: "col" },
      sort: true,
      sortValue: (cell, row, sortOrder) => {
        const numericValue = parseFloat(row.totalAmount);
        return sortOrder === "asc" ? numericValue : -numericValue;
      },
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <span>$</span>
              {row.totalAmount}
            </div>
          </>
        );
      },
    },
  ];
  const childColums = [
    {
      dataField: "year",
      headerAttrs: { scope: "col", className: "year_column" },
      text: "Year",
      classes: "rebates_year",
      sort: true,
    },

    {
      dataField: "subClassName",
      headerAttrs: { scope: "col", className: "class_column" },
      text: "Class",
      classes: "rebates_name",
      sort: true,
    },
    {
      dataField: "volumeType",
      headerAttrs: { scope: "col" },
      text: "volume type",
      classes: "volume_type",
      sort: true,
    },
    {
      dataField: "cYVolume",
      headerAttrs: { scope: "col" },
      text: "CY-Volume",
      classes: "total_volume",
      sort: true,
    },
    {
      dataField: "Q1",
      text: "Q1",
      headerAttrs: { className: "q1_column" },
      classes: "text-end q1_rebate",
      sort: false,
      // // hidden: quarter === "Q1" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q1}
              {/* <div>
                {row.checkNoQ1 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ1}</b>
                  </>
                )}
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q2",
      text: "Q2",
      headerAttrs: { className: "q2_column" },
      classes: "text-end q2_rebate",
      sort: false,
      // hidden: quarter === "Q2" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q2}
              <div>
                {row.checkNoQ2 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ2}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q3",
      text: "Q3",
      headerAttrs: { className: "q3_column" },
      classes: "text-end q3_rebate",
      sort: false,
      // hidden: quarter === "Q3" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q3}
              <div>
                {row.checkNoQ3 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ3}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q4",
      text: "Q4",
      headerAttrs: { className: "q4_column" },
      classes: "text-end q4_rebate",
      sort: false,
      // hidden: quarter === "Q4" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q4}
              <div>
                {row.checkNoQ4 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ4}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "amount",
      text: "Total Amount",
      classes: "text-end total_amount",
      // hidden: quarter === "" ? false : true,
      headerAttrs: { scope: "col" },
      sort: true,
      sortValue: (cell, row, sortOrder) => {
        const numericValue = parseFloat(row.totalAmount);
        return sortOrder === "asc" ? numericValue : -numericValue;
      },
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <span>$</span>
              {row.amount}
            </div>
          </>
        );
      },
    },
  ];
  const expandRow = {
    renderer: (row) => (
      <>
        <BootstrapTable
          id={"myTable"}
          classes={" small table-sm"}
          striped={true}
          bootstrap4
          keyField="id"
          data={
            row?.subClasses?.length > 0
              ? row?.subClasses?.map((item) => ({
                ...item,
                cYVolume: item.cYVolume,
                volumeType: item.volumeType,
                subClassName: item.subClassName,
                totalAmount: parseFloat(row.totalAmount),
                Q4: parseFloat(item.Q4),
                Q3: parseFloat(item.Q3),
                Q2: parseFloat(item.Q2),
                Q1: parseFloat(item.Q1),
                year: row.year
              }))
              : []
          }
          columns={childColums}
          bordered={true}
          defaultSorted={[{
            dataField: childColums[1]?.dataField, // default sorting by the first column
            order: 'asc' // 'asc' or 'desc'
          }]}
        // rowEvents={onRowClick}
        // expandRow={expandRow}
        />
      </>
    ),
    expanded: RebateData?.length > 0 && RebateData?.map((row) => row.id),
    expandByColumnOnly: true, // Only expand by clicking the custom expand column
  };
  return (
    <div>
      <ChildTableView
        data={
          RebateData
            ? RebateData.map((item) => ({
              ...item,
              totalAmount: parseFloat(item.totalAmount),
              Q4: parseFloat(item.Q4),
              Q3: parseFloat(item.Q3),
              Q2: parseFloat(item.Q2),
              Q1: parseFloat(item.Q1),
            }))
            : []
        }
        columns={columns}
        responsiveClass={
          responsiveClass + " rebate-table rebate_history rebate_history-tables"
        }
        tableId={"rebate-table"}
        expandRow={expandRow}
        defaultSorted={[{
          dataField: columns[1]?.dataField, // default sorting by the first column
          order: 'asc' // 'asc' or 'desc'
        }]}
      />
      {!RebateData?.length > 0 && (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}
    </div>
  );
}

export default RebateView;
