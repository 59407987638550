import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

export default function ChildTableView(props) {
  // const location = useLocation();

  const {
    data,
    customClass,
    responsiveClass,
    columns,
    handleScroll,
    rowClassName,
    tableId,
    rowEvents,
    expandRow,defaultSorted
  } = props;
  const onRowClick = {
    onClick: rowEvents ? rowEvents : () => {},
  };

  const rowClasses = (row, rowIndex) => {
    return (
      (rowIndex % 2 === 0 ? "even-row " : "odd-row ") +
      (rowClassName ? rowClassName : "")
    );
  };

  return (
    <>
      <div
        className={responsiveClass ? responsiveClass : "" + " table-responsive"}
        onScroll={handleScroll}
      >
        <BootstrapTable
          id={tableId ? tableId : "myTable"}
          classes={customClass ? customClass : "" + " small table-sm"}
          striped={true}
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          bordered={true}
          rowEvents={onRowClick}
          expandRow={expandRow}
          defaultSorted={defaultSorted}
          rowClasses={
            rowClasses
            // ? rowClassName
            // : (row, rowIndex) =>
            //     rowIndex === data?.length - 1 &&
            //         (location.pathname === "/rebate" || location.pathname === "/admin/rebate")
            //       ? "rebate-detail-total"
            //       : ""
          }
        />
      </div>
    </>
  );
}
