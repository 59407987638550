import React, { useState } from "react";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";
import NoDataFound from "../../../components/NotFound/NoDataFound";
import DatePicker from "react-datepicker";
import { DATE_FORMATER } from "../../../constant/commonConstants";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import {
  ApprovedSvg,
  DeleteSvg,
  DownArrowSvg,
  EmailSvg2,
  Phone_Svg,
  PluesSvg,
  RepresentativeSvg,
  ShortDownSvg,
  ShortUpSvg,
  UserSvg,
  ViewSvg,
} from "../../../components/CommonSvg";
import { Link, useNavigate } from "react-router-dom";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import ServiceRequest from "./ServiceRequest";
import { Button } from "reactstrap";
import { Accordion } from "react-bootstrap";
import { commonConfirmBox } from "../../../helper/messagehelpers";

export default function MemberData({
  dataPerPage,
  setCurrentPage,
  currentPage,
  setDataPerPage,
  totalRecords,
  allMemberList,
  dispatchData,
  isRefresh,
  removeStoreGroupRequest,
  onChangeOrderBy,
  orderBy, setSelectedStore
}) {
  const [modalView, setModaleView] = useState(false);
  const [hraMemberId, sethraMemberId] = useState("");
  const [isEditModal, setIsEditModal] = useState(null);
  const handleEditModalToggle = (id) => {
    sethraMemberId(id);
    setModaleView(!modalView);
  };
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("");

  const handleItemClick = (hraMemberId) => {
    navigate(`/admin/members/view/` + hraMemberId);
    // setActiveKey(index === activeKey ? null : index);
  };

  return (
    <>
      <Accordion activeKey={String(activeKey)} className="store_accordion">
        <ul className="vendor_member_row member_row_head ">
          <li className="member-hra_id">
            <span>HRA Id</span>
            <Button
              className="btn"
              onClick={() => onChangeOrderBy("hraMemberId")}
            >
              {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
            </Button>
          </li>
          <li className="member-name">
            <span>Member</span>
            <Button
              className="btn"
              onClick={() => onChangeOrderBy("firstName")}
            >
              {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
            </Button>
          </li>
          <li className="member-email">
            <span>Email</span>
            <Button
              className="btn"
              onClick={() => onChangeOrderBy("emailAddress")}
            >
              {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
            </Button>
          </li>
          <li className="member-phoneno">
            <span>Phone No.</span>
            <Button
              className="btn"
              onClick={() => onChangeOrderBy("phoneNumberMobileNumber")}
            >
              {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
            </Button>
          </li>
          <li className="member-representative">
            <span>Representative</span>
            <Button
              className="btn"
              onClick={() => onChangeOrderBy("managerTerritory")}
            >
              {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
            </Button>
          </li>
          <li className="member-relation">
            <span>Relation</span>
          </li>
          <li className="member-action-btn">
            <span></span>
          </li>
        </ul>
        {allMemberList &&
          allMemberList.map((data, index) => (
            <AccordionItem
              eventKey={String(index)}
              key={index}
              className={data?.StoreGroups.length === 0 && "remove_accodian"}
            >
              <AccordionHeader targetId="1"
                onClick={() => handleItemClick(data.hraMemberId)}
                className={activeKey === index ? "collapse-accordion" : ""}>
                <ul>
                  <li className="store_hra_id">
                    <span>{data.hraMemberId}</span>
                  </li>
                  <li>
                    {/* <Link
                    to={`/admin/members/view/` + data.hraMemberId}
                    className="view_link_user"
                  > */}
                    <UserSvg />
                    {(data.firstName !== null ? data.firstName : "") +
                      " " +
                      (data.lastName !== null ? data.lastName : "")}
                    {/* </Link> */}
                  </li>
                  <li className="store-hra-mail">
                    <p>
                      <EmailSvg2 />
                      {data.emailAddress}
                    </p>
                  </li>
                  <li className="store-hra-contact">
                    <p>
                      <Phone_Svg />
                      {data.phoneNumberMobileNumber}
                    </p>
                  </li>
                  <li className="store-hra-contact representative_field">
                    <p>
                      <RepresentativeSvg />
                      <span>{data?.managerTerritory}</span>
                    </p>
                  </li>
                  <li className="relation_badge">
                    {data.relationship && (
                      <span>{data.relationship}</span>
                    )}
                  </li>
                  <li className="action_button">
                    {/* <Link
                    to={`/admin/members/view/` + data.hraMemberId}
                    className="view_btn_icon mx-1"
                  >
                    <ViewSvg />
                  </Link> */}
                    <button
                      className="btn btn-info me-2"
                      title="Create Support Ticket"
                      onClick={(e) => {
                        if (e && e.stopPropagation) {
                          e.stopPropagation();
                        }
                        setSelectedStore(data)
                      }}
                    >
                      <PluesSvg />Support Ticket
                    </button>

                    {/* {!data.agreementAdded ? (
                      <button
                        className="btn btn-info me-2"
                        title="Add agreement"
                        onClick={(e) => {
                          if (e && e.stopPropagation) {
                            e.stopPropagation();
                          }
                          handleEditModalToggle(data.hraMemberId);
                        }}
                      >
                        <PluesSvg /> Agreement
                      </button>
                    ) : (
                      data?.agreementAdded == true && (
                        <span
                          className="ms-2 me-2 text-info"
                          onClick={() => {
                            handleEditModalToggle(data.hraMemberId);
                            setIsEditModal(data);
                          }}
                        >
                          <ApprovedSvg />
                        </span>
                      )
                    )} */}
                    {data?.StoreGroups.length > 0 && (
                      <Button
                        color="link"
                        className="view_substore"
                        onClick={(e) => {
                          if (e && e.stopPropagation) {
                            e.stopPropagation();
                          }
                          if (index === activeKey) {
                            setActiveKey("");
                          } else {
                            setActiveKey(index);
                          }
                        }}
                      >
                        <DownArrowSvg />
                      </Button>
                    )}
                  </li>
                </ul>
              </AccordionHeader>
              {data?.StoreGroups.length > 0 && (
                <AccordionBody accordionId="1">
                  {data?.StoreGroups?.map((sub, index) => (
                    <ul key={index}
                      className="cursor-pointer"
                      onClick={() => handleItemClick(sub?.subHra?.hraMemberId)}
                    >
                      <li className="store_hra_id">
                        <Link
                          to={`/admin/members/view/` + sub?.subHra?.hraMemberId}
                        >
                          <span>{sub?.subHra?.hraMemberId}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="view_link_user"
                          to={`/admin/members/view/` + sub?.subHra?.hraMemberId}
                        >
                          <UserSvg />
                          <span>
                            {(sub?.subHra?.firstName !== null
                              ? sub?.subHra.firstName
                              : "") +
                              " " +
                              (sub?.subHra?.lastName !== null
                                ? sub?.subHra.lastName
                                : "")}
                          </span>
                        </Link>
                      </li>
                      <li className="store-hra-mail">
                        <p>
                          <EmailSvg2 />
                          {sub?.subHra?.emailAddress}
                        </p>
                      </li>
                      <li className="store-hra-contact">
                        <p>
                          <Phone_Svg />
                          {sub?.subHra?.phoneNumberMobileNumber}
                        </p>
                      </li>
                      <li className="store-hra-contact representative_field">
                        <p>
                          <RepresentativeSvg />
                          <span>{sub?.subHra?.managerTerritory}</span>
                        </p>
                      </li>
                      <li className="relation_badge">
                        <span>{sub?.subHra?.relationship}</span>
                      </li>
                      <li className="action_button">
                        <Link
                          onClick={async (e) => {
                            if (e && e.stopPropagation) {
                              e.stopPropagation();
                            }
                            const response = await commonConfirmBox(
                              "Are you sure you want to delete this sub store?"
                            );
                            if (response) {
                              removeStoreGroupRequest({
                                value: {
                                  storeId: sub?.id,
                                  hraMemberId: sub?.hraMemberId,
                                  subHraMemberId: sub?.subHraMemberId,
                                },
                                dispatchData: dispatchData,
                              });
                            }
                          }}
                          className="delete_btn_icon mx-1"
                        >
                          <DeleteSvg />
                        </Link>
                        {!sub?.subHra?.agreementAdded ? (
                          <button
                            className="btn btn-info me-2"
                            title="Add agreement"
                            onClick={(e) => {
                              if (e && e.stopPropagation) {
                                e.stopPropagation();
                              }
                              handleEditModalToggle(sub?.subHra?.hraMemberId)
                            }}
                          >
                            <PluesSvg /> Agreement
                          </button>
                        ) : (
                          sub?.subHra?.agreementAdded == true && (
                            <span
                              className="ms-2 text-info  agree_icon_btn"
                              onClick={(e) => {
                                if (e && e.stopPropagation) {
                                  e.stopPropagation();
                                }
                                handleEditModalToggle(sub?.subHra?.hraMemberId);
                                setIsEditModal(sub);
                              }}
                            >
                              <ApprovedSvg />
                            </span>
                          )
                        )}
                      </li>
                    </ul>
                  ))}
                </AccordionBody>
              )}
            </AccordionItem>
          ))}
      </Accordion>
      {totalRecords > 0 ? (
        <CommonPagination
          dataPerPage={dataPerPage}
          totalData={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      ) : (
        <NoDataFound />
      )}
      <CustomisedModal
        modalView={modalView}
        customClass={"category-modal members-modal"}
        modalName={isEditModal ? "Edit Agreement" : "Add Agreement"}
        onModalDismiss={() => {
          setModaleView(false);
          sethraMemberId("");
          setIsEditModal(null);
        }}
      >
        <ServiceRequest
          onModalDismiss={() => {
            isRefresh(true);
            setModaleView(false);
            sethraMemberId("");
            setIsEditModal(null);
          }}
          isEditModal={isEditModal}
          hraMemberId={hraMemberId}
        />
      </CustomisedModal>
    </>
  );
}
