import React, { useRef, useState } from "react";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import VendorHtml from "../../../assets/images/vendor01.png";
import VendorUrl from "../../../assets/images/vendor02.png";
import VendorPdf from "../../../assets/images/vendor03.png";
import { Form, Formik } from "formik";
import { __t } from "../../../i18n/translator";
import CategoryList from "./Category/CategoryList";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HTMLView from "./HTMLView";
import URLView from "./URLView";
import PDFView from "./PDFView";
import DetailsView from "./DetailsView";
import { connect } from "react-redux";
import {
  addVendorRequest,
  clearVendorRequest,
  removeImageRequest,
  updateVendorRequest,
} from "../../../redux/admin/vendor/action";
import SimpleReactValidator from "simple-react-validator";
import { useEffect } from "react";
import { dispatchData, generateInitialData } from "./venderHelper";
import { commonConfirmBox } from "../../../helper/messagehelpers";

const tabData = [
  {
    alt: "vendor_html",
    src: VendorHtml,
    label: "HTML",
  },
  {
    alt: "vendor_URL",
    src: VendorUrl,
    label: "URL",
  },
  {
    alt: "vendor_PDF",
    src: VendorPdf,
    label: "PDF",
  },
];
function AddVendor({
  addVendorData,
  updateVendorData,
  vendorStatus,
  removeImage,
  clearVendorRequest,
}) {
  const { state } = useLocation();
  const params = useParams();

  const navigate = useNavigate();
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [selectView, setSelectedView] = useState(null);
  const [redirectStatus, setRedirectStatus] = useState(false);
  const handleCategoryListModal = () => setShowCategoryList(!showCategoryList);
  const validator = useRef(new SimpleReactValidator());
  const [removedImages, setRemovedImages] = useState([]);
  const handleSelectedTab = (data) => {
    validator.current.purgeFields();
    setSelectedView(data);
  };
  const initialData = generateInitialData(state);
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (
            key === "vendorLogo" ||
            key === "pdfAttachment" ||
            key === "htmlBannerImg"
          ) {
            if (value instanceof File) {
              formData.append(key, value);
            } else {
              formData.append(key, value);
            }
          } else if (key === "htmlFiles" && Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.append(key, item);
            });
          } else {
            formData.append(key, value);
          }
        });

        if (values?.vendorId) {
          if (values.htmlTemplate && removedImages?.length > 0) {
            removeImage(
              { deleteFiles: removedImages },
              { formData, dispatchData }
            );
          } else {
            updateVendorData({ formData, dispatchData });
          }
        } else {
          addVendorData({ formData, dispatchData });
        }

        setRedirectStatus(true);
      } catch (error) {
        console.error(
          "An error occurred while processing the form data:",
          error
        );

        // Handle the error here, e.g., display an error message to the user
      }
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    if (vendorStatus && redirectStatus) {
      clearVendorRequest();
      setRedirectStatus(false);
      navigate("/admin/vendor");
    }
  }, [vendorStatus]);
  useEffect(() => {
    if (state) {
      setSelectedView(
        state.htmlCreate ? "HTML" : state.pdfAttachment ? "PDF" : "URL"
      );
    }
  }, []);
  return (
    <>
      <div className="container">
        <TableHeader
          back={() => {
            navigate("/admin/vendor");
          }}
          hedingName={
            (params?.id ? "Edit Vendor " : "Add Vendor ") +
            (selectView ? ` | ${selectView}` : "")
          }
        />
        <Formik initialValues={initialData} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => {
            const dataValue = {
              values,
              setFieldValue,
              validator,
            };

            return (
              <Form>
                <DetailsView
                  dataValue={dataValue}
                  modal={{
                    show: showCategoryList,
                    handleOpen: handleCategoryListModal,
                  }}
                />
                <div
                  className={
                    selectView
                      ? "select_doctype select_doctype_open"
                      : "select_doctype"
                  }
                >
                  <h2>Please select any one option</h2>
                  <ul>
                    {tabData.map((tab, index) => (
                      <li key={index}>
                        <Link
                          className={
                            selectView === tab.label ? "active_tab" : ""
                          }
                          onClick={async () => {
                            if (selectView !== tab.label) {
                              if (selectView) {
                                const response = await commonConfirmBox(
                                  `Are you sure you want to remove the ${selectView} and add the ${tab.label}?`
                                );
                                if (response) {
                                  handleSelectedTab(tab.label);
                                }
                              } else {
                                handleSelectedTab(tab.label);
                              }
                            }
                          }}
                        >
                          <img alt={tab.alt} className="" src={tab.src} />
                          <span>{tab.label}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="selected_doctab">
                  {selectView === "HTML" && (
                    <HTMLView
                      dataValue={dataValue}
                      setRemovedImages={setRemovedImages}
                    />
                  )}
                  {selectView === "URL" && <URLView dataValue={dataValue} />}
                  {selectView === "PDF" && <PDFView dataValue={dataValue} />}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {showCategoryList && (
        <CategoryList
          modal={{
            show: showCategoryList,
            handleClose: handleCategoryListModal,
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ vendor }) => {
  let vendorStatus = vendor?.addVendor?.status ? true : false;
  return { vendorStatus: vendorStatus };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVendorData: (data) => dispatch(addVendorRequest(data)),
    updateVendorData: (data) => dispatch(updateVendorRequest(data)),
    removeImage: (data, call) => dispatch(removeImageRequest(data, call)),
    clearVendorRequest: (data, call) =>
      dispatch(clearVendorRequest(data, call)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
