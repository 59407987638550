import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Date_Svg } from "../../../components/CommonSvg";
import { connect } from "react-redux";
import {
    getOptInListRequest,
    getOptInRequest,
} from "../../../redux/admin/optinhistory/action";
import { useEffect } from "react";
import { useState } from "react";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import OptinModal from "./OptinModal";
import NoDataFound from "../../../components/NotFound/NoDataFound";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";

function OptinHistory({
    GetOptInHistoryList,
    activeTab,
    optInHistory,
    dispatchData,
    GetOptInListRequest,
    optInMemberData,
    dataPerPage,
    currentPage,
    searchData,
    setCurrentPage,
    setDataPerPage,
    startDate,
    endDate,
    stateId,
    totalRecords,
    cityId,
    countyId,
    assignedValue,
    exportDataExportRequest,
}) {
    const [modalView, setModaleView] = useState(false);
    const [optinId, setOptinId] = useState(null);
    const [headingTitle, setHeadingTitle] = useState(null);
    const OpenModalOptain = (id) => {
        setModaleView(!modalView);
        GetOptInListRequest({
            promotionalOfferId: id,
            searchText: "",
        });
        setOptinId(id);
    };

    useEffect(() => {
        if (activeTab === "2") {
            GetOptInHistoryList(dispatchData);
        }
    }, [
        dataPerPage,
        currentPage,
        searchData,
        startDate,
        endDate,
        stateId,
        cityId,
        countyId,
        assignedValue,
    ]);

    return (
        <>
            <Row className="">
                <Col lg={12}>
                    {optInHistory?.length > 0 ? (
                        <ul className="Promotion_section">
                            {optInHistory &&
                                optInHistory.map((data, index) => {
                                    const ImageUrl =
                                        data.PromotionalOffer.PromotionalOffersOthers.map((d) => {
                                            if (d.isBanner === true) {
                                                return d.documentPath;
                                            }
                                            return null;
                                        }).filter((imageUrl) => imageUrl !== null);
                                    return (
                                        <li key={index}>
                                            <Card
                                                className={
                                                    data.PromotionalOffer.isActive
                                                        ? `promotion_card`
                                                        : `promotion_card disabled`
                                                }
                                            >
                                                <CardBody>
                                                    <div className="card_img">
                                                        <div className="promotion_img">
                                                            <img
                                                                alt="Logo"
                                                                className="logo_main"
                                                                src={ImageUrl}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={"card_content cursor-auto"}
                                                        onClick={() => {
                                                            OpenModalOptain(data.promotionalOfferId);
                                                            setHeadingTitle(data.PromotionalOffer.title);
                                                        }}
                                                    >
                                                        <div className="card_content_text">
                                                            <h4>{data.PromotionalOffer.title}</h4>

                                                            <p>{data.PromotionalOffer.description}</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center px-2 pt-2 pb-2">
                                                            <div className="card_date">
                                                                <Date_Svg />
                                                                <p>{data.PromotionalOffer.startDate}</p>
                                                                <b>to</b>
                                                                <p>{data.PromotionalOffer.endDate}</p>
                                                            </div>
                                                            <div className="opt_in_assign_btn">
                                                                <span className="opt_in_count">
                                                                    {data.memberCount}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </li>
                                    );
                                })}
                        </ul>
                    ) : (
                        <NoDataFound />
                    )}
                </Col>
            </Row>
            {modalView && (
                <CustomisedModal
                    modalView={modalView}
                    customClass={"category-modal modal-xl"}
                    modalName={"Opt-in List"}
                    scrollable={true}
                    onModalDismiss={() => {
                        setModaleView(false);
                    }}
                >
                    <OptinModal
                        optInMemberData={optInMemberData}
                        optinId={optinId}
                        headingTitle={headingTitle}
                        updateData={() =>
                            GetOptInListRequest({
                                searchText: "",
                                promotionalOfferId: optinId,
                            })
                        }
                        exportDataExportRequest={exportDataExportRequest}
                        GetOptInListRequest={GetOptInListRequest}
                    />
                </CustomisedModal>
            )}
            {totalRecords > 0 && (
                <CommonPagination
                    dataPerPage={dataPerPage}
                    totalData={totalRecords}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setDataPerPage={setDataPerPage}
                />
            )}
        </>
    );
}
const mapStateToProps = ({ optInList }) => {
    let optianListData;
    if (optInList.getOptInList?.list?.length > 0) {
        optianListData = optInList.getOptInList.list;
    }
    return {
        optInHistory: optianListData,
        totalRecords:
            optInList.getOptInList?.totalRecords &&
            optInList.getOptInList.totalRecords,
        optInMemberData:
            optInList?.getOptIns?.list?.length > 0 && optInList.getOptIns.list,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetOptInHistoryList: (data) => dispatch(getOptInRequest(data)),
        GetOptInListRequest: (data) => dispatch(getOptInListRequest(data)),
        exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OptinHistory);
