import { Field, Form, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { BackSvg } from '../../../components/CommonSvg'
import { FormGroup, Label } from 'reactstrap'
import { __t } from '../../../i18n/translator'
import SimpleReactValidator from 'simple-react-validator'

export default function EditStoreDetails({ setIsEditSec, profileDetail, hraMemberId, upadteStoreDetailsRequest }) {
    const validator = useRef(new SimpleReactValidator());
    const [profileData] = useState({
        storeAccount: profileDetail?.emailAddress ? profileDetail.emailAddress : "",
        businessPhone: profileDetail?.businessPhone ? profileDetail.businessPhone : "",
        contactMobile: profileDetail?.phoneNumberMobileNumber ? profileDetail.phoneNumberMobileNumber : "",
        contactName: profileDetail?.contactName ? profileDetail.contactName : "",
        hraMemberId: hraMemberId
    });

    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            upadteStoreDetailsRequest({ values: values, hraMemberId: hraMemberId })
            setIsEditSec(false)
        } else {
            validator.current.showMessages();
        }
    };
    return (
        <>
            <Formik initialValues={profileData} onSubmit={handleSubmit}>
                {({ values }) => (
                    <>
                        <Form>
                            <div className="tab_content_head">
                                <div>
                                    <button className="back_btn" title="back">
                                        <BackSvg />
                                    </button>
                                    <h4>Business Details</h4>
                                </div>
                                <div className="d-flex gap-3">

                                    <button
                                        className="btn btn-secondary"
                                        title="Edit"
                                        onClick={() => setIsEditSec(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        title="Save"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="profile-field-list row">

                                <div className="col-12 col-sm-12 col-md-6">
                                    <div className="hra-light-form-box">
                                        <FormGroup className="m-0">
                                            <Field
                                                id="contactName"
                                                name="contactName"
                                                placeholder="Contact Name"
                                                className="form-control"
                                                type="text"
                                            />
                                            <Label className="form-label" htmlFor="contactName">
                                                Contact Name
                                                <span className="text-danger required">*</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.profile.contactName"),
                                                values.contactName,
                                                "required",
                                                {
                                                    messages: {
                                                        required: __t("validations.profile.contactName"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6">
                                    <div className="hra-light-form-box">
                                        <FormGroup className="m-0">
                                            <Field
                                                id="contactMobile"
                                                name="contactMobile"
                                                placeholder="Owner detail"
                                                className="form-control"
                                                type="text"
                                                maxLength={10}
                                                minLength={10}
                                            />
                                            <Label className="form-label" htmlFor="title">
                                                Contact Mobile No.
                                                <span className="text-danger required">*</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.profile.contactMobile"),
                                                values.contactMobile,
                                                "required|matches:^[0-9]{10}$|numeric",
                                                {
                                                    messages: {
                                                        required: __t("validations.profile.contactMobile"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6">
                                    <div className="hra-light-form-box">
                                        <FormGroup className="m-0">
                                            <Field
                                                id="storeAccount"
                                                name="storeAccount"
                                                placeholder="Store Account Email"
                                                className="form-control"
                                                type="text"
                                            />
                                            <Label className="form-label" htmlFor="storeAccount">
                                                Store Account Email
                                                <span className="text-danger required">*</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.profile.storeAccount"),
                                                values.storeAccount,
                                                "required",
                                                {
                                                    messages: {
                                                        required: __t("validations.profile.storeAccount"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6">
                                    <div className="hra-light-form-box">
                                        <FormGroup className="m-0">
                                            <Field
                                                id="businessPhone"
                                                name="businessPhone"
                                                className="form-control"
                                                type="text"
                                                maxLength={9}
                                                minLength={9}
                                            />
                                            <Label className="form-label" htmlFor="businessPhone">
                                                Store Phone No.
                                                <span className="text-danger required">*</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.profile.businessPhone"),
                                                values.businessPhone,
                                                "required|matches:^[0-9]{9}$|numeric",
                                                {
                                                    messages: {
                                                        required: __t("validations.profile.businessPhone"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    </>
                )}

            </Formik>
        </>
    )
}
