import * as type from "./type";

export const getPromotionListRequest = (data) => {
  return {
    type: type.GET_PROMOTION_LIST_REQUEST,
    payload: data,
  };
};
export const getPromotionListSuccess = (data) => {
  return {
    type: type.GET_PROMOTION_LIST_SUCCESS,
    payload: data,
  };
};
export const viewPromotionListRequest = (data) => {
  return {
    type: type.VIEW_PROMOTION_LIST_REQUEST,
    payload: data,
  };
};
export const viewPromotionListSuccess = (data) => {
  return {
    type: type.VIEW_PROMOTION_LIST_SUCCESS,
    payload: data,
  };
};
export const addPromotionListRequest = (data) => {
  return {
    type: type.ADD_PROMOTION_LIST_REQUEST,
    payload: data,
  };
};
export const addPromotionListSuccess = (data) => {
  return {
    type: type.ADD_PROMOTION_LIST_SUCCESS,
    payload: data,
  };
};
export const editPromotionListRequest = (data) => {
  return {
    type: type.EDIT_PROMOTION_LIST_REQUEST,
    payload: data,
  };
};
export const editPromotionListSuccess = (data) => {
  return {
    type: type.EDIT_PROMOTION_LIST_SUCCESS,
    payload: data,
  };
};
export const activeInactivePromotionRequest = (data) => {
  return {
    type: type.ACTIVE_INACTIVE_PROMOTION_REQUEST,
    payload: data,
  };
};
export const activeInactivePromotionSuccess = (data) => {
  return {
    type: type.ACTIVE_INACTIVE_PROMOTION_SUCCESS,
    payload: data,
  };
};
export const assignPromotionRequest = (data) => {
  return {
    type: type.ASSIGN_PROMOTION_REQUEST,
    payload: data,
  };
};
export const assignPromotionSuccess = (data) => {
  return {
    type: type.ASSIGN_PROMOTION_SUCCESS,
    payload: data,
  };
};
export const deleteImageRequest = (data) => {
  return {
    type: type.DELETE_IMAGE_REQUEST,
    payload: data,
  };
};
export const deleteImageSuccess = (data) => {
  return {
    type: type.DELETE_IMAGE_SUCCESS,
    payload: data,
  };
};

