import * as type from "./type";

export const getMemberListRequest = (data) => {
  return {
    type: type.GET_MEMBER_LIST_REQUEST,
    payload: data,
  };
};
export const getMemberListSuccess = (data) => {
  return {
    type: type.GET_MEMBER_LIST_SUCCESS,
    payload: data,
  };
};
export const removeStoreGroupRequest = (data) => {
  return {
    type: type.ROMOVE_STORE_GROUP_REQUEST,
    payload: data,
  };
};
export const removeStoreGroupSuccess = (data) => {
  return {
    type: type.ROMOVE_STORE_GROUP_SUCCESS,
    payload: data,
  };
};

export const memberDataExportRequest = (data) => {
  return {
    type: type.MEMBER_DATA_EXPORT_REQUEST,
    payload: data,
  };
};
export const memberDataExportSuccess = (data) => {
  return {
    type: type.MEMBER_DATA_EXPORT_SUCCESS,
    payload: data,
  };
};

export const getMemberHraListRequest = (data) => {
  return {
    type: type.GET_MEMBER_HRA_LIST_REQUEST,
    payload: data,
  };
};
export const getMemberHraListSuccess = (data) => {
  return {
    type: type.GET_MEMBER_HRA_LIST_SUCCESS,
    payload: data,
  };
};
export const getSubMemberHraListRequest = (data) => {
  return {
    type: type.GET_SUB_MEMBER_HRA_LIST_REQUEST,
    payload: data,
  };
};
export const getSubMemberHraListSuccess = (data) => {
  return {
    type: type.GET_SUB_MEMBER_HRA_LIST_SUCCESS,
    payload: data,
  };
};


export const createMultiStoreRequest = (data) => {
  return {
    type: type.CREATE_MULTI_STORE_REQUEST,
    payload: data,
  };
};
export const createMultiStoreSuccess = (data) => {
  return {
    type: type.CREATE_MULTI_STORE_SUCCESS,
    payload: data,
  };
};

export const getMultiStoreDetailsRequest = (data) => {
  return {
    type: type.GET_MULTI_STORE_DETAILS_REQUEST,
    payload: data,
  };
};
export const getMultiStoreDetailsSuccess = (data) => {
  return {
    type: type.GET_MULTI_STORE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const clearCreateMultiStoreSuccess = () => {
  return {
    type: type.CLEAR_CREATE_MULTI_STORE_SUCCESS,
  };
};

export const getMemberDetailsRequest = (data) => {
  return {
    type: type.GET_MEMBER_DETAILS_REQUEST,
    payload: data,
  };
};
export const getMemberDetailsSuccess = (data) => {
  return {
    type: type.GET_MEMBER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const createUploadAgreementRequest = (data) => {
  return {
    type: type.CREATE_UPLOAD_AGREEMENT_REQUEST,
    payload: data,
  };
};
export const createUploadAgreementSuccess = (data) => {
  return {
    type: type.CREATE_UPLOAD_AGREEMENT_SUCCESS,
    payload: data,
  };
};

export const clearUploadAgreementSuccess = (data) => {
  return {
    type: type.CLEAR_UPLOAD_AGREEMENT_SUCCESS,
    payload: data,
  };
};


export const zohoStoreListRequest = (data) => {
  return {
    type: type.ZOHO_STORE_LIST_REQUEST,
    payload: data,
  };
};
export const zohoStoreListSuccess = (data) => {
  return {
    type: type.ZOHO_STORE_LIST_SUCCESS,
    payload: data,
  };
};

export const syncVendorRequest = (data) => {
  return {
    type: type.SYNC_VENDOR_REQUEST,
    payload: data,
  };
};
export const syncVendorSuccess = (data) => {
  return {
    type: type.SYNC_VENDOR_SUCCESS,
    payload: data,
  };
};
export const getProfileVendorRequest = (data) => {
  return {
    type: type.GET_PROFILE_VENDOR_REQUEST,
    payload: data,
  };
};
export const getProfileVendorSuccess = (data) => {
  return {
    type: type.GET_PROFILE_VENDOR_SUCCESS,
    payload: data,
  };
};