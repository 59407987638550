import { Card, CardBody } from "reactstrap";
import {
    ActionbtnSvg,
    Date_Svg,
    EyeSvg,
    LoactionSvg,
} from "../../../components/CommonSvg";
import Switch from "react-switch";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const PromotionCard = ({
    data,
    promationStatus,
    dispatchData,
    setModaleView,
    setIsAssigned,
    setEditPromationData,
    promationData,
    setPromationData,
    setPromationId,
    promationId,
    isAccessedStaff,
    isAccess,
}) => {
    const ImageUrl = data.PromotionalOffersOthers.map((d) => {
        if (d.isBanner === true) {
            return d.documentPath;
        }
        return null;
    }).filter((imageUrl) => imageUrl !== null);
    const havePromationId = promationId.includes(data.id);
    const HandelSelectAssign = () => {
        if (data.isAssigned !== true && isAccessedStaff === true) {
            if (promationId.includes(data.id)) {
                const updatedPromationData = promationData.filter(
                    (item) => item.id !== data.id
                );
                const updatedPromationId = promationId.filter((id) => id !== data.id);
                setPromationData(updatedPromationData);
                setPromationId(updatedPromationId);
            } else {
                setPromationData([
                    ...promationData,
                    { imgUrl: ImageUrl, id: data.id, title: data.title, description: data.description, assignUser: data.createdBy },
                ]);
                setPromationId([...promationId, data.id]);
            }
        }
    };
    return (
        <>
            <li>
                <Card className={`promotion_card ${havePromationId ? "selected" : ""}`}>
                    <CardBody>
                        <div className="card_img">
                            {/* {data.isAssigned === true && (
                                <>
                                    <div className="assigned" id="UncontrolledTooltipExample">
                                        <LoactionSvg />
                                        <span>Assigned</span>
                                    </div>

                                </>
                            )} */}

                            <div className="promotion_img">
                                <img alt="Logo" className="logo_main" src={ImageUrl} />
                            </div>
                            <Link
                                to={{
                                    pathname: `/admin/promotion/view/${data.id}`,
                                    state: { ViewId: data.id },
                                }}
                                title="Click for item detail"
                                className="view_more"
                            >
                                <EyeSvg />
                            </Link>
                        </div>
                        <div
                            className={
                                data.isAssigned === true
                                    ? "card_content"
                                    : "card_content cursor-auto"
                            }
                            title={!data.isAssigned && promationId.length === 0 && "Double-click for the multiple select items."}
                            onDoubleClick={() => {
                                if (promationId.length === 0) {
                                    HandelSelectAssign();
                                }
                            }}
                            onClick={() => {
                                if (promationId.length >= 1) {
                                    HandelSelectAssign();
                                }
                            }}
                        >
                            <div className="card_content_text">
                                <div className="promotion_title">
                                    <h4>{data.title}</h4>
                                    {isAccess === true && (
                                        <div className="d-flex justify-content-end">
                                            <Switch
                                                className={`react-switch react-switch_card ${data.isActive && "isActive"
                                                    } switch-sm`}
                                                onChange={async () => {
                                                    const response = await commonConfirmBox(
                                                        !data.isActive
                                                            ? "Do you want to activate this promotion?"
                                                            : "Do you want to deactivate this promotion?"
                                                    );
                                                    if (response) {
                                                        promationStatus({
                                                            userId: data.id,
                                                            isActive: !data.isActive,
                                                            dispatchData,
                                                        });
                                                    }
                                                }}
                                                type="checkbox"
                                                id="custom-switch"
                                                checked={data.isActive}
                                            />
                                        </div>
                                    )}
                                    {/* {isAccessedStaff === true && (
                                        <Dropdown className="" align={'end'} direction="up">
                                            <Dropdown.Toggle
                                                id="dropdown-basic"
                                                className="action_dot_btn"
                                                title="Assign promotion"
                                            >
                                                <ActionbtnSvg />
                                            </Dropdown.Toggle>
                                            {promationId.length > 1 && data.isAssigned === true ? '' :
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            const havePromationId = promationId.includes(data.id);
                                                            if (!havePromationId) {

                                                                setModaleView(true);
                                                                setPromationId([...promationId, data.id]);
                                                                setEditPromationData(data.AllocatePromotions);
                                                                setIsAssigned(data.isAssigned);
                                                                setPromationData([
                                                                    ...promationData,
                                                                    {
                                                                        imgUrl: ImageUrl,
                                                                        id: data.id,
                                                                        title: data.title,
                                                                        assignUser: data.createdBy
                                                                    },
                                                                ]);
                                                            } else {
                                                                setModaleView(true);
                                                            }
                                                        }}
                                                    >
                                                        Assign Promotion
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>}
                                        </Dropdown>
                                    )} */}
                                </div>
                                <p>{data.description}</p>
                            </div>
                            <div className="d-flex justify-content-between align-center align-items-center px-2 pt-2 pb-2">
                                <div className="card_date">
                                    <Date_Svg />
                                    <p>{data.startDate}</p>
                                    <b>to</b>
                                    <p>{data.endDate}</p>
                                </div>

                                {/* {isAccess === true && (
                                    <div className="d-flex align-items-center">
                                        <Switch
                                            className={`react-switch react-switch_card ${data.isActive && "isActive"
                                                } switch-sm`}
                                            onChange={async () => {
                                                const response = await commonConfirmBox(
                                                    !data.isActive
                                                        ? "Do you want to activate this promotion?"
                                                        : "Do you want to deactivate this promotion?"
                                                );
                                                if (response) {
                                                    promationStatus({
                                                        userId: data.id,
                                                        isActive: !data.isActive,
                                                        dispatchData,
                                                    });
                                                }
                                            }}
                                            type="checkbox"
                                            id="custom-switch"
                                            checked={data.isActive}
                                        />
                                    </div>
                                )} */}
                            </div>
                        </div>
                        {isAccessedStaff === true && (
                            <button className={data.isAssigned === true ? "creat_btn assigned text-white" : "creat_btn text-white"} title="Assign Promotion" disabled={promationId.length > 1 && data.isAssigned === true}
                                onClick={() => {
                                    const havePromationId = promationId.includes(data.id);
                                    if (!havePromationId) {
                                        setModaleView(true);
                                        setPromationId([...promationId, data.id]);
                                        setEditPromationData(data.AllocatePromotions);
                                        setIsAssigned(data.isAssigned);
                                        setPromationData([
                                            ...promationData,
                                            {
                                                imgUrl: ImageUrl,
                                                id: data.id,
                                                title: data.title,
                                                assignUser: data.createdBy
                                            },
                                        ]);
                                    } else {
                                        setModaleView(true);
                                    }
                                }}
                            >
                                {data.isAssigned === true ? "Assigned" : "Assign"}
                            </button>
                        )}
                    </CardBody>
                </Card>
            </li>
        </>
    );
};
export default PromotionCard;
