import { DANGER } from "../constant/commonConstants";
import { ApiResponseMessage } from "./tosterHelper";

const handleFileUpload =
  (
    setFieldValue,
    id,
    setImgUpload,
    setUploadProgress,
    setOtherFileImage,
    otherFileImage,
    filesInfo
  ) =>
    (e) => {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      const isValidImage = chosenFiles.every((file) =>
        file.type.startsWith(filesInfo?.validateFileType)
      );
      if (!isValidImage) {
        ApiResponseMessage(filesInfo?.errorMessage, DANGER);
        return false;
      } else {
        if (chosenFiles.length > 0) {
          const validFiles = [];

          const processFile = async (file) => {
            return new Promise((resolve) => {
              if (file.size <= 1024 * 1024) {
                // Check if file size is less than or equal to 1 MB
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = () => {
                  const width = image.width;
                  if (width >= 1280) {
                    validFiles.push(file);
                  } else {
                    ApiResponseMessage(
                      "Image width must be at least 1280 pixels.",
                      DANGER
                    );
                  }
                  resolve();
                };
              } else {
                ApiResponseMessage(
                  "Image size must be less than or equal to 1 MB.",
                  DANGER
                );
                resolve();
              }
            });
          };
          Promise.all(chosenFiles.map(processFile)).then(() => {
            // Set otherFileImage only with valid files
            if (filesInfo?.isMultiple === false) {
              setOtherFileImage(validFiles);
            } else {
              setOtherFileImage((prevSelectedFiles) => [
                ...prevSelectedFiles,
                ...validFiles,
              ]);
            }

            // Set ImgUpload only with valid files
            const validImageData = validFiles.map((file) => ({
              fileName: file.name,
              imageUrl: URL.createObjectURL(file),
            }));

            if (filesInfo?.isMultiple === false) {
              setImgUpload(validImageData);
            } else {
              setImgUpload((prevImgUpload) => [
                ...prevImgUpload,
                ...validImageData,
              ]);
            }

            setFieldValue(id, validFiles);
            let count = 20;

            const startTime = new Date().getTime();

            const interval = setInterval(() => {
              const currentTime = new Date().getTime();
              const elapsedTime = currentTime - startTime;

              if (count === 100) {
                clearInterval(interval);
              } else if (elapsedTime >= 100) {
                setUploadProgress(count + 1);
                count += 1;
              }
            }, 4);
          });
        }
      }
    };

export { handleFileUpload };
