import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { getRoleListRequest } from "../../../redux/admin/role/action";
import { CloseSvg } from "../../../components/CommonSvg";
function AchFilter({ handelShowFIlter, selectedStatus, setSelectedStatus }) {
    const statusList = [
        { value: "", label: "All" },
        { value: "PENDING", label: "Pending" },
        { value: "APPROVED", label: "Approved" },
        { value: "REJECTED", label: "Rejected" },
    ];

    return (
        <>
            <Card className="filter_section user_filter">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>
                    <>
                        <form className="hra-create">
                            <Row>
                                <Col sm={12} className="no-floating-label">
                                    <FormGroup>
                                        <Select
                                            type="text"
                                            id="Status"
                                            name="Status"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={statusList}
                                            value={statusList.filter(
                                                (d) => d.value === selectedStatus && d.label
                                            )}
                                            onChange={(selectedOption) => {
                                                setSelectedStatus(selectedOption.value);
                                                handelShowFIlter();
                                            }}
                                            isClearable
                                        />
                                        <Label className="form-label" htmlFor="Status">
                                            Status
                                        </Label>
                                    </FormGroup>

                                    <Button
                                        className="my-tooltip cancel_filter"
                                        data-tooltip-content={"Close filter"}
                                        color="link"
                                        onClick={() => {
                                            setSelectedStatus("");
                                            handelShowFIlter();
                                        }}
                                    >
                                        <CloseSvg />
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </>
                </CardBody>
            </Card>
        </>
    );
}
export default AchFilter;
