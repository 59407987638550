import * as type from "./type";

export const getCityListRequest = () => {
    return {
        type: type.GET_CITY_LIST_REQUEST,
    };
};
export const getCityListSuccess = (data) => {
    return {
        type: type.GET_CITY_LIST_SUCCESS,
        payload: data
    };
};
export const getStateListRequest = () => {
    return {
        type: type.GET_STATE_LIST_REQUEST,
    };
};
export const getStateListSuccess = (data) => {
    return {
        type: type.GET_STATE_LIST_SUCCESS,
        payload: data
    };
};
export const getCountyListRequest = () => {
    return {
        type: type.GET_COUNTY_LIST_REQUEST,
    };
};
export const getCountyListSuccess = (data) => {
    return {
        type: type.GET_COUNTY_LIST_SUCCESS,
        payload: data
    };
};

export const getTerritoryListRequest = () => {
    return {
        type: type.GET_TERRITORY_LIST_REQUEST,
    };
};
export const getTerritoryListSuccess = (data) => {
    return {
        type: type.GET_TERRITORY_LIST_SUCCESS,
        payload: data
    };
};