import React from "react";
import { useNavigate } from "react-router-dom";
import { BackSvg, Date_Svg, EditbtnSvg } from "../CommonSvg";
function CommonBackHeader(props) {
    const { backPath, HeaderName, isEditUrl, createdDate, isEditButton, endDate, startDate } = props;
    const navigate = useNavigate();
    return (
        <>
            <div className="container">
                <div className="section-head mt-3">
                    <div>
                        <button
                            className="my-tooltip back_btn"
                            data-tooltip-content={"Back"}
                            onClick={() => {
                                navigate(backPath);
                            }}
                        >
                            <BackSvg />
                        </button>
                        <h3>{HeaderName}</h3>
                    </div>
                    <div>
                        {createdDate && (
                            <span className="badge create-date-badge">Created Date : {createdDate}</span>
                        )}
                        {endDate &&<div className="newsletter_date">
                            <span><Date_Svg />{startDate}</span>
                            {" "}to
                            <span><Date_Svg />{endDate}</span>
                        </div>}
                        {isEditButton && (
                            <button onClick={isEditUrl} className="creat_btn edit_hra_btn ms-3">
                                <EditbtnSvg />
                                {isEditButton}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CommonBackHeader;
