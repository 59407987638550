import React, { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import logosm from "../../../../src/assets/images/logo-dark.webp";
import login_bg from "../../../../src/assets/images/memberportal.webp";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import {
  clearResponse,
  getMemberForgotLoginRequest,
} from "../../../redux/frontend/auth/forgot/action";
import { useEffect } from "react";
import { useState } from "react";

function ForgotLogin({ ForgetLoginSend, clearResponse, MemberLoginStatus }) {
  const validator = useRef(new SimpleReactValidator());
  const location = useLocation();
  const navigate = useNavigate();
  const LoginInitialValues = {
    email: "",
  };
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      ForgetLoginSend(values);
    } else {
      validator.current.showMessages();
    }
  };
  const [loginFormData, setLoginFormData] = useState(LoginInitialValues);
  useEffect(() => {
    let refresh = false;
    if (location?.pathname == "/forgotlogin") {
      clearResponse();
      refresh = true;
    }

    if (refresh) {
      if (MemberLoginStatus) {
        navigate("/login");
        setLoginFormData();
      }
    }
  }, [MemberLoginStatus]);
  return (
    <div className="comtainer">
      <div className="login_box">
        <div className="login_bg_fornt">
          <img alt="login_bg" src={login_bg} />
        </div>
        <div className="login_field_fornt">
          <Row>
            <Formik initialValues={loginFormData} onSubmit={handleSubmit}>
              {({ values }) => (
                <Col className="login-form">
                  <div className="loging-head">
                    <img alt="Logo" src={logosm} />
                    <h2>Forgot Login</h2>
                  </div>
                  <Form className="form-horizontal form-login row">
               
                    <FormGroup className="col-12 sticky_label">
                      <Field
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        type="Email"
                      />
                      <Label className="form-label" htmlFor="email">
                        Email Address{" "}
                        <span className="text-danger required">*</span>
                      </Label>
                      {validator.current.message(
                        __t("validations.auth.email"),
                        values.email,

                        "required",
                        {
                          messages: {
                            required: __t("validations.auth.email"),
                          },
                        }
                      )}
                    </FormGroup>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Button
                        type="submit"
                        color="primary"
                        block
                        className="w-auto"
                        title="Send Link"
                      >
                        Submit
                      </Button>
                      <Link
                        to="/login"
                        className="pt-3 d-inline-block w-100 text-center"
                        title="back to login"
                      >
                        Back to login
                      </Link>
                    </div>
                  </Form>
                  <div className="policy-links">
                    <Link
                      to="https://www.hraga.com/terms-of-service"
                      target="_blank"
                      className="d-inline-block"
                      title="Terms of Service"
                    >
                      Terms of Service
                    </Link>
                    <Link
                      to="https://www.hraga.com/mbr-app-privacy-policy"
                      className="d-inline-block"
                      target="_blank"
                      title="Privacy Policy"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </Col>
              )}
            </Formik>
          </Row>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ MemberForgot }) => {
  let MemberLoginStatus =
    MemberForgot?.error?.status && MemberForgot.error.status;
  return { MemberLoginStatus };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearResponse: (crd) => dispatch(clearResponse(crd)),
    ForgetLoginSend: (data) => dispatch(getMemberForgotLoginRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotLogin);
