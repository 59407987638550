import React from "react";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";
import { getLatestPromotionListRequest } from "../../../redux/frontend/promotion/action";
import { useEffect } from "react";
import { connect } from "react-redux";
import CommonPromotionCard from "../../../components/Common/Frontend/Promotion/CommonPromotionCard";
import { getValueByKey } from "../../../utils/authUtils";
import { getOptInStatusRequest } from "../../../redux/admin/optinhistory/action";
import NoPromotionFound from "../../../components/Common/Frontend/Promotion/NoPromotionFound";

function NewPromotions({
    getDetails,
    getOptInStatusRequest,
    getLatestPromotionList,
}) {
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    useEffect(() => {
        getDetails({ hraMemberId: hraMemberId });
    }, []);
    return (
        <>
            <div className="container">
                <CommonSortHeader PromotionsName="Latest Promotions" BackTo={"/home"} />
                {getLatestPromotionList?.filter((product) => product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true)?.length > 0 ? (
                    <div className="product-card-list">
                        {getLatestPromotionList?.filter((product) => product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true).map((item, index) => {
                            return (
                                <CommonPromotionCard
                                    key={index}
                                    item={item}
                                    getOptInStatusRequest={getOptInStatusRequest}
                                    ApiUpdateReq={"membersLatestPromotions"}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <NoPromotionFound />
                )}
            </div>
        </>
    );
}
const mapStateToProps = ({ MemberPromotions }) => {
    let getLatestPromotionList = [];
    if (MemberPromotions?.latestPromotion?.data?.latestPromotions) {
        getLatestPromotionList =
            MemberPromotions.latestPromotion.data.latestPromotions;
    }
    return { getLatestPromotionList: getLatestPromotionList };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOptInStatusRequest: (data) => dispatch(getOptInStatusRequest(data)),
        getDetails: (data) => dispatch(getLatestPromotionListRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPromotions);
