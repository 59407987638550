import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { CloseSvg } from "../../../../components/CommonSvg";
import {
    getCityListRequest,
    getCountyListRequest,
    getStateListRequest,
} from "../../../../redux/admin/location/action";
import { connect } from "react-redux";

function VendorFilter({
    setFilter,
    filter,
    setFilterView,
    CityList,
    StateList,
    CountyList,
    GetCityList,
    GetStateList,
    GetCountyList,
    setCountyId,
    setCityId,
    setStateId,
    setAssignedValue, setExportFilterData
}) {
    const filterStatus = [
        {
            value: "",
            label: "All",
        },
        {
            value: "Active",
            label: "Active",
        },
        {
            value: "Deactive",
            label: "Deactive",
        },
    ];
    useEffect(() => {
        GetCityList();
        GetStateList();
        GetCountyList();
    }, []);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const [assigned, setAssigned] = useState(null);
    const cityOptions = CityList?.map((city) => ({
        value: city.id,
        label: city.cityName,
    }));

    const stateOptions = StateList?.map((city) => ({
        value: city.id,
        label: city.stateName,
    }));
    const countyOptions = CountyList?.map((city) => ({
        value: city.id,
        label: city.countyName,
    }));
    const assignOptions = [
        { value: "Assigned", label: "Assigned" },
        { value: "Not-assigned", label: "Not Assigned" },
    ];
    const handelClearAll = () => {
        setCityId("")
        setSelectedCity("")
        setSelectedState("");
        setSelectedCounty("");
        setCountyId("");
        setStateId("");
        setAssigned("");
        setAssignedValue("");
        setExportFilterData(false);
    }
    return (
        <>
            <Card className="filter_section user_filter vendor_filter">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>

                    <div className="d-flex justify-content-end">
                        <Button
                            className="clear_btn btn-primary"
                            style={{ color: "black" }}
                            onClick={handelClearAll}
                        >
                            Clear all
                        </Button>
                    </div>
                    <>
                        <form className="hra-create">
                            <Row>
                                <Col className="no-floating-label">
                                    <FormGroup>
                                        <Select
                                            type="text"
                                            id="Active"
                                            name="Active"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={filterStatus}
                                            value={filter}
                                            onChange={(selectedOption) => {
                                                setFilter(selectedOption);
                                                //   setRoleId(selectedOption ? selectedOption.value : "")
                                                setExportFilterData(true)
                                            }}
                                        // isClearable
                                        />
                                        <Label className="form-label" htmlFor="assignStatus">
                                            Active
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col className="no-floating-label">
                                    <FormGroup className="m-0">
                                        <Select
                                            type="text"
                                            id="cityId"
                                            name="cityId"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={cityOptions}
                                            value={selectedCity}
                                            onChange={(selectedOption) => {
                                                setCityId(selectedOption ? selectedOption.value : "");
                                                setExportFilterData(true);
                                                setSelectedCity(selectedOption);
                                            }}
                                            isClearable
                                        />
                                        <Label className="form-label" htmlFor="stateId">
                                            City
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col className="no-floating-label">
                                    <FormGroup className="m-0">
                                        <Select
                                            type="text"
                                            id="stateId"
                                            name="stateId"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={stateOptions}
                                            value={selectedState}
                                            onChange={(selectedOption) => {
                                                setSelectedState(selectedOption);
                                                setExportFilterData(true);
                                                setStateId(selectedOption ? selectedOption.value : "");
                                            }}
                                            isClearable
                                        />
                                        <Label className="form-label" htmlFor="stateId">
                                            State
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col className="no-floating-label">
                                    <FormGroup className="m-0">
                                        <Select
                                            type="text"
                                            id="countyId"
                                            name="countyId"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={countyOptions}
                                            value={selectedCounty}
                                            onChange={(selectedOption) => {
                                                setSelectedCounty(selectedOption);
                                                setExportFilterData(true);
                                                setCountyId(selectedOption ? selectedOption.value : "");
                                            }}
                                            isClearable
                                        />
                                        <Label className="form-label" htmlFor="stateId">
                                            County
                                        </Label>
                                    </FormGroup>
                                </Col>
                                {/* {activeTab == "1" && ( */}
                                <Col className="no-floating-label">
                                    <FormGroup className="m-0">
                                        <Select
                                            type="text"
                                            id="isAssigned"
                                            name="isAssigned"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={assignOptions}
                                            value={assigned}
                                            onChange={(selectedOption) => {
                                                  setExportFilterData(true);
                                                setAssigned(selectedOption);
                                                setAssignedValue(
                                                    selectedOption ? selectedOption.value : ""
                                                );
                                            }}
                                            isClearable
                                        />
                                        <Label className="form-label" htmlFor="isAssigned">
                                            Assign Status
                                        </Label>
                                    </FormGroup>
                                </Col>
                                {/* )} */}
                            </Row>
                        </form>
                    </>
                    <Button
                        className="my-tooltip cancel_filter"
                        data-tooltip-content={"Close filter"}
                        color="link"
                        onClick={() => setFilterView(true)}
                    >
                        <CloseSvg />
                    </Button>
                </CardBody>
            </Card>
        </>
    );
}
const mapStateToProps = ({ location }) => {
    let CityList;
    let StateList;
    let CountyList;
    if (
        location.getCity &&
        Array.isArray(location.getCity.list) &&
        location.getCity.list.length > 0
    ) {
        CityList = location.getCity.list;
    }

    if (
        location.getState &&
        Array.isArray(location.getState.list) &&
        location.getState.list.length > 0
    ) {
        StateList = location.getState.list;
    }

    if (
        location.getCounty &&
        Array.isArray(location.getCounty.list) &&
        location.getCounty.list.length > 0
    ) {
        CountyList = location.getCounty.list;
    }
    return {
        CityList: CityList,
        StateList: StateList,
        CountyList: CountyList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetCityList: () => {
            dispatch(getCityListRequest());
        },
        GetStateList: () => {
            dispatch(getStateListRequest());
        },
        GetCountyList: () => {
            dispatch(getCountyListRequest());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorFilter);
