import React from "react";
import {
    BackSvg,
    CloseSvg,
    DeleteSvg,
    EditbtnSvg,
    PromotionsHistorySvg,
    RightSvg,
} from "../../../components/CommonSvg";
import { Col, Row } from "reactstrap";
import {
    removeAchRequest,
    viewAchRequest,
} from "../../../redux/frontend/ach/action";
import { useEffect } from "react";
import { getValueByKey } from "../../../utils/authUtils";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../components/NotFound/NoDataFound";
import { commonConfirmBox } from "../../../helper/messagehelpers";
function ViewAchManagement({
    viewAchRequest,
    viewAchDetails,
    removeAchRequest,
    accountName
}) {

    const navigate = useNavigate();
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    localStorage.setItem("accountName", JSON.stringify(accountName));
    useEffect(() => {
        viewAchRequest({ hraMemberId: hraMemberId });
    }, []);

    return (
        <>
            <div className="container">
                <div className={`section-head  ${viewAchDetails?.achManagement?.[0]?.status ? "ach_section_head" : ""}`}>
                    <div>
                        <button
                            className="back_btn"
                            title="back"
                            onClick={() => {
                                navigate("/home");
                            }}
                        >
                            <BackSvg />
                        </button>
                        <h3>ACH Management</h3>
                    </div>
                    <div className="view_btn">
                        {viewAchDetails !== null ? (
                            <>
                                <div>
                                    {viewAchDetails.achManagement?.[0]?.status === "PENDING" && (
                                        <span className="badge pendding_badge">
                                            <PromotionsHistorySvg />
                                            Pending
                                        </span>
                                    )}
                                    {viewAchDetails.achManagement?.[0]?.status === "APPROVED" && (
                                        <span className="badge approved_badge">
                                            <RightSvg />
                                            Approved
                                        </span>
                                    )}
                                    {viewAchDetails.achManagement?.[0]?.status === "REJECTED" && (
                                        <span className="badge rejected_badge">
                                            <CloseSvg />
                                            Rejected
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <button
                                        className="btn btn-primary small_btn"
                                        title="Edit ACH"
                                        onClick={() => {
                                            navigate("/ach/edit", { state: { viewAchDetails } });
                                        }}
                                    >
                                        <EditbtnSvg />
                                    </button>
                                    <button
                                        className="btn btn-primary small_btn"
                                        title="Delete ACH"
                                        onClick={async () => {
                                            const response = await commonConfirmBox(
                                                "Are you sure you want to remove this ACH?"
                                            );
                                            if (response) {
                                                removeAchRequest({ hraMemberId: hraMemberId });
                                            }
                                        }}
                                    >
                                        <DeleteSvg />
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <button
                                    className="btn btn-primary add_ach_btn"
                                    title=" Add ACH"
                                    onClick={() => {
                                        navigate("/ach/add");
                                    }}
                                >
                                    Add ACH
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {viewAchDetails !== null ? (
                    <>
                        <div className="ach_details mb-4">
                            <h3 className="d-flex align-items-center justify-content-between flex-wrap">
                                Details
                                <div className="account_name">
                                    {accountName && (
                                        <>
                                            <strong>Account Name: </strong> {accountName}
                                        </>
                                    )}

                                </div>
                            </h3>
                            <Row>
                                <Col sm={12} md={3}>
                                    <div className="hra-light-form-box">
                                        <label>Account No. :</label>
                                        <span>
                                            {viewAchDetails.accountNumber
                                                ? viewAchDetails.accountNumber
                                                : viewAchDetails.previousAccountNumber}
                                        </span>
                                    </div>
                                </Col>
                                <Col sm={12} md={3}>
                                    <div className="hra-light-form-box">
                                        <label>Routing No. :</label>
                                        <span>
                                            {viewAchDetails.routingNumber
                                                ? viewAchDetails.routingNumber
                                                : viewAchDetails.previousRoutingNumber}
                                        </span>
                                    </div>
                                </Col>
                                <Col sm={12} md={3}>
                                    <div className="hra-light-form-box">
                                        <label>FEIN No. :</label>
                                        <span>
                                            {viewAchDetails.chequeNumber
                                                ? viewAchDetails.chequeNumber
                                                : viewAchDetails.previousChequeNumber}
                                        </span>
                                    </div>
                                </Col>
                                <Col sm={12} md={3}>
                                    <div className="hra-light-form-box">
                                        <label>Bank Name. :</label>
                                        <span>
                                            {viewAchDetails.bankName
                                                ? viewAchDetails.bankName
                                                : viewAchDetails.previousBankName}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ach_details">
                            <h3>Document Image</h3>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="ach_document">
                                        <img
                                            alt="Document-image"
                                            className="ach_img"
                                            src={
                                                viewAchDetails.achManagement?.[0].currentDocument
                                                    ? viewAchDetails.achManagement?.[0].currentDocument
                                                    : viewAchDetails.achManagement?.[0].previousDocument
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : (
                    <>
                        <NoDataFound />
                    </>
                )}
            </div>
        </>
    );
}
const mapStateToProps = ({ AchManagement }) => {
    let viewAchDetails = null;
    let accountName = null;
    if (
        AchManagement?.viewAch?.data?.list &&
        AchManagement?.viewAch?.data?.list !== undefined
    ) {
        viewAchDetails = AchManagement.viewAch.data.list;
    }

    if (AchManagement?.viewAch?.data?.accountName?.accountName) {
        accountName = AchManagement.viewAch.data.accountName.accountName;
    }
    return {
        viewAchDetails: viewAchDetails,
        accountName: accountName,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        viewAchRequest: (data) => dispatch(viewAchRequest(data)),
        removeAchRequest: (data) => dispatch(removeAchRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAchManagement);
