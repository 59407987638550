import {
    CLEAR_SUPPORT_REQ,
    SUPPORT_REQUEST,
    SUPPORT_SUCCESS,
} from "./type";

export const supportRequest = (data) => {
    return {
        type: SUPPORT_REQUEST,
        payload: data,
    };
};
export const supportSuccess = (data) => {
    return {
        type: SUPPORT_SUCCESS,
        payload: data,
    }
};

export const clearSupportReq = (data) => {
    return {
        type: CLEAR_SUPPORT_REQ,
        payload: data,
    };
};
