import React from "react";
import Modal from "react-bootstrap/Modal";
export default function CustomisedModal(props) {
  return (
    <Modal
      show={props.modalView}
      className={props.customClass}
      scrollable={props.scrollable ? true : false}
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          props.onModalDismiss(false);
        }}
      >
        <Modal.Title>{props.modalName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}
