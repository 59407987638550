import { getFileNameFromURL } from "../../../utils/generalUtils";

export const promotionInitialState = (values, setImgUpload, setPdfFile) => {
  if (
    !values ||
    !values.PromotionalOffersOthers ||
    !values.PromotionalOffersOthers.length
  ) {
    return {
      bannerFile: "",
      description: "",
      endDate: "",
      startDate: "",
      documentFiles: [],
      imageFiles: [],
      title: "",
    };
  }

  const imageUrlsArray = values.PromotionalOffersOthers.filter(
    (d) => d.documentType === "IMAGE"
  ).map((d) => ({
    fileName: getFileNameFromURL(d?.documentPath),
    imageUrl: d?.documentPath,
    promotionalOfferId: d?.promotionalOfferId,
    id: d?.id,
  }));
  const documentUrlsArray =
    values.PromotionalOffersOthers.find((d) => d.documentType === "DOCUMENT")
      ?.documentPath || null;
  const isBanner = values.PromotionalOffersOthers.findIndex(
    (d) => d.isBanner === true
  );
  const isBannerName = getFileNameFromURL(
    values.PromotionalOffersOthers[isBanner]?.documentPath
  );
  return {
    bannerFile: isBanner,
    description: values?.description || "",
    endDate: values?.endDate || "",
    startDate: values?.startDate || "",
    documentFiles: documentUrlsArray,
    imageFiles: imageUrlsArray,
    title: values?.title || "",
    isBannerName: isBannerName || " ",
  };
};
export const CustomInput = ({ value, onClick, placeholder }) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    readOnly 
    className="form-control"
    placeholder={placeholder}
  />
);
