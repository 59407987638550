import { Field, Form, Formik } from "formik";
import React from "react";
import { Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import Iframe from "react-iframe";
import { isValidHttpUrl } from "../../../utils/generalUtils";
import { __t } from "../../../i18n/translator";
import { useEffect } from "react";

export default function URLView({ dataValue }) {
  const { values, setFieldValue, validator } = dataValue;

  useEffect(() => {
    setFieldValue("pdfAttachment", "");
    setFieldValue("htmlBannerImg", "");
    setFieldValue("htmlTemplateDescription", "");
    setFieldValue("htmlFiles", []);
    setFieldValue("htmlTemplate", false);
  }, []);
 
  return (
    <>
      <Card>
        <CardBody>
          <div className="form-horizontal d-flex w-100">
            <FormGroup className="w-100 m-0 me-3">
              <Field
                type="URL"
                name="htmlFileUrl"
                placeholder="Add URL"
                className="form-control"
              />
              <Label className="form-label" htmlFor="htmlFileUrl">
                Enter URL <span className="text-danger required">*</span>
              </Label>
              
              {validator.current.message(
                __t("validations.vendor.html_file_url"),
                values.htmlFileUrl,
                "required|url",
                {
                  messages: {
                    required: __t("validations.vendor.html_file_url"),
                  },
                }
              )}
            </FormGroup>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </div>
          {values.htmlFileUrl && isValidHttpUrl(values.htmlFileUrl) && (
            <Iframe
              type="URL"
              src={values.htmlFileUrl}
              width="100%"
              height="600px"
              loading="lazy"
              className="mt-4"
            >
              <p>
                Your browser does not support embedded URL. You can download the
                PDF{" "}
              </p>
            </Iframe>
          )}
        </CardBody>
      </Card>
    </>
  );
}
