export const generateChartMix = (Data) => {
  const optIn = Data.optInGraph?.map((obj) => obj.count);
  const promotions = Data.promotionsGraph?.map((obj) => obj.count);

  const chartMix = {
    series: [
      {
        name: "Total Opt-in ",
        type: "column",
        data: optIn,
      },
      {
        name: "Total Promotions ",
        type: "area",
        data: promotions,
      },
    ],
    options: {
      chart: {
        fontFamily: "GT Walsheim Pro, Roboto, sans-serif",
        height: "400px",
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
        colors: ["#9bd732", "#00A7B5"],
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
          color: ["#002E70"],
        },
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        colors: ["#002E70", "#00A7B5"],
        gradient: {
          inverseColors: true,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      markers: {
        size: 0,
      },
      colors: ["#002E70", "#00A7B5", "#9bd732"],
      legend: {
        show: true,
        fontSize: "15px",
        // labels: {
        //   colors: '#fff',
        // },
      },
      grid: {
        show: false,
      },
      xaxis: {
        labels: {
          show: true,
          // style: {
          //   colors: '#fff',
          // },
        },
        axisBorder: {
          show: true,
          color: undefined,
        },
      },
      yaxis: {
        labels: {
          show: true,
          // style: {
          //   colors: '#fff',
          // },
        },
        axisBorder: {
          show: true,
          color: "#c4c4c4",
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
      },
      responsive: [
        {
          breakpoint: 1599,
          options: {
            chart: {
              height: "360px",
            },
          },
        },
        {
          breakpoint: 1439,
          options: {
            chart: {
              height: "300px",
            },
          },
        },
      ],
    },
  };

  return chartMix;
};

export const generatechartRadialbar = (item) => {
  const chartRadialbar = {
    series: [item.pendingInquiry, item.successInquiry],
    options: {
      chart: {
        fontFamily: "GT Walsheim Pro, Roboto, sans-serif",
        height: "400px",
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
              // color: '#ffffff',
            },
            total: {
              show: true,
              label: "Total",
              formatter: () => {
                return item.pendingInquiry + item.successInquiry;
              },
            },
          },

          hollow: {
            margin: 15,
            size: "60%",
          },
        },
      },
      colors: ["#002E70", "#9bd732"],
      labels: ["Report Approve", "Report Reject"],
      legend: {
        show: true,
        fontSize: "15px",
        position: "bottom",
        offsetY: -20,
        horizontalAlign: "center",
        floating: false,
        labels: {
          show: true,
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
      },
      labels: ["Pending", "Respond"],
      responsive: [
        {
          breakpoint: 1599,
          options: {
            chart: {
              height: "360px",
            },
          },
        },
        {
          breakpoint: 1439,
          options: {
            chart: {
              height: "300px",
            },
          },
        },
      ],
    },
  };
  return chartRadialbar;
};
