import { all } from "redux-saga/effects";
import LoginSaga from "./admin/auth/login/saga";
import ForgotSaga from "./admin/auth/forgot/saga";
import UserSaga from "./admin/user/saga";
import rolesSaga from "./admin/role/saga";
import PromotionSaga from "./admin/promotion/saga";
import LocationSaga from "./admin/location/saga";
import MembersSaga from "./admin/members/saga";
import OptInSaga from "./admin/optinhistory/saga";
import InquirySaga from "./frontend/inquiry/saga";
import InquiryListSaga from "./admin/inquiry/saga";
import MemberLoginSaga from "./frontend/auth/login/saga";
import MemberForgotSaga from "./frontend/auth/forgot/saga";
import ExportDataSaga from "./exportData/saga";
import SupportSaga from "./admin/support/saga";
import MemberPromotionSaga from "./frontend/promotion/saga";
import AgreementFormsSaga from "./frontend/agreementForms/saga";
import SupportMemberSaga from "./frontend/support/saga";
import ProfileSaga from "./frontend/profile/saga";
import AchManagementSaga from "./frontend/ach/saga";
import AchListSaga from "./admin/Ach/saga";
import RebateSaga from "./frontend/rebate/saga";
import DashboardSaga from "./admin/dashboard/saga";
import RebateManageSaga from "./admin/rebate/saga";
import NewsLetterSaga from "./admin/newsletter/saga";
import RebateSyncSaga from "./admin/sync/saga";
import CategorySaga from "./admin/category/saga";
import VendorSaga from "./admin/vendor/saga";
import VendorUserSaga from "./frontend/vendor/saga";
import SettingSaga from "./admin/setting/saga";
import SupportTicketSaga from "./admin/supportTickets/saga";

function* rootSaga() {
  yield all([
    InquirySaga(),
    LoginSaga(),
    ForgotSaga(),
    UserSaga(),
    rolesSaga(),
    PromotionSaga(),
    LocationSaga(),
    MembersSaga(),
    OptInSaga(),
    InquiryListSaga(),
    AchListSaga(),
    NewsLetterSaga(),
    RebateSyncSaga(),
    CategorySaga(),
    VendorSaga(),
    SupportTicketSaga(),
    //frontend saga
    MemberLoginSaga(),
    MemberForgotSaga(),
    ExportDataSaga(),
    SupportSaga(),
    MemberPromotionSaga(),
    AgreementFormsSaga(),
    SupportMemberSaga(),
    ProfileSaga(),
    AchManagementSaga(),
    RebateSaga(),
    DashboardSaga(),
    RebateManageSaga(),
    VendorUserSaga(),
    SettingSaga(),
  ]);
}
export default rootSaga;
