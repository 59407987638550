import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import VendorList from "./VendorList";
import MemberList from "./MemberList";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import SettingView from "./Setting/SettingView";
import { SettingSvg } from "../../../components/CommonSvg";
import MemberFilter from "./Filter/MemberFilter";
import { connect } from "react-redux";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import VendorFilter from "./Filter/VendorFilter";
import { RoleWiseModule } from "../../../utils/generalUtils";
import { AdminAccess, StaffAccess } from "../../../constant/commonConstants";

function IndexVender({ exportDataExportRequest }) {
  const [search, setSearch] = useState("");
  const [clear, setClear] = useState(false);
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [assignedValue, setAssignedValue] = useState("");
  const [countyId, setCountyId] = useState("");
  const [filter, setFilter] = useState({
    value: "",
    label: "All",
  });
  const [modalView, setModaleView] = useState(false);
  const [tab, setTab] = useState("Vendor");
  const [emailSettingData, setEmailSettingData] = useState();
  const initialValues = {
    search: search,
    setSearch: setSearch,
    clear: clear,
    setClear: setClear,
    filter: filter?.value,
    cityId: cityId,
    assignedValue: assignedValue,
    setAssignedValue: setAssignedValue,
    countyId: countyId,
    setCountyId: setCountyId,
    setCityId: setCityId,
    stateId: stateId,
    setStateId: setStateId,
  };
  const handleModalToggle = () => {
    setModaleView(!modalView);
  };
  const [exportFilterData, setExportFilterData] = useState(false);

  const [filterView, setFilterView] = useState(false);
  useEffect(() => {
    if (filterView) {
      setFilterView(false);
    }
  }, [filterView]);

  useEffect(() => {
    if (tab) {
      setFilterView(true);
      setFilter({
        value: "",
        label: "All",
      });
      if (filterView) {
        setFilterView(false);
      }
    }
  }, [tab]);
  return (
    <div className="container">
      <TableHeader
        hedingName={"Vendor Program"}
        linkUrl={"/admin/vendor/add"}
        buttonName={tab === "Vendor" ? "Add Vendor" : ""}
        searchBar={true}
        setExportFilterData={setExportFilterData}
        exportData={true}
        setSearchData={setSearch}
        settings={
          tab === "Vendor" && (
            <button
              className="creat_btn btn btn-secondary"
              onClick={handleModalToggle}
            >
              <SettingSvg /> Configuration
            </button>
          )
        }
        exportDataHandel={() => {
          tab === "Member"
            ? exportDataExportRequest({
                type: "post",
                url: "/interestedMembersDataExport",
                data: {
                  searchText: search,

                  isFilteredDataExport: filter?.value ? true : exportFilterData,
                  status: filter?.value,
                },
              })
            : exportDataExportRequest({
                type: "post",
                url: "/vendorListDataExport",
                data: {
                  searchText: search,
                  isFilteredDataExport: exportFilterData,
                  stateId: stateId,
                  cityId: cityId,
                  countyId: countyId,
                  isAssigned: assignedValue,
                },
              });
        }}
        updateData={() => {}}
        Filter={true}
        filterType={"vendor"}
        filterView={filterView}
        filterBody={
          tab === "Member" ? (
            <MemberFilter
              setFilterView={setFilterView}
              setFilter={setFilter}
              filter={filter}
            />
          ) : (
            <VendorFilter
              setFilterView={setFilterView}
              setFilter={setFilter}
              filter={filter}
              setCountyId={setCountyId}
              setStateId={setStateId}
              setAssignedValue={setAssignedValue}
              setCityId={setCityId}
              setExportFilterData={setExportFilterData}
            />
          )
        }
      />
      <Nav tabs className="promotion_tab">
        <NavItem>
          <NavLink
            className={tab === "Vendor" ? "active" : ""}
            onClick={() => setTab("Vendor")}
          >
            Vendor
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tab === "Member" ? "active" : ""}
            onClick={() => setTab("Member")}
          >
            Interested Members
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={tab} className="promotion_tab-content">
        {tab === "Vendor" && (
          <VendorList
            values={initialValues}
            setEmailSettingData={setEmailSettingData}
          />
        )}
        {tab === "Member" && <MemberList values={initialValues} />}
      </TabContent>
      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={"category-modal"}
          modalName={"Setting"}
          onModalDismiss={() => {
            setModaleView(false);
          }}
        >
          <SettingView
            emailSettingData={emailSettingData}
            onModalDismiss={() => {
              setModaleView(false);
            }}
          />
        </CustomisedModal>
      )}
    </div>
  );
}
const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndexVender);
