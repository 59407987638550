import { Button } from "reactstrap";
import { PluesSvg } from "../../../../components/CommonSvg";
export const columns = [
  {
    dataField: "ownerEmail",
    text: "Owner Email",
    headerClasses: "store_col",
    sort: true,
  },

  {
    dataField: "details",
    text: "Details",
    sort: true,
    headerClasses: "store_col",

  },
  {
    dataField: "store",
    text: "Store",
    headerClasses: "store_col",
    sort: true,
  },
  {
    dataField: "source",
    text: "Source",
    headerClasses: "source_col",
    sort: true,
  },
  {
    dataField: "vendor",
    text: "Vendor",
    headerClasses: "vendor_col",
    sort: true,
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
  },

  {
    dataField: "priority",
    text: "Priority",
    sort: true,
  },
  {
    dataField: "modifierName",
    text: "Modifier Name",
    sort: true,
  },
  {
    dataField: "modifierEmail",
    text: "Modifier Email",
    sort: true,
  }, {
    dataField: "managerTerritory",
    text: "Created By",
    sort: true,
  },
  {
    dataField: "createdDate",
    text: "Created Date",
    sort: true,
  },
];


export const memberColumns = (closeAndOpen) => {
  return [
    {
      dataField: "hraMemberId",
      text: "HRA Id",
      sort: true,
    },
    {
      dataField: "storeNameRegular",
      text: "Store name",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      //   formatter: (cell, row) => <>{row.createdDate}</>,
    },
    {
      dataField: "county",
      text: "County",
      sort: true,
      //   formatter: (cell, row) => <>{row.createdDate}</>,
    },
    {
      dataField: "contactName",
      text: "Contact Person",
      sort: true,
      formatter: (cell, row) => <>{row.firstName + ' ' + row.lastName}</>,
    },
    {
      dataField: "Action",
      text: "",
      headerClasses: "action_col",
      formatter: (cell, row) => (
        <div className="action_col text-center">
          <Button
            color="primary"
            className="my-tooltip"
            data-tooltip-content={"Edit"}
            size="sm"
            onClick={() => {
              closeAndOpen.setSelectedStore(row)
              closeAndOpen.handleOpenSupportModal()
              // if (e && e.stopPropagation) {
              //   e.stopPropagation();
              // }
              // handleEditModalToggle(row);
            }}
          >
            <PluesSvg /> Create
          </Button>
        </div>
      ),

    },
  ];
};
