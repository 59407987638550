import * as type from "./type";

const initialState = {
    createAch: null,
    removeAch: null,
    viewAch: null,
};

const AchManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.CREATE_ACH_REQUEST:
            return { ...state, createAch: null };
        case type.CREATE_ACH_SUCCESS:
            return { ...state, createAch: action.payload };
        case type.VIEW_ACH_REQUEST:
            return { ...state, viewAch: null };
        case type.VIEW_ACH_SUCCESS:
            return { ...state, viewAch: action.payload };
        case type.REMOVE_ACH_REQUEST:
            return { ...state, removeAch: null };
        case type.REMOVE_ACH_SUCCESS:
            return { ...state, removeAch: action.payload };
        default:
            return { ...state };
    }
};
export default AchManagementReducer;
