import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { DELETE_INQUIRY_REQUEST, GET_INQUIRY_REQUEST, STATUS_CHANGE_REQUEST } from "./type";
import {
    changeStatusSuccess,
    deleteInquirySuccess,
    getInquiryRequest,
    getInquirySuccess
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* InquiryList({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/inquiryList", data);
        if (response.status) {
            yield put(getInquirySuccess(response.data));
        } else {
            yield put(getInquirySuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}
function* InquiryRemove({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/removeInquiry", data.deleteId);
        if (response.status) {
            yield put(deleteInquirySuccess(response.data));
            ApiResponseMessage(response.message, SUCCESS);
            yield put(getInquiryRequest(data.dispatchData));
        } else {
            yield put(deleteInquirySuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}
function* InquiryStatus({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/inquiryStatus", data.UpdatedData);
        if (response.status) {
            yield put(changeStatusSuccess(response.data));
            ApiResponseMessage(response.message, SUCCESS);
            yield put(getInquiryRequest(data.dispatchData));

        } else {
            yield put(changeStatusSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}

function* watchInquiryRequest() {
    yield takeLatest(GET_INQUIRY_REQUEST, InquiryList);
    yield takeLatest(STATUS_CHANGE_REQUEST, InquiryStatus);
    yield takeLatest(DELETE_INQUIRY_REQUEST, InquiryRemove);
}

function* InquiryListSaga() {
    yield all([fork(watchInquiryRequest)]);
}

export default InquiryListSaga;
