export const settings = {
  dots: true,
  speed: 300,
  centerMode: true,
  centerPadding: '20%',
  infinite: true,
  slidesToShow: 1,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        centerPadding: '5%',
      },
    },
  ],
};

export const productslist = {
  speed: 500,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};
export const productslistMinMum = {
  speed: 500,
  dots: false,
  className: "align-left",
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
  ],
};


export const getCardImage = (data) => {
  const noImageFound = 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect width="100" height="100" fill="lightgray"/><text x="50" y="50" text-anchor="middle" dominant-baseline="middle" font-family="Arial" font-size="10" fill="black">No Image Found</text></svg>';
  const Image = data?.PromotionalOffersOthers?.map((d) => {
    if (d.isBanner === true) {
      return d.documentPath;
    }
    return null;
  }).filter((imageUrl) => imageUrl !== null);


  if (Image && Image.length > 0) {
    return Image[0];
  } else {
    return noImageFound;
  }
};
