import React, { useEffect, useState } from "react";
import { EyeSvg, LikeSvg } from "../../../components/CommonSvg";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getOptInStatusRequest } from "../../../redux/admin/optinhistory/action";
import { getValueByKey } from "../../../utils/authUtils";
import { getTodayDate } from "../../../helper/DataTimeHelpers";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import {
  clearMemberPromotionListOnScrollResponse,
  getMembersPromotionListRequest,
} from "../../../redux/frontend/promotion/action";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";
import NoPromotionFound from "../../../components/Common/Frontend/Promotion/NoPromotionFound";
import { useCallback } from "react";
import { useRef } from "react";
import { Button } from "reactstrap";

function MyPromotionPage(props) {
  const { getMembersPromotionListRequest } = props;
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";
  const navigate = useNavigate();
  const isLogin = getValueByKey("hraMemberId");
  const [page, setPage] = useState(0);
  const [filterItem, setFilterItem] = useState(null);
  const [promotionList, setPromotionList] = useState([]);
  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    if (props.promotionList) {
      setPromotionList(props.promotionList);
    }
  }, [props.promotionList]);

  const getSearchRequest = (newSearch = false) => {
    let SearchRequest = {
      offset: newSearch ? 0 : props.promotionList?.length,
      hraMemberId: hraMemberId,
      sortBy: "opted",
    };
    return SearchRequest;
  };

  useEffect(() => {
    if (page > 0) {
      props.getMembersPromotionListRequest(getSearchRequest(false, null));
    }
  }, [page]);

  useEffect(() => {
    if (hraMemberId) {
      props.clearResp();
      props.getMembersPromotionListRequest(getSearchRequest(false, null));
    }
  }, [hraMemberId]);

  const arrangeInOrder = (sort) => {
    props.clearResp();
    setFilterItem(sort);
    getMembersPromotionListRequest({
      sortBy: "opted",
      hraMemberId: hraMemberId,
      offset: 0,
    });
  };
  const todayDate = getTodayDate();

  const optStatus = (product) => {
    let maxIdObject = null;
    let maxId = -1;
    for (const offer of product.PromotionalOffer?.InterestedMemberOffers) {
      const offerId = parseInt(offer.id);
      if (!isNaN(offerId) && offerId > maxId) {
        maxId = offerId;
        maxIdObject = offer;
      }
    }
    const values = {
      hraMemberId: hraMemberId,
      promotionalOfferId: product?.promotionalOfferId,
      interestedMemberId: maxIdObject?.id,
      isActive: maxIdObject?.isActive == true ? false : true,
    };

    props.clearResp();
    props.getOptInStatusRequest({
      optin: values,
      status: "promation",
      sort: "opted",
    });
  };

  const getCardImage = (data) => {
    const Image = data?.PromotionalOffer?.PromotionalOffersOthers.map((d) => {
      if (d.isBanner === true) {
        return d.documentPath;
      }
      return null;
    }).filter((imageUrl) => imageUrl !== null);
    return Image ? Image[0] : "";
  };

  return (
    <>
      <div className="container">
        <CommonSortHeader
          PromotionsName="My Promotions"
          arrangeInOrder={arrangeInOrder}
          BackTo={"/home"}
        />
        {promotionList?.length > 0 ? (
          <div className="product-card-list">
            {promotionList?.map((product, index) => {
              let maxIdObject =
                product.PromotionalOffer?.InterestedMemberOffers[0];
              const filteredCustomFields = (
                product?.PromotionalOffer?.customFields || []
              ).filter((item) => item?.label && item?.value);
              return (
                <>
                  <div className="product-card" key={index}>
                    <div className="product-card-img">
                      <img src={getCardImage(product)} alt="Image" />
                      {maxIdObject?.isActive && (
                        <p className="product-card-overlay">
                          Opted-On:
                          <b>
                            {maxIdObject?.updatedDate === null
                              ? maxIdObject.createdDate
                              : maxIdObject.updatedDate}
                          </b>
                        </p>
                      )}
                      <Link
                        title="Click for item detail"
                        className="view_more"
                        to={{
                          pathname: `/promotion/view/${product.promotionalOfferId}`,
                        }}
                        state={{ isPromotionPage: true }}
                      >
                        <EyeSvg />
                      </Link>
                    </div>
                    <div className="product-card-body">
                      <p className="product-expire-date">
                        Expiry date :{" "}
                        <span>{product.PromotionalOffer?.endDate}</span>
                      </p>
                      {filteredCustomFields?.length > 0 && (
                        <div className="product-data">
                          <div className="cost-discount-box">
                            <p>
                              <span>{filteredCustomFields[0]?.label} :</span>{" "}
                              {filteredCustomFields[0]?.value}
                            </p>
                            <p>
                              <span>{filteredCustomFields[1]?.label}:</span>
                              {filteredCustomFields[1]?.value}
                            </p>
                            {filteredCustomFields?.length > 2 && (
                              <a
                                href="javascript:void(0);"
                                onClick={async () => {
                                  if (isLogin === null) {
                                    const response = await commonConfirmBox(
                                      "Would you like to see more updates and details? Please log in to view."
                                    );
                                    if (response) {
                                      navigate("/login");
                                    }
                                  } else {
                                    navigate(
                                      `/promotion/view/${product.promotionalOfferId}`
                                    );
                                  }
                                }}
                                className="product-more-btn"
                                title={
                                  filteredCustomFields?.length - 2 + " more"
                                }
                              >
                                {filteredCustomFields?.length - 2} more
                              </a>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="like-expire-box justify-content-center bottom_btn">
                        <Button
                          title={maxIdObject?.isActive ? "Opt-Out" : " Opt-In"}
                          color="primary"
                          className={
                            maxIdObject?.isActive == true ? " active" : ""
                          }
                          onClick={async () => {
                            const resp = await commonConfirmBox(
                              maxIdObject?.isActive
                                ? `Are you sure to Opt-Out ${product.PromotionalOffer?.title} ?`
                                : `Are you sure to Opt-In ${product.PromotionalOffer?.title}?`,
                              "Confirm",
                              "Cancel"
                            );
                            if (resp) {
                              optStatus(product);
                            }
                          }}
                        >
                          <LikeSvg />
                          {maxIdObject?.isActive ? "Opt-Out" : " Opt-In"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <NoPromotionFound />
        )}
        <div ref={loader} />
      </div>
    </>
  );
}

const mapStateToProps = ({ MemberPromotions }) => {
  let promotionList =
    MemberPromotions?.memberPromotion && MemberPromotions?.memberPromotion;
  return {
    promotionList: promotionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMembersPromotionListRequest: (data) => {
      dispatch(getMembersPromotionListRequest(data));
    },
    getOptInStatusRequest: (data) => dispatch(getOptInStatusRequest(data)),
    clearResp: (data) =>
      dispatch(clearMemberPromotionListOnScrollResponse(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyPromotionPage);
