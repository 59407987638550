import {

    GET_LOGIN_CLEAR,
    GET_LOGIN_REQUEST,
    GET_LOGIN_SUCCESS,
} from "./type";

export const getLoginRequest = (cred) => {
    return {
        type: GET_LOGIN_REQUEST,
        payload: cred,
    };
};
export const getLoginSuccess = (cred) => {
    return {
        type: GET_LOGIN_SUCCESS,
        payload: cred,
    }
};
export const getLoginClear = (cred) => {
    return {
        type: GET_LOGIN_CLEAR,
        payload: cred,
    }
};