import {
  CLEAR_MEMBER_FORGOT_PASSWORD_REPONSE,
  GET_MEMBER_FORGOT_LOGIN_REQUEST,
  GET_MEMBER_FORGOT_LOGIN_SUCCESS,
  GET_MEMBER_FORGOT_PASSWORD_REQUEST,
  GET_MEMBER_FORGOT_PASSWORD_SUCCESS,
  MEMBER_RESET_PASSWORD_REQUEST,
  MEMBER_RESET_PASSWORD_SUCCESS,
} from "./type";

export const getMemberForgotRequest = (cred) => {
  return {
    type: GET_MEMBER_FORGOT_PASSWORD_REQUEST,
    payload: cred,
  };
};

export const getMemberForgotSuccess = (cred) => {
  return {
    type: GET_MEMBER_FORGOT_PASSWORD_SUCCESS,
    payload: cred,
  };
};

export const clearResponse = (cred) => {
  return {
    type: CLEAR_MEMBER_FORGOT_PASSWORD_REPONSE,
    payload: cred,
  };
};

export const getMemberForgotLoginRequest = (cred) => {
  return {
    type: GET_MEMBER_FORGOT_LOGIN_REQUEST,
    payload: cred,
  };
};
export const getMemberForgotLoginSuccess = (cred) => ({
  type: GET_MEMBER_FORGOT_LOGIN_SUCCESS,
  payload: cred,
});
export const resetMemberPasswordRequest = (email) => ({
  type: MEMBER_RESET_PASSWORD_REQUEST,
  payload: email,
});

export const resetMemberPasswordSuccess = (message) => ({
  type: MEMBER_RESET_PASSWORD_SUCCESS,
  payload: message,
});
