import * as type from "./type";

export const getVendorListRequest = (data) => {
  return {
    type: type.GET_VENDER_LIST_REQUEST,
    payload: data,
  };
};
export const getVendorListSuccess = (data) => {
  return {
    type: type.GET_VENDER_LIST_SUCCESS,
    payload: data,
  };
};
export const addVendorRequest = (data) => {
  return {
    type: type.ADD_VENDER_REQUEST,
    payload: data,
  };
};
export const addVendorSuccess = (data) => {
  return {
    type: type.ADD_VENDER_SUCCESS,
    payload: data,
  };
};
export const updateVendorRequest = (data) => {
  return {
    type: type.UPDATE_VENDER_REQUEST,
    payload: data,
  };
};
export const updateVendorSuccess = (data) => {
  return {
    type: type.UPDATE_VENDER_SUCCESS,
    payload: data,
  };
};
export const clearVendorRequest = (data) => {
  return {
    type: type.CLEAR_VENDER_REQ,
    payload: data,
  };
};
export const deleteVendorRequest = (data) => {
  return {
    type: type.DELETE_VENDER_REQUEST,
    payload: data,
  };
};
export const deleteVendorSuccess= (data) => {
  return {
    type: type.DELETE_VENDER_SUCCESS,
    payload: data,
  };
};
export const removeVendorRequest = (data) => {
  return {
    type: type.REMOVE_VENDER_REQUEST,
    payload: data,
  };
};
export const removeVendorSuccess = (data) => {
  return {
    type: type.REMOVE_VENDER_SUCCESS,
    payload: data,
  };
};
export const getVendorDetailsRequest = (data) => {
  return {
    type: type.GET_VENDER_DETAILS_REQUEST,
    payload: data,
  };
};
export const getVendorDetailsSuccess = (data) => {
  return {
    type: type.GET_VENDER_DETAILS_SUCCESS,
    payload: data,
  };
};
export const getInterestedMemberListRequest = (data) => {
  return {
    type: type.GET_INTERESTED_MEMBER_LIST_REQUEST,
    payload: data,
  };
};
export const getInterestedMemberListSuccess = (data) => {
  return {
    type: type.GET_INTERESTED_MEMBER_LIST_SUCCESS,
    payload: data,
  };
};
export const approveMemberRequest = (data) => {
  return {
    type: type.APPROVE_MEMBER_REQUEST,
    payload: data,
  };
};
export const approveMemberSuccess = (data) => {
  return {
    type: type.APPROVE_MEMBER_SUCCESS,
    payload: data,
  };
};
export const removeImageRequest = (data, api) => {
  return {
    type: type.REMOVE_IMAGE_REQUEST,
    payload: { data, api },
  };
};
export const removeImageSuccess = (data) => {
  return {
    type: type.REMOVE_IMAGE_SUCCESS,
    payload: data,
  };
};
export const getVendorMemberRequest = (data) => {
  return {
    type: type.GET_VENDOR_MEMBER_REQUEST,
    payload: data,
  };
};
export const getVendorMemberSuccess = (data) => {
  return {
    type: type.GET_VENDOR_MEMBER_SUCCESS,
    payload: data,
  };
};
export const assignVendorRequest = (data) => {
  return {
      type: type.ASSIGN_VENDOR_REQUEST,
      payload: data,
  };
};
export const assignVendorSuccess = (data) => {
  return {
      type: type.ASSIGN_VENDOR_SUCCESS,
      payload: data,
  };
};