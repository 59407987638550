import * as type from "./type";

const initialState = {
  getInquiry: null,
  changeStatus: null,
  exportDataResp: null,
};

const ExportReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.EXPORT_DATA_EXPORT_REQUEST:
      return { ...state, exportDataResp: null };
    case type.EXPORT_DATA_EXPORT_SUCCESS:
      return { ...state, exportDataResp: action.payload };
    default:
      return state;
  }
};

export default ExportReducer;
