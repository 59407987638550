import React from "react";
import { __t } from "../../../i18n/translator";
import { changeStatusRequest } from "../../../redux/admin/inquiry/action";
import { connect } from "react-redux";
import Switch from "react-switch";
import { commonConfirmBox } from "../../../helper/messagehelpers";
function InquiryStatus({ changeStatusReq, dispatchData, row, onModalDismiss }) {
    return (
        <>
            <Switch
                className={`react-switch react-switch_card switch-sm status_switch ${row.isActive && "isActive"
                    }`}
                type="checkbox"
                id="custom-switch"
                onChange={async () => {
                    const response = await commonConfirmBox(
                        !row.isActive
                            ? "Are you interested in wrapping up this inquiry?"
                            : "Are you interested in resolving this inquiry?"
                    );
                    if (response) {
                        let UpdatedData = {
                            memberId: row.id,
                            isActive: !row.isActive,
                        };
                        changeStatusReq({ UpdatedData, dispatchData });
                        onModalDismiss()
                    }
                }}
                uncheckedIcon={
                    <div className="pending_switch">
                        Pending
                    </div>
                }
                checkedIcon={<div className="Done_switch">Done</div>}
                checked={row.isActive}
            />
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return { changeStatusReq: (data) => dispatch(changeStatusRequest(data)) };
};
export default connect(null, mapDispatchToProps)(InquiryStatus);
