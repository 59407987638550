import * as type from "./type";

export const getUserListRequest = (data) => {
    return {
        type: type.GET_USER_LIST_REQUEST,
        payload: data
    };
};
export const getUserListSuccess = (data) => {
    return {
        type: type.GET_USER_LIST_SUCCESS,
        payload: data
    };
};
export const addUserListRequest = (data) => {
    return {
        type: type.ADD_USER_LIST_REQUEST,
        payload: data
    };
};
export const addUserListSuccess = (data) => {
    return {
        type: type.ADD_USER_LIST_SUCCESS,
        payload: data
    };
};
export const activeInactiveUserRequest = (data) => {
    return {
        type: type.ACTIVE_INACTIVE_USER_REQUEST,
        payload: data
    };
};
export const activeInactiveUserSuccess = (data) => {
    return {
        type: type.ACTIVE_INACTIVE_USER_SUCCESS,
        payload: data
    };
};