import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { createLoadingSelector } from "../../../../redux/shared/selector";
import Loader from "./Loader";
import { getValueByKey, removeValueByKey } from "../../../../utils/authUtils";
function LayoutMember(props) {
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("/vendor")) {
      if (getValueByKey("selectedCategory")) {
        removeValueByKey("selectedCategory");
      }
    }
  }, [location]);
  return (
    <div
      className={
        location.pathname === "/admin/home"
          ? "main-body dashboard_header"
          : "main-body front_main"
      }
    >
      {props?.loadingSelector && <Loader />}
      <Header />
      <main className="main-inner">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

const mapStatetoProps = createStructuredSelector({
  loadingSelector: createLoadingSelector(),
});

export default connect(mapStatetoProps)(LayoutMember);
