import React, { useState } from "react";
import { getCardImage } from "../../../../pages/Frontend/Home/Common";
import { Link, useNavigate } from "react-router-dom";
import { EyeSvg, LikeSvg } from "../../../CommonSvg";
import { getValueByKey } from "../../../../utils/authUtils";
import { commonConfirmBox } from "../../../../helper/messagehelpers";
import { Button } from "reactstrap";

export default function CommonPromotionCard({
    item,
    getOptInStatusRequest,
    ApiUpdateReq,
}) {
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    const isLogin = getValueByKey("hraMemberId");
    const navigate = useNavigate();
    const optStatus = (product) => {
        let values = {};
        if (product.PromotionalOffer?.InterestedMemberOffers?.[0]?.id) {
            values = {
                hraMemberId: hraMemberId,
                promotionalOfferId: item.promotionalOfferId,
                interestedMemberId:
                    product.PromotionalOffer?.InterestedMemberOffers?.[0]?.id,
                isActive:
                    !product.PromotionalOffer?.InterestedMemberOffers?.[0]?.isActive,
            };
        } else {
            values = {
                hraMemberId: hraMemberId,
                promotionalOfferId: item.promotionalOfferId,
            };
        }
        getOptInStatusRequest({ optin: values, status: ApiUpdateReq });
    };
    const filteredCustomFields = (
        item?.PromotionalOffer?.customFields || []
    ).filter((item) => item?.label && item?.value);
    const filteredCustomFieldsHome = (
        item?.customFields || []
    ).filter((item) => item?.label && item?.value);
    return (
        <>
            <div className="product-card">
                <div className="product-card-img">
                    <img
                        src={getCardImage(isLogin ? item?.PromotionalOffer : item)}
                        alt="Image"
                    />
                    {item.PromotionalOffer?.InterestedMemberOffers?.[0]?.isActive && (
                        <p className="product-card-overlay">
                            Opted-On:
                            <b>
                                {item.PromotionalOffer?.InterestedMemberOffers?.[0]
                                    ?.updatedDate === null
                                    ? item.PromotionalOffer?.InterestedMemberOffers?.[0]
                                        ?.createdDate
                                    : item.PromotionalOffer?.InterestedMemberOffers?.[0]
                                        ?.updatedDate}
                            </b>
                        </p>
                    )}
                    <button
                        title="Click for item detail"
                        className="view_more"
                        onClick={async () => {
                            if (isLogin === null) {
                                const response = await commonConfirmBox(
                                    "Would you like to see more updates and details? Please log in to view."
                                );
                                if (response) {
                                    navigate("/login");
                                }
                            } else {
                                navigate(`/promotion/view/${item.promotionalOfferId}`);
                            }
                        }}
                    >
                        <EyeSvg />
                    </button>
                </div>
                <div className="product-card-body">
                    <p className="product-expire-date">
                        Expiry date :{" "}
                        <span>
                            {isLogin ? item?.PromotionalOffer?.endDate : item?.endDate}
                        </span>
                    </p>
                    {((isLogin && filteredCustomFields?.length > 0) ||
                        (!isLogin && filteredCustomFieldsHome?.length > 0)) && (
                            <div className="product-data">
                                <div className="cost-discount-box">
                                    <p>
                                        {isLogin
                                            ? filteredCustomFields[0]?.label && (
                                                <>
                                                    <span>{filteredCustomFields[0]?.label} :</span>{" "}
                                                    {filteredCustomFields[0]?.value}
                                                </>
                                            )
                                            : filteredCustomFieldsHome?.[0]?.label && (
                                                <>
                                                    <span>{filteredCustomFieldsHome?.[0]?.label} :</span>{" "}
                                                    {filteredCustomFieldsHome[0]?.value}
                                                </>
                                            )}
                                    </p>
                                    <p>
                                        {isLogin
                                            ? filteredCustomFields[1]?.label && (
                                                <>
                                                    <span>{filteredCustomFields[1]?.label} :</span>
                                                    {filteredCustomFields[1]?.value}
                                                </>
                                            )
                                            : filteredCustomFieldsHome[1]?.label && (
                                                <>
                                                    <span>{filteredCustomFieldsHome[1]?.label} :</span>
                                                    {filteredCustomFieldsHome[1]?.value}
                                                </>
                                            )}
                                    </p>
                                    {isLogin
                                        ? filteredCustomFields?.length > 2 && (
                                            <a
                                                href="javascript:void(0);"
                                                onClick={async () => {
                                                    if (isLogin === null) {
                                                        const response = await commonConfirmBox(
                                                            "Would you like to see more updates and details? Please log in to view."
                                                        );
                                                        if (response) {
                                                            navigate("/login");
                                                        }
                                                    } else {
                                                        navigate(
                                                            `/promotion/view/${item.promotionalOfferId}`
                                                        );
                                                    }
                                                }}
                                                className="product-more-btn"
                                                title={filteredCustomFields?.length - 2 + " more"}
                                            >
                                                {filteredCustomFields?.length - 2} more
                                            </a>
                                        )
                                        : filteredCustomFieldsHome?.length > 3 && (
                                            <a
                                                href="javascript:void(0);"
                                                onClick={async () => {
                                                    if (isLogin === null) {
                                                        const response = await commonConfirmBox(
                                                            "Would you like to see more updates and details? Please log in to view."
                                                        );
                                                        if (response) {
                                                            navigate("/login");
                                                        }
                                                    } else {
                                                        navigate(
                                                            `/promotion/view/${item.promotionalOfferId}`
                                                        );
                                                    }
                                                }}
                                                className="product-more-btn"
                                            title={filteredCustomFieldsHome.length - 2 + " more"}
                                            >
                                            {filteredCustomFieldsHome.length - 2} more
                                            </a>
                                        )}
                                </div>
                            </div>
                        )}
                    <div className="like-expire-box justify-content-center bottom_btn">
                        <Button
                            title="Opt-In"
                            color="primary"
                            className={
                                !item.PromotionalOffer?.InterestedMemberOffers?.[0]?.isActive
                                    ? " "
                                    : " active"
                            }
                            onClick={async () => {
                                if (!isLogin) {
                                    const response = await commonConfirmBox(
                                        "Would you like to see more updates and details? Please log in to view."
                                    );
                                    if (response) {
                                        navigate("/login");
                                    }
                                } else {
                                    const resp = await commonConfirmBox(
                                        item.PromotionalOffer?.InterestedMemberOffers?.[0]?.isActive
                                            ? `Are you sure to Opt-Out ${item.PromotionalOffer?.title} ?`
                                            : `Are you sure to Opt-In ${item.PromotionalOffer?.title}?`,
                                        "Confirm",
                                        "Cancel"
                                    );
                                    if (resp) {
                                        optStatus(item);
                                    }
                                }
                            }}
                        >
                            <LikeSvg /> Opt-In
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
