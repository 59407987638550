import React from "react";
import Nodatdafound from "../../../../assets/images/not_available.png";

export default function NoPromotionFound() {
    return (
        <>
            <div className="no-content-img">
                <span>
                    <img src={Nodatdafound} alt="Image Promotion" />
                </span>
            </div>
        </>
    );
}
