import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_MEMBER_LOGIN_REQUEST } from "./type";
import { ApiResponseMessage } from "../../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../../constant/commonConstants";
import { invokeApi } from "../../../../helper/axiosHelper";
import { getMemberLoginSuccess } from "./action";
import { setLoginMemberData } from "../../../../utils/authUtils";


function* login({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/memberLogin", data);
        if (res.status) {
            setLoginMemberData(res);
            yield put(getMemberLoginSuccess(res));
            ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
        } else {
            yield put(getMemberLoginSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* watchLoginRequest() {
    yield takeLatest(GET_MEMBER_LOGIN_REQUEST, login);
}

function* MemberLoginSaga() {
    yield all([fork(watchLoginRequest)]);
}

export default MemberLoginSaga;
