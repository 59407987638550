import { connect } from "react-redux";
import {
  clearResp,
  getVendorRequest,
  likeVendorRequest,
} from "../../../redux/frontend/vendor/action";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  getValueByKey,
  removeValueByKey,
  setValueByKey,
} from "../../../utils/authUtils";
import { Card, CardBody, Col, Row, Container, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  BackSvg,
  InterestfillSvg,
  LikeSvg,
} from "../../../components/CommonSvg";
import { getCategoryListRequest } from "../../../redux/admin/category/action";
import Nodata from "../../../assets/images/no_data_found001.webp";
import ServiceRequestModalComponent from "./ServiceRequest";
import { commonConfirmBox } from "../../../helper/messagehelpers";

const Vendor = ({
  vendorList,
  getVendorList,
  getCategoryList,
  categoryList,
  clearResp, likeVendorRequest,
}) => {
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";
  const loader = useRef(null);
  const [page, setPage] = useState(0);
  useEffect(() => {
    getCategoryList();
  }, []);
  const [categoryId, setcategoryId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (categoryList?.length > 0) {
      const isBack = JSON.parse(getValueByKey("selectedCategory"?getValueByKey("selectedCategory"):""));
      if (categoryList.some((category) => category.id === isBack?.id)) {
        setcategoryId(isBack);
      } else {
        setcategoryId(categoryList[0]);
      }
    }
  }, [categoryList?.length > 0]);
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  const getSearchRequest = () => {
    let SearchRequest = {
      offset: vendorList?.length > 0 ? vendorList?.length : 0,
      categoryId: categoryId?.id ? parseInt(categoryId?.id) : 1,
      hraMemberId: hraMemberId,
    };
    return SearchRequest;
  };

  useEffect(() => {
    if (page > 0) {
      getVendorList(getSearchRequest());
    }
  }, [page]);

  useEffect(() => {
    if (categoryId && hraMemberId) {
      clearResp();
      getVendorList(getSearchRequest());
    }
  }, [hraMemberId, categoryId]);
  const [modalView, setModaleView] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(null);
  const slides = vendorDetails?.VendorHtmlImages?.map((item) => ({
    src: item.imageName,
  }));
  const isLiked =
    vendorDetails?.InterestedVendors?.length > 0
      ? vendorDetails.InterestedVendors.find((data) => {
        return data.hraMemberId === hraMemberId;
      })
      : null;

  return (
    <>
      <div className="section-head vender_section_head">
        <div className="container">
          <button
            className="my-tooltip back_btn"
            data-tooltip-content={"Back"}
            onClick={() => navigate("/home")}
          >
            <BackSvg />
          </button>
          <h3>Vendor Program</h3>
        </div>
      </div>
      <section className="vender_card_list_tab">
        <Container className="h-100 p-0">
          <Row className="h-100 m-0">
            <Col lg={12} className="p-0">
              <h1>Navigate through our Vendors by Categories</h1>
              {categoryList?.length > 0 && (
                <ul>
                  {categoryList?.map((data) => {
                    return (
                      <li
                        key={data}
                        className={data.id === categoryId?.id ? "active" : ""}
                      >
                        <Button
                          color="primary"
                          onClick={() => {
                            clearResp();
                            setcategoryId(data);
                          }}
                        >
                          {data.catName}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="vender_card_list">
        <Container className="h-100 p-0">
          {categoryList?.length > 0 && (
            <Row className="h-100 m-0">
              <Col lg={12} className="p-0">
                <div className="vendor_cat_title">
                  <h2>{categoryId?.catName}</h2>
                  {/* <img alt="Logo" className="logo_small" src={Logo} /> */}
                </div>
                {vendorList?.length > 0 ? (
                  <Row>
                    {vendorList.map((vendorData) => {
                      let data = vendorData.Vendor
                      const isLiked =
                        data?.InterestedVendors?.length > 0
                          ? data.InterestedVendors.find((d) => {
                            return d.hraMemberId === hraMemberId;
                          })
                          : null;
                      return (
                        <Col sm={6} md={6} lg={4} xl={3}>
                          <Card>
                            <div className="logo_vendor">
                              <img alt="Logo" src={data?.logo} />
                            </div>
                            <CardBody>
                              <h3>{data?.name}</h3>
                              <Link
                                className="btn btn-link"
                                state={categoryId}
                                to={"/vendor/" + data?.id}
                              >
                                HRA Vendor Website
                                <span>
                                  <BackSvg />
                                </span>
                              </Link>
                              <Link color="primary"
                                className={
                                  isLiked?.isActive
                                    ? "btn btn-success justify-content-center"
                                    : "btn btn-link justify-content-center"
                                }
                                data-tooltip-content={
                                  isLiked?.isActive
                                    ? "Remove interest from program"
                                    : "Join the Program"
                                }
                                onClick={async () => {
                                  const response = await commonConfirmBox(
                                    isLiked?.isActive
                                      ? `Are you sure you want to exit the program?`
                                      : `Are you sure you want to join the program?`
                                  );
                                  if (response) {
                                    setModaleView(!modalView);
                                    setVendorDetails(data)
                                  }
                                }}
                              >
                                {isLiked?.isActive && <InterestfillSvg />}{isLiked?.isActive ? 'Joined the Program' : 'Join the Program'}

                              </Link>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  // <h4>No data found</h4>
                  <div className="d-flex justify-content-center">
                    <img
                      alt="NO Data Found"
                      className="no_data_found"
                      src={Nodata}
                    />
                  </div>
                )}
              </Col>
            </Row>
          )}
          <div ref={loader} />
        </Container>
      </section>
      <ServiceRequestModalComponent
        modalView={modalView}
        setModalView={setModaleView}
        hraMemberId={hraMemberId}
        vendorDetails={vendorDetails}
        isLiked={isLiked}
        likeVendorRequest={likeVendorRequest}
        listAPi={{
          offset: 0,
          categoryId: categoryId?.id ? parseInt(categoryId?.id) : 1,
          hraMemberId: hraMemberId,
        }}
      />
    </>
  );
};
const mapStateToProps = ({ vendorUser, category }) => {
  let vendorList = vendorUser.getVendor?.length > 0 ? vendorUser.getVendor : [];
  let categoryList =
    category?.getCategory?.list?.length > 0 ? category.getCategory.list : [];
  return { vendorList, categoryList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorList: (data) => dispatch(getVendorRequest(data)),
    getCategoryList: () => dispatch(getCategoryListRequest({ isActive: true })),
    clearResp: () => dispatch(clearResp()),
    likeVendorRequest: (data) => dispatch(likeVendorRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
