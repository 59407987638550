import * as actionTypes from "./type";

const initialState = {
  getSync: null,
  getClassSync: null,
  syncTime: null
};

const SyncReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REBATES_SYNC_REQ:
      return { ...state, getSync: action.payload };
    case actionTypes.GET_REBATES_SYNC_SUC:
      return { ...state, getSync: action.payload };
    // case actionTypes.GET_SYNC_REQ:
    //   return { ...state, syncTime: action.payload };
    // case actionTypes.GET_SYNC_SUC:
    //   return { ...state, syncTime: action.payload };
    case actionTypes.GET_CLASS_SYNC_REQ:
      return { ...state, getClassSync: null };
    case actionTypes.GET_CLASS_SYNC_SUC:
      return { ...state, getClassSync: action.payload };
    default:
      return state;
  }
};

export default SyncReducer;
