import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import AddCategory from "./AddCategory";
import { connect } from "react-redux";
import {
  addCategoryRequest,
  getCategoryListRequest,
  statusCategoryRequest,
} from "../../../../redux/admin/category/action";
import { EditSvg } from "../../../../components/CommonSvg";
import { commonConfirmBox } from "../../../../helper/messagehelpers";
import ReactSwitch from "react-switch";

function CategoryList({
  modal,
  addCategory,
  getCategoryList,
  categoryList,
  statusCategoryRequest,
}) {
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    getCategoryList();
  }, []);
  const handleEditCategory = (data) => {
    setEditData(data);
  };

  return (
    <>
      <Offcanvas show={modal.show} onHide={modal.handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Category List </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddCategory
            addCategory={addCategory}
            editData={editData}
            setEditData={setEditData}
          />
          <div className="category_list">
            {categoryList?.length > 0 && (
              <ul>
                {categoryList.map((data) => {
                  return (
                    <li>
                      <p>{data.catName}</p>
                      <div>
                      <ReactSwitch
                        className={`react-switch justify-content-center switch-sm ${
                          data.isActive && "isActive"
                        }`}
                        onChange={async () => {
                          const response = await commonConfirmBox(
                            data.isActive
                              ? `Are you sure you want to deactive this category?`
                              : `Are you sure you want to active this category?`
                          );
                          if (response) {
                            statusCategoryRequest({
                              categoryId: data.id,
                              isActive: !data.isActive,
                            });
                          }
                        }}
                        type="checkbox"
                        id="custom-switch"
                        checked={data.isActive}
                      />
                      <button
                        className="my-tooltip btn btn-link edit_btn"
                        onClick={() => handleEditCategory(data)}
                      >
                        <EditSvg />
                      </button>

                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const mapStateToProps = ({ category }) => {
  let categoryList =
    category?.getCategory?.list?.length > 0 ? category.getCategory.list : [];
  return { categoryList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCategory: (data) => dispatch(addCategoryRequest(data)),
    getCategoryList: (data) => dispatch(getCategoryListRequest()),
    statusCategoryRequest: (data) => dispatch(statusCategoryRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
