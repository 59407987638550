import React, { useEffect, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import {
  activeInactivePromotionRequest,
  getPromotionListRequest,
} from "../../../redux/admin/promotion/action";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import AssignPromation from "./AssignPromation";
import {
  formatDateToMMDDYYYY,
  getDateObjectWithValueFromMomentPayload,
} from "../../../helper/DataTimeHelpers";
import {
  AdminAccess,
  MarketingAccess,
  StaffAccess,
} from "../../../constant/commonConstants";
import { RoleWiseModule } from "../../../utils/generalUtils";
import PromotionData from "./PromotionData";
import OptinHistory from "../OptIn/OptinHistory";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import {
  PromotionsHistorySvg,
  PromotionsSvg,
} from "../../../components/CommonSvg";
import { getValueByKey } from "../../../utils/authUtils";
function PromotionList(props) {
  const {
    getPromotionsList,
    promotionsList,
    totalRecords,
    AssignPromotionStatus,
    promationStatus,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [assignedValue, setAssignedValue] = useState("");
  const [countyId, setCountyId] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [modalView, setModaleView] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [promationData, setPromationData] = useState([]);
  const [promationId, setPromationId] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAssigned, setIsAssigned] = useState("");
  const [activeTab, setActiveTab] = useState(getValueByKey("activeTab") || "1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCurrentPage(1)
      setDataPerPage(10)
    }
  };

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const dispatchData = {
    searchText: searchData ? searchData : "",
    startDate: startDate ? formatDateToMMDDYYYY(startDate) : "",
    endDate: endDate ? formatDateToMMDDYYYY(endDate) : "",
    stateId: stateId,
    cityId: cityId,
    countyId: countyId,
    isAssigned: assignedValue,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };

  useEffect(() => {
    if (activeTab == "1") {
      if (
        dataPerPage ||
        currentPage ||
        searchData ||
        startDate ||
        endDate ||
        stateId ||
        cityId ||
        countyId ||
        assignedValue
      ) {
        getPromotionsList(dispatchData);
      }
    }
  }, [
    dataPerPage,
    currentPage,
    searchData,
    startDate,
    endDate,
    stateId,
    cityId,
    countyId,
    assignedValue,
    activeTab == "1",
  ]);

  useEffect(() => {
    if (AssignPromotionStatus === true) {
      setModaleView(false);
      setPromationId([]);
      setPromationData([]);
    }
  }, [AssignPromotionStatus]);

  const multipalAssignHandel = () => {
    if (promationId) {
      setModaleView(true);
      setPromationId([...promationId]);
      setPromationData([...promationData]);
    }
  };

  const handleExport = () => {
    if (activeTab) {
      props.exportDataExportRequest(
        activeTab == 1
          ? {
            type: "POST",
            url: "/promotionDataExport",
            data: { ...dispatchData, isFilteredDataExport: exportData },
          }
          : {
            type: "POST",
            url: "/optInDataExport",
            data: { ...dispatchData, isFilteredDataExport: exportData },
          }
      );
    }
  };

  return (
    <>
      <div className="container">
        <TableHeader
          hedingName={"Promotion"}
          activeTab={activeTab}
          setExportData={setExportData}
          setCurrentPage={setCurrentPage}
          multipalAssign={promationId.length}
          multipalAssignlData={
            RoleWiseModule(AdminAccess, StaffAccess) && multipalAssignHandel
          }
          multipalAssignName={
            RoleWiseModule(AdminAccess, StaffAccess) && "Multiple Assign"
          }
          linkUrl={
            RoleWiseModule(AdminAccess, MarketingAccess) &&
            "/admin/promotion/add"
          }
          buttonName={
            RoleWiseModule(AdminAccess, MarketingAccess) && "Add Promotion"
          }
          exportData={true}
          exportDataHandel={() => {
            handleExport();
          }}
          searchBar={"true"}
          currentPage={currentPage}
          setSearchData={setSearchData}
          setEndDate={setEndDate}
          endDate={endDate}
          startDate={startDate}
          setAssignedValue={setAssignedValue}
          setStartDate={setStartDate}
          updateData={() => {
            getPromotionsList({
              searchText: "",
              startDate: "",
              endDate: "",
              pagination: {
                limit: dataPerPage,
                page: currentPage,
                orderKey: "createdDate",
                orderBy: "ASC",
              },
            });
          }}
          setStateId={setStateId}
          Filter={true}
          filterType={"promotion"}
          setCountyId={setCountyId}
          setCityId={setCityId}
        />
        <Row>
          <Col className="d-flex justify-content-between align-items-end">
            <Nav tabs className="promotion_tab request_tab">
              <>
                <NavItem>
                  <NavLink
                    className={activeTab == "1" && "active"}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    <PromotionsSvg />
                    <span>Promotion</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab == "2" && "active"}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    <PromotionsHistorySvg />
                    <span>Opt-In History</span>
                  </NavLink>
                </NavItem>
              </>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab} className="promotion_tab-content">
          {activeTab == "1" && (
            <TabPane tabId="1">
              <PromotionData
                promationId={promationId}
                setPromationData={setPromationData}
                setModaleView={setModaleView}
                setPromationId={setPromationId}
                promationData={promationData}
                promotionsList={promotionsList}
                setIsAssigned={setIsAssigned}
                dataPerPage={dataPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setDataPerPage={setDataPerPage}
                modalView={modalView}
                totalRecords={totalRecords}
                AssignPromotionStatus={AssignPromotionStatus}
                isAssigned={isAssigned}
                AssignPromation={AssignPromation}
                dispatchData={dispatchData}
                promationStatus={promationStatus}
              />
            </TabPane>
          )}
          {activeTab == "2" && (
            <TabPane tabId="2">
              <OptinHistory
                dispatchData={dispatchData}
                dataPerPage={dataPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setDataPerPage={setDataPerPage}
                activeTab={activeTab}
                searchData={searchData}
                startDate={startDate}
                endDate={endDate}
                stateId={stateId}
                cityId={cityId}
                countyId={countyId}
                assignedValue={assignedValue}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    </>
  );
}
const mapStateToProps = ({ Promotions }) => {
  let promotionsList = [];
  let totalRecords = null;
  if (
    Promotions?.getPromotions?.list &&
    Promotions?.getPromotions?.list.length > 0
  ) {
    promotionsList = Promotions.getPromotions.list;
    totalRecords = Promotions.getPromotions.totalRecords;
  }
  return {
    promotionsList: promotionsList,
    totalRecords: totalRecords,
    AssignPromotionStatus:
      Promotions.assignPromotion?.status && Promotions.assignPromotion.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionsList: (data) => dispatch(getPromotionListRequest(data)),
    promationStatus: (data) => dispatch(activeInactivePromotionRequest(data)),
    exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionList);
