import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_CITY_LIST_REQUEST, GET_COUNTY_LIST_REQUEST, GET_STATE_LIST_REQUEST, GET_TERRITORY_LIST_REQUEST } from "./type";
import { getCityListSuccess, getCountyListSuccess, getStateListSuccess, getTerritoryListSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";


function* CityList() {
    try {
        const respons = yield call(invokeApi, "get", "/cityList");
        if (respons.status) {
            yield put(getCityListSuccess(respons.data));
        } else {
            yield put(getCityListSuccess([]));
            ApiResponseMessage(respons.message, DANGER);
            handleApiCallException(respons.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}
function* StateList() {
    try {
        const respons = yield call(invokeApi, "get", "/stateList");
        if (respons.status) {
            yield put(getStateListSuccess(respons.data));
        } else {
            yield put(getStateListSuccess([]));
            ApiResponseMessage(respons.message, DANGER);
            handleApiCallException(respons.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}
function* CountyList() {
    try {
        const respons = yield call(invokeApi, "get", "/countyList");
        if (respons.status) {
            yield put(getCountyListSuccess(respons.data));
        } else {
            yield put(getCountyListSuccess([]));
            ApiResponseMessage(respons.message, DANGER);
            handleApiCallException(respons.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}
function* TerritoryList() {
    try {
        const respons = yield call(invokeApi, "get", "/territoryList");
        if (respons.status) {
            yield put(getTerritoryListSuccess(respons.data));
        } else {
            yield put(getTerritoryListSuccess([]));
            ApiResponseMessage(respons.message, DANGER);
            handleApiCallException(respons.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}

function* watchCityRequest() {
    yield takeLatest(GET_CITY_LIST_REQUEST, CityList);
    yield takeLatest(GET_STATE_LIST_REQUEST, StateList);
    yield takeLatest(GET_COUNTY_LIST_REQUEST, CountyList);
    yield takeLatest(GET_TERRITORY_LIST_REQUEST, TerritoryList);
}

function* LocationSaga() {
    yield all([fork(watchCityRequest)]);
}

export default LocationSaga;
