import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { __t } from "../../../i18n/translator";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getCityListRequest,
  getCountyListRequest,
  getStateListRequest,
} from "../../../redux/admin/location/action";
import CommonTable from "../../../components/Common/TableView/CommonTable";
import { getMemberListRequest } from "../../../redux/admin/members/action";
import { memberColumns } from "./Setting/CommonFunction";

function SupportStoreView(props) {
  const {
    GetCityList,
    GetStateList,
    GetCountyList,
    CityList,
    CountyList,
    StateList,
    allMemberList,
    rowClassName,
    getMemberListReq,
    totalRecords,
    closeAndOpen,
  } = props;
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [countyId, setCountyId] = useState("");
  const [search, setSearch] = useState("");
  const dispatchData = {
    searchText: search,
    state: stateId.label,
    city: cityId.label,
    county: countyId.label,
    pagination: {
      limit: 10,
      page: 0,
      orderBy: "ASC",
      orderKey: "createdDate",
    },
  };

  useEffect(() => {
    getMemberListReq(dispatchData);
  }, [stateId, cityId, countyId, search]);
  useEffect(() => {
    GetCityList();
    GetStateList();
    GetCountyList();
  }, []);
  const cityOptions = CityList?.map((city) => ({
    value: city.id,
    label: city.cityName,
  }));
  const stateOptions = StateList?.map((city) => ({
    value: city.id,
    label: city.stateName,
  }));
  const countyOptions = CountyList?.map((city) => ({
    value: city.id,
    label: city.countyName,
  }));

  return (
    <>
      <h6 className="separate-head mb-2">Select Store</h6>
      <Card className="filter_section store-filter">
        <CardBody>
          <form className="hra-create">
            <Row>
              <Col className="col-12 mb-3">
                <FormGroup className="mb-0">
                  <Input
                    type="text"
                    className="form-control"
                    id="store"
                    name="store"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search..."
                  />
                  <Label className="form-label" htmlFor="store">
                    Search
                  </Label>
                </FormGroup>
              </Col>
              <Col className="no-floating-label">
                <FormGroup className="mb-0">
                  <Select
                    type="text"
                    id="cityId"
                    name="cityId"
                    className="form-control selectbox"
                    menuPlacement="auto"
                    options={cityOptions}
                    value={cityId}
                    onChange={(selectedOption) => {
                      setCityId(selectedOption ? selectedOption : "");
                      // setSelectedCity(selectedOption);
                      // setExportFilterData(true)
                    }}
                    isClearable
                  />
                  <Label className="form-label" htmlFor="stateId">
                    City
                  </Label>
                </FormGroup>
              </Col>
              <Col className="no-floating-label">
                <FormGroup className="mb-0">
                  <Select
                    type="text"
                    id="stateId"
                    name="stateId"
                    className="form-control selectbox"
                    menuPlacement="auto"
                    options={stateOptions}
                    value={stateId}
                    onChange={(selectedOption) => {
                      // setSelectedState(selectedOption);
                      // setExportFilterData(true)
                      setStateId(selectedOption ? selectedOption : "");
                    }}
                    isClearable
                  />
                  <Label className="form-label" htmlFor="stateId">
                    State
                  </Label>
                </FormGroup>
              </Col>
              <Col className="no-floating-label">
                <FormGroup className="mb-0">
                  <Select
                    type="text"
                    id="countyId"
                    name="countyId"
                    className="form-control selectbox"
                    menuPlacement="auto"
                    options={countyOptions}
                    value={countyId}
                    onChange={(selectedOption) => {
                      // setSelectedCounty(selectedOption);
                      // setExportFilterData(true)
                      setCountyId(selectedOption ? selectedOption : "");
                    }}
                    isClearable
                  />
                  <Label className="form-label" htmlFor="stateId">
                    County
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>

      <CommonTable
        // handleScroll={handleScroll}
        customClass={"support-request-store"}
        data={allMemberList}
        columns={memberColumns(closeAndOpen)}
        rowClassName={rowClassName}
        // tableId={tableId}
        // rowEvents={rowEvents}
      />
      {totalRecords > 0 ? (
        <></>
      ) : (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}
    </>
  );
}

const mapStateToProps = ({ location, Members }) => {
  let CityList;
  let StateList;
  let CountyList;
  if (
    location.getCity &&
    Array.isArray(location.getCity.list) &&
    location.getCity.list.length > 0
  ) {
    CityList = location.getCity.list;
  }

  if (
    location.getState &&
    Array.isArray(location.getState.list) &&
    location.getState.list.length > 0
  ) {
    StateList = location.getState.list;
  }

  if (
    location.getCounty &&
    Array.isArray(location.getCounty.list) &&
    location.getCounty.list.length > 0
  ) {
    CountyList = location.getCounty.list;
  }
  let totalRecords = null;
  let allMemberList = [];
  if (Members?.getMembers?.list && Members?.getMembers?.list?.length > 0) {
    allMemberList = Members.getMembers.list;
    totalRecords = Members.getMembers.totalRecords;
  }

  return {
    CityList: CityList,
    StateList: StateList,
    CountyList: CountyList,
    allMemberList: allMemberList,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetCityList: () => {
      dispatch(getCityListRequest());
    },
    GetStateList: () => {
      dispatch(getStateListRequest());
    },
    GetCountyList: () => {
      dispatch(getCountyListRequest());
    },
    getMemberListReq: (data) => dispatch(getMemberListRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportStoreView);
