import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FormGroup, Label, Button } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../../i18n/translator";
import { AddSvg } from "../../../../components/CommonSvg";
export default function AddCategory({ addCategory, editData, setEditData }) {
  const validator = useRef(new SimpleReactValidator());
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      addCategory(values);
      setEditData(null);
      setReRenderState(false);
      validator.current.hideMessages();
    } else {
      validator.current.showMessages();
    }
  };
  const [reRenderState, setReRenderState] = useState(true);

  useEffect(() => {
    if (reRenderState) {
      setReRenderState(false);
    }
  }, [editData]);
  useEffect(() => {
    if (reRenderState === false) {
      setReRenderState(true);
    }
  }, [reRenderState]);
  const Category = {
    id: editData?.id ? editData.id : "",
    catName: editData?.catName ? editData.catName : "",
  };
  return (
    <>
      <div className="add_caregory_field">
        {reRenderState && (
          <Formik initialValues={Category} onSubmit={handleSubmit}>
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="form-horizontal">
                <FormGroup>
                  <Field
                    type="text"
                    name="catName"
                    placeholder="Category Name"
                    className="form-control"
                  />
                  <Label className="form-label" htmlFor="catName">
                    Category Name
                  </Label>
                  {validator.current.message(
                    __t("validations.vendor.category_name"),
                    values.catName,
                    "required",
                    {
                      messages: {
                        required: __t("validations.vendor.category_name"),
                      },
                    }
                  )}
                </FormGroup>

                <Button type="submit" color="primary" block>
                  <AddSvg />
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}
