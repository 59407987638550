import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { ADD_CATEGORY_REQUEST, GET_CATEGORY_LIST_REQUEST, STATUS_CATEGORY_REQUEST } from "./type";
import { addCategorySuccess, getCategoryListRequest, getCategoryListSuccess, statusCategorySuccess, } from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";


function* categoryList({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/categoryList", data);
        if (res.status) {
            yield put(getCategoryListSuccess(res.data));
        } else {
            yield put(getCategoryListSuccess([]));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}

function* addCategory({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/category", data);
        if (res.status) {
            ApiResponseMessage(res.message, SUCCESS)
            yield put(addCategorySuccess(res));
            yield put(getCategoryListRequest({isActive :""}))
        } else {
            yield put(addCategorySuccess(res));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        yield put(addCategorySuccess());
        handleApiCallException(error)
    }
}


function* statusCategory({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/categoryStatus", data);
        if (res.status) {
            yield put(statusCategorySuccess(res.data));
            yield put(getCategoryListRequest({ isActive: "" }))
            ApiResponseMessage(res.message, SUCCESS);
        } else {
            yield put(statusCategorySuccess([]));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error)
    }
}
function* watchCategoryRequest() {
    yield takeLatest(GET_CATEGORY_LIST_REQUEST, categoryList);
    yield takeLatest(ADD_CATEGORY_REQUEST, addCategory);
    yield takeLatest(STATUS_CATEGORY_REQUEST, statusCategory);
}

function* CategorySaga() {
    yield all([fork(watchCategoryRequest)]);
}

export default CategorySaga;
