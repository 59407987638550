import { Link, useNavigate } from "react-router-dom";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";
import { EyeSvg, PdfSvg } from "../../../components/CommonSvg";
import { connect } from "react-redux";
import {
  clearMemberNewsLetterOnScrollResponse,
  getNewsLetterRequest,
} from "../../../redux/admin/newsletter/action";
import { useCallback, useEffect, useRef, useState } from "react";
import { getValueByKey } from "../../../utils/authUtils";
import { clearMemberPromotionListOnScrollResponse } from "../../../redux/frontend/promotion/action";
import { isImage, isPDF } from "../../../utils/generalUtils";

const NewsLetterPage = ({ GetNewsLetters, NewsLetterData, clearResp }) => {
  const loader = useRef(null);
  const [page, setPage] = useState(0);
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);


  const getSearchRequest = (newSearch = false) => {
    let SearchRequest = {
      offset: newSearch ? 0 : NewsLetterData?.length,
      hraMemberId: hraMemberId,
    };
    return SearchRequest;
  };
  useEffect(() => {
    if (page > 0) {
      GetNewsLetters(getSearchRequest(false, null));
    }
  }, [page]);

  useEffect(() => {
    
      clearResp();
  }, []);
  useEffect(() => {
    if (hraMemberId) {
        clearResp();
      GetNewsLetters(getSearchRequest(false, null));
    }
  }, [hraMemberId]);
  return (
    <>
      <div className="container">
        <CommonSortHeader PromotionsName="Newsletter" BackTo={"/home"} />
        <div className="product-card-list news-letter-list">
          {NewsLetterData?.length > 0 ?
            NewsLetterData.map((data) => {
              return (
                <div className="product-card">
                  <div className="product-card-img">
                    {/* {!isImage(data.Newsletter.documentPath) ? (
                      <div className="product_svg">
                        <span className="docfile">
                          <PdfSvg />
                        </span>
                      </div>
                    ) : (
                      <div className="promotion_img">
                        <img width="80" src={data.Newsletter.documentPath} />
                      </div>
                    )} */}
                    {data.Newsletter.thumbnailPath ? (
                      <div className="promotion_img">
                        <img width="80" src={data.Newsletter.thumbnailPath} />
                      </div>
                    ) : isImage(data.Newsletter.documentPath) ? (
                      <div className="promotion_img">
                        <img width="80" src={data.Newsletter.documentPath} />
                      </div>
                    ) : (
                      <div className="product_svg">
                        <span className="docfile">
                          <PdfSvg />
                        </span>
                      </div>
                    )}
                    {/* <img
                                  width="80"
                                  src={data.Newsletter.documentPath}
                              /> */}
                    <a
                      className="pe-auto view_more"
                      title="Click for item detail"
                      onClick={() => {
                        navigate(`/newsletter/${data.Newsletter.id}`, {
                          state: {
                            NewsLetterData: NewsLetterData,
                          },
                        });
                      }}
                    >
                      <EyeSvg />
                    </a>
                  </div>
                  <div className="product-card-body">
                    {/* <p className="product-expire-date">
                                  Expiry date : <span>01-30-2024</span>
                              </p> */}
                    <div className="product-data">
                      <div className="cost-discount-box">
                        <h4>{data.Newsletter.title}</h4>
                        <p>{data.Newsletter.description}</p>
                        {/* <a
                                          href="javascript:void(0);"
                                          className="product-more-btn"
                                          title=""
                                      >
                                          Read more
                                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) : (
          <h4 className="text-center mt-5 mb-5">No Data Found</h4>
            )}
        </div>
        <div ref={loader} />
      </div>
    </>
  );
};
const mapStateToProps = ({ NewsLetter }) => {
  let NewsLetterData = [];
  if (NewsLetter?.newsLetterList?.length > 0) {
    NewsLetterData = NewsLetter.newsLetterList;
  }
  return { NewsLetterData };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetNewsLetters: (data) => dispatch(getNewsLetterRequest(data)),
    clearResp: (data) => dispatch(clearMemberNewsLetterOnScrollResponse(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterPage);
