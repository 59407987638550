export const GET_SUPPORT_TICKET_REQUEST = "GET_SUPPORT_TICKET_REQUEST";
export const GET_SUPPORT_TICKET_SUCCESS = "GET_SUPPORT_TICKET_SUCCESS";

export const SET_COLUMNS_REQUEST = "SET_COLUMNS_REQUEST";
export const SET_COLUMNS_SUCCESS = "SET_COLUMNS_SUCCESS";

export const GET_COLUMNS_REQUEST = "GET_COLUMNS_REQUEST";
export const GET_COLUMNS_SUCCESS = "GET_COLUMNS_SUCCESS";

export const SYNC_SUPPORT_TICKET_REQUEST = "SYNC_SUPPORT_TICKET_REQUEST";
export const SYNC_SUPPORT_TICKET_SUCCESS = "SYNC_SUPPORT_TICKET_SUCCESS";
export const GET_STATUS_REQUEST = "GET_STATUS_REQUEST";
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";

export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
