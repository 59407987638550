import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from "reactstrap";
import { Field, Formik, Form } from "formik";
import Logo from "../../../../assets/images/logo-dark.webp";
import Nonotification from "../../../../assets/images/no-notifications.png";
import {
  getValueByKey,
  removeLoggedInMemberDetails,
} from "../../../../utils/authUtils";
import {
  getInitialsFirstCharechter,
  isZohoSyncEnabled,
} from "../../../../utils/generalUtils";
import {
  AgreementSvg,
  BellSvg,
  LogoutSvg,
  MenuSvg,
  PromotionSvg,
  PromotionsSvg,
  SupportSvg,
  UserSvg,
  AchSvg,
  MoneySvg,
  DownArrowSvg,
  HomeSvg,
  NewsletterSvg,
  VendorSvg,
} from "../../../CommonSvg";
import Select from "react-select";
import { connect } from "react-redux";
import {
  StoreDetailRequest,
  getLatestNotificationRequest,
  getReadNotificationRequest,
} from "../../../../redux/frontend/profile/action";
import VendorModal from "../../../../pages/Frontend/Home/VendorModal";
import { useModal } from "../../../../helper/modalHelper";
import { commonOopsBox } from "../../../../helper/messagehelpers";
function Header({
  getReadNotification,
  StoreDetail,
  notificationList,
  getLatestNotification,
  StoreDetailRequest,
}) {
  const navigate = useNavigate();
  const [showForms, setShowForms] = useState(false);
  const location = useLocation();
  const memberAccessToken = getValueByKey("memberAccessToken");
  const handleLogout = () => {
    removeLoggedInMemberDetails();
    navigate("/login");
  };
  const firstName = JSON.parse(getValueByKey("firstName"));
  const lastName = JSON.parse(getValueByKey("lastName"));
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
  const parentHraMemberId = JSON.parse(getValueByKey("parentHraMemberId"));
  const dispatchRoute = (route) => {
    navigate(route);
    setShowForms(false);
  };
  useEffect(() => {
    if (hraMemberId !== null) {
      StoreDetailRequest();
      getLatestNotification({ hraMemberId: hraMemberId });
    }
  }, [hraMemberId]);
  const updateAccessToken = (data) => {
    localStorage.setItem("hraMemberId", JSON.stringify(data?.value));
    localStorage.setItem("firstName", JSON.stringify(data?.firstName));
    localStorage.setItem("lastName", JSON.stringify(data?.lastName));
    localStorage.setItem("mailingStreetAddress", data?.streetAddress);
    localStorage.setItem("storeNameRegular", data?.storeNameRegular);
    localStorage.setItem("phoneNumber", data?.phoneNumber);
    localStorage.setItem("memberEmail", data?.emailAddress);
    window.location.reload();
  };
  useEffect(() => {
    const pattern = /^\/promotion\/view\/\d+$/;
    const isMatch = pattern.test(location.pathname);
    if (isMatch) {
      navigate("/promotion");
    }
  }, [hraMemberId !== parentHraMemberId]);
  useEffect(() => {
    if (location.pathname === "/ach/add" || location.pathname === "/ach/edit") {
      navigate("/ach/view");
    }
  }, [hraMemberId !== parentHraMemberId]);
  const NotificationCount = notificationList?.filter(
    (d) => d?.isRead === false
  )?.length;
  const formLinks = [
    {
      name: "Membership Application",
      link: "https://form.jotform.com/240225007986153",
      // link: "https://form.jotform.com/ShahNirav/HRAMemberInformation",
    },
    {
      name: "ACH Application",
      link: "https://www.jotform.com/220315574133043",
      // link: "https://form.jotform.com/ShahNirav/HRADirectDepositACH",
    },
    {
      name: "Felonious Assault Waiver",
      link: "https://www.jotform.com/220965300028145",
      // link: "https://form.jotform.com/ShahNirav/HRA_BBGFeloniousAssaultWaiver",
    },
    {
      name: "Membership Verification",
      link: "https://www.jotform.com/220825781191154",
      // link: "https://form.jotform.com/ShahNirav/HRAMembershipVerification",
    },
    {
      name: "Activate/Deactivate Membership",
      // link: "https://form.jotform.com/ShahNirav/activatedeactivate-membership-form",
      link: "https://www.jotform.com/220315188151044",
    },
    {
      name: "Member User Guide",
      link: "https://www.hraga.com/member-application-user-guide",
    },
  ];
  const openInNewTab = (url) => {
    window.open(url);
    // window.open(url, " _self", "noreferrer");
  };
  // const modal = useModal();

  return (
    <div className="front_header">
      <Navbar>
        <NavbarBrand href="/home" title="HRA">
          <img alt="Logo" className="logo_main" src={Logo} />
        </NavbarBrand>
        <Nav className="" navbar>
          {memberAccessToken === null && (
            <NavItem className="become_btn">
              <NavLink to={"/inquiry"}>Become A Member</NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink to={"/home"}>
              <HomeSvg /> Home
            </NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar className="promotion-story">
            <DropdownToggle
              className={
                location.pathname === "/myPromotions" ||
                  location.pathname === "/promotion/all"
                  ? "active"
                  : ""
              }
              nav
              caret
            >
              <PromotionsSvg /> Promotions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                className={
                  location.pathname === "/promotion/all" ? "active" : ""
                }
                onClick={() => {
                  dispatchRoute("/promotion/all");
                }}
              >
                All Promotions
              </DropdownItem>
              <DropdownItem
                className={
                  location.pathname === "/myPromotions" ? "active" : ""
                }
                onClick={() => {
                  dispatchRoute("/myPromotions");
                }}
              >
                My Promotions
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <NavLink to="/support">
              <SupportSvg /> Member support
            </NavLink>
          </NavItem>
          {memberAccessToken !== null ? (
            <>
              <NavItem className="store_dropdown show_parents">
                <Formik>
                  <Form className="form-horizontal form-login">
                    <FormGroup className="m-0">
                      <Field
                        type="Substore"
                        id="Substore"
                        name="Substore"
                        className="form-control selectbox     "
                        menuPlacement="auto"
                        placeholder="Select Store"
                        component={Select}
                        options={StoreDetail}
                        value={StoreDetail?.filter((option) => {
                          return option?.value === hraMemberId;
                        })}
                        onChange={(data) => {
                          updateAccessToken(data);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </Formik>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="notification_bell">
                <DropdownToggle nav caret title="Notification">
                  <BellSvg />
                  {NotificationCount > 0 && (
                    <span className="notification_badge">
                      {NotificationCount}
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="d-flex justify-content-between align-items-center px-3">
                    <span>Notifications</span>
                  </div>
                  <ul>
                    {notificationList?.length > 0 ? (
                      notificationList?.map((d, index) => {
                        return (
                          <li
                            key={index}
                            className={
                              d.isRead !== true
                                ? "unread-notification-box"
                                : "read-notification-box"
                            }
                          >
                            <Link
                              type="button"
                              to={
                                d.isRead === false && d.type === 2
                                  ? `/newsletter/${d.typeId}`
                                  : d.isRead === false && d.type === 3 ? `/vendor/${d.typeId}` : `/promotion/view/${d.typeId}`
                              }
                              onClick={() => {
                                if (d.isRead === false) {
                                  getReadNotification({
                                    hraMemberId: hraMemberId,
                                    notificationId: d.id,
                                  });
                                }
                              }}
                              className={
                                d.isRead === false
                                  ? "notification_item "
                                  : "notification_item pe-none"
                              }
                            >
                              <div className="chat-title">
                                <span className="assigne-box green-round">
                                  <PromotionsSvg />
                                </span>
                              </div>
                              <div className="notification_detail">
                                <div>
                                  <p className="mb-1">{d.message}</p>
                                  <div className="small-title">
                                    <small>{d.createdDate}</small>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <li className="no_notification">
                        <img
                          alt="Notification"
                          className="no_notification_img"
                          src={Nonotification}
                        />
                      </li>
                    )}
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="login_btn loggd_in_btn">
                <NavLink to="/profile" title="Profile">
                  {firstName && lastName && (
                    <>
                      <span className="loggd_in_svg">
                        {getInitialsFirstCharechter(firstName, lastName)}
                      </span>
                      {/* <div className="loggd_in_text">
                        <h5>{firstName + " " + lastName}</h5>
                        <span>
                          {hraMemberId}{" "}
                          <small>
                            {StoreDetail[0]?.value !== hraMemberId
                              ? "(Sub-store)"
                              : "(Store)"}
                          </small>
                        </span>
                      </div> */}
                    </>
                  )}
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="menu_dropdown">
                <DropdownToggle
                  nav
                  caret
                  title="Menu"
                  onClick={() => {
                    setShowForms(false);
                  }}
                >
                  <MenuSvg />
                  <span>MENU</span>
                </DropdownToggle>
                <DropdownMenu right className="small-scroll">
                  <div className="mobile-view-user drop_down">
                    <NavItem className="store_dropdown show_parents">
                      <Formik>
                        <Form className="form-horizontal form-login">
                          <FormGroup className="m-0">
                            <Field
                              type="Substore"
                              id="Substore"
                              name="Substore"
                              className="form-control selectbox     "
                              menuPlacement="auto"
                              placeholder="Select Store"
                              component={Select}
                              options={StoreDetail}
                              value={StoreDetail?.filter((option) => {
                                return option?.value === hraMemberId;
                              })}
                              onChange={(data) => {
                                updateAccessToken(data);
                              }}
                            />
                          </FormGroup>
                        </Form>
                      </Formik>
                    </NavItem>
                  </div>
                  <DropdownItem className="mobile-view-user">
                    <NavItem className="login_btn loggd_in_btn">
                      <NavLink to="/profile">
                        {firstName && lastName && (
                          <>
                            <span className="loggd_in_svg">
                              {getInitialsFirstCharechter(firstName, lastName)}
                            </span>
                            <div className="loggd_in_text">
                              <h5>{firstName + " " + lastName}</h5>
                              <span>
                                {hraMemberId}
                                <small>
                                  {StoreDetail[0]?.value !== hraMemberId
                                    ? "(Sub-store)"
                                    : "(Store)"}
                                </small>
                              </span>
                            </div>
                          </>
                        )}
                      </NavLink>
                    </NavItem>
                  </DropdownItem>
                  {memberAccessToken === null && (
                    <DropdownItem className="mobile-view-user">
                      <NavItem className="become_btn">
                        <NavLink>Become A Member</NavLink>
                      </NavItem>
                    </DropdownItem>
                  )}
                  {/* <DropdownItem
                    onClick={() => {
                      dispatchRoute("/home");
                    }}
                  >
                    <span>
                      <HomeSvg />
                    </span>
                    Home
                  </DropdownItem> */}
                  <DropdownItem
                    className={location.pathname === "/profile" ? "active" : ""}
                    onClick={() => {
                      dispatchRoute("/profile");
                    }}
                  >
                    <span>
                      <UserSvg />
                    </span>
                    Profile
                  </DropdownItem>
                  <DropdownItem
                    className={
                      location.pathname === "/promotion" ? "active" : ""
                    }
                    onClick={() => {
                      dispatchRoute("/promotion");
                    }}
                  >
                    <span>
                      <PromotionSvg />
                    </span>
                    Promotions
                  </DropdownItem>
                  <DropdownItem
                    className={location.pathname === "/support" ? "active" : ""}
                    onClick={() => {
                      dispatchRoute("/support");
                    }}
                  >
                    <span>
                      <SupportSvg />
                    </span>
                    Support & Service Request
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      dispatchRoute("/rebate");
                    }}
                    className={location.pathname === "/rebate" ? "active" : ""}

                  // onClick={async () => {
                  //   const response = await commonOopsBox(
                  //     "Rebates under maintenance",
                  //   );

                  // }}
                  >
                    <span>
                      <MoneySvg />
                    </span>
                    Rebates
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      dispatchRoute("/newsletter");
                    }}
                    className={
                      location.pathname === "/newsletter" ? "active" : ""
                    }
                  >
                    <span>
                      <NewsletterSvg />
                    </span>
                    Newsletter
                    {/* & Vendor program */}
                  </DropdownItem>
                  <div
                    className={
                      showForms ? "agree_dropdown active" : "agree_dropdown "
                    }
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setShowForms(!showForms);
                      }}
                    >
                      <span>
                        <AgreementSvg />
                      </span>
                      Agreement & Forms
                      <span className="arrow">
                        <DownArrowSvg />
                      </span>
                    </div>
                    {showForms && (
                      <ul>
                        {/* <li>
                          <DropdownItem
                            onClick={() => {
                              dispatchRoute("/agreement");
                            }}
                          >
                            <span></span>
                            Agreement
                          </DropdownItem>
                        </li> */}
                        {formLinks?.map((d) => {
                          return (
                            <li>
                              <DropdownItem
                                role="link"
                                color="primary"
                                target="_blank"
                                onClick={() => openInNewTab(d.link)}
                              >
                                <span>{/* <AgreementSvg /> */}</span>
                                {d.name}
                              </DropdownItem>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  <DropdownItem
                    className={
                      location.pathname === "/vendor"
                        ? "active vendor-menu"
                        : "vendor-menu"
                    }
                    // onClick={() => modal.openModal(true)}
                    onClick={() => {

                      dispatchRoute("/vendor")

                    }}
                  >
                    <span>
                      <VendorSvg />
                    </span>
                    Vendor Program
                    {/* Vendor & Partners */}
                  </DropdownItem>
                  {isZohoSyncEnabled && (
                    <DropdownItem
                      className={
                        location.pathname === "/ach/view" ? "active" : ""
                      }
                      onClick={() => {
                        dispatchRoute("/ach/view");
                      }}
                    >
                      <span>
                        <AchSvg />
                      </span>
                      ACH Management
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={handleLogout}>
                    <span>
                      <LogoutSvg />
                    </span>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ) : (
            <NavItem className="login_btn">
              <NavLink to={"/login"}>
                <span className="loggd_in_svg">
                  <UserSvg />
                </span>
                <div className="login_text">
                  <span>Log in</span>
                </div>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Navbar>
      {/* <VendorModal modal={modal} /> */}
    </div>
  );
}
const mapStateToProps = ({ profileData }) => {
  let StoreDetail = null;
  let parentDetails = null;
  let notificationList =
    profileData?.notificationList?.data?.list &&
    profileData.notificationList?.data?.list;
  if (profileData?.StoreDetail?.list) {
    StoreDetail = profileData?.StoreDetail?.list?.map((value) => {
      return {
        emailAddress: value.subHra.emailAddress,
        storeNameRegular: value.subHra.storeNameRegular,
        phoneNumber: value.subHra.phoneNumberMobileNumber,
        streetAddress:
          value.subHra.streetAddress +
          ", " +
          value.subHra.city +
          ", " +
          value.subHra.state +
          ", " +
          value.subHra.mailingZip,
        value: value.subHraMemberId,
        firstName: value.subHra.firstName,
        lastName: value.subHra.lastName,
        label:
          (value.subHra.storeNameRegular ? value.subHra.storeNameRegular + " -- " : "") +
          (value.subHraMemberId ? value.subHraMemberId + " -- " : "") +
          (value.subHra.county ? value.subHra.county : ""),
      };
    });
  }

  if (profileData?.StoreDetail?.parentDetails) {
    const values = profileData?.StoreDetail?.parentDetails;

    parentDetails = {
      emailAddress: values.emailAddress,
      storeNameRegular: values.storeNameRegular ? values.storeNameRegular : "",
      phoneNumber: values.phoneNumberMobileNumber,
      streetAddress:
        values.streetAddress +
        ", " +
        values.city +
        ", " +
        values.state +
        ", " +
        values.mailingZip,
      value: values.hraMemberId,
      firstName: values.firstName,
      lastName: values.lastName,
      label:
        (values?.storeNameRegular ? values.storeNameRegular + " -- " : "") +
        values.hraMemberId +
        " -- " +
        values.county,
    };
  }

  return {
    StoreDetail: [
      ...(parentDetails ? [parentDetails] : []),
      ...(StoreDetail || []),
    ],
    notificationList: notificationList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestNotification: (data) =>
      dispatch(getLatestNotificationRequest(data)),
    getReadNotification: (data) => dispatch(getReadNotificationRequest(data)),
    StoreDetailRequest: () => dispatch(StoreDetailRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
