import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_COLUMNS_REQUEST,
  GET_STATUS_REQUEST,
  GET_SUPPORT_TICKET_REQUEST,
  SET_COLUMNS_REQUEST,
  SYNC_SUPPORT_TICKET_REQUEST,
  UPDATE_STATUS_REQUEST,
} from "./type";
import {
  getColumesRequest,
  getColumesSuccess,
  getStatusSuccess,
  getSupportTicketRequest,
  getSupportTicketSuccess,
  setColumesSuccess,
  syncSupportTicketsSuccess,
  updateStatusSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* SupportTicketList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/supportTicketList", data);
    if (response.status) {
      yield put(getSupportTicketSuccess(response.data));
      handleApiCallException(response.message);
    } else {
      yield put(getSupportTicketSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* SetColoumns({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/saveColums", data);
    if (response.status) {
      yield put(setColumesSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getColumesRequest());
    } else {
      yield put(setColumesSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getColumns({ payload: data }) {
  try {
    const response = yield call(invokeApi, "get", "/columnsList", data);
    if (response.status) {
      handleApiCallException(response.message);
      yield put(getColumesSuccess(response.data));
    } else {
      yield put(getColumesSuccess(response.data));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getStatus() {
  try {
    const response = yield call(invokeApi, "get", "/ticketStatus");
    if (response.status) {
      handleApiCallException(response.message);
      yield put(getStatusSuccess(response.data));
    } else {
      yield put(getStatusSuccess(response.data));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* updateStatus({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/updateTicketStatus", data.status);
    if (response.status) {
      yield put(updateStatusSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getSupportTicketRequest(data.dispatchData));
    } else {
      yield put(updateStatusSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* syncTicket({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/syncSupportTickets", {});
    if (response.status) {
      handleApiCallException(response.message);
      ApiResponseMessage(response.message, SUCCESS);
      yield put(syncSupportTicketsSuccess(response.data));
      yield put(getSupportTicketRequest(data)); 
    } else {
      yield put(syncSupportTicketsSuccess(response.data));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* watchTicketsRequest() {
  yield takeLatest(GET_SUPPORT_TICKET_REQUEST, SupportTicketList);
  yield takeLatest(SET_COLUMNS_REQUEST, SetColoumns);
  yield takeLatest(GET_COLUMNS_REQUEST, getColumns);
  yield takeLatest(SYNC_SUPPORT_TICKET_REQUEST, syncTicket);
  yield takeLatest(GET_STATUS_REQUEST, getStatus);
  yield takeLatest(UPDATE_STATUS_REQUEST, updateStatus);
}

function* SupportTicketSaga() {
  yield all([fork(watchTicketsRequest)]);
}

export default SupportTicketSaga;
