import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { CloseSvg } from "../../../components/CommonSvg";
import { DATE_FORMATER } from "../../../constant/commonConstants";
import Select from "react-select";
import {
    getCityListRequest,
    getCountyListRequest,
    getStateListRequest,
} from "../../../redux/admin/location/action";
import { connect } from "react-redux";
function PromationFilter(props) {
    const {
        setAssignedValue,
        startDate,
        setStartDate,
        endDate,
        CityList,
        StateList,
        CountyList,
        setEndDate,
        GetCountyList,
        handelShowFIlter,
        GetCityList,
        GetStateList, setExportData,
        setCountyId,
        setCityId,
        setStateId,
        activeTab,
    } = props;
    useEffect(() => {
        GetCityList();
        GetStateList();
        GetCountyList();
    }, []);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const [assigned, setAssigned] = useState(null);
    const cityOptions = CityList?.map((city) => ({
        value: city.id,
        label: city.cityName,
    }));

    const stateOptions = StateList?.map((city) => ({
        value: city.id,
        label: city.stateName,
    }));
    const countyOptions = CountyList?.map((city) => ({
        value: city.id,
        label: city.countyName,
    }));
    const assignOptions = [
        { value: "Assigned", label: "Assigned" },
        { value: "Not-assigned", label: "Not Assigned" },
    ];
    const handelClearAll = () => {
        setEndDate("");
        setStartDate("");
        setCityId("")
        setSelectedCity("")
        setSelectedState("");
        setSelectedCounty("");
        setCountyId("");
        setStateId("");
        setAssigned("");
        setAssignedValue("");
        setExportData(false);
    }
    useEffect(() => {
        handelClearAll()
    }, [activeTab])

    return (
        <>
            <Card className="filter_section">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>

                    <div className="d-flex justify-content-end">
                        <Button
                            className="clear_btn btn-primary"
                            style={{ color: "black" }}
                            onClick={handelClearAll}
                        >
                            Clear all
                        </Button>
                    </div>

                    <form className="hra-create">
                        <Row>
                            <Col>
                                <DatePicker
                                    placeholderText="Start Date"
                                    className="form-control"
                                    selected={startDate}
                                    onChange={(opt) => {
                                        if (endDate && opt && opt > endDate) {
                                            setEndDate(null);
                                        }
                                        setExportData(true);
                                        setStartDate(opt);
                                    }}
                                    dateFormat={DATE_FORMATER}
                                    isClearable={startDate}
                                />
                                <Button
                                    className="my-tooltip cancel_filter"
                                    data-tooltip-content={"Close filter"}
                                    color="link"
                                    onClick={handelShowFIlter}
                                >
                                    <CloseSvg />
                                </Button>
                            </Col>
                            <Col className="no-floating-label">
                                <DatePicker
                                    placeholderText="End Date"
                                    className="form-control"
                                    name="hraDate"
                                    selected={endDate}
                                    isClearable={endDate}
                                    onChange={(opt) => {
                                        if (startDate && opt && opt < startDate) {
                                            setStartDate(null);
                                        }
                                        setExportData(true);
                                        setEndDate(opt);
                                    }}
                                    dateFormat={DATE_FORMATER}
                                />
                            </Col>
                            <Col className="no-floating-label">
                                <FormGroup className="m-0">
                                    <Select
                                        type="text"
                                        id="cityId"
                                        name="cityId"
                                        className="form-control selectbox"
                                        menuPlacement="auto"
                                        options={cityOptions}
                                        value={selectedCity}
                                        onChange={(selectedOption) => {
                                            setCityId(selectedOption ? selectedOption.value : "");
                                            setExportData(true);
                                            setSelectedCity(selectedOption);
                                        }}
                                        isClearable
                                    />
                                    <Label className="form-label" htmlFor="stateId">
                                        City
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col className="no-floating-label">
                                <FormGroup className="m-0">
                                    <Select
                                        type="text"
                                        id="stateId"
                                        name="stateId"
                                        className="form-control selectbox"
                                        menuPlacement="auto"
                                        options={stateOptions}
                                        value={selectedState}
                                        onChange={(selectedOption) => {
                                            setSelectedState(selectedOption);
                                            setExportData(true);
                                            setStateId(selectedOption ? selectedOption.value : "");
                                        }}
                                        isClearable
                                    />
                                    <Label className="form-label" htmlFor="stateId">
                                        State
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col className="no-floating-label">
                                <FormGroup className="m-0">
                                    <Select
                                        type="text"
                                        id="countyId"
                                        name="countyId"
                                        className="form-control selectbox"
                                        menuPlacement="auto"
                                        options={countyOptions}
                                        value={selectedCounty}
                                        onChange={(selectedOption) => {
                                            setSelectedCounty(selectedOption);
                                            setExportData(true);
                                            setCountyId(selectedOption ? selectedOption.value : "");
                                        }}
                                        isClearable
                                    />
                                    <Label className="form-label" htmlFor="stateId">
                                        County
                                    </Label>
                                </FormGroup>
                            </Col>
                            {activeTab == "1" && (
                                <Col className="no-floating-label">
                                    <FormGroup className="m-0">
                                        <Select
                                            type="text"
                                            id="isAssigned"
                                            name="isAssigned"
                                            className="form-control selectbox"
                                            menuPlacement="auto"
                                            options={assignOptions}
                                            value={assigned}
                                            onChange={(selectedOption) => {
                                                setExportData(true);
                                                setAssigned(selectedOption);
                                                setAssignedValue(
                                                    selectedOption ? selectedOption.value : ""
                                                );
                                            }}
                                            isClearable
                                        />
                                        <Label className="form-label" htmlFor="isAssigned">
                                            Assign Status
                                        </Label>
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                    </form>

                </CardBody>
            </Card>
        </>
    );
}
const mapStateToProps = ({ location }) => {
    let CityList;
    let StateList;
    let CountyList;

    if (
        location?.getCity?.list?.length > 0 &&
        location?.getState?.list?.length > 0 &&
        location?.getCounty?.list?.length > 0
    ) {
        return {
            CityList: location.getCity.list,
            StateList: location.getState.list,
            CountyList: location.getCounty.list,
        };
    }
    return {
        CityList: CityList,
        StateList: StateList,
        CountyList: CountyList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetCityList: () => {
            dispatch(getCityListRequest());
        },
        GetStateList: () => {
            dispatch(getStateListRequest());
        },
        GetCountyList: () => {
            dispatch(getCountyListRequest());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PromationFilter);
