import React, { useEffect, useState } from "react";
import { BackSvg, EditbtnSvg } from "../../../components/CommonSvg";
import { connect } from "react-redux";
import EditAccount from "./EditAccount";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup } from "reactstrap";
import AddEditAccountInfo from "./AddEditAccountInfo";
import { getProfileVendorRequest } from "../../../redux/admin/members/action";

function AccountInfo({
    profileDetail,
    hraMemberId,
    setActive,
    upadteProfileDetailRequest,
    getVendors,
    vendorProfile,
}) {
    const [isEditSec, setIsEditSec] = useState(false);
    const [editAccountNum, setEditAccountNum] = useState(false);
    useEffect(() => {
        getVendors();
    }, []);
    const vendorNumbers = profileDetail?.vendorNumbers ?JSON.parse(profileDetail?.vendorNumbers) :[];
    return (
        <>
            {!isEditSec && (
                <div className="owner_view_section tab-card mb-4">
                    <div className="tab_content_head">
                        <div>
                            <button
                                className="back_btn"
                                title="back"
                                onClick={() => setActive(true)}
                            >
                                <BackSvg />
                            </button>
                            <h4>Owner Details</h4>
                        </div>
                        <div>
                            <button
                                className="btn btn-primary"
                                title="Edit"
                                onClick={() => setIsEditSec(true)}
                            >
                                <EditbtnSvg />
                                Edit
                            </button>
                        </div>
                    </div>
                    <div className="profile-field-list row">
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner First Name</label>
                                <span>{profileDetail?.firstName}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner Last Name</label>
                                <span>{profileDetail?.lastName}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner Email</label>
                                <span>{profileDetail?.emailAddress}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner Mobile Number</label>
                                <span>{profileDetail?.phoneNumberMobileNumber}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Status</label>
                                <span>{profileDetail?.status || "--"}</span>
                            </div>
                        </div>{" "}
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Date Enrolled</label>
                                <span>{profileDetail?.dateEnrolled || "--"}</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="hra-light-form-box">
                                <label>Mailing Address</label>
                                <span>
                                    {profileDetail?.mailingStreetAddress +
                                        ", " +
                                        profileDetail?.mailingAddressCity +
                                        ", " +
                                        profileDetail?.mailingState}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isEditSec && (
                <EditAccount
                    profileDetail={profileDetail}
                    upadteProfileDetailRequest={upadteProfileDetailRequest}
                    hraMemberId={hraMemberId}
                    setIsEditSec={setIsEditSec}
                   
                />
            )}

            {!editAccountNum ? (
                <div className="tab-card">
                    <div className="tab_content_head">
                        <h4>Vendors Account Number</h4>
                        <Button
                            color="primary"
                            size="sm"
                            title="Edit"
                            onClick={() => setEditAccountNum(true)}
                        >
                            <EditbtnSvg />
                            Edit
                        </Button>
                    </div>
                    <div className="profile-field-list row">
                        {vendorProfile?.length > 0 &&
                            vendorProfile.map((x) => {
                                const { apiName } = x;
                                const accNumber =
                                    vendorNumbers.find((item) => item.apiKey === apiName)
                                        ?.accNumber || "--"
                                return (
                                    <div
                                        className="col-12 col-sm-12 col-md-6 col-xl-4"
                                        key={apiName}
                                    >
                                        <div className="hra-light-form-box">
                                            <label>{`${apiName.replace(/_/g, " ")} #`}</label>
                                            <span>{accNumber}</span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : (
                <AddEditAccountInfo
                    profileDetail={profileDetail}
                        setEditAccountNum={setEditAccountNum}
                        vendorNumbers={vendorNumbers}
                        vendorProfile={vendorProfile}
                />
            )}
        </>
    );
}

const mapStateToProps = ({ profileData, Members }) => {
    let profileDetail = profileData?.profileDetail?.details
        ? profileData.profileDetail.details
        : null;
    let vendorProfileList =
        Members.proVendor?.list?.length > 0 ? Members.proVendor.list : [];
    return {
        vendorProfile: vendorProfileList,
        profileDetail: profileDetail,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getVendors: () => {
            dispatch(getProfileVendorRequest());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
