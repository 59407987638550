import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { DANGER, SUCCESS } from "../../../../constant/commonConstants";
import { getForgotSuccess, resetPasswordSuccess } from "./action";
import { GET_FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST } from "./type";
import { ApiResponseMessage } from "../../../../helper/tosterHelper";
import { invokeApi } from "../../../../helper/axiosHelper";

function* Forgot({ payload: data }) {

    try {
        const response = yield call(invokeApi, 'post', '/forgotPassword', data);
        if (response.status) {
            yield put(getForgotSuccess(response));
            ApiResponseMessage(response.message.message, response.status ? SUCCESS : DANGER);
        } else {
            yield put(getForgotSuccess([]));
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* resetPassword({ payload: data }) {
    try {
        const res = yield call(invokeApi, 'post', '/resetPassword', data);
        if (res.status) {

            yield put(resetPasswordSuccess(res));
            ApiResponseMessage(res.message, SUCCESS);
        } else {
            yield put(resetPasswordSuccess(res));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
    }
}

function* watchForgotRequest() {
    yield takeLatest(GET_FORGOT_PASSWORD_REQUEST, Forgot);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}

function* ForgotSaga() {
    yield all([fork(watchForgotRequest)]);
}

export default ForgotSaga;