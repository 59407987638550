import * as type from "./type";

const initialState = {
  getMembers: null,
  getToken: null,
  deleteUser: null,
  memberDataExportApiResponse: null,
  createMultiStoreApiResponse: null,
  multiStoreDetaisApiResponse: null,
  memberHraList: null,
  memberSubHraList: null,
  memberDetailsApiResponse: null,
  createUploadAgreementApiResponse: null,
  zohoStore: null,
  zohoVendor: null,
  removeStoreGroup: null,
  proVendor:null
};

const MembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_MEMBER_LIST_REQUEST:
      return { ...state, getMembers: null };
    case type.GET_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        getMembers: action.payload,
      };
    case type.ROMOVE_STORE_GROUP_REQUEST:
      return { ...state, removeStoreGroup: null };
    case type.ROMOVE_STORE_GROUP_SUCCESS:
      return {
        ...state,
        removeStoreGroup: action.payload,
      };

    case type.MEMBER_DATA_EXPORT_REQUEST:
      return { ...state, memberDataExportApiResponse: null };
    case type.MEMBER_DATA_EXPORT_SUCCESS:
      return { ...state, memberDataExportApiResponse: action.payload };

    case type.CREATE_MULTI_STORE_SUCCESS:
      return { ...state, createMultiStoreApiResponse: action.payload };

    case type.GET_MULTI_STORE_DETAILS_REQUEST:
      return { ...state, multiStoreDetaisApiResponse: null };

    case type.GET_MULTI_STORE_DETAILS_SUCCESS:
      return { ...state, multiStoreDetaisApiResponse: action.payload };

    case type.GET_MEMBER_HRA_LIST_SUCCESS:
      return { ...state, memberHraList: action.payload };

    case type.GET_SUB_MEMBER_HRA_LIST_SUCCESS:
      return { ...state, memberSubHraList: action.payload };

    case type.CLEAR_CREATE_MULTI_STORE_SUCCESS:
      return { ...state, createMultiStoreApiResponse: null };

    case type.GET_MEMBER_DETAILS_SUCCESS:
      return { ...state, memberDetailsApiResponse: action.payload };

    case type.CREATE_UPLOAD_AGREEMENT_SUCCESS:
      return { ...state, createUploadAgreementApiResponse: action.payload };

    case type.CLEAR_UPLOAD_AGREEMENT_SUCCESS:
      return { ...state, createUploadAgreementApiResponse: null };

    case type.ZOHO_STORE_LIST_REQUEST:
      return { ...state, zohoStore: action.payload };

    case type.ZOHO_STORE_LIST_SUCCESS:
      return { ...state, zohoStore: action.payload };
    case type.SYNC_VENDOR_REQUEST:
      return { ...state, zohoVendor: action.payload };

    case type.SYNC_VENDOR_SUCCESS:
      return { ...state, zohoVendor: action.payload };
    case type.GET_PROFILE_VENDOR_REQUEST:
      return { ...state, proVendor: null };

    case type.GET_PROFILE_VENDOR_SUCCESS:
      return { ...state, proVendor: action.payload };
    default:
      return state;
  }
};

export default MembersReducer;
