import * as type from "./type";

const initialState = {
    getCategory: null,
    addCategory: null,
    statusCategory: null
};

const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_CATEGORY_LIST_REQUEST:
            return { ...state, getCategory: null };
        case type.GET_CATEGORY_LIST_SUCCESS:
            return { ...state, getCategory: action.payload };
        case type.ADD_CATEGORY_REQUEST:
            return { ...state, addCategory: null };
        case type.ADD_CATEGORY_SUCCESS:
            return { ...state, addCategory: action.payload };
        case type.STATUS_CATEGORY_REQUEST:
            return { ...state, statusCategory: null };
        case type.STATUS_CATEGORY_SUCCESS:
            return { ...state, statusCategory: action.payload };
        default:
            return {
                ...state,
            };
    }
};
export default CategoryReducer;
