import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_MEMBER_PROMOTION_VIEW_DETAILS_REQUEST,
  GET_MEMBER_PROMOTION_LIST_REQUEST,
  GET_LATEST_PROMOTION_LIST_REQUEST,
  GET_MEMBER_PROMOTION_LIST_ON_SCROLL_REQUEST,
} from "./type";
import {
  getMembersPromotionViewDetailsSuccess,
  getMembersPromotionListSuccess,
  getLatestPromotionListSuccess,
  getMemberPromotionListOnScrollSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { invokeApi } from "../../../helper/axiosHelper";

function* viewPromotionCardDetails({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      `/promotionalOfferDetails`, { id: data.id, hraMemberId: data.hraMemberId }
    );
    if (response.status) {
      yield put(getMembersPromotionViewDetailsSuccess(response));
    } else {
      yield put(getMembersPromotionViewDetailsSuccess(response));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* memberPromotion({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/membersPromotionList",
      data
    );
    if (response.status) {
      yield put(getMembersPromotionListSuccess(response,
        data?.offset));
    } else {
      yield put(getMembersPromotionListSuccess(response));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* latestPromotion({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/membersLatestPromotions",
      data
    );

    if (response.status) {
      yield put(getLatestPromotionListSuccess(response));
    } else {
      yield put(getLatestPromotionListSuccess([]));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* memeberScrollDataPromotion({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/memberPromotionListScroll",
      data
    );
    if (response.status) {
      yield put(
        getMemberPromotionListOnScrollSuccess(
          response?.data?.list,
          data?.offset
        )
      );
    } else {
      yield put(getMemberPromotionListOnScrollSuccess([]));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* watchMemberPromotionRequest() {
  yield takeLatest(
    GET_MEMBER_PROMOTION_VIEW_DETAILS_REQUEST,
    viewPromotionCardDetails
  );
  yield takeLatest(GET_MEMBER_PROMOTION_LIST_REQUEST, memberPromotion);
  yield takeLatest(GET_LATEST_PROMOTION_LIST_REQUEST, latestPromotion);
  yield takeLatest(
    GET_MEMBER_PROMOTION_LIST_ON_SCROLL_REQUEST,
    memeberScrollDataPromotion
  );
}

function* MemberPromotionSaga() {
  yield all([fork(watchMemberPromotionRequest)]);
}

export default MemberPromotionSaga;
