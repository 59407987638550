import { Field, Form, Formik } from "formik";
import React from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { BackSvg, DeleteSvg, FileIconSvg } from "../../../components/CommonSvg";
import { handleSingleFileUpload } from "../../../helper/imageSingalHelper";
import { useState } from "react";
import { getValueByKey } from "../../../utils/authUtils";
import { createAchRequest } from "../../../redux/frontend/ach/action";
import { useLocation, useNavigate } from "react-router-dom";
import { achInitialState, editAchInitialState } from "./CommonFunction";
import { useEffect } from "react";
import { getFileNameFromURL } from "../../../utils/generalUtils";

function AddAchManagement({ createAchRequest, AddACH }) {
    const validator = useRef(new SimpleReactValidator());
    const location = useLocation()
    const navigate = useNavigate()
    const [imageFile, setImageFile] = useState();
    const [imageFileData, setImageFileData] = useState(null);
    const [isRedirect, setIsRedirect] = useState(false);
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    const accountName = JSON.parse(getValueByKey("accountName"))
    const isEditView = location.state?.viewAchDetails ? location.state.viewAchDetails : null
    const [achInitialValues] = useState(isEditView?.routingNumber ? achInitialState(location.state?.viewAchDetails) : editAchInitialState(location.state?.viewAchDetails))

    useEffect(() => {
        if (isEditView !== null) {
            const ImageData = isEditView?.achManagement?.map((d) => {
                if (isEditView.routingNumber) {
                    return {
                        fileNames: getFileNameFromURL(d.currentDocument),
                        imageUrl: d.currentDocument,
                    };
                } else {
                    return {
                        fileNames: getFileNameFromURL(d.previousDocument),
                        imageUrl: d.previousDocument,
                    };
                }

            });
            setImageFile(ImageData[0])
            setImageFileData(ImageData[0])
        }
    }, [isEditView])

    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            const formData = new FormData();
            formData.append("routingNumber", values.routingNumber);
            formData.append("accountNumber", values.accountNumber);
            formData.append("chequeNumber", values.chequeNumber);
            formData.append("bankName", values.bankName);
            if (!imageFile?.fileNames) {
                formData.append("imageFile", imageFile);
            }
            formData.append("hraMemberId", hraMemberId);
            createAchRequest(formData)
            setIsRedirect(true)
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        if (AddACH === true && isRedirect === true) {
            navigate("/ach/view");
            setIsRedirect(false)
        }
    }, [AddACH])
    return <>
        <div className="container">
            <Formik initialValues={achInitialValues} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form >
                        <>
                            <div className="section-head">
                                <div>
                                    <button
                                        className="back_btn"
                                        title="back"
                                        type="button"
                                        onClick={() => {
                                            navigate("/ach/view");
                                        }}
                                    >
                                        <BackSvg />
                                    </button>
                                    <h3>{isEditView ? "Edit ACH Management" : "Add ACH Management"}</h3>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-auto"
                                        title="Send"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                            <div className="ach_details mb-4">
                                <h3 className="d-flex align-items-center justify-content-between flex-wrap">Details
                                    {accountName && <>
                                        <div className="account_name">
                                            <strong>Account Name: </strong>
                                            {accountName}
                                        </div>
                                    </>}
                                </h3>
                                <div className="form-horizontal form-login row">
                                    <Col sm={12} md={6} lg={6} xl={3}>
                                        <FormGroup>
                                            <Field
                                                id="accountNumber"
                                                name="accountNumber"
                                                placeholder="example@example.com"
                                                className="form-control"
                                                type="text"
                                                maxLength={16}
                                                minLength={7}
                                            />
                                            <Label className="form-label" htmlFor="accountNumber">
                                                Account No.
                                                <span className="text-danger required"> *</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.ACH.accountNumber"),
                                                values.accountNumber,
                                                "required|matches:^[0-9]{16}$|numeric",
                                                {
                                                    messages: {
                                                        required: __t("validations.ACH.accountNumber"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={3}>
                                        <FormGroup>
                                            <Field
                                                id="routingNumber"
                                                name="routingNumber"
                                                className="form-control"
                                                placeholder="Routing No."
                                                type="text"
                                                maxLength={12}
                                                minLength={9}
                                            />
                                            <Label className="form-label" htmlFor="routingNumber">
                                                Routing No. <span className="text-danger required">*</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.ACH.routingNumber"),
                                                values.routingNumber,
                                                "required|matches:^[0-9]{10}$|numeric",
                                                {
                                                    messages: {
                                                        required: __t("validations.ACH.routingNumber"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={3}>
                                        <FormGroup>
                                            <Field
                                                id="chequeNumber"
                                                name="chequeNumber"
                                                className="form-control"
                                                placeholder="FEIN No."
                                                type="text"
                                                maxLength={9}
                                                minLength={9}
                                            />
                                            <Label className="form-label" htmlFor="chequeNumber">
                                                FEIN No.
                                                <span className="text-danger required"> *</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.ACH.chequeNumber"),
                                                values.chequeNumber,
                                                "required|matches:^[0-9]{9}$|numeric",
                                                {
                                                    messages: {
                                                        required: __t("validations.ACH.chequeNumber"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={3}>
                                        <FormGroup>
                                            <Field
                                                id="bankName"
                                                name="bankName"
                                                className="form-control"
                                                placeholder="Bank Name."
                                                type="text"
                                            />
                                            <Label className="form-label" htmlFor="bankName">
                                                Bank Name
                                                <span className="text-danger required"> *</span>
                                            </Label>
                                            {validator.current.message(
                                                __t("validations.ACH.bankName"),
                                                values.bankName,
                                                "required",
                                                {
                                                    messages: {
                                                        required: __t("validations.ACH.bankName"),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </Col>
                                </div>
                            </div>
                            <div className="ach_details">
                                <div className="d-flex justify-content-between">
                                    <h3>Document Image  <small className="text-danger required">*</small></h3>
                                    {imageFileData && <button className="btn btn-primary delete_document"
                                        title="Delete ACH"
                                        onClick={() => {
                                            setImageFile(null)
                                            setImageFileData(null)
                                            setFieldValue("imageFile", null);
                                        }}
                                        type="button"
                                    >
                                        <DeleteSvg />
                                    </button>}
                                </div>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div className="ach_document">
                                            {!imageFileData ?
                                                <div className="dropzone">
                                                    <label htmlFor="imageFile" className="dropzone-container">
                                                        <div className="file-icon">
                                                            <FileIconSvg />
                                                        </div>
                                                        <div className="dropzone-title">
                                                            Drag and drop your files or&nbsp;
                                                            <span className="browse  d-inline-block text-center">
                                                                {" "}
                                                                browse
                                                            </span>{" "}
                                                            your files

                                                        </div>
                                                        <input
                                                            id="imageFile"
                                                            name="imageFile"
                                                            type="file"
                                                            className="form-control"
                                                            accept="image/*"
                                                            onChange={(e) =>
                                                                handleSingleFileUpload(
                                                                    setImageFile,
                                                                    setFieldValue,
                                                                    "imageFile",
                                                                    setImageFileData,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </label>
                                                </div> :
                                                <img src={imageFileData.imageUrl} alt={imageFileData.fileNames} />
                                            }
                                            {validator.current.message(
                                                __t("validations.ACH.check_image"),
                                                values.imageFile,
                                                "required",
                                                {
                                                    messages: {
                                                        required: __t("validations.ACH.check_image"),
                                                    },
                                                }
                                            )}


                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div >

    </>;
}
const mapStateToProps = ({ AchManagement }) => {
    return {
        AddACH: AchManagement?.createAch?.status
    }
}
const mapDispatchToProps = (dispatch) => {
    return { createAchRequest: (data) => dispatch(createAchRequest(data)) }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddAchManagement);