import React from "react";
import CommonTable from "./CommonTable";
import CommonPagination from "./CommonPagination";

export default function TableView(props) {
    const { handleScroll, data, responsiveClass, columns, rowClassName, tableId, setCurrentPage, setDataPerPage, rowEvents, pagination, currentPage, totalRecords, dataPerPage } = props
    return (
        <>
            <div className="container">
                <CommonTable
                    data={data}
                    handleScroll={handleScroll}
                    responsiveClass={responsiveClass}
                    columns={columns}
                    rowClassName={rowClassName}
                    tableId={tableId}
                    rowEvents={rowEvents}
                />
                {pagination &&
                    (totalRecords > 0 ? (
                        <CommonPagination
                            dataPerPage={dataPerPage}
                            totalData={totalRecords}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setDataPerPage={setDataPerPage}
                        />
                    ) : (
                        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
                    ))}
            </div>
        </>
    );
}
