import * as type from "./type";

export const getMembersPromotionViewDetailsRequest = (data) => {

  return {
    type: type.GET_MEMBER_PROMOTION_VIEW_DETAILS_REQUEST,
    payload: data,
  };
};
export const getMembersPromotionViewDetailsSuccess = (data) => {
  return {
    type: type.GET_MEMBER_PROMOTION_VIEW_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getMembersPromotionListRequest = (data) => {

  return {
    type: type.GET_MEMBER_PROMOTION_LIST_REQUEST,
    payload: data,
  };
};
export const getMembersPromotionListSuccess = (data, offset) => {
  return {
    type: type.GET_MEMBER_PROMOTION_LIST_SUCCESS,
    payload: { data, offset },
  };
};

export const getLatestPromotionListRequest = (data) => {
  return {
    type: type.GET_LATEST_PROMOTION_LIST_REQUEST,
    payload: data,
  };
};
export const getLatestPromotionListSuccess = (data) => {
  return {
    type: type.GET_LATEST_PROMOTION_LIST_SUCCESS,
    payload: data,
  };
};

export const getMemberPromotionListOnScrollRequest = (data) => {
  return {
    type: type.GET_MEMBER_PROMOTION_LIST_ON_SCROLL_REQUEST,
    payload: data,
  };
};

export const getMemberPromotionListOnScrollSuccess = (data, offset) => {
  return {
    type: type.GET_MEMBER_PROMOTION_LIST_ON_SCROLL_SUCCESS,
    payload: { data, offset },
  };
};

export const clearMemberPromotionListOnScrollResponse = (data) => {
  return {
    type: type.CLEAR_REPONSE,
    payload: data,
  };
};
