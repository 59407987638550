import React, { useEffect } from "react";
import { Container } from "reactstrap";
import RebateView from "../../../components/Common/RebateView";
import BootstrapTable from "react-bootstrap-table-next";

export default function TableViewRebate({
  data,
  columns,
  selectedClass,
  setSelectedClass,
  quarter,
  totalAmount,
  childColums,
}) {
  useEffect(() => {
    var checkTable = document.getElementById(data.storeId)?.getElementsByTagName("tbody")[0];
    var existingTfoot = checkTable?.querySelector("tfoot");
    if (existingTfoot) {
      existingTfoot.remove();
    }
  
    // Create a new tfoot element
    var tfoot = document.createElement("tfoot");
  
    // Create a new row for the total
    var newRow = document.createElement("tr");
    newRow.className = "rebate-detail-total";
    let values = ["", "", "", ""];
  
    if (quarter === "Q1" || quarter === "") {
      values.push(`$${totalAmount?.Q1 || "0"}`);
    }
  
    if (quarter === "Q2" || quarter === "") {
      values.push(`$${totalAmount?.Q2 || "0"}`);
    }
  
    if (quarter === "Q3" || quarter === "") {
      values.push(`$${totalAmount?.Q3 || "0"}`);
    }
  
    if (quarter === "Q4" || quarter === "") {
      values.push(`$${totalAmount?.Q4 || "0"}`);
    }
  
    if (quarter === "") {
      values.push(`$${totalAmount?.finalTotal || "0"}`);
    }
  
    for (var i = 0; i < values.length; i++) {
      var newCell = document.createElement("td");
      newCell.className = "text-end";
      if (i >= 4 && i <= 7) {
        newCell.innerHTML = `<span>${
          quarter ? quarter : "Q" + (i - 3)
        }: </span> ${values[i]}`;
      } else if (i === 8) {
        newCell.innerHTML = `<span>Total: </span> ${values[i]}`;
      } else {
        newCell.innerHTML = `${values[i]}`;
      }
      newRow.appendChild(newCell);
    }
    // Append the new row to the tfoot
    tfoot.appendChild(newRow);
  
    // Get the table body where you want to append the tfoot
    var tableBody = document.getElementById(data.storeId)?.getElementsByTagName("tbody")[0];
  
    // Append the tfoot to the table if tableBody exists
    if (tableBody) {
      tableBody.parentNode.appendChild(tfoot); // Append tfoot to the parent of tableBody (which is usually the table)
    }
  }, [data.storeId, quarter]);
  // useEffect(() => {
  //   var existingRow = document.querySelector(".rebate-detail-total");
  //   // if (existingRow && data?.storeId) {
  //   //   existingRow.remove();
  //   // }
  //   var newRow = document.createElement("tr");
  //   newRow.className = "rebate-detail-total";
  //   let values = ["", "", "", ""];
  //   if (quarter === "Q1" || quarter === "") {
  //     values.push(`$${totalAmount?.Q1 || "0"}`);
  //   }

  //   if (quarter === "Q2" || quarter === "") {
  //     values.push(`$${totalAmount?.Q2 || "0"}`);
  //   }

  //   if (quarter === "Q3" || quarter === "") {
  //     values.push(`$${totalAmount?.Q3 || "0"}`);
  //   }

  //   if (quarter === "Q4" || quarter === "") {
  //     values.push(`$${totalAmount?.Q4 || "0"}`);
  //   }

  //   if (quarter === "") {
  //     values.push(`$${totalAmount?.finalTotal || "0"}`);
  //   }

  //   for (var i = 0; i < values.length; i++) {
  //     var newCell = document.createElement("td");
  //     newCell.className = "text-end";
  //     if (i >= 4 && i <= 7) {
  //       newCell.innerHTML = `<span>${
  //         quarter ? quarter : "Q" + (i - 3)
  //       }: </span> ${values[i]}`;
  //     } else if (i === 8) {
  //       newCell.innerHTML = `<span>Total: </span> ${values[i]}`;
  //     } else {
  //       newCell.innerHTML = `${values[i]}`;
  //     }
  //     newRow?.appendChild(newCell);
  //   }
  //   var tableBody = document
  //     .getElementById(data.storeId)
  //     ?.getElementsByTagName("tbody")[0];
  //   if (tableBody) {
  //     tableBody.appendChild(newRow);
  //   }
  // }, [data.classes?.length > 0, quarter]);
  useEffect(() => {
    if (selectedClass !== "All") {
      var existingRow = document.querySelector(".rebate-detail-total");
      if (existingRow) {
        existingRow.remove();
      }
    }
  }, [selectedClass]);

  const expandRow = {
    renderer: (row) => (
      <>
        <BootstrapTable
          id={"myTable"}
          classes={" small table-sm"}
          striped={true}
          bootstrap4
          keyField="id"
          data={
            row?.subClasses?.length > 0
              ? row?.subClasses?.map((item) => ({
                  ...item,
                  cYVolume: item.cYVolume,
                  volumeType: item.volumeType,
                  subClassName: item.subClassName,
                  totalAmount: parseFloat(row.totalAmount),
                  Q4: parseFloat(item.Q4),
                  Q3: parseFloat(item.Q3),
                  Q2: parseFloat(item.Q2),
                  Q1: parseFloat(item.Q1),
                  year: row.year
                }))
              : []
          }
          columns={childColums}
          bordered={true}
          defaultSorted={[{
            dataField: childColums[1]?.dataField, // default sorting by the first column
            order: 'asc' // 'asc' or 'desc'
          }]}
          // rowEvents={onRowClick}
          // expandRow={expandRow}
        />
      </>
    ),
    expanded: data?.classes?.length > 0 && data.classes?.map((row) => row.id),
    // showExpandColumn: true,
    expandByColumnOnly: true, // Only expand by clicking the custom expand column
    // expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
    //   // <ExpandButton expanded={expanded} onToggle={expandable.toggleRow} />
    // ),
    // expandHeaderColumnRenderer: () => <div style={{ width: '20px' }}></div>,
    // expandColumnPos: 'left', // Position of the expand column
  };
  return (
    <div>
      <Container>
        {" "}
        <h6>{data.storeName}</h6>
      </Container>
      <RebateView
        responsiveClass="rebate_history rebate_history-tables"
        data={
          data.classes?.length > 0
            ? data.classes.map((item) => ({
                ...item,
                totalAmount: parseFloat(item.totalAmount),
                Q4: parseFloat(item.Q4),
                Q3: parseFloat(item.Q3),
                Q2: parseFloat(item.Q2),
                Q1: parseFloat(item.Q1),
              }))
            : []
        }
        expandRow={expandRow}
        columns={columns}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        tableId={data.storeId}
        defaultSorted={[{
          dataField: columns[1]?.dataField, // default sorting by the first column
          order: 'asc' // 'asc' or 'desc'
        }]}
      />
    </div>
  );
}
