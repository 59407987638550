import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_LOGIN_REQUEST } from "./type";
import { ApiResponseMessage } from "../../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../../constant/commonConstants";
import { setLoginUserData } from "../../../../utils/authUtils";
import { getLoginSuccess } from "./action";
import { invokeApi } from "../../../../helper/axiosHelper";


function* login({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/login", data);
        if (res.status) {
            setLoginUserData(res);
            yield put(getLoginSuccess(res));
            ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
        } else {
            yield put(getLoginSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* watchLoginRequest() {
    yield takeLatest(GET_LOGIN_REQUEST, login);
}

function* LoginSaga() {
    yield all([fork(watchLoginRequest)]);
}

export default LoginSaga;
