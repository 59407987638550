import * as type from "./type";

const initialState = {
  getVendor: null,
  addVendor: null,
  removeVendor: null,
  deleteVendor: null,
  vendor: null,
  approve: null,
  vendorIntrestedList: [],
  members: [],
  assign:null
};

const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_VENDER_LIST_REQUEST:
      return { ...state, getVendor: null };
    case type.GET_VENDER_LIST_SUCCESS:
      return { ...state, getVendor: action.payload };
    case type.ADD_VENDER_REQUEST:
      return { ...state, addVendor: null };
    case type.ADD_VENDER_SUCCESS:
      return { ...state, addVendor: action.payload };
    case type.CLEAR_VENDER_REQ:
      return { ...state, addVendor: null };
    case type.UPDATE_VENDER_REQUEST:
      return { ...state, addVendor: null };
    case type.UPDATE_VENDER_SUCCESS:
      return { ...state, addVendor: action.payload };
    case type.REMOVE_VENDER_REQUEST:
      return { ...state, removeVendor: null };
    case type.REMOVE_VENDER_SUCCESS:
      return { ...state, removeVendor: action.payload };
    case type.DELETE_VENDER_REQUEST:
      return { ...state, deleteVendor: null };
    case type.DELETE_VENDER_SUCCESS:
      return { ...state, deleteVendor: action.payload };
    case type.GET_VENDER_DETAILS_REQUEST:
      return { ...state, vendor: null };
    case type.GET_VENDER_DETAILS_SUCCESS:
      return { ...state, vendor: action.payload };
    case type.GET_INTERESTED_MEMBER_LIST_REQUEST:
      return { ...state, vendorIntrestedList: null };
    case type.GET_INTERESTED_MEMBER_LIST_SUCCESS:
      return { ...state, vendorIntrestedList: action.payload };
    case type.APPROVE_MEMBER_REQUEST:
      return { ...state, approve: null };
    case type.APPROVE_MEMBER_SUCCESS:
      return { ...state, approve: action.payload };
    case type.REMOVE_IMAGE_REQUEST:
      return { ...state, removeImage: null };
    case type.REMOVE_IMAGE_SUCCESS:
      return { ...state, removeImage: action.payload };
    case type.GET_VENDOR_MEMBER_REQUEST:
      return { ...state, members: null };
    case type.GET_VENDOR_MEMBER_SUCCESS:
      return { ...state, members: action.payload };
      case type.ASSIGN_VENDOR_REQUEST:
        return { ...state, assign: null };
      case type.ASSIGN_VENDOR_SUCCESS:
        return { ...state, assign: action.payload };
    default:
      return {
        ...state,
      };
  }
};
export default VendorReducer;
