import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { CloseSvg } from "../../../components/CommonSvg";
import { DATE_FORMATER } from "../../../constant/commonConstants";
function NewsLetterFilter(props) {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        GetCountyList,
        handelShowFIlter,
    } = props;
    const handelClearAll = () => {
        setEndDate("");
        setStartDate("");
    }

    return (
        <>
            <Card className="filter_section">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>

                    <div className="d-flex justify-content-end">
                        <Button
                            className="clear_btn btn-primary"
                            style={{ color: "black" }}
                            onClick={handelClearAll}
                        >
                            Clear all
                        </Button>
                    </div>

                    <form className="hra-create">
                        <Row>
                            <Col>
                                <DatePicker
                                    placeholderText="Start Date"
                                    className="form-control"
                                    selected={startDate}
                                    onChange={(opt) => {
                                        if (endDate && opt && opt > endDate) {
                                            setEndDate(null);
                                        }
                                        setStartDate(opt);
                                    }}
                                    dateFormat={DATE_FORMATER}
                                    isClearable={startDate}
                                />
                                <Button
                                    className="my-tooltip cancel_filter"
                                    data-tooltip-content={"Close filter"}
                                    color="link"
                                    onClick={handelShowFIlter}
                                >
                                    <CloseSvg />
                                </Button>
                            </Col>
                            <Col className="no-floating-label">
                                <DatePicker
                                    placeholderText="End Date"
                                    className="form-control"
                                    name="hraDate"
                                    selected={endDate}
                                    isClearable={endDate}
                                    onChange={(opt) => {
                                        if (startDate && opt && opt < startDate) {
                                            setStartDate(null);
                                        }
                                        setEndDate(opt);
                                    }}
                                    dateFormat={DATE_FORMATER}
                                />
                            </Col>
                        </Row>
                    </form>

                </CardBody>
            </Card>
        </>
    );
}
export default NewsLetterFilter;
