import * as type from "./type";

export const getClassRequest = (data) => {
    return {
        type: type.GET_CLASS_REQUEST,
        payload: data
    };
};
export const getClassSuccess = (data) => {
    return {
        type: type.GET_CLASS_SUCCESS,
        payload: data
    };
};
export const getYearRequest = (data) => {
    return {
        type: type.GET_YEAR_REQUEST,
        payload: data
    };
};
export const getYearSuccess = (data) => {
    return {
        type: type.GET_YEAR_SUCCESS,
        payload: data
    };
};
export const getQuarterRequest = (data) => {
    return {
        type: type.GET_QUARTER_REQUEST,
        payload: data
    };
};
export const getQuarterSuccess = (data) => {
    return {
        type: type.GET_QUARTER_SUCCESS,
        payload: data
    };
};

export const getRebateListRequest = (data) => {
    return {
        type: type.GET_REBATES_REQUEST,
        payload: data
    };
};
export const getRebateListSuccess = (data) => {
    return {
        type: type.GET_REBATES_SUCCESS,
        payload: data
    };
};
