import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, FormGroup, Label, Col, Button } from "reactstrap";
import { Field, Form, Formik } from "formik";
import {
  Add_Svg,
  DeleteSvg,
  FileIconSvg,
  LogoiconSvg,
} from "../../../components/CommonSvg";
import { handleSingleFileUpload } from "../../../helper/imageSingalHelper";
import Select from "react-select";
import { connect } from "react-redux";
import { getCategoryListRequest } from "../../../redux/admin/category/action";
import {
  getFileNameFromURL,
  isValidHttpUrl,
} from "../../../utils/generalUtils";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { useRef } from "react";
import { __t } from "../../../i18n/translator";

function DetailsView({ categoryList, getCategoryList, dataValue, modal }) {
  const [imageFileData, setImageFileData] = useState(null);
  const [imageFile, setImageFile] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { values, setFieldValue, validator } = dataValue;
  const fileInputRef = useRef(null);
  const clearFileInput = () => {
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = "";
    }
  };
  useEffect(() => {
    getCategoryList();
  }, []);
  useEffect(() => {
    if (values?.vendorId && isValidHttpUrl(values?.vendorLogo)) {
      setImageFileData({
        fileNames: getFileNameFromURL(values?.vendorLogo),
        imageUrl: values?.vendorLogo,
      });
      setSelectedCategory(values?.catId);
    }
  }, [values]);

  return (
    <>
      <Card className="vendor_details">
        <CardBody>
          <Row className="m-0">
            <Col lg={2} className="brand_logo">
              <FormGroup>
                <Label className="form-label" htmlFor="brand_logo">
                  Brand logo
                  <span className="text-danger required">*</span>
                </Label>
                <div className="ach_document vendor_logo_file">
                  {!imageFileData ? (
                    <div className="dropzone">
                      <label
                        htmlFor="vendorLogo"
                        className="dropzone-container"
                      >
                        <div className="file-icon">
                          <LogoiconSvg />
                        </div>
                        <div className="dropzone-title">
                          Drag and drop logo image or&nbsp;
                          <span className="browse  d-inline-block text-center">
                            {" "}
                            browse
                          </span>{" "}
                          logo image
                        </div>
                        <input
                          id="vendorLogo"
                          name="vendorLogo"
                          type="file"
                          className="form-control"
                          accept="image/*"
                          ref={fileInputRef}
                          onChange={(e) =>
                            handleSingleFileUpload(
                              setImageFile,
                              setFieldValue,
                              "vendorLogo",
                              setImageFileData,
                              e
                            )
                          }
                        />
                      </label>
                    </div>
                  ) : (
                    <>
                      <img
                        src={imageFileData.imageUrl}
                        alt={imageFileData.fileNames}
                      />
                      <a
                        className="remove-box"
                        title="remove"
                        onClick={async () => {
                          const response = await commonConfirmBox(
                            "Do you want to remove this image?"
                          );
                          if (response) {
                            setFieldValue("vendorLogo", null);
                            setImageFileData("");
                            setImageFile("");
                            clearFileInput();
                          }
                        }}
                      >
                        <DeleteSvg />
                      </a>
                    </>
                  )}
                </div>
                {validator.current.message(
                  __t("validations.vendor.logo"),
                  imageFileData,
                  "required",
                  {
                    messages: {
                      required: __t("validations.vendor.logo"),
                    },
                  }
                )}
              </FormGroup>
            </Col>
            <Col className="pe-0">
              <Row className="m-0 vendor_details_row">
                <Col lg={8}>
                  <FormGroup className="">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      className="form-control"
                    />
                    <Label className="form-label" htmlFor="name">
                      Name
                      <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      __t("validations.user.fullName"),
                      values.name,
                      "required",
                      {
                        messages: {
                          required: __t("validations.user.fullName"),
                        },
                      }
                    )}
                  </FormGroup>
                </Col>

                <Col className="select_category_box">
                  <FormGroup>
                    <Select
                      type="text"
                      id="category"
                      name="category"
                      className="form-control selectbox"
                      menuPlacement="auto"
                      options={categoryList}
                      value={categoryList.filter(
                        (d) => d.value === selectedCategory && d.label
                      )}
                      onChange={(selectedOption) => {
                        setSelectedCategory(selectedOption.value);
                        setFieldValue("catId", selectedOption.value);
                      }}
                      // isClearable
                    />
                    <Label className="form-label" htmlFor="category">
                      Category
                      <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      __t("validations.vendor.category"),
                      values.catId,
                      "required",
                      {
                        messages: {
                          required: __t("validations.vendor.category"),
                        },
                      }
                    )}
                  </FormGroup>
                  <Button color="primary" onClick={() => modal.handleOpen()}>
                    <Add_Svg />
                  </Button>
                </Col>
              </Row>
              <Row className="m-0 vendor_details_row ">
                <Col>
                  <FormGroup className="">
                    <input
                      type="tel" // Use "tel" for phone numbers
                      id="phoneNumber"
                      name="phoneNumber"
                      className="form-control"
                      placeholder="Phone No."
                      maxLength={30}
                      onChange={(e) => {
                        // Remove spaces and hyphens from the input value
                        const cleanedValue = e.target.value.replace(/[^\d- ]/g, ''); // Allow digits, hyphens, and spaces
                    
                        // Update the field value using setFieldValue
                        setFieldValue("phoneNumber", cleanedValue);
                      }}
                      // onChange={(d) => {
                      //   setFieldValue("phoneNumber", d.target.value);
                      // }}
                      value={values?.phoneNumber}
                    />
                    <Label className="form-label" htmlFor="phoneNumber">
                      Phone No.
                      <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      "phoneNumber",
                      values.phoneNumber,
                      "required",
                      {
                        messages: {
                          required: __t("validations.user.phoneNumber"),
                          matches: __t("validations.user.matches"),
                        },
                      }
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="">
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email Address"
                    />
                    <Label className="form-label" htmlFor="email">
                      Email Address
                      <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      __t("validations.user.email"),
                      values.email,
                      "required|email",
                      {
                        messages: {
                          required: __t("validations.user.email"),
                          email: "Please enter a valid email address.",
                        },
                      }
                    )}
                    {validator.current.message(
                      __t("validations.user.email"),
                      values.email.endsWith(".com"),
                      "endsWith:.com",
                      {
                        messages: {
                          endsWith: "Email must end with '.com'.",
                        },
                      }
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="">
                    <input
                      type="number"
                      id="vendorZohoId"
                      name="vendorZohoId"
                      className="form-control"
                      placeholder="Zoho ID"
                      onChange={(d) => {
                        setFieldValue("vendorZohoId", d.target.value);
                      }}
                      value={values?.vendorZohoId}
                    />
                    <Label className="form-label" htmlFor="vendorZohoId">
                      Zoho ID
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="m-0">
                <Col lg={12}>
                  <FormGroup className="mb-0">
                    <Field
                      // as="text"
                      className="form-control"
                      id="address"
                      as="textarea"
                      rows={4}
                      placeholder="Address.."
                      name="address"
                    />
                    <Label className="form-label" htmlFor="address">
                      Address <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      __t("validations.inquiry.address"),
                      values.address,

                      "required",
                      {
                        messages: {
                          required: __t("validations.inquiry.address"),
                        },
                      }
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
const mapStateToProps = ({ category }) => {
  let categoryList =
    category?.getCategory?.list?.length > 0
      ? category.getCategory.list.map((data) => {
          return {
            label: data.catName,
            value: data.id,
          };
        })
      : [];
  return { categoryList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: () => dispatch(getCategoryListRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsView);
