import React, { useEffect, useState } from "react";
import CommonTable from "../../../components/Common/TableView/CommonTable";
import {
    CloseSvg,
    FileIconSvg,
    SearchSvg,
} from "../../../components/CommonSvg";
import { Button } from "reactstrap";
export default function OptinModal({
    optInMemberData,
    optinId,
    updateData,
    GetOptInListRequest,
    headingTitle,
    exportDataExportRequest,
}) {
    const [exportFilterData, setExportFilterData] = useState(false);

    const [searchDataOpt, setSearchDataOpt] = useState(null);
    const columns = [
        {
            dataField: "hraMemberId",
            text: "HRA #",
            sort: true,
        },
        {
            dataField: "storeNameRegular",
            text: "Store Name",
            sort: true,
            formatter: (cell, row) => (
                <>
                    <span title={row.member[0]?.storeNameRegular}>
                        {row.member[0]?.storeNameRegular}
                    </span>
                </>
            ),
        },
        {
            dataField: "stateName",
            text: "State",
            sort: true,
            formatter: (cell, row) => <>{row.member[0]?.state}</>,
        },
        {
            dataField: "cityName",
            text: "City",
            sort: true,
            formatter: (cell, row) => <>{row.member[0]?.city}</>,
        },
        {
            dataField: "countyName",
            text: "County",
            sort: true,
            formatter: (cell, row) => <>{row.member[0]?.county}</>,
        },
        {
            dataField: "firstName",
            text: "Name",
            sort: true,
            formatter: (cell, row) =>
                row.member[0]?.firstName + " " + row.member[0]?.lastName,
        },
        {
            dataField: "emailAddress",
            text: "Email",
            sort: true,
            formatter: (cell, row) => row.member[0]?.emailAddress,
        },
        {
            dataField: "phoneNumberMobileNumber",
            text: "Mobile",
            sort: true,
            formatter: (cell, row) => row.member[0]?.phoneNumberMobileNumber,
        },
        {
            dataField: "createdDate",
            text: "Opted-On",
            sort: true,
            formatter: (cell, row) => (
                <span className="text-nowrap">{row.createdDate}</span>
            ),
        },
        {
            dataField: "updatedDate",
            text: "Opt-out",
            sort: true,
            formatter: (cell, row) => (
                <span className="text-nowrap">
                    {row.updatedDate ? row.updatedDate : "--"}
                </span>
            ),
        },
    ];
    const [search, setSearch] = useState("");
    useEffect(() => {
        GetOptInListRequest({
            searchText: search,
            promotionalOfferId: optinId,
        });
    }, [optinId, searchDataOpt]);
    return (
        <>
            <div className="section-head mt-0">
                <div>
                    <h3>{headingTitle}</h3>
                </div>

                <div className="header-action-item">
                    <button
                        className="creat_btn btn btn-secondary"
                        onClick={() => {
                            exportDataExportRequest({
                                url: "/optInListDataExport",
                                type: "post",
                                data: {
                                    searchText: search,
                                    PromotionalOfferId: optinId,
                                    isFilteredDataExport: exportFilterData,
                                },
                            });
                        }}
                    >
                        <FileIconSvg />
                        Export Data
                    </button>

                    <div className="search_box">
                        <Button
                            color="link"
                            className="p-0"
                            onClick={() => {
                                setSearchDataOpt(search);
                            }}
                        >
                            <SearchSvg />
                        </Button>

                        {search && (
                            <Button
                                color="link"
                                className="p-0 close-btn"
                                onClick={() => {
                                    setSearch("");
                                    setSearchDataOpt("");
                                    updateData({
                                        searchText: "",
                                        promotionalOfferId: optinId,
                                    });
                                }}
                            >
                                <CloseSvg />
                            </Button>
                        )}
                        <input
                            name="searchbox"
                            onChange={(e) => {
                                setExportFilterData(true);
                                setSearch(e.target.value);
                                if (e.target.value?.length <= 0) {
                                    updateData({
                                        searchText: "",
                                        promotionalOfferId: optinId,
                                    });
                                    setExportFilterData(false);
                                    setSearch("");
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setSearchDataOpt(search);
                                }
                            }}
                            placeholder="Search box"
                            value={search}
                        />
                    </div>
                </div>
            </div>
            <CommonTable
                data={optInMemberData ? optInMemberData : []}
                columns={columns}
            />
            {!optInMemberData?.length > 0 && (
                <h4 className="text-center mt-5 mb-5">No Data Found</h4>
            )}
        </>
    );
}
