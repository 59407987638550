import * as type from "./type";

const initialState = {
  getRoles: null,
  setPath: null,
};

const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ROLE_LIST_SUCCESS:
      return { ...state, getRoles: action.payload };
    case type.GET_DEFALT_PATH_REQUEST:
      return { ...state, setPath: null };
    case type.GET_DEFALT_PATH_SUCCESS:
      return { ...state, setPath: action.payload };
    default:
      return {
        ...state,
      };
  }
};
export default RoleReducer