import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { DANGER, SUCCESS } from "../../../../constant/commonConstants";
import {
  getMemberForgotLoginSuccess,
  getMemberForgotSuccess,
  resetMemberPasswordSuccess,
} from "./action";
import {
  GET_MEMBER_FORGOT_LOGIN_REQUEST,
  GET_MEMBER_FORGOT_PASSWORD_REQUEST,
  MEMBER_RESET_PASSWORD_REQUEST,
} from "./type";
import { ApiResponseMessage } from "../../../../helper/tosterHelper";
import { invokeApi } from "../../../../helper/axiosHelper";

function* ForgotMember({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/memberForgotPassword",
      data
    );
    if (response) {
      yield put(getMemberForgotSuccess(response));
      ApiResponseMessage(
        response.status === false ? response.message : response.message.message,
        response.status ? SUCCESS : DANGER
      );
    } else {
      yield put(getMemberForgotSuccess([]));
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    ApiResponseMessage(error.message, DANGER);
  }
}
function* ForgotLogin({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/memberForgotLogin", data);
    if (response.status) {
      yield put(getMemberForgotLoginSuccess(response));
      ApiResponseMessage(
        response.message.message,
        response.status ? SUCCESS : DANGER
      );
    } else {
      yield put(getMemberForgotLoginSuccess([]));
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    ApiResponseMessage(error.message, DANGER);
  }
}
function* resetPassword({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/memberResetPassword", data);
    if (res.status) {
      yield put(resetMemberPasswordSuccess(res));
      ApiResponseMessage(res.message, SUCCESS);
    } else {
      yield put(resetMemberPasswordSuccess(res));
      ApiResponseMessage(res.message, DANGER);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
  }
}

function* watchForgotRequest() {
  yield takeLatest(GET_MEMBER_FORGOT_PASSWORD_REQUEST, ForgotMember);
  yield takeLatest(MEMBER_RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(GET_MEMBER_FORGOT_LOGIN_REQUEST, ForgotLogin);
}

function* MemberForgotSaga() {
  yield all([fork(watchForgotRequest)]);
}

export default MemberForgotSaga;
