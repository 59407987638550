import * as type from "./type";
const settingRequest = (data) => {
  return {
    type: type.SETTING_REQUEST,
    payload: data,
  };
};
const settingSuccess = (data) => {
  return {
    type: type.SETTING_SUCCESS,
    payload: data,
  };
};
const emailSettingRequest = (data) => {
  return {
    type: type.EMAIL_SETTING_REQUEST,
    payload: data,
  };
};
const emailSettingSuccess = (data) => {
  return {
    type: type.EMAIL_SETTING_SUCCESS,
    payload: data,
  };
};
export {
  settingRequest,
  settingSuccess,
  emailSettingSuccess,
  emailSettingRequest,
};
