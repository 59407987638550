export const GET_PROMOTION_LIST_REQUEST = "GET_PROMOTION_LIST_REQUEST";
export const GET_PROMOTION_LIST_SUCCESS = "GET_PROMOTION_LIST_SUCCESS";
export const VIEW_PROMOTION_LIST_REQUEST = "VIEW_PROMOTION_LIST_REQUEST";
export const VIEW_PROMOTION_LIST_SUCCESS = "VIEW_PROMOTION_LIST_SUCCESS";
export const ADD_PROMOTION_LIST_REQUEST = "ADD_PROMOTION_LIST_REQUEST";
export const ADD_PROMOTION_LIST_SUCCESS = "ADD_PROMOTION_LIST_SUCCESS";
export const EDIT_PROMOTION_LIST_REQUEST = "EDIT_PROMOTION_LIST_REQUEST";
export const EDIT_PROMOTION_LIST_SUCCESS = "EDIT_PROMOTION_LIST_SUCCESS";
export const ACTIVE_INACTIVE_PROMOTION_REQUEST =
  "ACTIVE_INACTIVE_PROMOTION_REQUEST";
export const ACTIVE_INACTIVE_PROMOTION_SUCCESS =
  "ACTIVE_INACTIVE_PROMOTION_SUCCESS";
export const ASSIGN_PROMOTION_REQUEST = "ASSIGN_PROMOTION_REQUEST";
export const ASSIGN_PROMOTION_SUCCESS = "ASSIGN_PROMOTION_SUCCESS";
export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";