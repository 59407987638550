import * as type from "./type";

export const getDocumentListRequest = (data) => {
  return {
    type: type.GET_DOCUMENT_LIST_REQUEST,
    payload: data,
  };
};
export const getDocumentListSuccess = (data) => {
  return {
    type: type.GET_DOCUMENT_LIST_SUCCESS,
    payload: data,
  };
};
