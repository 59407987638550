import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_CLASS_REQUEST,
  GET_QUARTER_REQUEST,
  GET_REBATES_REQUEST,
  GET_YEAR_REQUEST,
} from "./type";
import {
  getClassSuccess,
  getQuarterSuccess,
  getRebateListSuccess,
  getYearSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";
import { getValueByKey, removeZohoToken } from "../../../utils/authUtils";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { getRebateSyncReq } from "../sync/action";

function* ClassList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/getRebateClass", data);
    if (response.status) {
      yield put(getClassSuccess(response.data));
    } else {
      yield put(getClassSuccess(response));
      removeZohoToken();
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    yield put(getClassSuccess([]));
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
    removeZohoToken();
  }
}
function* getRebateYear({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/getRebateYear", data);

    if (response.status) {
      yield put(getYearSuccess(response.data));
    } else {
      yield put(getYearSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    yield put(getYearSuccess([]));

    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getRebateQuarter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/getRebateQuarter", data);
    if (response.status) {
      yield put(getQuarterSuccess(response.data));
    } else {
      yield put(getQuarterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    yield put(getQuarterSuccess([]));
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getRebateList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/zohoRebateList", data);
    if (response.status) {
      yield put(getRebateListSuccess(response.data));
      if (response.data?.list?.length === 0 && !data.rebateClassId && !data.cityName && !data.countyName && !data.rebateStoreId && !data.stateName && !data.searchText) {
        const confirmResponse = yield call(
          commonConfirmBox,
          "Do you want to start  Rebate sync ?"
        );
        if (confirmResponse) {
          
          yield put(getRebateSyncReq({
            rebateYear: data.rebateYear,
            userId: getValueByKey("user"),
          }));
        }
      }
      response.message !== "" && ApiResponseMessage(response.message, SUCCESS);
    } else {
      yield put(getRebateListSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      removeZohoToken();
      handleApiCallException(response.message);
    }
  } catch (error) {
    yield put(getYearSuccess([]));
    removeZohoToken();
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* watchRebateRequest() {
  yield takeLatest(GET_CLASS_REQUEST, ClassList);
  yield takeLatest(GET_YEAR_REQUEST, getRebateYear);
  yield takeLatest(GET_QUARTER_REQUEST, getRebateQuarter);
  yield takeLatest(GET_REBATES_REQUEST, getRebateList);
}

function* RebateManageSaga() {
  yield all([fork(watchRebateRequest)]);
}

export default RebateManageSaga;
