import * as type from "./type";

export const getDashboardRequest = () => {
    return {
        type: type.GET_DASHBOARD_REQUEST,
    };
};
export const getDashboardSuccess = (data) => {
    return {
        type: type.GET_DASHBOARD_SUCCESS,
        payload: data
    };
};
export const createDashboardMenuRequest = (data) => {
    return {
        type: type.CREATE_DASHBOARD_MENU_REQUEST,
        payload: data

    };
};
export const createDashboardMenuSuccess = (data) => {
    return {
        type: type.CREATE_DASHBOARD_MENU_SUCCESS,
        payload: data
    };
};
export const getDashboardMenuRequest = (data) => {
    return {
        type: type.GET_DASHBOARD_MENU_REQUEST,
        payload: data

    };
};
export const getDashboardMenuSuccess = (data) => {
    return {
        type: type.GET_DASHBOARD_MENU_SUCCESS,
        payload: data
    };
};
export const deleteDashboardMenuRequest = (data) => {
    return {
        type: type.DELETE_DASHBOARD_MENU_REQUEST,
        payload: data

    };
};
export const deleteDashboardMenuSuccess = (data) => {
    return {
        type: type.DELETE_DASHBOARD_MENU_SUCCESS,
        payload: data
    };
};