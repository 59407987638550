import * as type from "./type";

export const getInquiryRequest = (data) => {
    return {
        type: type.GET_INQUIRY_REQUEST,
        payload: data
    };
};
export const getInquirySuccess = (data) => {
    return {
        type: type.GET_INQUIRY_SUCCESS,
        payload: data
    };
};
export const changeStatusRequest = (data) => {
    return {
        type: type.STATUS_CHANGE_REQUEST,
        payload: data
    };
};
export const changeStatusSuccess = (data) => {
    return {
        type: type.STATUS_CHANGE_SUCCESS,
        payload: data
    };
};

export const deleteInquiryRequest = (data) => {
    return {
        type: type.DELETE_INQUIRY_REQUEST,
        payload: data
    };
};
export const deleteInquirySuccess = (data) => {
    return {
        type: type.DELETE_INQUIRY_SUCCESS,
        payload: data
    };
};