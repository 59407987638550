import React from "react";

export default function Loader() {
    return (
        <>
            <div className="preloader-main">
                <div className="preloader-wrapper">
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
