import * as type from "./type";

export const  getRebateSyncReq = (data) => {
    return {
        type: type.GET_REBATES_SYNC_REQ,
        payload: data
    };
};
export const getRebateSyncSuc = (data) => {
    return {
        type: type.GET_REBATES_SYNC_SUC,
        payload: data
    };
};

export const getClassSyncReq = (data) => {
    return {
        type: type.GET_CLASS_SYNC_REQ,
        payload: data
    };
};
export const getClassSyncSuc = (data) => {
    return {
        type: type.GET_CLASS_SYNC_SUC,
        payload: data
    };
};
// export const getSyncReq = (data) => {
//     return {
//         type: type.GET_SYNC_REQ,
//         payload: data
//     };
// };
// export const getSyncSuc = (data) => {
//     return {
//         type: type.GET_SYNC_SUC,
//         payload: data
//     };
// };
