import React, { useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Field } from "formik";
import { DeleteSvg, PaperclipSvg } from "../../../components/CommonSvg";
import demoimg from "../../../assets/images/contac-us-bg.jpg";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER } from "../../../constant/commonConstants";

const FileAttachment = ({files, setFiles}) => {

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = [...files, ...selectedFiles];

    // Ensure no more than 5 files and each file is <= 20MB
    if (
      newFiles.length <= 5 &&
      selectedFiles.every((file) => file.size <= 20 * 1024 * 1024)
    ) {
      setFiles(newFiles);
    } else {
      ApiResponseMessage( "Maximum 5 files allowed and each file must be less than or equal to 20MB.", DANGER
      );
    }
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <>
      {files.length < 5 && (
        <FormGroup className="col-12 mb-2 file-attchment">
          <Field
            name="file"
            id="file"
            type="file"
            multiple
            onChange={handleFileChange}
            accept="image/*"
          />
          <Label for="file">
            <PaperclipSvg />{" "}
            <span>
              Upload attachment<small>(Max 5 files or 20 MB allowed)</small>
            </span>
          </Label>
        </FormGroup>
      )}
      <ul className="col-12 uploaded-attachment">
        {files.map((file, index) => (
          <li key={index}>
            <Button
              color="link"
              className="text-danger"
              onClick={() => handleRemoveFile(index)}
            >
              <DeleteSvg />
            </Button>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default FileAttachment;
