import React from "react";
import { Card, CardBody, Button } from "reactstrap";
import { DeleteSvg, EyeSvg, FileIconSvg, ImageSvg } from "../../../components/CommonSvg";
import { pdfUploadHelper } from "../../../helper/pdfUploadHelper";
import { useRef } from "react";
import { useState } from "react";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { useEffect } from "react";
import { getFileNameFromURL, isValidHttpUrl } from "../../../utils/generalUtils";
import { __t } from "../../../i18n/translator";

export default function PDFView({ dataValue }) {
  const { values, setFieldValue, validator } = dataValue;
  const fileInputRef = useRef(null);
  const [pdfFileData, setPdfFileData] = useState(null);
  const [pdfFile, setPdfFile] = useState();
  const clearFileInput = () => {
    fileInputRef.current.value = "";
  };
  useEffect(() => {
    if (values?.vendorLogo && values?.vendorId && isValidHttpUrl(values?.pdfAttachment)) {
      setPdfFileData({
        fileNames: getFileNameFromURL(values?.pdfAttachment),
        pdfUrl: values?.pdfAttachment,
      });
    }
  }, [values])
  useEffect(() => {
    setFieldValue("htmlFileUrl", "");
    setFieldValue("htmlBannerImg", "");
    setFieldValue("htmlTemplateDescription", "");
    setFieldValue("htmlFiles", []);
    
    setFieldValue("htmlTemplate", "false");
  }, []);
  return (
    <>
      <Card>
        <CardBody>
          <div className="dropzone dropzone_img vendors_dropzone">
            <label htmlFor="pdfAttachment" className="dropzone-container mb-2">
              <div className="file-icon">
                <ImageSvg />
              </div>
              <div className="dropzone-title text-center">
                Drag and drop your pdf file or{" "}
                <span className="browse">browse</span> your pdf file{" "}
                <small className="w-100 d-inline-block text-center">
                  Accepted file types : PDF{" "}
                </small>
                {/* <small className="w-100 d-inline-block text-center">
                  File size upto 1 MB (Min width: 1280px){" "}
                </small> */}
              </div>
              <input
                id="pdfAttachment"
                name="pdfAttachment"
                type="file"
                className="form-control"
                accept=".pdf"
                ref={fileInputRef}
                onChange={(e) =>
                  pdfUploadHelper(
                    setPdfFile,
                    setFieldValue,
                    "pdfAttachment",
                    setPdfFileData,
                    e
                  )
                }
              />
           
            </label>
            {validator.current.message(
              __t("validations.vendor.pdf"),
              values.pdfAttachment,
              "required",
              {
                messages: {
                  required: __t("validations.vendor.pdf"),
                },
              }
            )}
          </div>
          {pdfFileData !== null && pdfFileData !== undefined && (
            <div className="attachment-data-list  mt-2 mb-2">
              <div className="attachment-data">
                <a
                  href={pdfFileData?.pdfUrl}
                  target="_blank"
                  title={pdfFileData?.fileNames}
                  className="file-box"
                >
                  <FileIconSvg />
                  <h6>{pdfFileData?.fileNames}</h6>
                </a>
                <a
                  href={pdfFileData?.pdfUrl}
                  className="vieweye-box"
                  title="view"
                  target="_blank"
                >
                  <EyeSvg />
                </a>
              </div>
              <a
                onClick={async () => {
                  const response = await commonConfirmBox(
                    "Do you want to remove this document?"
                  );
                  if (response) {
                    setPdfFileData(null);
                    setFieldValue("pdfAttachment", null);
                    setPdfFile(null);
                    clearFileInput();

                  }
                }}
                className="remove-box"
                title="remove"
              >
                <DeleteSvg />
              </a>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <Button type="submit" color="primary">
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
