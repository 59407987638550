import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_MULTI_STORE_REQUEST,
  CREATE_UPLOAD_AGREEMENT_REQUEST,
  GET_MEMBER_DETAILS_REQUEST,
  GET_MEMBER_HRA_LIST_REQUEST,
  GET_MEMBER_LIST_REQUEST,
  GET_MULTI_STORE_DETAILS_REQUEST,
  GET_PROFILE_VENDOR_REQUEST,
  GET_SUB_MEMBER_HRA_LIST_REQUEST,
  MEMBER_DATA_EXPORT_REQUEST,
  ROMOVE_STORE_GROUP_REQUEST,
  SYNC_VENDOR_REQUEST,
  ZOHO_STORE_LIST_REQUEST,
} from "./type";
import {
  createMultiStoreSuccess,
  createUploadAgreementSuccess,
  getMemberDetailsSuccess,
  getMemberHraListSuccess,
  getMemberListRequest,
  getMemberListSuccess,
  getMultiStoreDetailsSuccess,
  getProfileVendorSuccess,
  getSubMemberHraListSuccess,
  memberDataExportSuccess,
  removeStoreGroupSuccess,
  syncVendorSuccess,
  zohoStoreListSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* MemberList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/membersList", data);
    if (response.status) {
      yield put(getMemberListSuccess(response.data));
    } else {
      yield put(getMemberListSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* memberDataExport() {
  try {
    const response = yield call(
      invokeApi,
      "get",
      "/memberDataExport",
      "arraybuffer"
    );
    if (response.status) {
      yield put(memberDataExportSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      window.open(response.data.url);
    } else {
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* getMemberHraList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/memberHraList", data);
    if (response.status) {
      yield put(getMemberHraListSuccess(response.data));
    } else {
      yield put(getMemberHraListSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getSubHraList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/memberHraSubList", data);
    if (response.status) {
      yield put(getSubMemberHraListSuccess(response.data));
    } else {
      yield put(getSubMemberHraListSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* createMultiStore({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/multistore", data);
    if (response.status) {
      yield put(createMultiStoreSuccess(response.status));
    } else {
      yield put(createMultiStoreSuccess(response.status));

      handleApiCallException(response.message);
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* geMultiStoreDetails({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/multiStoreDetails", data);
    if (response.status) {
      yield put(getMultiStoreDetailsSuccess(response.data));
    } else {
      yield put(getMultiStoreDetailsSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* getMemberDetails({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/memberDetails", data);
    if (response.status) {
      yield put(getMemberDetailsSuccess(response.data.data));
    } else {
      yield put(getMemberDetailsSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* createUploadAgreement({ payload: data }) {
  try {
    const headers = { ContentType: "multipart/form-data" };
    const response = yield call(
      invokeApi,
      "post",
      "/uploadAgreement",
      data,
      headers
    );
    if (response.status) {
      yield put(createUploadAgreementSuccess(response.status));
    } else {
      yield put(createUploadAgreementSuccess(response.status));

      handleApiCallException(response.message);
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* zohoStoreList() {
  try {
    const response = yield call(invokeApi, "post", "/zohoStoreList", {
      event: "start scheduler",
    });
    if (response.status) {
      yield put(zohoStoreListSuccess(response));
    } else {
      yield put(zohoStoreListSuccess(response.status));
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* removeStoreGroup({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/statusMultistore",
      data.value
    );
    if (response.status) {
      yield put(removeStoreGroupSuccess(response));
      yield put(getMemberListRequest(data.dispatchData));
    } else {
      yield put(removeStoreGroupSuccess(response.status));
      handleApiCallException(response.message);
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* zohoVendor({ }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/syncVendor"
    );
    if (response.status) {
      yield put(syncVendorSuccess(response));
      handleApiCallException(response.message);
    } 
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* proVendor({ }) {
  try {
    const response = yield call(
      invokeApi,
      "get",
      "/vendorProfile"
    );
    if (response.status) {
      yield put(getProfileVendorSuccess(response.data));
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(getProfileVendorSuccess());
    handleApiCallException(error);
  }
}
function* watchMembersRequest() {
  yield takeLatest(GET_MEMBER_LIST_REQUEST, MemberList);
  yield takeLatest(MEMBER_DATA_EXPORT_REQUEST, memberDataExport);
  yield takeLatest(GET_MEMBER_HRA_LIST_REQUEST, getMemberHraList);
  yield takeLatest(GET_SUB_MEMBER_HRA_LIST_REQUEST, getSubHraList);
  yield takeLatest(CREATE_MULTI_STORE_REQUEST, createMultiStore);
  yield takeLatest(GET_MULTI_STORE_DETAILS_REQUEST, geMultiStoreDetails);
  yield takeLatest(GET_MEMBER_DETAILS_REQUEST, getMemberDetails);
  yield takeLatest(CREATE_UPLOAD_AGREEMENT_REQUEST, createUploadAgreement);
  yield takeLatest(ZOHO_STORE_LIST_REQUEST, zohoStoreList);
  yield takeLatest(ROMOVE_STORE_GROUP_REQUEST, removeStoreGroup);
  yield takeLatest(SYNC_VENDOR_REQUEST, zohoVendor);
  yield takeLatest(GET_PROFILE_VENDOR_REQUEST, proVendor);
}

function* MembersSaga() {
  yield all([fork(watchMembersRequest)]);
}

export default MembersSaga;
