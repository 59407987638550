import React from "react";
import { Badge, Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { generateChartMix, generatechartRadialbar } from "./ReactChartDetails";
import {
  createDashboardMenuRequest,
  getDashboardMenuRequest,
  getDashboardRequest,
} from "../../../redux/admin/dashboard/action";
import {
  AddSvg,
  DeleteSvg,
  EditSvg,
  MembersSvg,
  PromotionsOptINSvg,
  PromotionsSvg,
  ThumbnailSvg,
} from "../../../components/CommonSvg";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import dashboardButton from "../../../assets/images/vendor_partners.webp";
import AddMenu from "./MenuModal";
import { getValueByKey } from "../../../utils/authUtils";

const Home = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(!showModal);
  };
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const { counts } = props;
  const getPermissions = useOutletContext();
  const domContainer = document.querySelector("#app");
  const [chartRadialbarData, setChartRadialbarData] = useState([]);
  const [chartMixData, setChartMixData] = useState([]);
  // ReactDOM.render(React.createElement(ApexChart), domContainer);
  // //const permissions = getPermissions ? getPermissions[0] : 0
  const roleId = JSON.parse(getValueByKey("roleId")) === "1";

  const navigate = useNavigate();
  useEffect(() => {
    props?.getDashboardList();
    if (roleId) {
      props?.getDashboardMenu();
    } else {
      props?.getDashboardMenu({ isActive: true });
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.chartInquiryCount) {
        setChartRadialbarData(generatechartRadialbar(props.chartInquiryCount));
      }

      if (props.chartColumnsData) {
        setChartMixData(generateChartMix(props.chartColumnsData));
      }
    }, []);

    return () => clearTimeout(timeout);
  }, [props.chartColumnsData, props.chartQueriesCount]);

  useEffect(() => {
    setSeries([
      {
        name: "Promotion",
        data: props.graph2.map((x) => x.count),
      },
      {
        name: "Inquiry",
        data: props.graph1.map((x) => x.count),
      },
    ]);
    setOptions({
      chart: {
        toolbar: {
          show: false,
        },
        height: "150px",
        type: "area",
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      xaxis: {
        type: "category",
        show: true,
        categories: props.graph1.map((x) => x.month),
        labels: {
          show: true,
          align: "left",
          colors: ["#fff"],
          style: {
            fontFamily: "Roboto, sans-serif",
            colors: "#fff",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: ["#fff"],
            fontFamily: "Roboto, sans-serif",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#00A7B5", "#9bd732"],
      legend: {
        show: true,
        labels: {
          colors: ["#fff", "#fff"],
        },
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    });
  }, [props.graph1]);

  return (
    <>
      <div className="container">
        <div className="dashboard">
          <Row>
            <Col lg="12" className="dashboard_count_main">
              <Link className="dashboard_count">
                <h4>
                  <small>Total Active Promotions</small>
                  {counts.totalPromotions}
                </h4>
                <span className="bg-primary">
                  <PromotionsSvg />
                </span>
              </Link>
              <Link className="dashboard_count">
                <h4>
                  <small>Total Active Opt-In Promotions</small>
                  {counts.totalOptInPromotions}
                </h4>
                <span className="bg-orange">
                  <PromotionsOptINSvg />
                </span>
              </Link>
              <Link className="dashboard_count">
                <h4>
                  <small>Total Members</small>
                  {counts.members}
                </h4>
                <span className="bg-info">
                  <MembersSvg />
                </span>
              </Link>
              {/* <Link className="dashboard_count">
              <h4>
                <small>Total Store Group</small>
                {counts.storeGroup}
              </h4>
              <span className="bg-orange">
                <svg
                  width="42"
                  height="49"
                  viewBox="0 0 42 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="37.0588"
                    height="45"
                    rx="2"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <path
                    d="M10 10.5686H25.8824"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 20H25.8824"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 29H20.5882"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 38H15.4902"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M29.9536 37.2477C29.5858 37.2477 29.2757 37.1146 29.0232 36.8485C28.77 36.5825 28.6588 36.2694 28.6894 35.9094C28.7354 35.252 28.8886 34.6808 29.1491 34.1955C29.4096 33.7103 29.8233 33.2095 30.3903 32.693C31.0185 32.1295 31.4972 31.6402 31.8263 31.2251C32.1561 30.8107 32.3209 30.3374 32.3209 29.8052C32.3209 29.1635 32.1104 28.6313 31.6893 28.2087C31.2677 27.7861 30.6814 27.5748 29.9306 27.5748C29.4402 27.5748 29.0228 27.6803 28.6784 27.8913C28.3333 28.1029 28.0535 28.3652 27.839 28.6783C27.6245 28.9913 27.3603 29.2026 27.0465 29.3122C26.7321 29.4217 26.4293 29.4139 26.1382 29.2887C25.7857 29.1322 25.5446 28.8739 25.4146 28.5139C25.2841 28.1539 25.3107 27.8174 25.4946 27.5044C25.939 26.8 26.5366 26.2131 27.2874 25.7435C28.0382 25.274 28.9193 25.0392 29.9306 25.0392C31.5395 25.0392 32.777 25.4972 33.643 26.4131C34.5084 27.3284 34.9412 28.4278 34.9412 29.7113C34.9412 30.4939 34.7766 31.1632 34.4475 31.7191C34.1177 32.2744 33.6004 32.9043 32.8956 33.6086C32.3746 34.1095 32.0068 34.5164 31.7923 34.8294C31.5778 35.1425 31.4322 35.5025 31.3556 35.9094C31.2943 36.3007 31.1334 36.6216 30.8729 36.872C30.6124 37.1224 30.306 37.2477 29.9536 37.2477ZM29.9306 42.8823C29.4249 42.8823 28.9922 42.6986 28.6324 42.3311C28.272 41.9629 28.0918 41.5206 28.0918 41.0041C28.0918 40.4876 28.272 40.0453 28.6324 39.6771C28.9922 39.3096 29.4249 39.1259 29.9306 39.1259C30.4362 39.1259 30.8693 39.3096 31.2296 39.6771C31.5894 40.0453 31.7693 40.4876 31.7693 41.0041C31.7693 41.5206 31.5894 41.9629 31.2296 42.3311C30.8693 42.6986 30.4362 42.8823 29.9306 42.8823Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </Link> */}
            </Col>

            {/* <Col lg="4">
            <div id="chart">
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height="200"
              />
            </div>
          </Col> */}
          </Row>
          {/* <img alt="Dashboard charts" src={dashboard} /> */}
        </div>

        {/* <Row className="dashboard-setting">
        <Col lg={12}>
          
          <Link className="dashboard_count">
              <h4>
                <small>Total Active Promotions</small>
                {counts.totalPromotions}
              </h4>
              <span className="bg-primary">
                <PromotionsSvg />
              </span>
            </Link>
          
        </Col>
      </Row> */}

        {roleId && <div className="header-action-item d-flex justify-content-end gap-2 col-lg-12 mt-3">
          <button
            onClick={handleShowModal}
            className="creat_btn btn btn-secondary"
          >
            <AddSvg />
            Add New
          </button>
        </div>}

        <Row className="mt-3 row-gap-3">
          {props.dashboardMenulist?.length > 0 ? (
            props.dashboardMenulist.map((x) => {
              return (
                <>
                  <Col
                    xl="4"
                    className={x.isActive ? "col-xxl-3" : "col-xxl-3 deactive"}
                  >
                    <div className="dashboard-main-inner">
                      <Link
                        className="dashboard-inner-part"
                        to={x.menuLink}
                        target={
                          x.menuLink.startsWith("http://") ||
                            x.menuLink.startsWith("https://")
                            ? "_blank"
                            : "_self"
                        }
                      >
                        <div className="promotion_img dashboard-image">
                          <img
                            alt="Logo"
                            className="logo_main dashboard-image-inner"
                            src={x.menuImage}
                          />
                        </div>
                        <h5 className="mb-0">{x.label}</h5>
                        {x.isActive === false && <Badge className="dashboard-deactive" color="danger">Deactive</Badge>}
                      </Link>

                      {roleId && <div className="edit-delete-icon">
                        <button
                          title="Edit"
                          class="view_more edit-hover-icon"
                          onClick={() => {
                            setShowModal(true);
                            setIsEditModal(x);
                          }}
                        >
                          <EditSvg />
                        </button>
                      </div>}
                    </div>
                  </Col>
                </>
              );
            })
          ) : (
            <h4> No Data Found</h4>
          )}
        </Row>

        {/* <Row>
        <Col lg="12" className="dashboard_part_main mt-4">
          <Link className="dashboard_part">
            <h4>
              <small>Total Active Promotions</small>
              {counts.totalPromotions}
            </h4>
            <span className="bg-primary">
              <PromotionsSvg />
            </span>
          </Link>
          <Link className="dashboard_part">
            <h4>
              <small>Total Active Opt-In  Promotions</small>
              {counts.totalOptInPromotions}
            </h4>
            <span className="bg-orange">
              <PromotionsOptINSvg />
            </span>
          </Link>
          <Link className="dashboard_part">
            <h4>
              <small>Total Members</small>
              {counts.members}
            </h4>
            <span className="bg-info">
              <MembersSvg />
            </span>
          </Link> */}

        {/* <Link className="dashboard_count">
              <h4>
                <small>Total Store Group</small>
                {counts.storeGroup}
              </h4>
              <span className="bg-orange">
                <svg
                  width="42"
                  height="49"
                  viewBox="0 0 42 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="37.0588"
                    height="45"
                    rx="2"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <path
                    d="M10 10.5686H25.8824"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 20H25.8824"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 29H20.5882"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 38H15.4902"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M29.9536 37.2477C29.5858 37.2477 29.2757 37.1146 29.0232 36.8485C28.77 36.5825 28.6588 36.2694 28.6894 35.9094C28.7354 35.252 28.8886 34.6808 29.1491 34.1955C29.4096 33.7103 29.8233 33.2095 30.3903 32.693C31.0185 32.1295 31.4972 31.6402 31.8263 31.2251C32.1561 30.8107 32.3209 30.3374 32.3209 29.8052C32.3209 29.1635 32.1104 28.6313 31.6893 28.2087C31.2677 27.7861 30.6814 27.5748 29.9306 27.5748C29.4402 27.5748 29.0228 27.6803 28.6784 27.8913C28.3333 28.1029 28.0535 28.3652 27.839 28.6783C27.6245 28.9913 27.3603 29.2026 27.0465 29.3122C26.7321 29.4217 26.4293 29.4139 26.1382 29.2887C25.7857 29.1322 25.5446 28.8739 25.4146 28.5139C25.2841 28.1539 25.3107 27.8174 25.4946 27.5044C25.939 26.8 26.5366 26.2131 27.2874 25.7435C28.0382 25.274 28.9193 25.0392 29.9306 25.0392C31.5395 25.0392 32.777 25.4972 33.643 26.4131C34.5084 27.3284 34.9412 28.4278 34.9412 29.7113C34.9412 30.4939 34.7766 31.1632 34.4475 31.7191C34.1177 32.2744 33.6004 32.9043 32.8956 33.6086C32.3746 34.1095 32.0068 34.5164 31.7923 34.8294C31.5778 35.1425 31.4322 35.5025 31.3556 35.9094C31.2943 36.3007 31.1334 36.6216 30.8729 36.872C30.6124 37.1224 30.306 37.2477 29.9536 37.2477ZM29.9306 42.8823C29.4249 42.8823 28.9922 42.6986 28.6324 42.3311C28.272 41.9629 28.0918 41.5206 28.0918 41.0041C28.0918 40.4876 28.272 40.0453 28.6324 39.6771C28.9922 39.3096 29.4249 39.1259 29.9306 39.1259C30.4362 39.1259 30.8693 39.3096 31.2296 39.6771C31.5894 40.0453 31.7693 40.4876 31.7693 41.0041C31.7693 41.5206 31.5894 41.9629 31.2296 42.3311C30.8693 42.6986 30.4362 42.8823 29.9306 42.8823Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </Link> */}
        {/* </Col>
      </Row> */}

        {/* <Row className="dashboard-charts mt-4">
        <Col lg={12}>
          <Card className="mb-0 h-100">
            <h5>Promotion and Opt-in </h5>
            {chartMixData?.series?.length > 0 && (
              <ReactApexChart
                options={chartMixData.options}
                series={chartMixData.series}
                type="line"
                height="350"
              />
            )}
          </Card>
        </Col> */}

        {/* <Col lg={4}>
          <Card className="mb-0 h-100 ">
            <h5>Inquiry</h5>

            {chartRadialbarData?.series &&
              chartRadialbarData?.series?.length > 0 && (
                <ReactApexChart
                  options={chartRadialbarData?.options}
                  series={chartRadialbarData?.series}
                  type="radialBar"
                  height="375"
                />
              )}
          </Card>
        </Col> */}
        {/* </Row> */}
        {showModal && (
          <CustomisedModal
            modalView={showModal}
            customClass={"category-modal modal-lg inquiry_view_modal"}
            modalName={
              isEditModal?.id
                ? "Edit dashboard configuration"
                : "Add dashboard configuration"
            }
            onModalDismiss={() => {
              setShowModal(false);
              setIsEditModal(false)
            }}
          >
            <AddMenu
              isEditModal={isEditModal}
              onModalDismiss={() => {
                setShowModal(false);
                setIsEditModal(false)
              }}
            />
          </CustomisedModal>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ dashboardReducer }) => {
  let counts = [];
  let graph1 = [];
  let graph2 = [];
  let chartColumnsData = [];
  let chartInquiryCount = null;
  let dashboardMenulist = [];
  if (dashboardReducer?.DashboardList?.list?.length > 0) {
    dashboardMenulist = dashboardReducer.DashboardList.list;
  }
  if (dashboardReducer.DashboardData) {
    counts = dashboardReducer?.DashboardData?.counts;

    // *DashBoard Charts column , area , line
    chartColumnsData = dashboardReducer?.DashboardData?.graphs;
    chartInquiryCount = dashboardReducer?.DashboardData?.roundGraphs;

    if (
      dashboardReducer?.DashboardData?.charts?.inquiryLastSixMonth &&
      dashboardReducer?.DashboardData?.charts?.promotionLastSixMonth
    ) {
      graph1 = dashboardReducer?.DashboardData?.charts?.inquiryLastSixMonth;
      graph2 = dashboardReducer?.DashboardData?.charts?.promotionLastSixMonth;
    }
  }
  return {
    dashboardMenulist: dashboardMenulist,
    // React Chart Columns Counts
    chartColumnsData: chartColumnsData,
    chartInquiryCount: chartInquiryCount,
    counts: counts,
    graph1: graph1,
    graph2: graph2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardList: () => {
      dispatch(getDashboardRequest());
    },
    getDashboardMenu: (data) => {
      dispatch(getDashboardMenuRequest(data));
    },
    createDashboardMenu: (data) => {
      dispatch(createDashboardMenuRequest());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
