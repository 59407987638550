import React from "react";
import CommonTable from "./TableView/CommonTable";
import CommonPagination from "./TableView/CommonPagination";
import TableHeader from "./TableView/TableHeader";
import ChildTableView from "./TableView/ChildTableView";

export default function RebateView({
    data,
    columns,
    buttonName,
    buttonNameModal,
    setSearchData,
    dataPerPage,
    addModalData,
    setSelectedStatus,
    totalRecords,
    Header,
    pagination,
    currentPage,
    searchBar,
    updateData,
    hedingName,
    responsiveClass,
    exportData,
    exportDataHandel,
    setExportFilterData,
    setCurrentPage,
    Filter,
    linkUrl,
    handleScroll,
    setCountyId,
    filterType,
    setStateId,
    setDataPerPage,
    setRoleId,
    setStartDate,
    setEndDate,
    rowClassName,
    setCityId, searchData, cityId,
    endDate,
    startDate,
    promotionModalButton,
    promotionModal,
    selectedStatus, setSelectedClass,defaultSorted,
    selectedClass, selectedYear, setSelectedYear, selectedQuater, setSelectedQuater, tableId, rowEvents,expandRow
}) {
    return (

        <>
            <div className="container">
                {Header && (
                    <TableHeader
                        promotionModal={promotionModal}
                        promotionModalButton={promotionModalButton}
                        exportData={exportData}
                        setRoleId={setRoleId}
                        Filter={Filter}
                        updateData={updateData}
                        setSelectedStatus={setSelectedStatus}
                        selectedStatus={selectedStatus}
                        hedingName={hedingName}
                        setCurrentPage={setCurrentPage}
                        linkUrl={linkUrl}
                        buttonName={buttonName}
                        buttonNameModal={buttonNameModal}
                        cityId={cityId}
                        addModalData={addModalData}
                        searchBar={searchBar}
                        filterType={filterType}
                        currentPage={currentPage}
                        setSearchData={setSearchData}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setCityId={setCityId}
                        setCountyId={setCountyId}
                        setExportFilterData={setExportFilterData}
                        setStateId={setStateId}
                        endDate={endDate}
                        exportDataHandel={exportDataHandel}
                        startDate={startDate}
                        selectedClass={selectedClass}
                        setSelectedClass={setSelectedClass}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                        selectedQuater={selectedQuater}
                        setSelectedQuater={setSelectedQuater}
                        searchData={searchData}
                        data={data}
                        
                    />
                )}
                <ChildTableView
                    data={data}
                    handleScroll={handleScroll}
                    responsiveClass={responsiveClass}
                    columns={columns}
                    rowClassName={rowClassName}
                    tableId={tableId}
                    rowEvents={rowEvents}
                    expandRow={expandRow}
                    defaultSorted={defaultSorted}
                />
                {pagination &&
                    (totalRecords > 0 ? (
                        <CommonPagination
                            dataPerPage={dataPerPage}
                            totalData={totalRecords}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setDataPerPage={setDataPerPage}
                        />
                    ) : (
                        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
                    ))}
            </div>
        </>
    );
}
