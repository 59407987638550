import * as type from "./type";

export const getRoleListRequest = () => {
  return {
    type: type.GET_ROLE_LIST_REQUEST
  };
};
export const getRoleListSuccess = (data) => {
  return {
    type: type.GET_ROLE_LIST_SUCCESS,
    payload: data
  };
};
export const getDefaultPathRequest = (data) => {
  return {
    type: type.GET_DEFALT_PATH_REQUEST,
    payload: data

  };
};
export const getDefaultPathSuccess = (data) => {
  return {
    type: type.GET_DEFALT_PATH_SUCCESS,
    payload: data
  };
};
