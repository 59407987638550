import * as type from "./type";
import produce from "immer";

let promotionList = [];
let promotionListMember = [];

const initialState = {
  memberPromotionDetails: null,
  memberPromotion: [],
  latestPromotion: null,
  memberPromotionScrollData: [],
};

const MemberPromotionReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case type.GET_MEMBER_PROMOTION_VIEW_DETAILS_REQUEST:
        draft.memberPromotionDetails = null;
        break;
      case type.GET_MEMBER_PROMOTION_VIEW_DETAILS_SUCCESS:
        draft.memberPromotionDetails = action.payload;
        break;
      case type.GET_MEMBER_PROMOTION_LIST_SUCCESS:
        if (!promotionListMember.includes(action?.payload?.offset)) {
          promotionListMember.push(action?.payload?.offset);
          draft.memberPromotion = draft.memberPromotion?.concat(
            action.payload?.data?.data?.list
          );
        }
        break;
      case type.GET_LATEST_PROMOTION_LIST_REQUEST:
        draft.latestPromotion = null;
        break;
      case type.GET_LATEST_PROMOTION_LIST_SUCCESS:
        draft.latestPromotion = action.payload;
        break;
      case type.GET_MEMBER_PROMOTION_LIST_ON_SCROLL_SUCCESS: {
        if (!promotionList.includes(action?.payload?.offset)) {
          promotionList.push(action?.payload?.offset);
          draft.memberPromotionScrollData = draft.memberPromotionScrollData?.concat(action?.payload?.data);
        }
        break;
      }
      case type.CLEAR_REPONSE: {
        draft.memberPromotionScrollData = [];
        promotionList = [];
        promotionListMember = [];
        draft.memberPromotion = [];
        break;
      }
      default:
        break;
    }
  });
};

export default MemberPromotionReducer;
