import * as type from "./type";

const initialState = {
    getInquiry: null,
    changeStatus: null,
    deleteInquiry: null,
};

const InquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_INQUIRY_REQUEST:
            return { ...state, getInquiry: null };
        case type.GET_INQUIRY_SUCCESS:
            return { ...state, getInquiry: action.payload };
        case type.DELETE_INQUIRY_REQUEST:
            return { ...state, deleteInquiry: null };
        case type.DELETE_INQUIRY_SUCCESS:
            return { ...state, deleteInquiry: action.payload };
        case type.STATUS_CHANGE_REQUEST:
            return { ...state, changeStatus: null };
        case type.STATUS_CHANGE_SUCCESS:
            return { ...state, changeStatus: action.payload };
        default:
            return state;
    }
};

export default InquiryReducer;
