import * as type from "./type";

export const getSupportTicketRequest = (data) => {
    return {
        type: type.GET_SUPPORT_TICKET_REQUEST,
        payload: data
    };
};
export const  getSupportTicketSuccess = (data) => {
    return {
        type: type.GET_SUPPORT_TICKET_SUCCESS,
        payload: data
    };
};
export const setColumesRequest = (data) => {
    return {
        type: type.SET_COLUMNS_REQUEST,
        payload: data
    };
};
export const setColumesSuccess = (data) => {
    return {
        type: type.SET_COLUMNS_SUCCESS,
        payload: data
    };
};
export const getColumesRequest = (data) => {
    return {
        type: type.GET_COLUMNS_REQUEST,
        payload: data
    };
};
export const getColumesSuccess = (data) => {
    return {
        type: type.GET_COLUMNS_SUCCESS,
        payload: data
    };
};
export const syncSupportTicketsRequest = (data) => {
    return {
        type: type.SYNC_SUPPORT_TICKET_REQUEST,
        payload: data
    };
};
export const syncSupportTicketsSuccess = (data) => {
    return {
        type: type.SYNC_SUPPORT_TICKET_SUCCESS,
        payload: data
    };
};
export const getStatusRequest = (data) => {
    return {
        type: type.GET_STATUS_REQUEST,
        payload: data
    };
};
export const getStatusSuccess = (data) => {
    return {
        type: type.GET_STATUS_SUCCESS,
        payload: data
    };
};
export const updateStatusRequest = (data) => {
    return {
        type: type.UPDATE_STATUS_REQUEST,
        payload: data
    };
};
export const  updateStatusSuccess = (data) => {
    return {
        type: type.UPDATE_STATUS_SUCCESS,
        payload: data
    };
};