import React, { useEffect, useState } from "react";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { connect } from "react-redux";
import { getValueByKey } from "../../../utils/authUtils";
import Tickets from "./Tickets";

import {
  clearSupportReq,
  supportRequest,
} from "../../../redux/frontend/support/action";
import ServiceRequestModal from "./ServiceRequestModal";

const initialSupportValues = {
  subject: "",
  description: "",
};

function SupportPage(props) {
  const { SupportDetails, RepresentiveDetails } = props;
  const [modalView, setModaleView] = useState(false);
  const [initialValues] = useState(initialSupportValues);
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";
  const accountName = getValueByKey("accountName");
  const storeNameRegular = getValueByKey("storeNameRegular");
  const phoneNumber = getValueByKey("phoneNumber");
  const name =
    getValueByKey("firstName")?.replace(/"/g, "") +
    " " +
    getValueByKey("lastName")?.replace(/"/g, "");
  const email = getValueByKey("memberEmail")?.replace(/"/g, "");

  const memberData = {
    accountName: accountName,
    storeNameRegular: storeNameRegular,
    phoneNumber: phoneNumber,
    name: name,
    email: email,
  };

  const handleEditModalToggle = () => {
    setModaleView(!modalView);
  };

  useEffect(() => {
    props.getSupportRequest({ hraMemberId: hraMemberId });
  }, [hraMemberId]);

  return (
    <>
      <div className="container">
        <Tickets
          SupportDetails={SupportDetails}
          RepresentiveDetails={RepresentiveDetails}
          handleEditModalToggle={handleEditModalToggle}
        />
      </div>

      <CustomisedModal
        modalView={modalView}
        customClass={"category-modal support-request-modal"}
        modalName={
          <div className="ach_modal_header d-flex justify-content-between w-100">
            Service Request
            <span>{hraMemberId}</span>
          </div>
        }
        onModalDismiss={() => {
          setModaleView(false);
        }}
      >
        <ServiceRequestModal
          initialValues={initialValues}
          memberData={memberData}
          onModalDismiss={() => {
            setModaleView(false);
          }}
        />
      </CustomisedModal>
    </>
  );
}

const mapStateToProps = ({ support, supportData }) => {
  let Support = support?.ticketApiRepsonse?.status ? true : false;
  let SupportDetails =
    supportData?.support?.data?.details && supportData.support.data.details;
  let RepresentiveDetails =
    supportData?.support?.data?.representiveDetails &&
    supportData.support.data.representiveDetails;
  return {
    Support: Support,
    SupportDetails: SupportDetails,
    RepresentiveDetails: RepresentiveDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportRequest: (data) => dispatch(supportRequest(data)),
    clearSupportReq: (data) => dispatch(clearSupportReq(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);
