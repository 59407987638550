import { DANGER } from "../constant/commonConstants";
import { __t } from "../i18n/translator";
import { getFileNameFromURL } from "../utils/generalUtils";
import { ApiResponseMessage } from "./tosterHelper";

export const pdfUploadHelper = (setPdfFile, setFieldValue, id, setPdfFileData, e) => {
  const chosenFile = e.target.files[0];
  const isValidPDF = chosenFile?.type === 'application/pdf';
  if (!isValidPDF) {
    ApiResponseMessage('Invalid file. Please select only PDF files.', DANGER);
    return false;
  } else {
    if (chosenFile) {
      setPdfFile(chosenFile);
      setFieldValue(id, chosenFile);
      const pdfUrls = URL.createObjectURL(chosenFile);
      const fileNames = chosenFile.name;
      const pdfDataFile = {
        fileNames,
        pdfUrl: pdfUrls,
      };
      setPdfFileData(pdfDataFile);
    }
  }
};
