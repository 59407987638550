import * as type from "./type";

const initialState = {
  getOptInList: null,
  optStatusapiResponse: null,
};

const OptInReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_OPT_IN_REQUEST:
      return { ...state, getOptInList: null };
    case type.GET_OPT_IN_SUCCESS:
      return { ...state, getOptInList: action.payload };
    case type.GET_OPT_IN_LIST_REQUEST:
      return { ...state, getOptIns: null };
    case type.GET_OPT_IN_LIST_SUCCESS:
      return { ...state, getOptIns: action.payload };
    case type.GET_OPT_IN_STATUS_REQUEST:
      return { ...state, optStatusapiResponse: null };
    case type.GET_OPT_IN_STATUS_SUCCESS:
      return { ...state, optStatusapiResponse: action.payload };
    default:
      return { ...state };
  }
};

export default OptInReducer;
