import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { INQUIRY_REQUEST } from "./type";
import { inquirySuccess } from "./action";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { invokeApi } from "../../../helper/axiosHelper";

function* InquiryReq({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/inquiry", data);
        if (res.status) {
            yield put(inquirySuccess(res));
            ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
        } else {
            yield put(inquirySuccess(res));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* watchInquiryRequest() {
    yield takeLatest(INQUIRY_REQUEST, InquiryReq);
}

function* InquirySaga() {
    yield all([fork(watchInquiryRequest)]);
}

export default InquirySaga;
