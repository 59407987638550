import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button } from 'reactstrap'
import forgotbg from '../../../../assets/images/forgot_bg.jpg'
import logosm from '../../../../assets/images/logo-dark.webp'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { getForgotRequest } from '../../../../redux/admin/auth/forgot/action'
import { BackToLoginSvg } from '../../../../components/CommonSvg'
import { __t } from '../../../../i18n/translator'


const ForgotPassward = (props) => {
    const { ForgotApiRequest, loginReducer } = props
    const [RedirectState, setRedirectState] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const initialValues = {
        email: "",

    };
    const navigate = useNavigate()
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            ForgotApiRequest(values);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        setRedirectState(true)
        if (RedirectState === true) {
            navigate("/admin/login");
            setRedirectState(false)
        }
    }, [loginReducer?.status === true]);
    return (
        <div className="login-page">
            <Row>
                <Col className="login-img">
                    <img alt="Reset Password" src={forgotbg} />
                </Col>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {(props) => {
                        const { handleSubmit, values } = props

                        return (
                            <Col className="login-form">
                                <div className="login-main">
                                    <div className="loging-head">
                                        <img alt="Logo" src={logosm} />
                                    </div>

                                    <div className="loging-content">
                                        <h2>Reset Password</h2>
                                        <p>
                                            No worries,Please enter your registered email address. We
                                            will send you a link to reset your password.
                                        </p>
                                        <Form
                                            role={'form'}
                                            onSubmit={handleSubmit}
                                            className="form-horizontal form-login" 
                                        >
                                            <FormGroup className="col-12 sticky_label">
                                                <Field
                                                    id="email"
                                                    name="email"
                                                    placeholder="example@example.com"
                                                    className="form-control"
                                                    type="email"
                                                />

                                                <Label className="form-label" htmlFor="email">
                                                    Email Address
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.auth.email"),
                                                    values.email,

                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.auth.email"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                       
                                            <Col lg="12">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    block
                                                    className="w-100 waves-effect waves-light"
                                                >
                                                    Submit
                                                </Button>
                                            </Col>
                                            <Col lg="12">
                                                <Link
                                                    to="/admin/login"
                                                    className="pt-3 w-100 text-center backto_login"
                                                >
                                                    <BackToLoginSvg />
                                                    Back To Login
                                                </Link>
                                            </Col>
                                        </Form>
                                    </div>
                                    <div className="policy-links">
                                        <Link
                                            to="https://www.hraga.com/terms-of-service"
                                            className="d-inline-block"
                                            target="_blank"
                                            title="Terms of Service"
                                        >
                                            Terms of Service
                                        </Link>
                                        <Link
                                            to="https://www.hraga.com/mbr-app-privacy-policy"
                                            target="_blank"
                                            className="d-inline-block"
                                            title="Privacy Policy"
                                        >
                                            Privacy Policy
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        )
                    }}
                </Formik>
            </Row>
            <div className="login-footer">
                <p className="mb-0">
                    &#169; {new Date().getFullYear()} - All rights reserved.
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = ({ forgot }) => {
    let loginReducer
    if (forgot?.forgotUser && forgot?.forgotUser?.data) {
        loginReducer = forgot?.forgotUser
    }
    return {
        loginReducer: loginReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ForgotApiRequest: (creds) => {
            dispatch(getForgotRequest(creds));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassward)
