import * as actionTypes from "./type";

const initialState = {
  getClass: null,
  getYear: null,
  getQuarter: null,
  getRebateList: null,
};

const RebateManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLASS_REQUEST:
      return { ...state, getClass: null };
    case actionTypes.GET_CLASS_SUCCESS:
      return { ...state, getClass: action.payload };
    case actionTypes.GET_YEAR_REQUEST:
      return { ...state, getYear: null };
    case actionTypes.GET_YEAR_SUCCESS:
      return { ...state, getYear: action.payload };
    case actionTypes.GET_QUARTER_REQUEST:
      return { ...state, getQuarter: null };
    case actionTypes.GET_QUARTER_SUCCESS:
      return { ...state, getQuarter: action.payload };
    case actionTypes.GET_REBATES_REQUEST:
      return { ...state, getRebateList: null };
    case actionTypes.GET_REBATES_SUCCESS:
      return { ...state, getRebateList: action.payload };
    default:
      return state;
  }
};

export default RebateManageReducer;
