import { CREATE_DASHBOARD_MENU_REQUEST, CREATE_DASHBOARD_MENU_SUCCESS, DELETE_DASHBOARD_MENU_REQUEST, DELETE_DASHBOARD_MENU_SUCCESS, GET_DASHBOARD_MENU_REQUEST, GET_DASHBOARD_MENU_SUCCESS, GET_DASHBOARD_REQUEST, GET_DASHBOARD_SUCCESS } from "./type";

const initialState = {
    DashboardData: null,
    DashboardMenu: null,
    DashboardList: null
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_REQUEST:
            return {
                ...state,
                DashboardData: null,
            };
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                DashboardData: action.payload,
            };
        case CREATE_DASHBOARD_MENU_REQUEST:
            return {
                ...state,
                DashboardMenu: null,
            };
        case CREATE_DASHBOARD_MENU_SUCCESS:
            return {
                ...state,
                DashboardMenu: action.payload,
            };
        case GET_DASHBOARD_MENU_REQUEST:
            return {
                ...state,
                DashboardList: null,
            };
        case GET_DASHBOARD_MENU_SUCCESS:
            return {
                ...state,
                DashboardList: action.payload,
            };
        case DELETE_DASHBOARD_MENU_REQUEST:
            return {
                ...state,
                dashboardStaus: null,
            };
        case DELETE_DASHBOARD_MENU_SUCCESS:
            return {
                ...state,
                dashboardStaus: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
export default dashboardReducer