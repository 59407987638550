import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { SUPPORT_REQUEST } from "./type";
import { supportSuccess } from "./action";
import { DANGER } from "../../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { invokeApi } from "../../../helper/axiosHelper";

function* supportReq({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/supportDetails", data);
        if (res.status) {
            yield put(supportSuccess(res));
        } else {
            yield put(supportSuccess([]));
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* watchSupportRequest() {
    yield takeLatest(SUPPORT_REQUEST, supportReq);
}

function* SupportMemberSaga() {
    yield all([fork(watchSupportRequest)]);
}

export default SupportMemberSaga;
