import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ACTIVE_INACTIVE_PROMOTION_REQUEST,
  ADD_PROMOTION_LIST_REQUEST,
  ASSIGN_PROMOTION_REQUEST,
  DELETE_IMAGE_REQUEST,
  EDIT_PROMOTION_LIST_REQUEST,
  GET_PROMOTION_LIST_REQUEST,
  VIEW_PROMOTION_LIST_REQUEST,
} from "./type";
import {
  activeInactivePromotionSuccess,
  addPromotionListSuccess,
  assignPromotionSuccess,
  deleteImageSuccess,
  editPromotionListSuccess,
  getPromotionListRequest,
  getPromotionListSuccess,
  viewPromotionListSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* PromotionList({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/promotionalOfferList",
      data
    );
    if (response.status) {
      yield put(getPromotionListSuccess(response.data));
    } else {
      yield put(getPromotionListSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* ViewPromotion({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/promotionalOfferDetails`, { id: data });
    if (response.status) {
      yield put(viewPromotionListSuccess(response.data));
    } else {
      yield put(viewPromotionListSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* addPromotionList({ payload: data }) {
  const { formData, dispatchData } = data;
  try {
    const headers = { ContentType: "multipart/form-data" };
    const response = yield call(
      invokeApi,
      "post",
      "/promotionalOffer",
      formData,
      headers
    );
    if (response.status) {
      yield put(addPromotionListSuccess(response));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getPromotionListRequest(dispatchData));
    } else {
      yield put(addPromotionListSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      yield call(handleApiCallException, response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield call(handleApiCallException, error);
  }
}
function* editPromotionList({ payload: data }) {
  const { formData, dispatchData } = data;
  try {
    const headers = { ContentType: "multipart/form-data" };
    const response = yield call(
      invokeApi,
      "post",
      "/editpromotionalOffer",
      formData,
      headers
    );
    if (response.status) {
      ApiResponseMessage(response.message, SUCCESS);
      yield put(editPromotionListSuccess(response));
      yield put(getPromotionListRequest(dispatchData));
    } else {
      ApiResponseMessage(response.message, DANGER);
      yield put(editPromotionListSuccess(response));
      yield call(handleApiCallException, response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield call(handleApiCallException, error);
  }
}

function* activeInactivePromotion({ payload: data }) {
  const Promotional = {
    isActive: data.isActive,
    promotionalOfferId: data.userId,
  };
  try {
    const response = yield call(
      invokeApi,
      "PUT",
      "/promotionalOfferStatus",
      Promotional
    );
    if (response.status) {
      yield put(activeInactivePromotionSuccess(response));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getPromotionListRequest(data.dispatchData));
    } else {
      yield put(activeInactivePromotionSuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* assignPromotion({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/allocatePromotions",
      data.AssignedValues
    );
    if (response.status) {
      yield put(assignPromotionSuccess(response));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getPromotionListRequest(data.dispatchData));
    } else {
      yield put(assignPromotionSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* deleteImage({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/removePromotionDocument", data);
    if (response.status) {
      yield put(deleteImageSuccess(response));
    } else {
      yield put(deleteImageSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}


function* watchLoginRequest() {
  yield takeLatest(GET_PROMOTION_LIST_REQUEST, PromotionList);
  yield takeLatest(VIEW_PROMOTION_LIST_REQUEST, ViewPromotion);
  yield takeLatest(ADD_PROMOTION_LIST_REQUEST, addPromotionList);
  yield takeLatest(EDIT_PROMOTION_LIST_REQUEST, editPromotionList);
  yield takeLatest(ACTIVE_INACTIVE_PROMOTION_REQUEST, activeInactivePromotion);
  yield takeLatest(ASSIGN_PROMOTION_REQUEST, assignPromotion);
  yield takeLatest(DELETE_IMAGE_REQUEST, deleteImage);
}

function* PromotionSaga() {
  yield all([fork(watchLoginRequest)]);
}

export default PromotionSaga;
