import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RebateChart = ({ data }) => {
    const totalAmount = data?.reduce((acc, d) => acc + parseFloat(d.Amount), 0) || 0;
    const chartData = {
        series: data?.map((d) => (parseFloat(d.Amount))) || [],
        options: {
            labels: ["Quarter-1", "Quarter-2", "Quarter-3", "Quarter-4"],
            dataLabels: {
                // formatter: function (val) {
                //     return val + "%"
                //   },
                textAnchor: 'middle',
                distributed: false,
                style: {
                    fontSize: '10px',
                    fontWeight: '300',
                },
                dropShadow: {
                    enabled: false,
                },
            },
            colors: ['#00255a', '#9BD732', '#00A7B5', '#C24300'],
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    size: 200,
                    donut: {
                        size: '65%',
                        stroke: 'transparent',
                        strokeWidth: 50,
                        labels: {
                            show: true,
                            background: '#000',
                            value: {
                                show: 'label',
                                color: '#000',
                                fontSize: '16px',
                                fontWeight: 800,
                            },
                            total: {
                                show: true,
                                showAlways: false,
                                label: 'Total',
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#000',
                                background: '#ddd',
                                lineHeight: 0.1,
                                formatter: function (w) {
                                    return ("$" + totalAmount.toFixed(2));
                                },
                            },
                        },
                    }
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '12px',
                fontWeight: 400,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                    offsetX: 0,
                    offsetY: 0,
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0,
                },
            },
        
            responsive: [
                {
                    breakpoint: 1300,
                    options: {
                        chart: {
                            width: 350,
                        },
                    },
                },
                {
                    breakpoint: 1024,
                    options: {
                        chart: {
                            width: 350,
                        },
                    },
                },
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 350,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        value: {
                                            fontSize: '14px',
                                        },
                                        total: {
                                            fontSize: '14px',
                                        },
                                    },
                                }
                            }
                        },
                    }
                },
                {
                    breakpoint: 450,
                    options: {
                        chart: {
                            width: 280,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        value: {
                                            fontSize: '13px',
                                        },
                                        total: {
                                            fontSize: '14px',
                                        },
                                    },
                                }
                            }
                        },
                        dataLabels: {
                            style: {
                                fontSize: '8px',
                            },
                        },
                    }
                },
                {
                    breakpoint: 375,
                    options: {
                        chart: {
                            width: 250,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        value: {
                                            fontSize: '12px',
                                        },
                                        total: {
                                            fontSize: '14px',
                                        },
                                    },
                                }
                            }
                        },
                        dataLabels: {
                            style: {
                                fontSize: '7px',
                            },
                        },
                    }
                },
        ]
            
        },
    };
    return (
        <div id="chart">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                labels={chartData.labels}
            />
        </div>
    );
};

export default RebateChart;
