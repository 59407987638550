import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { CREATE_DASHBOARD_MENU_REQUEST, DELETE_DASHBOARD_MENU_REQUEST, GET_DASHBOARD_MENU_REQUEST, GET_DASHBOARD_REQUEST } from "./type";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { handleApiCallException } from "../../shared/action";
import { createDashboardMenuSuccess, deleteDashboardMenuSuccess, getDashboardMenuRequest, getDashboardMenuSuccess, getDashboardSuccess } from "./action";
import { invokeApi } from "../../../helper/axiosHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";

function* getDashboard() {
    try {
        const response = yield call(invokeApi, "get", "/dashboard");
        if (response.status) {
            yield put(getDashboardSuccess(response.data));
        } else {
            yield put(getDashboardSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}

function* createDashboardMenu({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/createDashboardMenu", data);
        if (response.status) {
            yield put(createDashboardMenuSuccess(response));
            ApiResponseMessage(response.message, SUCCESS);
            yield put(getDashboardMenuRequest())
        } else {
            yield put(createDashboardMenuSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}

function* getDashboardMenu({payload: data}) {
    try {
        const response = yield call(invokeApi, "post", "/menuList",data);
        if (response.status) {
            yield put(getDashboardMenuSuccess(response.data));
        } else {
            yield put(getDashboardMenuSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}

function* deleteDashboardMenu({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/deleteMenu", data);
        if (response.status) {
            yield put(deleteDashboardMenuSuccess(response));
            ApiResponseMessage(response.message, SUCCESS);
            yield put(getDashboardMenuRequest())
        } else {
            yield put(deleteDashboardMenuSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}

function* watchDashboardRequest() {
    yield takeLatest(GET_DASHBOARD_REQUEST, getDashboard);
    yield takeLatest(CREATE_DASHBOARD_MENU_REQUEST, createDashboardMenu);
    yield takeLatest(GET_DASHBOARD_MENU_REQUEST, getDashboardMenu);
    yield takeLatest(DELETE_DASHBOARD_MENU_REQUEST, deleteDashboardMenu);
}

function* DashboardSaga() {
    yield all([fork(watchDashboardRequest)]);
}

export default DashboardSaga;
