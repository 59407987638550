import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_STORE_REBATE_REQUEST, REBATE_REQUEST } from "./type";
import { DANGER } from "../../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { invokeApi } from "../../../helper/axiosHelper";
import { getStoreRebatesSuccess, rebateSuccess } from "./action";
import { removeZohoToken } from "../../../utils/authUtils";

function* rebateReq({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/getRebateDetails", data);
        if (res.status) {
            yield put(rebateSuccess(res));
            removeZohoToken()
        } else {
            yield put(rebateSuccess([]));
            removeZohoToken()
        }
    } catch (error) {
        yield put(rebateSuccess([]));
        ApiResponseMessage(error.message, DANGER);
    }
}
function* getStoreRebates({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/zohoRebateDetails", data);
        if (res.status) {
            yield put(getStoreRebatesSuccess(res));
        } else {
            yield put(getStoreRebatesSuccess([]));
            ApiResponseMessage(res.message, DANGER);
            removeZohoToken()
        }
    } catch (error) {
        yield put(getStoreRebatesSuccess([]));
        removeZohoToken()
        ApiResponseMessage(error.message, DANGER);

    }
}

function* watchRebateRequest() {
    yield takeLatest(REBATE_REQUEST, rebateReq);
    yield takeLatest(GET_STORE_REBATE_REQUEST, getStoreRebates);
}

function* RebateSaga() {
    yield all([fork(watchRebateRequest)]);
}

export default RebateSaga;
