import React from "react";
import Autocomplete from "react-autocomplete";

const AutocompleteInput = ({
  items,
  onChange,
  value,
  onSelect,
  isOnBlur,
  placeholder,
  inputClassName,
}) => {
  return (
    <Autocomplete
      className="autocomplete"
      inputProps={{
        // onBlur: () => {
        //   if (items.length == 0) {
        //     isOnBlur();
        //   }
        // },
        placeholder: placeholder,
        className: inputClassName,
      }}
      getItemValue={(item) => item.value}
      items={items}
      value={value}
      renderItem={(item, isHighlighted) => (
        <div
          className={`suggestion ${isHighlighted ? "highlighted" : ""}`}
          style={{
            background: isHighlighted ? "lightgray" : "white",
          }}
        >
          {item.storeNameRegular} - {item.label} - {item.city}
        </div>
      )}
      onChange={(event, value) => onChange(value)}
      // selectOnBlur={(value) => console.log(value)}
      onSelect={(value) => {
        onSelect(value);
      }}
    />
  );
};

export default AutocompleteInput;
