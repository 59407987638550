import React from "react";
import { Badge, Col, Row } from "reactstrap";
import InquiryStatus from "./InquiryStatus";

export default function InquiryView({
    inquiryData,
    dispatchData,
    onModalDismiss,
}) {
    return (
        <div className="inqury_view">
            <div className="inqury_view_head">

                <InquiryStatus
                    row={inquiryData}
                    dispatchData={dispatchData}
                    onModalDismiss={onModalDismiss}
                />
                <span className="created_date">{inquiryData.createdDate}</span>
            </div>
            <Row>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Business Name</p>
                        <span>{inquiryData.businessName}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Name</p>
                        <span>{inquiryData.name}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Email Address</p>
                        <span>{inquiryData.email}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Mobile No.</p>
                        <span>{inquiryData.mobileNo}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Phone No.</p>
                        <span>{inquiryData.phoneNumber}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>State</p>
                        <span>{inquiryData.state}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>City</p>
                        <span>{inquiryData.city}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>County</p>
                        <span> {inquiryData.county}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Address</p>
                        <span>{inquiryData.address}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="inqury_view_field">
                        <p>Mailing Zip</p>
                        <span>{inquiryData.mailingZip} </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
