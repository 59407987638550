import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { CloseSvg } from "../../../components/CommonSvg";
import { DATE_FORMATER } from "../../../constant/commonConstants";
import Select from "react-select";
import {
    getCityListRequest,
    getCountyListRequest,
    getStateListRequest,
} from "../../../redux/admin/location/action";
import { connect } from "react-redux";
function MemberFilter(props) {
    const {
        startDate,
        setStartDate,
        endDate,
        CityList,
        StateList,
        CountyList,
        setEndDate, setExportFilterData,
        GetCountyList,
        handelShowFIlter,
        GetCityList,
        GetStateList,
        setCountyId,
        setCityId,
        setStateId,
    } = props;
    useEffect(() => {
        GetCityList();
        GetStateList();
        GetCountyList();
    }, []);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const cityOptions = CityList?.map((city) => ({
        value: city.id,
        label: city.cityName,
    }));
    const stateOptions = StateList?.map((city) => ({
        value: city.id,
        label: city.stateName,
    }));
    const countyOptions = CountyList?.map((city) => ({
        value: city.id,
        label: city.countyName,
    }));
    const handelClearAll = () => {
        setEndDate("");
        setStartDate("");
        setCityId("");
        setSelectedCity("");
        setSelectedState("");
        setSelectedCounty("");
        setCountyId("");
        setStateId("");
        setExportFilterData(false)
    };
    return (
        <>
            <Card className="filter_section">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>
                    <div className="d-flex justify-content-end pb-2">
                        <Button
                            className="clear_btn btn-primary"
                            style={{ color: "black" }}
                            onClick={handelClearAll}
                        >
                            Clear all
                        </Button>
                    </div>
                    <form className="hra-create">
                        <Row>

                            <Col className="no-floating-label">
                                <FormGroup>
                                    <Select
                                        type="text"
                                        id="cityId"
                                        name="cityId"
                                        className="form-control selectbox"
                                        menuPlacement="auto"
                                        options={cityOptions}
                                        value={selectedCity}
                                        onChange={(selectedOption) => {
                                            setCityId(selectedOption ? selectedOption.label : "");
                                            setSelectedCity(selectedOption);
                                            setExportFilterData(true)
                                        }}
                                        isClearable
                                    />
                                    <Label className="form-label" htmlFor="stateId">
                                        City
                                    </Label>
                                </FormGroup>

                                <Button
                                    className="my-tooltip cancel_filter"
                                    data-tooltip-content={"Close filter"}
                                    color="link"
                                    onClick={handelShowFIlter}
                                >
                                    <CloseSvg />
                                </Button>
                            </Col>
                            <Col className="no-floating-label">
                                <FormGroup>
                                    <Select
                                        type="text"
                                        id="stateId"
                                        name="stateId"
                                        className="form-control selectbox"
                                        menuPlacement="auto"
                                        options={stateOptions}
                                        value={selectedState}
                                        onChange={(selectedOption) => {
                                            setSelectedState(selectedOption);
                                            setExportFilterData(true)
                                            setStateId(selectedOption ? selectedOption.label : "");
                                        }}
                                        isClearable
                                    />
                                    <Label className="form-label" htmlFor="stateId">
                                        State
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col className="no-floating-label">
                                <FormGroup>
                                    <Select
                                        type="text"
                                        id="countyId"
                                        name="countyId"
                                        className="form-control selectbox"
                                        menuPlacement="auto"
                                        options={countyOptions}
                                        value={selectedCounty}
                                        onChange={(selectedOption) => {
                                            setSelectedCounty(selectedOption);
                                            setExportFilterData(true)
                                            setCountyId(selectedOption ? selectedOption.label : "");
                                        }}
                                        isClearable
                                    />
                                    <Label className="form-label" htmlFor="stateId">
                                        County
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </>
    );
}
const mapStateToProps = ({ location }) => {
    let CityList;
    let StateList;
    let CountyList;

    if (
        location?.getCity?.list?.length > 0 &&
        location?.getState?.list?.length > 0 &&
        location?.getCounty?.list?.length > 0
    ) {
        return {
            CityList: location.getCity.list,
            StateList: location.getState.list,
            CountyList: location.getCounty.list,
        };
    }
    return {
        CityList: CityList,
        StateList: StateList,
        CountyList: CountyList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetCityList: () => {
            dispatch(getCityListRequest());
        },
        GetStateList: () => {
            dispatch(getStateListRequest());
        },
        GetCountyList: () => {
            dispatch(getCountyListRequest());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberFilter);
