import * as type from "./type";

export const exportDataExportRequest = (data) => {
  return {
    type: type.EXPORT_DATA_EXPORT_REQUEST,
    payload: data,
  };
};
export const exportDataExportSuccess = (data) => {
  return {
    type: type.EXPORT_DATA_EXPORT_SUCCESS,
    payload: data,
  };
};
