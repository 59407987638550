import {
    INQUIRY_REQUEST,
    INQUIRY_SUCCESS,
} from "./type";

export const inquiryRequest = (data) => {
    return {
        type: INQUIRY_REQUEST,
        payload: data,
    };
};
export const inquirySuccess = (data) => {
    return {
        type: INQUIRY_SUCCESS,
        payload: data,
    }
};