import { CHANGE_STATUS_ACH_REQUEST, CHANGE_STATUS_ACH_SUCCESS, GET_ACH_LIST_REQUEST, GET_ACH_LIST_SUCCESS } from "./type";

const initialState = {
    getAchList: null,
    statusChange: null
};

const AchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACH_LIST_REQUEST:
            return { ...state, getAchList: null };
        case GET_ACH_LIST_SUCCESS:
            return { ...state, getAchList: action.payload };
        case CHANGE_STATUS_ACH_REQUEST:
            return { ...state, statusChange: null };
        case CHANGE_STATUS_ACH_SUCCESS:
            return { ...state, statusChange: action.payload };
        default:
            return state;
    }
};

export default AchReducer;