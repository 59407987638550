import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { EXPORT_DATA_EXPORT_REQUEST } from "./type";
import { exportDataExportSuccess } from "./action";
import { handleApiCallException } from "../shared/action";
import { ApiResponseMessage } from "../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../helper/axiosHelper";

function* exportDataExport(data) {
  try {
    const response = yield call(
      invokeApi,
      data?.payload?.type ? data?.payload?.type : "get",
      data?.payload?.url,
      data?.payload?.data ? data?.payload?.data : "arraybuffer"
    );
    if (response.status) {
      window.open(response.data.url);
      yield put(exportDataExportSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
    } else {
      ApiResponseMessage(response.message, DANGER);
      yield put(exportDataExportSuccess(response));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* watchExportData() {
  yield takeLatest(EXPORT_DATA_EXPORT_REQUEST, exportDataExport);
}

function* ExportDataSaga() {
  yield all([fork(watchExportData)]);
}

export default ExportDataSaga;
