import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { upadteVendorAccountDetailsRequest } from "../../../redux/frontend/profile/action";
import { getValueByKey } from "../../../utils/authUtils";
import { commonConfirmBox } from "../../../helper/messagehelpers";

function AddEditAccountInfo({
  setEditAccountNum,
  profileDetail,
  upadteVendorAccountDetailsRequest,
  vendorNumbers,
  vendorProfile,
}) {
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
  const handleSubmit = async (values) => {
    // console.log(values, "valuesvalues");
    const response = await commonConfirmBox(
      "Are you sure you want to update the vendor account number?"
    );
    if (response) {
      const updatedValues = {
        hraMemberId: hraMemberId,
        ...values,
      };
      upadteVendorAccountDetailsRequest(updatedValues);
      setEditAccountNum(false);
    }
  };
  const [vendorDetails, setVendorDetails] = useState([]);
  useEffect(() => {
    if (vendorProfile?.length > 0) {
      const updatedVendorDetails = vendorProfile.map((x, index) => {
        const { apiName, id } = x;
        let accNumber = vendorNumbers.find((item) => item.apiKey === apiName);

        if (!accNumber) {
          accNumber = {
            id: id,
            apiKey: apiName,
            accNumber: null,
          };
        } else {
          accNumber = {
            id: id,
            apiKey: apiName,
            accNumber: accNumber?.accNumber,
          };
        }
        return accNumber;
      });

      setVendorDetails(updatedVendorDetails);
    }
  }, [vendorProfile, vendorNumbers]);

  return (
    <div className="tab-card">
      <Formik
        initialValues={{
          vendorNumbers: vendorDetails,
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
            <Form>
              <div className="tab_content_head">
                <h4>Vendors Account Number</h4>

                <div className="d-flex gap-3">
                  <Button
                    color="secondary"
                    className="btn"
                    size="sm"
                    title="Edit"
                    type="button"
                    onClick={() => setEditAccountNum(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="btn"
                    color="primary"
                    title="Edit"
                  >
                    Save
                  </Button>
                </div>
              </div>
              <FieldArray name="vendorNumbers">
                {({ push, remove }) => (
                  <div className="profile-field-list row">
                    {values.vendorNumbers?.map((x, index) => {
                      const { accNumber, apiKey, id } = x;

                      return (
                        <div
                          key={id}
                          className="col-12 col-sm-12 col-md-6 col-xl-4"
                        >
                          <div className="hra-light-form-box">
                            <FormGroup>
                              <label>{apiKey.replace(/_/g, " ")} #</label>
                              <span>
                                <Field
                                  id={apiKey}
                                  placeholder={apiKey?.replace(/_/g, " ")}
                                  className="form-control"
                                  type="text"
                                  name={`vendorNumbers[${index}].accNumber`}
                                  value={accNumber ? accNumber : ""}
                                />
                              </span>
                            </FormGroup>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    upadteVendorAccountDetailsRequest: (data) =>
      dispatch(upadteVendorAccountDetailsRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEditAccountInfo);
