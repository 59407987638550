import React, { useEffect, useState } from "react";
import { BackSvg } from "../../../components/CommonSvg";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AccountInfo from "./AccountInfo";
import StoreDetails from "./StoreDetails";
import {
  profileDetailRequest,
  upadteProfileDetailRequest,
  upadteStoreDetailsRequest,
} from "../../../redux/frontend/profile/action";
import sliderImg1 from "../../../assets/images/logos/BodyArmor-530-min.png";
import sliderImg2 from "../../../assets/images/logos/Coca-Cola_logo_brand.png";
import sliderImg3 from "../../../assets/images/logos/Community_Coffee_Company_Logo.png";
import sliderImg4 from "../../../assets/images/logos/McLane_logo.png";
import sliderImg5 from "../../../assets/images/logos/Pepsi-Logo.png";
import sliderImg6 from "../../../assets/images/logos/Red-Bull-Logo.png";
import sliderImg7 from "../../../assets/images/logos/buff-530.png";
import sliderImg8 from "../../../assets/images/logos/celcius_logo.png";
import sliderImg9 from "../../../assets/images/logos/coremark_logo.png";
import sliderImg10 from "../../../assets/images/logos/eby-brown_logo.png";
import sliderImg11 from "../../../assets/images/logos/frito-lay-logo-517x309-1.png";
import sliderImg12 from "../../../assets/images/logos/icw_logo.png";
import sliderImg13 from "../../../assets/images/logos/monster-energy-logo-on-white-min.png";
import sliderImg14 from "../../../assets/images/logos/Gatorade-logo.png";
import sliderImg15 from "../../../assets/images/logos/hthackney_logo.png";
import { connect } from "react-redux";
import { getInitialsFirstCharechter } from "../../../utils/generalUtils";
import { getValueByKey } from "../../../utils/authUtils";
import Slider from "react-slick";

const ProfileView = ({
  profileDetailRequest,
  profileDetail,
  representiveDetails,
  upadteProfileDetailRequest,
  upadteStoreDetailsRequest,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) || "";
  const navigate = useNavigate();
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    profileDetailRequest({ hraMemberId: hraMemberId });
  }, []);
  const [active, setActive] = useState(true)
  const toggleaddclass = (tab) => {
    setActive(tab);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,          
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,          
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,          
        },
      },
    ],
  };
  return (
    <div className="container">
      <div className="section-head">
        <div>
          <button
            className="back_btn"
            title="back"
            onClick={() => {
              navigate("/home");
            }}
          >
            <BackSvg />
          </button>
          <h3>Profile & Vendor Accounts</h3>
        </div>
        <div className="head_hraid">
          <span>{profileDetail?.hraMemberId}</span>
        </div>
      </div>
      <div className={active ? "profile_section active" : "profile_section"}>
        <div className="tabs_section">
          <div className="tabs_head">
            <span>
              {getInitialsFirstCharechter(
                profileDetail?.firstName,
                profileDetail?.lastName
              )}
            </span>
            <h3>{profileDetail?.firstName + " " + profileDetail?.lastName}</h3>
            <p>Store Owner</p>
      {profileDetail?.contactName &&      <p className="contact_person"><span>Contact person</span>{profileDetail?.contactName}</p>}
          </div>
          <Nav tabs onClick={() => { }}>
            <NavItem>
              <NavLink
                className={activeTab === "tab1" ? "active" : ""}
                onClick={() => {
                  toggleTab("tab1")
                  toggleaddclass(false)
                }}
                title="Profile & Vendors Account"
              >
                Profile & Vendors Account
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "tab2" ? "active" : ""}
                onClick={() => {
                  toggleTab("tab2")
                  toggleaddclass(false)
                }}
                title="Business & Store Details"
              >
                Business & Store Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "tab3" ? "active" : ""}
                onClick={() => {
                  toggleTab("tab3")
                  toggleaddclass(false)
                }}
                title="Representative  Details"
              >
                Representative Details
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div
          className={
            activeTab === "tab1"
              ? "content_section profile-vendors"
              : "content_section business-store"
          }
        >
          <TabContent activeTab={activeTab}>
            <TabPane tabId="tab1">
              {activeTab === "tab1" && (
                <AccountInfo
                  hraMemberId={hraMemberId}
                  profileDetail={profileDetail}
                  setActive={setActive}
                  upadteProfileDetailRequest={upadteProfileDetailRequest}
                />
              )}
            </TabPane>
            <TabPane tabId="tab2">
              {activeTab === "tab2" && (
                <StoreDetails
                  upadteStoreDetailsRequest={upadteStoreDetailsRequest}
                  profileDetail={profileDetail}
                  setActive={setActive}
                />
              )}
            </TabPane>
            <TabPane tabId="tab3">
              {activeTab === "tab3" && (
                <>
                  {" "}
                  {
                    <>
                      <div className="owner_view_section tab-card mb-4">
                        <div className="tab_content_head">
                          <div>
                            <button className="back_btn" title="back" onClick={() => setActive(true)}>
                              <BackSvg />
                            </button>
                            <h4>Representative  Details</h4>
                          </div>
                          <div>
                            <div></div>
                          </div>
                        </div>
                        <div className="profile-field-list row">
                          <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                              <label>Representative Name</label>
                              <span>{representiveDetails?.userName}</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                              <label> Representative Contact Number</label>
                              <span>{representiveDetails?.contactNumber}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </>
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
      <div className="slider-client-logo">
        <h4>
            Some of our Partner Vendors
        </h4>
        <Slider {...settings}>
          <div>
            <img alt="Slider Image" src={sliderImg1} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg2} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg3} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg4} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg5} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg6} />
          </div>

          <div>
            <img alt="Slider Image" src={sliderImg7} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg8} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg9} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg10} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg11} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg12} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg13} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg14} />
          </div>
          <div>
            <img alt="Slider Image" src={sliderImg15} />
          </div>
        </Slider>
      </div>
    </div>
  );
};

const mapStateToProps = ({ profileData }) => {
  let profileDetail = profileData?.profileDetail?.details
    ? profileData.profileDetail.details
    : null;
  let representiveDetails = profileData?.profileDetail?.representiveDetails
    ? profileData.profileDetail.representiveDetails
    : null;
  return {
    profileDetail: profileDetail,
    representiveDetails: representiveDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profileDetailRequest: (data) => dispatch(profileDetailRequest(data)),
    upadteProfileDetailRequest: (data) => {
      dispatch(upadteProfileDetailRequest(data));
    },
    upadteStoreDetailsRequest: (data) =>
      dispatch(upadteStoreDetailsRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
