import React from "react";
import laptop from "../../../src/assets/images/laptop.png";
export default function PageNotFound404() {
  return (
    <>
      <div className="wrapper error_wrapper">
        <main className="container">
          <div className="row error_main">
            <div className="col-md-4 errorcontent">
              <article>
                <h1>OOPS! You Don't want to be here...</h1>
              </article>
            </div>
            <div className="col-md-8 laptop hidden-xs">
              <img src={laptop} alt="laptop" />
              <div className="glitch">404</div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
