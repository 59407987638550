export const GET_INTERESTED_MEMBER_LIST_REQUEST = "GET_INTERESTED_MEMBER_LIST_REQUEST"
export const GET_INTERESTED_MEMBER_LIST_SUCCESS = "GET_INTERESTED_MEMBER_LIST_SUCCESS"
export const GET_VENDER_LIST_REQUEST = "GET_VENDER_LIST_REQUEST"
export const GET_VENDER_LIST_SUCCESS = "GET_VENDER_LIST_SUCCESS"
export const ADD_VENDER_REQUEST = "ADD_VENDER_REQUEST"
export const ADD_VENDER_SUCCESS = "ADD_VENDER_SUCCESS"
export const CLEAR_VENDER_REQ = "CLEAR_VENDER_REQ"
export const UPDATE_VENDER_REQUEST = "UPDATE_VENDER_REQUEST"
export const UPDATE_VENDER_SUCCESS = "UPDATE_VENDER_SUCCESS"
export const REMOVE_VENDER_REQUEST = "REMOVE_VENDER_REQUEST"
export const REMOVE_VENDER_SUCCESS = "REMOVE_VENDER_SUCCESS"
export const DELETE_VENDER_REQUEST = "DELETE_VENDER_REQUEST"
export const DELETE_VENDER_SUCCESS = "DELETE_VENDER_SUCCESS"
export const GET_VENDER_DETAILS_REQUEST = "GET_VENDER_DETAILS_REQUEST"
export const GET_VENDER_DETAILS_SUCCESS = "GET_VENDER_DETAILS_SUCCESS"
export const APPROVE_MEMBER_REQUEST = 'APPROVE_MEMBER_REQUEST';
export const APPROVE_MEMBER_SUCCESS = 'APPROVE_MEMBER_SUCCESS';
export const REMOVE_IMAGE_REQUEST = "REMOVE_IMAGE_REQUEST"
export const REMOVE_IMAGE_SUCCESS = "REMOVE_IMAGE_SUCCESS"
export const GET_VENDOR_MEMBER_REQUEST = "GET_VENDOR_MEMBER_REQUEST"
export const GET_VENDOR_MEMBER_SUCCESS = "GET_VENDOR_MEMBER_SUCCESS"
export const ASSIGN_VENDOR_REQUEST = "ASSIGN_VENDOR_REQUEST";
export const ASSIGN_VENDOR_SUCCESS = "ASSIGN_VENDOR_SUCCESS";