import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BackSvg } from "../../../CommonSvg";
import Select from "react-select";
export default function CommonSortHeader(props) {
  const {
    PromotionsName,
    BackTo,
    sorting,
    arrangeInOrder,
    selectYear,
    selectedYear,
    backLatest,
    setSelectedYear,
    backLatestLink,
    filterItem,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const navigate = useNavigate();
  return (
    <>
      <div className="section-head promotion_section_head">
        <div>
          {BackTo && (
            <button
              className="back_btn"
              title="back"
              onClick={() => {
                navigate(BackTo);
              }}
            >
              <BackSvg />
            </button>
          )}
          <h3>{PromotionsName}</h3>
        </div>
        <div className="gap-2">
          {backLatest && (
            <button
              className="btn btn-primary btn-sm"
              title="View Latest Promotions"
              onClick={() => {
                navigate(backLatestLink);
              }}
            >
              {backLatest}
            </button>
          )}
          {sorting && (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H16.5C16.8978 0 17.2794 0.158035 17.5607 0.43934C17.842 0.720644 18 1.10218 18 1.5V3.586C17.9999 4.11639 17.7891 4.62501 17.414 5L12 10.414V17.838C12 18.0255 11.9521 18.2099 11.8608 18.3737C11.7695 18.5375 11.6379 18.6753 11.4783 18.7739C11.3188 18.8724 11.1368 18.9286 10.9494 18.9371C10.7621 18.9455 10.5757 18.9059 10.408 18.822L6.691 16.964C6.48337 16.8602 6.30875 16.7006 6.1867 16.5031C6.06466 16.3057 6.00001 16.0781 6 15.846V10.414L0.586 5C0.210901 4.62501 0.000113275 4.11639 0 3.586V1.5Z"
                    fill="currentColor"
                  />
                </svg>{" "}
                Filter By
              </DropdownToggle>
              <DropdownMenu container="body" className="front-dropdown">
                <DropdownItem
                  className={!filterItem  ? "active" : ""}
                  onClick={() => {
                    arrangeInOrder();
                  }}
                >
                  All
                </DropdownItem>
                <DropdownItem
                  className={filterItem === "latest" ? "active" : ""}
                  onClick={() => {
                    arrangeInOrder("latest");
                  }}
                >
                  Latest
                </DropdownItem>
                <DropdownItem
                  className={filterItem === "expireSoon" ? "active" : ""}
                  onClick={() => {
                    arrangeInOrder("expireSoon");
                  }}
                >
                  Expire soon
                </DropdownItem>
                <DropdownItem
                  className={filterItem === "opted" ? "active" : ""}
                  onClick={() => {
                    arrangeInOrder("opted");
                  }}
                >
                  Opted Promotions
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
    
        </div>
      </div>
    </>
  );
}
