import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import SettingView from "./SettingView";
import Settings from "./Settings";

export default function SettingTab({
  dispatchData,
  setColumesRequest,
  settingView,
  setSettingView,
  colList,
  emailSettingData,
}) {
  const [tab, setTab] = useState("column");
  return (
    <div>
      <div className="d-flex justify-content-center mb-3">
        <Nav tabs className="email-configration">
          <NavItem>
            <NavLink
              className={tab === "column" ? "active" : ""}
              onClick={() => {
                setTab("column");
              }}
            >
              Table column configuration
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={tab === "email" ? "active" : ""}
              onClick={() => {
                setTab("email");
              }}
            >
              Email configuration
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={tab}>
        <TabPane tabId="column">
          <Settings
            onModalDismiss={() => {
              setSettingView(false);
            }}
            data={colList?.columnsSelected ? colList.columnsSelected : []}
            setColumesRequest={setColumesRequest}
            modalView={settingView}
            dispatchData={dispatchData}
          />
        </TabPane>
        <TabPane tabId="email">
          <SettingView
            emailSettingData={emailSettingData}
            onModalDismiss={() => {
              setSettingView(false);
            }}
          />
        </TabPane>
      </TabContent>
    </div>
  );
}
