import {
  CLEAR_MEMBER_FORGOT_PASSWORD_REPONSE,
  GET_MEMBER_FORGOT_LOGIN_REQUEST,
  GET_MEMBER_FORGOT_LOGIN_SUCCESS,
  GET_MEMBER_FORGOT_PASSWORD_REQUEST,
  GET_MEMBER_FORGOT_PASSWORD_SUCCESS,
  MEMBER_RESET_PASSWORD_REQUEST,
  MEMBER_RESET_PASSWORD_SUCCESS,
} from "./type";

const initialState = {
  forgotUser: null,
  error: null,
  loading: false,
  forgotLogin: null,
  resetPassword: null,
  forgotPassApiResponse: null,
};

const MemberForgotReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotUser: action.payload,
        loading: false,
        error: null,
        forgotPassApiResponse: null,
      };
    case GET_MEMBER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: null,
        loading: false,
        error: action?.payload?.status,
        forgotPassApiResponse: action?.payload,
      };

    case CLEAR_MEMBER_FORGOT_PASSWORD_REPONSE:
      return {
        ...state,
        forgotPassApiResponse: null,
      };

    case GET_MEMBER_FORGOT_LOGIN_REQUEST:
      return {
        ...state,
        forgotLogin: action.payload,
        loading: false,
        error: null,
      };
    case GET_MEMBER_FORGOT_LOGIN_SUCCESS:
      return {
        ...state,
        forgotLogin: null,
        loading: false,
        error: action.payload,
      };
    case MEMBER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        message: null,
        error: action.payload.error,
      };
    case MEMBER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default MemberForgotReducer;
