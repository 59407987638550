import React from "react";

export default function ViewCustomFields({ promotionsData }) {
  return (
    <>
      {promotionsData?.customFields?.map((data, index) => {
        if (data?.label && data?.value) {
          return (
            <div className="col-6" key={index}>
              <div className="hra-form-box">
                <label>{data.label}</label>
                <span>{data.value}</span>
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
