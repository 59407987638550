import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearMemberPromotionListOnScrollResponse,
  getLatestPromotionListRequest,
  getMemberPromotionListOnScrollRequest,
} from "../../../redux/frontend/promotion/action";

import AllPromotions from "../../../assets/images/all_promotions.webp";
import MyPromotions from "../../../assets/images/my_promotions.webp";
import MyRebates from "../../../assets/images/my_rebates.webp";
import MemberSupport from "../../../assets/images/member-support.webp";
import NewsLetters from "../../../assets/images/newsletters.webp";
import VendorPartners from "../../../assets/images/vendor_partners.webp";
import { getValueByKey } from "../../../utils/authUtils";
import { getOptInStatusRequest } from "../../../redux/admin/optinhistory/action";

function HomePage({
  getDetails,
  getList,
  getOptInStatusRequest,
  memberListOnScrollRequest,
  getmemberPromotionList,
  clearResp,
}) {
  // const loader = useRef(null);
  // const [page, setPage] = useState(0);
  // const [list, setList] = useState("");
  // const [promotionList, setPromotionList] = useState([]);
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) || "";

  // useEffect(() => {
  //   getDetails({ hraMemberId: hraMemberId });
  // }, []);

  // useEffect(() => {
  //   setPromotionList([]);
  //   if (getmemberPromotionList) {
  //     setPromotionList(getmemberPromotionList);
  //   }
  //   if (getList) {
  //     setList(getList);
  //   }
  // }, [getList, getmemberPromotionList]);

  // const handleObserver = useCallback((entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     setPage((prev) => prev + 1);
  //   }
  // }, []);
  // useEffect(() => {
  //   clearResp();
  // }, []);

  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);
  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  // const getSearchRequest = (newSearch = false, searchvalues, filterValues) => {
  //   let SearchRequest = {
  //     offset: newSearch ? 0 : getmemberPromotionList?.length,
  //     hraMemberId: hraMemberId,
  //   };
  //   return SearchRequest;
  // };

  // useEffect(() => {
  //   async function fetchData() {
  //     memberListOnScrollRequest(getSearchRequest(false, null));
  //   }
  //   fetchData();
  // }, [page]);

  // useEffect(() => {
  //   if (hraMemberId) {
  //     async function fetchData() {
  //       memberListOnScrollRequest(getSearchRequest(false, null));
  //     }
  //     fetchData();
  //   }
  // }, [hraMemberId, page]);

  // const SliderResponsive =
  //   list?.expireSoonPromotions?.filter(
  //     (product) =>
  //       product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true
  //   )?.length > 3
  //     ? productslist
  //     : productslistMinMum;
  // const sliderResponse =
  //   list?.latestPromotions?.filter(
  //     (product) =>
  //       product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !== true
  //   )?.length > 3
  //     ? productslist
  //     : productslistMinMum;
  // const modal = useModal();
  return (
    <>
      <div className="front-home">
        <h2>
          Welcome{" "}
          <span>
            {JSON.parse(getValueByKey("firstName")) +
              " " +
              JSON.parse(getValueByKey("lastName"))}
            !
          </span>
        </h2>
        <div className="container">
          <ul>
            <li>
              <Link type="button" to="/promotion/all">
                <span>
                  <img alt="All Promotions" src={AllPromotions} />
                </span>
                All Promotions
              </Link>
            </li>
            <li>
              <Link type="button" to="/myPromotions">
                <span>
                  <img alt="My Promotions" src={MyPromotions} />
                </span>
                My Promotions
              </Link>
            </li>
            <li>
              <Link
                type="button"
                to="/rebate"
                // onClick={async () => {
                //   const response = await commonOopsBox(
                //     "Rebates under maintenance",
                //   );

                // }}
              >
                <span>
                  <img alt="My Rebates" src={MyRebates} />
                </span>
                My Rebates
              </Link>
            </li>
            <li>
              <Link type="button" to="/support">
                <span>
                  <img alt="Member Support" src={MemberSupport} />
                </span>
                Member Support
              </Link>
            </li>
            <li>
              <Link type="button" to="/newsletter">
                <span>
                  <img alt="Newsletter" src={NewsLetters} />
                </span>
                Newsletter
              </Link>
            </li>
            <li>
           
                <Link to="/vendor">
                  <span>
                    <img alt="Vendor & Partners" src={VendorPartners} />
                  </span>
                  Vendor Program
                  {/* Vendor & Partners */}
                </Link>

            </li>
          </ul>
        </div>
      </div>
      {/* <VendorModal modal={modal} /> */}
      {/* =========Old Code=========*/}
      {/* <div className="promotion_slide">
        <Slider {...settings}>
          {list?.latestPromotions
            ?.filter(
              (product) =>
                product.PromotionalOffer?.InterestedMemberOffers[0]
                  ?.isActive !== true
            )
            ?.map((item, index) => (
              <div key={index}>
                <img
                  alt="promotion"
                  className="promotion_slide_img"
                  src={getCardImage(
                    hraMemberId ? item?.PromotionalOffer : item
                  )}
                />
              </div>
            ))}
        </Slider>
      </div> */}

      {/* <section className="product-cards-main latest_promotion_card">
        <div className="container">
          <div
            className={
              list?.latestPromotions?.filter(
                (product) =>
                  product.PromotionalOffer?.InterestedMemberOffers[0]
                    ?.isActive !== true
              )?.length > 0
                ? "title-box"
                : "title-box remove"
            }
          >
            <h3>Latest Promotions</h3>
            {list?.latestPromotions?.filter(
              (product) =>
                product.PromotionalOffer?.InterestedMemberOffers[0]
                  ?.isActive !== true
            )?.length > 0 && (
              <Link
                to="/promotion/latest"
                title="View all"
                className="link-primary"
              >
                View all
              </Link>
            )}
          </div>
          {list?.latestPromotions?.filter(
            (product) =>
              product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !==
              true
          )?.length > 0 ? (
            <Slider {...sliderResponse}>
              {list.latestPromotions
                ?.filter(
                  (product) =>
                    product.PromotionalOffer?.InterestedMemberOffers[0]
                      ?.isActive !== true
                )
                ?.map((item, index) => {
                  return (
                    <>
                      <CommonPromotionCard
                        key={index}
                        item={item}
                        getOptInStatusRequest={getOptInStatusRequest}
                        ApiUpdateReq={"membersLatestPromotions"}
                      />
                    </>
                  );
                })}
            </Slider>
          ) : (
            <>
              <NoPromotionFound />
            </>
          )}
        </div>
      </section> */}
      {/* <section className="product-cards-main expire_soon_card">
        <div className="container">
          <div
            className={
              list?.expireSoonPromotions?.filter(
                (product) =>
                  product.PromotionalOffer?.InterestedMemberOffers[0]
                    ?.isActive !== true
              )?.length > 0
                ? "title-box"
                : "title-box remove"
            }
          >
            <h3>Expire Soon</h3>
            {list?.expireSoonPromotions?.filter(
              (product) =>
                product.PromotionalOffer?.InterestedMemberOffers[0]
                  ?.isActive !== true
            )?.length > 0 && (
              <Link
                to="/promotion/expiresoon"
                title="View all"
                className="link-primary"
              >
                View all
              </Link>
            )}
          </div>
          {list?.expireSoonPromotions?.filter(
            (product) =>
              product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !==
              true
          )?.length > 0 ? (
            <Slider {...SliderResponsive}>
              {list.expireSoonPromotions
                ?.filter(
                  (product) =>
                    product.PromotionalOffer?.InterestedMemberOffers[0]
                      ?.isActive !== true
                )
                ?.map((item, index) => (
                  <CommonPromotionCard
                    key={index}
                    item={item}
                    getOptInStatusRequest={getOptInStatusRequest}
                    ApiUpdateReq={"memberPromotionListScroll"}
                  />
                ))}
            </Slider>
          ) : (
            <NoPromotionFound />
          )}
        </div>
      </section> */}

      {/* <section className="product-cards-main promotion_card_list">
        <div className="container">
          <div
            className={
              getmemberPromotionList?.filter(
                (product) =>
                  product.PromotionalOffer?.InterestedMemberOffers[0]
                    ?.isActive !== true
              )?.length > 0
                ? "title-box"
                : "title-box remove"
            }
          >
            <h3>Promotions</h3>
            {getmemberPromotionList?.filter(
              (product) =>
                product.PromotionalOffer?.InterestedMemberOffers[0]
                  ?.isActive !== true
            )?.length > 0 && (
              <Link
                to="/promotion/all"
                title="View all"
                className="link-primary"
              >
                View all
              </Link>
            )}
          </div>
          {getmemberPromotionList?.filter(
            (product) =>
              product.PromotionalOffer?.InterestedMemberOffers[0]?.isActive !==
              true
          )?.length > 0 ? (
            <ul>
              {getmemberPromotionList
                ?.filter(
                  (product) =>
                    product.PromotionalOffer?.InterestedMemberOffers[0]
                      ?.isActive !== true
                )
                ?.map((item, index) => {
                  return (
                    <li key={index}>
                      <CommonPromotionCard
                        key={index}
                        item={item}
                        getOptInStatusRequest={getOptInStatusRequest}
                        ApiUpdateReq={"memberPromotionListScroll"}
                      />
                    </li>
                  );
                })}
            </ul>
          ) : (
            <NoPromotionFound />
          )}
          <div ref={loader} />
        </div>
      </section> */}
      {/* =========End Old Code=========*/}
    </>
  );
}

const mapStateToProps = ({ MemberPromotions }) => {
  let getList = [];
  if (
    MemberPromotions?.latestPromotion &&
    MemberPromotions?.latestPromotion?.data !== undefined
  ) {
    getList = MemberPromotions.latestPromotion.data;
  }
  return {
    getList: getList,
    getmemberPromotionList: MemberPromotions?.memberPromotionScrollData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOptInStatusRequest: (data) => dispatch(getOptInStatusRequest(data)),
    getDetails: (data) => dispatch(getLatestPromotionListRequest(data)),
    memberListOnScrollRequest: (data) =>
      dispatch(getMemberPromotionListOnScrollRequest(data)),
    clearResp: (data) =>
      dispatch(clearMemberPromotionListOnScrollResponse(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
