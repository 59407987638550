import * as type from "./type";

const initialState = {
    getCity: null,
    getState: null,
    getCounty: null,
    getRegion: null,
    getTerritory: null
};

const LocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_CITY_LIST_REQUEST:
            return { ...state, getCity: null };
        case type.GET_CITY_LIST_SUCCESS:
            return { ...state, getCity: action.payload };
        case type.GET_STATE_LIST_REQUEST:
            return { ...state, getState: null };
        case type.GET_STATE_LIST_SUCCESS:
            return { ...state, getState: action.payload };
        case type.GET_COUNTY_LIST_REQUEST:
            return { ...state, getCounty: null };
        case type.GET_COUNTY_LIST_SUCCESS:
            return { ...state, getCounty: action.payload };
        case type.GET_TERRITORY_LIST_REQUEST:
            return { ...state, getTerritory: null };
        case type.GET_TERRITORY_LIST_SUCCESS:
            return { ...state, getTerritory: action.payload };
        default:
            return {...state}
    }
};
export default LocationReducer;
