import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_VENDER_REQUEST, LIKE_VENDER_REQUEST } from "./type";
import { clearResp, getVendorRequest, getVendorSuccess, likeVendorSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";
import { getVendorDetailsRequest } from "../../admin/vendor/action";


function* vendorDataList({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/memberVendorList", data);
        if (res.status) {
            yield put(getVendorSuccess(res.data));
        } else {
            yield put(getVendorSuccess([]));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        yield put(getVendorSuccess([]));
        handleApiCallException(error)
    }
}

function* likeVender({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/interestedVendor ", data);
        if (res.status) {
            ApiResponseMessage(res.message, SUCCESS)
            yield put(likeVendorSuccess(res));
            yield put(getVendorDetailsRequest({ vendorId: data.vendorId }));
            if(data?.listAPi){
                yield put(clearResp());
            yield put(getVendorRequest(data.listAPi));
            }
        } else {
            yield put(likeVendorSuccess(res));
            ApiResponseMessage(res.message, DANGER);
            handleApiCallException(res.message)
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        yield put(likeVendorSuccess());
        handleApiCallException(error)
    }
}

function* watchVendorRequest() {
    yield takeLatest(GET_VENDER_REQUEST, vendorDataList);
    yield takeLatest(LIKE_VENDER_REQUEST, likeVender);
}

function* VendorUserSaga() {
    yield all([fork(watchVendorRequest)]);
}

export default VendorUserSaga;
