import {
    ADD_NEWS_LETTER_REQUEST,
    ADD_NEWS_LETTER_SUCCESS,
    ASSIGN_NEWS_LETTER_REQUEST,
    ASSIGN_NEWS_LETTER_SUCCESS,
    CLEAR_RESPONSE,
    DELETE_NEWS_LETTER_REQUEST,
    DELETE_NEWS_LETTER_SUCCESS,
    DETAILS_NEWS_LETTER_REQUEST,
    DETAILS_NEWS_LETTER_SUCCESS,
    EDIT_NEWS_LETTER_REQUEST,
    EDIT_NEWS_LETTER_SUCCESS,
    GET_NEWS_LETTER_SUCCESS,
    NEWS_LETTER_REQUEST,
    NEWS_LETTER_SUCCESS,
} from "./type";
import produce from "immer";
let promotionListMember = [];
const initialState = {
    addNewsLetter: null,
    newsLetter: null,
    newsLetterDetails: null,
    assignNewsLetter: null,
    deleteLetter: null,
    newsLetterList: []
};

const NewsLetterReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case ADD_NEWS_LETTER_REQUEST:
                return { ...state, addNewsLetter: action.payload };
            case ADD_NEWS_LETTER_SUCCESS:
                return { ...state, addNewsLetter: action.payload };
            case EDIT_NEWS_LETTER_REQUEST:
                return { ...state, editNewsLetter: action.payload };
            case EDIT_NEWS_LETTER_SUCCESS:
                return { ...state, editNewsLetter: action.payload };
            case NEWS_LETTER_REQUEST:
                return { ...state, newsLetter: action.payload };
            case NEWS_LETTER_SUCCESS:
                return { ...state, newsLetter: action.payload };
            case DETAILS_NEWS_LETTER_REQUEST:
                return { ...state, newsLetterDetail: action.payload };
            case DETAILS_NEWS_LETTER_SUCCESS:
                return { ...state, newsLetterDetail: action.payload };
            case GET_NEWS_LETTER_SUCCESS:
                if (!promotionListMember.includes(action?.payload?.offset)) {
promotionListMember.push(action?.payload?.offset);
                    draft.newsLetterList = draft.newsLetterList?.concat(
                        action.payload?.data?.list
                    );
                }
                break;
            case GET_NEWS_LETTER_SUCCESS:
                return { ...state, newsLetterList: action.payload };
            case DELETE_NEWS_LETTER_REQUEST:
                return { ...state, deleteLetter: action.payload };
            case DELETE_NEWS_LETTER_SUCCESS:
                return { ...state, deleteLetter: action.payload };
            case ASSIGN_NEWS_LETTER_REQUEST:
                return { ...state, assignNewsLetter: action.payload };
            case ASSIGN_NEWS_LETTER_SUCCESS:
                return { ...state, assignNewsLetter: action.payload };
            case CLEAR_RESPONSE: {
                draft.newsLetterList = [];
                promotionListMember = [];
                break;
            }
            default:
                return state;
        }
    });
};

export default NewsLetterReducer;
