import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { createLoadingSelector } from "../../../redux/shared/selector";
function Layout(props) {
  const location = useLocation();
  return (
      <div
        className={
          location.pathname === "/admin/home"
            ? "main-body backend dashboard_header"
            : "main-body backend"
        }
      >
        {props?.loadingSelector && (
          <div className="preloader-main">
            <div className="preloader-wrapper">
              <div className="loader">
                <div className="dot"></div>
              </div>
              <div className="loader">
                <div className="dot"></div>
              </div>
              <div className="loader">
                <div className="dot"></div>
              </div>
              <div className="loader">
                <div className="dot"></div>
              </div>
              <div className="loader">
                <div className="dot"></div>
              </div>
              <div className="loader">
                <div className="dot"></div>
              </div>
            </div>
          </div>
        )}
        <Header />
        <main className="main-inner">
          <Outlet />
        </main>
        <Footer />
      </div>
  );
}

const mapStatetoProps = createStructuredSelector({
  loadingSelector: createLoadingSelector(),
});

export default connect(mapStatetoProps)(Layout);
