import { Field, Formik, Form } from "formik";
import React, { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../../i18n/translator";
import { Button, FormGroup, Label } from "reactstrap";
import { connect } from "react-redux";
import { settingRequest } from "../../../../redux/admin/setting/action";

function SettingView({ settingRequest, emailSettingData, onModalDismiss }) {
  const validator = useRef(new SimpleReactValidator());
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      settingRequest(values);
      onModalDismiss()
    } else {
      validator.current.showMessages();
    }
  };
  const initialValues = {
    hours: emailSettingData?.hours || "",
    email: emailSettingData?.email || "",
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <>
          <Form className="form-horizontal form-login">
            <div className="category-head">
              <FormGroup className=" mb-3">
                <Field
                  type="number"
                  id="hours"
                  name="hours"
                  className="form-control"
                  placeholder="Enter Hours"
                  min="1" 
                  step="1" 
                  pattern="\d*" 
                  inputMode="numeric"
                  onKeyDown={(e) => {
                    if (!/\d/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                      e.preventDefault();
                    }
                  }}
                />
                <Label className="form-label" htmlFor="hours">
                  Pending Approval Hours
                  <span className="text-danger required">*</span>
                </Label>
                {validator.current.message(
                  __t("validations.vendor.hours"),
                  values.hours,
                  "required",
                  {
                    messages: {
                      required: __t("validations.vendor.hours"),
                    },
                  }
                )}
              </FormGroup>

              <FormGroup className=" mb-3">
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                />
                <Label className="form-label" htmlFor="email">
                  Support Email Address
                  <span className="text-danger required">*</span>
                </Label>
                {validator.current.message(
                  __t("validations.user.email"),
                  values.email,
                  "required|emails",
                  {
                    messages: {
                      required: __t("validations.user.email"),
                    },
                  }
                )}
              </FormGroup>
            </div>

            <div className="btn-box">
              <Button
                color="primary"
                className=""
                title="user data"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
                {/* {IsEditData ? "Update" : ""} */}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    settingRequest: (data) => dispatch(settingRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingView);
