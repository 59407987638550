import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_NEWS_LETTER_REQUEST,
  ASSIGN_NEWS_LETTER_REQUEST,
  DELETE_NEWS_LETTER_REQUEST,
  DETAILS_NEWS_LETTER_REQUEST,
  EDIT_NEWS_LETTER_REQUEST,
  GET_NEWS_LETTER_REQUEST,
  NEWS_LETTER_REQUEST,
} from "./type";
import {
  addNewsLetterSuccess,
  assignNewsLetterSuccess,
  deleteNewsLetterSuccess,
  detailsNewsLetterSuccess,
  editNewsLetterSuccess,
  getNewsLetterSuccess,
  newsLetterRequest,
  newsLetterSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* AddNewsLetter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/newsletter", data);
    if (response.status) {
      ApiResponseMessage(response.message, SUCCESS);
      yield put(addNewsLetterSuccess(response));
    } else {
      yield put(addNewsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    addNewsLetterSuccess([]);
    handleApiCallException(error);
  }
}
function* EditNewsLetter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/editNewsletter", data);
    if (response.status) {
      ApiResponseMessage(response.message, SUCCESS);
      yield put(editNewsLetterSuccess(response));
    } else {
      yield put(editNewsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    editNewsLetterSuccess([]);
    handleApiCallException(error);
  }
}

function* NewsLetter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/newsletterList", data);
    if (response.status) {
      yield put(newsLetterSuccess(response.data));
    } else {
      yield put(newsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    newsLetterSuccess([]);
    handleApiCallException(error);
  }
}
function* DeatilNewsLetter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/newsLetterDetails", data);
    if (response.status) {
      yield put(detailsNewsLetterSuccess(response.data));
    } else {
      yield put(detailsNewsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    detailsNewsLetterSuccess   ([]);
    handleApiCallException(error);
  }
}
function* GetNewsLetter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/membersNewsletterList", data);
    if (response.status) {
      yield put(getNewsLetterSuccess(response.data, data?.offset));
    } else {
      yield put(getNewsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    getNewsLetterSuccess([]);
    handleApiCallException(error);
  }
}
function* DeleteNewsLetter({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/status", data);
    if (response.status) {
      yield put(deleteNewsLetterSuccess(response.data));
        yield put(newsLetterRequest(data.dispatchData));
    } else {
      yield put(deleteNewsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    newsLetterSuccess([]);
    handleApiCallException(error);
  }
}

function* assignNewsLetter({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/allocateNewsletters",
      data.AssignedValues
    );
    if (response.status) {
      yield put(assignNewsLetterSuccess(response));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(newsLetterRequest(data.dispatchData));
    } else {
      yield put(assignNewsLetterSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* watchNewsLetterRequest() {
  yield takeLatest(ADD_NEWS_LETTER_REQUEST, AddNewsLetter);
  yield takeLatest(EDIT_NEWS_LETTER_REQUEST, EditNewsLetter);
  yield takeLatest(NEWS_LETTER_REQUEST, NewsLetter);
  yield takeLatest(DETAILS_NEWS_LETTER_REQUEST, DeatilNewsLetter);
  yield takeLatest(GET_NEWS_LETTER_REQUEST, GetNewsLetter);
  yield takeLatest(DELETE_NEWS_LETTER_REQUEST, DeleteNewsLetter);
  yield takeLatest(ASSIGN_NEWS_LETTER_REQUEST, assignNewsLetter);
}

function* NewsLetterSaga() {
  yield all([fork(watchNewsLetterRequest)]);
}

export default NewsLetterSaga;
