import { GET_LATEST_NOTIFICATION_REQUEST, GET_LATEST_NOTIFICATION_SUCCESS, GET_READ_NOTIFICATION_REQUEST, GET_READ_NOTIFICATION_SUCCESS, PROFILE_DETAILS_REQUEST, PROFILE_DETAILS_SUCCESS, STORE_DETAILS_REQUEST, STORE_DETAILS_SUCCESS, STORE_DETAIL_REQUEST, STORE_DETAIL_SUCCESS, UPDATE_PROFILE_DETAILS_REQUEST, UPDATE_PROFILE_DETAILS_SUCCESS, UPDATE_STORE_DETAILS_REQUEST, UPDATE_STORE_DETAILS_SUCCESS, UPDATE_VENDOR_ACCOUNT_DETAILS_REQUEST, UPDATE_VENDOR_ACCOUNT_DETAILS_SUCCESS } from "./type";

const initialState = {
    StoreDetail: null,
    profileDetail: null,
    ownStoreDetail: null,
    updateProfileDetail: null,
    notificationList: null,
    notificationRead: null,
    updateVendorDetail: null,

};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_DETAILS_REQUEST:
            return {
                ...state,
                StoreDetail: null
            };
        case STORE_DETAILS_SUCCESS:
            return {
                ...state,
                StoreDetail: action.payload
            };
        case PROFILE_DETAILS_REQUEST:
            return {
                ...state,
                profileDetail: null
            };
        case PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                profileDetail: action.payload
            };
        case STORE_DETAIL_REQUEST:
            return {
                ...state,
                ownStoreDetail: null
            };
        case STORE_DETAIL_SUCCESS:
            return {
                ...state,
                ownStoreDetail: action.payload
            };
        case UPDATE_PROFILE_DETAILS_REQUEST:
            return {
                ...state,
                updateProfileDetail: action.payload
            };
        case UPDATE_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                updateProfileDetail: action.payload
            };
        case UPDATE_STORE_DETAILS_REQUEST:
            return {
                ...state,
                updateProfileDetail: action.payload
            };
        case UPDATE_STORE_DETAILS_SUCCESS:
            return {
                ...state,
                updateProfileDetail: action.payload
            };
        case GET_LATEST_NOTIFICATION_REQUEST:
            return {
                ...state,
                notificationList: null,
            };
        case GET_LATEST_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationList: action.payload,
            };
        case GET_READ_NOTIFICATION_REQUEST:
            return {
                ...state,
                notificationRead: null,
            };
        case GET_READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationRead: action.payload,
            };
        case UPDATE_VENDOR_ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                updateVendorDetail: action.payload
            };
        case UPDATE_VENDOR_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                updateVendorDetail: action.payload
            };
        default:
            return state;
    }
};

export default profileReducer;
