import React from "react";
import {
  CloseSvg,
  EmailSvg2,
  FilterSvg,
  FiltericonSvg,
  Phone_Svg,
  RepresentativeSvg,
  RightSvg,
  SearchSvg,
  UserSvg,
} from "../../../components/CommonSvg";
import { connect } from "react-redux";
import {
  approveMemberRequest,
  getInterestedMemberListRequest,
} from "../../../redux/admin/vendor/action";
import { useEffect } from "react";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";
import { useState } from "react";
import { Button } from "reactstrap";
import Nodata from "../../../assets/images/no_data_found001.webp";
import { Dropdown } from "react-bootstrap";
import { commonConfirmBox } from "../../../helper/messagehelpers";

function IntrestedMember({
  getInterestedMemberList,
  id,
  vendorIntrestedList,
  approveMember,
  totalRecords,
  setMemberCount,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchDataOpt, setSearchDataOpt] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [dataPerPage, setDataPerPage] = useState(10);
  const dispatchData = {
    vendorId: id,
    searchText: searchDataOpt,
    status: status,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };
  useEffect(() => {
    if (id) {
      getInterestedMemberList(dispatchData);
    }
  }, [id, dataPerPage, currentPage, searchDataOpt, status]);
  const filterStatus = [
    {
      value: "",
      label: "All",
    },
    {
      value: "APPROVED",
      label: "Approved",
    },
    {
      value: "PENDING",

      label: "Pending",
    },
  ];
  const handelApplayFilter = (value) => {
    setStatus(value);
  };
  useEffect(() => {
    if (totalRecords) {
      setMemberCount({ id: id, count: totalRecords });
    } else {
      setMemberCount({ id: id, count: 0 });
    }
  }, [totalRecords]);
  return (
    <>
      <div className="interested_head">
        <h3>Interested Members</h3>
        <div>
          <div className="search_box">
            <Button
              color="link"
              className="p-0"
              onClick={() => {
                setSearchDataOpt(search);
              }}
            >
              <SearchSvg />
            </Button>

            {search && (
              <Button
                color="link"
                className="p-0 close-btn"
                onClick={() => {
                  setSearch("");
                  setSearchDataOpt("");
                }}
              >
                <CloseSvg />
              </Button>
            )}
            <input
              name="searchbox"
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value?.length <= 0) {
                  setSearch("");
                  setSearchDataOpt("");
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchDataOpt(search);
                }
              }}
              placeholder="Search box"
              value={search}
            />
          </div>
          <Dropdown
            className="my-tooltip filter_btn"
            data-tooltip-content={"Apply Filter"}
            // onClick={handelShowFIlter}
          >
            <Dropdown.Toggle id="dropdown-basic">
              <FiltericonSvg />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {filterStatus.map((data) => {
                return (
                  <Dropdown.Item
                    className={status === data.value ? "Active" : ""}
                    onClick={() => handelApplayFilter(data.value)}
                  >
                    {data.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <ul className="vendor_row_head interested_row_head">
        <li className="interested_id">
          <span>HRA Id</span>
        </li>
        <li className="interested_user">
          <span>User Name</span>
        </li>
        <li className="interested_email">
          <span>Email</span>
        </li>
        <li className="interested_phone">
          <span>Phone No.</span>
        </li>
        <li className="interested_representative">
          <span>Representative</span>
        </li>
        <li className="store-hra-action">
          <span></span>
        </li>
      </ul>
      {vendorIntrestedList.map((user) => {
        return (
          <ul
            className={
              user.schedularStatus
                ? "interested_row rejected_row"
                : user.status
                ? "interested_row approverd_row"
                : "interested_row "
            }
          >
            <li className="store_hra_id">
              <span>{user.hraMemberId}</span>
            </li>
            <li>
              <p>
                <UserSvg />
                <span>
                  {user.member.firstName + " " + user.member.lastName}
                </span>
              </p>
            </li>
            <li className="store-hra-mail">
              <p>
                <EmailSvg2 />
                <span>{user.member.emailAddress}</span>
              </p>
            </li>
            <li className="store-hra-contact">
              <p>
                <Phone_Svg />
                <span>{user.member.phoneNumberMobileNumber}</span>
              </p>
            </li>
            <li className="store-hra-representative">
              <p>
                <RepresentativeSvg />
                <span>{user.member.managerTerritory}</span>
              </p>
            </li>
            <li className="store-hra-action">
              {user.status ? (
                <Button color="primary" className="approve_btn approved_show">
                  <RightSvg />
                  Approved
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="approve_btn"
                  onClick={async () => {
                    const response = await commonConfirmBox(
                      `Are you sure you want to approve this member?`
                    );
                    if (response) {
                      approveMember({
                        data: {
                          vendorId: id,
                          status: true,
                          hraMemberId: user.hraMemberId,
                        },
                        dispatchData,
                      });
                    }
                  }}
                >
                  <RightSvg />
                  Approve
                </Button>
              )}
              {/* <Button className="reject_btn">
                  <CloseSvg/>
                  Reject
                </Button> */}
            </li>
          </ul>
        );
      })}
      {totalRecords > 0 ? (
        <CommonPagination
          dataPerPage={dataPerPage}
          totalData={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
          direction={"up"}
        />
      ) : (
        <div className="d-flex justify-content-center">
          <img alt="NO Data Found" className="no_data_found" src={Nodata} />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ vendor }) => {
  let vendorIntrestedList =
    vendor.vendorIntrestedList?.list?.length > 0
      ? vendor.vendorIntrestedList.list
      : [];
  let totalRecords = vendor.vendorIntrestedList?.totalRecords;
  return { vendorIntrestedList, totalRecords };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInterestedMemberList: (data) =>
      dispatch(getInterestedMemberListRequest(data)),
    approveMember: (data) => dispatch(approveMemberRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IntrestedMember);
