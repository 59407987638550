import * as type from "./type";

export const createAchRequest = (data) => {
    return {
        type: type.CREATE_ACH_REQUEST,
        payload: data,
    };
};
export const createAchSuccess = (data) => {
    return {
        type: type.CREATE_ACH_SUCCESS,
        payload: data,
    };
};

export const viewAchRequest = (data) => {
    return {
        type: type.VIEW_ACH_REQUEST,
        payload: data,
    };
};
export const viewAchSuccess = (data) => {
    return {
        type: type.VIEW_ACH_SUCCESS,
        payload: data,
    };
};

export const removeAchRequest = (data) => {
    return {
        type: type.REMOVE_ACH_REQUEST,
        payload: data,
    };
};
export const removeAchSuccess = (data) => {
    return {
        type: type.REMOVE_ACH_SUCCESS,
        payload: data,
    };
};
