import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_DEFALT_PATH_REQUEST, GET_ROLE_LIST_REQUEST } from "./type";
import { getDefaultPathSuccess, getRoleListSuccess } from "./action";

import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";
import { handleApiCallException } from "../../shared/action";


function* roleList({ payload: data }) {
  try {
    const res = yield call(invokeApi, "get", "/roleList");
    if (res.status) {
      yield put(getRoleListSuccess(res.data));
    } else {
      yield put(getRoleListSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message)
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error)
  }
}


function* setPath({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/favouriteMenu", data);
        if (res.status) {
      yield put(getDefaultPathSuccess(res.data));
      ApiResponseMessage(res.message, SUCCESS);
    } else {
      yield put(getDefaultPathSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message)
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error)
  }
}
function* watchRoleRequest() {
  yield takeLatest(GET_ROLE_LIST_REQUEST, roleList);
  yield takeLatest(GET_DEFALT_PATH_REQUEST, setPath);
}

function* RoleSaga() {
  yield all([fork(watchRoleRequest)]);
}

export default RoleSaga;
