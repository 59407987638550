export const ADD_NEWS_LETTER_REQUEST = "ADD_NEWS_LETTER_REQUEST";
export const ADD_NEWS_LETTER_SUCCESS = "ADD_NEWS_LETTER_SUCCESS";
export const EDIT_NEWS_LETTER_REQUEST = "EDIT_NEWS_LETTER_REQUEST";
export const EDIT_NEWS_LETTER_SUCCESS = "EDIT_NEWS_LETTER_SUCCESS";
export const NEWS_LETTER_REQUEST = "NEWS_LETTER_REQUEST";
export const NEWS_LETTER_SUCCESS = "NEWS_LETTER_SUCCESS";
export const GET_NEWS_LETTER_REQUEST = "GET_NEWS_LETTER_REQUEST";
export const GET_NEWS_LETTER_SUCCESS = "GET_NEWS_LETTER_SUCCESS";
export const DELETE_NEWS_LETTER_REQUEST = "DELETE_NEWS_LETTER_REQUEST";
export const DELETE_NEWS_LETTER_SUCCESS = "DELETE_NEWS_LETTER_SUCCESS";
export const ASSIGN_NEWS_LETTER_REQUEST = "ASSIGN_NEWS_LETTER_REQUEST";
export const ASSIGN_NEWS_LETTER_SUCCESS = "ASSIGN_NEWS_LETTER_SUCCESS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const DETAILS_NEWS_LETTER_REQUEST = "DETAILS_NEWS_LETTER_REQUEST";
export const DETAILS_NEWS_LETTER_SUCCESS = "DETAILS_NEWS_LETTER_SUCCESS";