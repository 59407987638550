import { getFileNameFromURL } from "../../../utils/generalUtils";

export const achInitialState = (values) => {
  const ImageData = values?.achManagement?.map((d) => {
    return {
      fileNames: getFileNameFromURL(d.currentDocument),
      imageUrl: d.currentDocument,
    };
  });
  return {
    routingNumber: values?.routingNumber ? values.routingNumber : "",
    accountNumber: values?.accountNumber ? values.accountNumber : "",
    chequeNumber: values?.chequeNumber ? values.chequeNumber : "",
    imageFile: ImageData ? ImageData : "",
    hraMemberId: values?.hraMemberId ? values.hraMemberId : "",
    bankName: values?.bankName ? values.bankName : "",
  };
};
export const editAchInitialState = (values) => {
  const ImageData = values?.achManagement?.map((d) => {
    return {
      fileNames: getFileNameFromURL(d.previousDocument),
      imageUrl: d.previousDocument,
    };
  });

  return {
    routingNumber: values?.previousRoutingNumber
      ? values.previousRoutingNumber
      : "",
    accountNumber: values?.previousAccountNumber
      ? values.previousAccountNumber
      : "",
    chequeNumber: values?.previousChequeNumber
      ? values.previousChequeNumber
      : "",
    imageFile: ImageData ? ImageData : "",
    hraMemberId: values?.hraMemberId ? values.hraMemberId : "",
    bankName: values?.previousBankName ? values.previousBankName : "",
  };
};
