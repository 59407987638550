import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_DOCUMENT_LIST_REQUEST } from "./type";
import { getDocumentListSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { invokeApi } from "../../../helper/axiosHelper";

function* getdocumentList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/documentList`, data);
    if (response.status) {
      yield put(getDocumentListSuccess(response.data.list));
    } else {
      yield put(getDocumentListSuccess(response));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* watchAgreementFormsRequest() {
  yield takeLatest(GET_DOCUMENT_LIST_REQUEST, getdocumentList);
}

function* AgreementFormsSaga() {
  yield all([fork(watchAgreementFormsRequest)]);
}

export default AgreementFormsSaga;
