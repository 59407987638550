import * as type from "./type";

export const getCategoryListRequest = (data) => {
  return {
    type: type.GET_CATEGORY_LIST_REQUEST,
    payload: data
  };
};
export const getCategoryListSuccess = (data) => {
  return {
    type: type.GET_CATEGORY_LIST_SUCCESS,
    payload: data,
  };
};
export const statusCategoryRequest = (data) => {
  return {
    type: type.STATUS_CATEGORY_REQUEST,
    payload: data,
  };
};
export const statusCategorySuccess = (data) => {
  return {
    type: type.STATUS_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const addCategoryRequest = (data) => {
  return {
    type: type.ADD_CATEGORY_REQUEST,
    payload: data,
  };
};
export const addCategorySuccess = (data) => {
  return {
    type: type.ADD_CATEGORY_SUCCESS,
    payload: data,
  };
};
