import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { CHANGE_STATUS_ACH_REQUEST, GET_ACH_LIST_REQUEST } from "./type";
import { invokeApi } from "../../../helper/axiosHelper";
import { changeStatusAchSuccess, getAchListRequest, getAchListSuccess } from "./action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { handleApiCallException } from "../../shared/action";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
function* AchList({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/listAchManagement", data)
        if (response.status) {
            yield put(getAchListSuccess(response.data));
        } else {
            yield put(getAchListSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}
function* AchStatus({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/statusAchManagement", data.value)
        if (response.status) {
            yield put(changeStatusAchSuccess(response.data));
            ApiResponseMessage(response.message, SUCCESS);
            yield put(getAchListRequest(data.dispatchData))
        } else {
            yield put(changeStatusAchSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}
function* watchAchRequest() {
    yield takeLatest(GET_ACH_LIST_REQUEST, AchList);
    yield takeLatest(CHANGE_STATUS_ACH_REQUEST, AchStatus);
}

function* AchListSaga() {
    yield all([fork(watchAchRequest)]);
}

export default AchListSaga;